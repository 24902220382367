import { useState, useEffect } from 'react';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDefaults } from '../helpers/enums';
import { useDispatch } from 'react-redux';
import { setAllAreas, setKeepAliveRequest, setSubscribeAreasRequest } from '../store/reducers/AccountReducer';
import { mqttDisconnectRequest } from '../utils/connection/mqttConnection';
import { useAccountStatus } from '../store/AccountStatusStore';
import { useOrganizations } from '../store/OrganizationsStore';
import { useNotificationStore } from '../store/CreateNotificationStore';
import { useCustomerOrgLocations } from '../store/CustomerOrgLocationsStore';
import { useLoggedInUserData } from '../store/LoggedInAccountStore';
import { resetPlatformInfo } from '../store/reducers/StreamingReducer';
import { Utils } from '../helpers';
import { resetCustomerOrgData } from '../store/OrganizationsStoreIDB';

export const SetAuthToken = (token) => {
  const { keycloak } = useKeycloak();
  const [url, setUrl] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logOutStatus, setLogOutStatus] = useState(false);
  const [accountStatus, setAccountStatus] = useState(false);
  // const resetCustomerOrgData = useOrganizations(
  //   (state) => state.resetCustomerOrgData
  // );
  const resetNotificationData = useNotificationStore(
    (state) => state.resetStepData
  );
  const resetCustomerOrgLocations = useCustomerOrgLocations(
    (state) => state.resetCustomerOrgLocations
  );
  const resetLoggedInUserData = useLoggedInUserData(
    (state) => state.resetLoggedInUserData
  );
  // const resetSelfAccount = useOrganizations(
  //   (state)=> state.resetSelfAccount
  // )
  const setIsActNotActivated = useAccountStatus(
    (state) => state.setIsActNotActivated
  );
  const setIsActNotEnabled = useAccountStatus(
    (state) => state.setIsActNotEnabled
  );

  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const setPingApiCallTime = useLoggedInUserData(
    (state) => state.setPingApiCallTime
  );

  useEffect(() => {
    if (logOutStatus) {
      setTimeout(() => {
        invalidateSessionApi();
      }, 1000);
    }
  }, [logOutStatus]);

  useEffect(() => {
    if (accountStatus) {
      setTimeout(() => {
        invalidateSessionApi();
      }, 1000);
    }
  }, [accountStatus]);

  useEffect(() => {
    let requestUrl = location.pathname.replace(/\/+$/, '');
    setUrl(requestUrl);
  }, [location]);

    // INVALIDATE API INTEGRATION
    const invalidateSessionApi = async () => {
      const reqBody = {
        refresh_token: keycloak?.refreshToken,
      };
      await axios
        .post('/user/token/invalidate/app', reqBody, Utils.requestHeader())
        .then(() => {
          logoutUser();
        });
    };


  const logoutUser = async ()=>{
    localStorage.removeItem('tokenSend');
    localStorage.setItem('authenticated', false);
    localStorage.setItem('vmsAuthToken', null);
    localStorage.setItem('isSessionApiCall', false);
    localStorage.setItem('isAmplitudeSingleCall', false);
    await resetCustomerOrgData();
    resetNotificationData();
    resetCustomerOrgLocations();
    resetLoggedInUserData();
    setAccountStatus(false);
    setLogOutStatus(false);
    keycloak.logout({
      redirectUri: process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL,
    });
    setPingApiCallTime(0)
    dispatch(setAllAreas([]));
    dispatch(setSubscribeAreasRequest(false));
    dispatch(setKeepAliveRequest(false));
    dispatch(resetPlatformInfo());
    mqttDisconnectRequest();
  }
  /* Set global base URL */
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
  const tenantID =
  !process.env.REACT_APP_PROJECT && !AppDefaults.PROJECT_MEGATRON
    ? 'dc'
    : process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
    ? 'hva'
    : 'dc';
const appID =
  !process.env.REACT_APP_PROJECT && !AppDefaults.PROJECT_MEGATRON
    ? 'vms'
    : process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
    ? 'portal'
    : 'vms';

  // Set up important system config
  if (!localStorage?.getItem('net.duclo.vms.system')) {
    localStorage.setItem(
      'net.duclo.vms.system',
      JSON.stringify({
        init: true,
        broadcast: false,
        register: '1.0.0',
      })
    );
  }

  if (window && console) {
    window.vmsConsole = window.console || console;
  }
  
  if (token) {
    /* Set authorizartion header or Axios if token is available */
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common['X-Tenant-Id'] = tenantID;
    axios.defaults.headers.common['X-App-Id'] = appID;

    /* Set event to handle the 401 error code when token gets expire  */
    axios.interceptors.response.use(
      (response) => {
        let metaCode = response?.data?.meta?.code;
        if (
          metaCode &&
          (metaCode?.toString() === '2014' || metaCode?.toString() === '2013')
        ) {
          if(!accountStatus){
            setAccountStatus(true)
          }          
          if (metaCode?.toString() === '2013') {
            setIsActNotEnabled(true);
          }
          if (metaCode?.toString() === '2014') {
            setIsActNotActivated(true);
          }
        }
        //=== Check if user is inavtive redirect to dashboard and show inactive user message
        if (url !== '/customers/manage.html') {
          let metaCode = response?.data?.meta?.code;
          if (metaCode && metaCode?.toString() === '1013') {
            navigate('/customers/manage.html');
          }
        }
        return response;
      },
      (error) => {
        if (error?.response?.status === 401) {
          if(!logOutStatus){
            setLogOutStatus(true)
          }
        }
        return error;
      }
    );
  } else delete axios.defaults.headers.common['Authorization'];
};
