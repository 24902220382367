import { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import {
  BreadcrumbList,
  Header,
  PrimaryButton,
  SiteModal,
  Tabs,
  TextBlock,
} from '../../../components/common';
import { constants, Utils, roles } from '../../../helpers';
import PageWrapper from '../../PageWrapper';
import {
  AudioSettingsBlock,
  DeviceInfoBlock,
  DeviceNetworkBlock,
  DeviceSettingsBlock,
  LicensesBlock,
  AnalyticsSettings,
  FocusSettingBlock,
} from './blocks';
import { useDispatch, useSelector } from 'react-redux';
import {
  // getAllDevicesData,
  getDeviceInformation,
  // getSelectedOrganization,
  setDeviceInformation,
  // setAllDevicesData,
  getAllMqttDataFromResponse,
  resetAllMqttDataFromResponse,
  getAccountId,
} from '../../../store/reducers/AccountReducer';
import Live from '../../../components/streaming/live';
import Playback from '../../../components/streaming/playback';
import {
  setCDNInfo,
  setLiveStream,
  setRemoteStream,
  setStartDate,
  getPlatformInfo,
  setIsLiveViewEntitlementExist,
  setIsLiveViewEntitlementExpired,
} from '../../../store/reducers/StreamingReducer';
import {
  HiOutlineInformationCircle,
  HiOutlineVideoCamera,
} from 'react-icons/hi';
import {
  TbDeviceAnalytics,
  TbFileCertificate,
  TbHeadphones,
  TbNetwork,
  TbFocusCentered,
} from 'react-icons/tb';
import axios from 'axios';
import { useLoggedInUserData } from '../../../store/LoggedInAccountStore';
import { getCurrentEventResource } from '../../../utils/connection/mqttConnection';
import './DeviceDashboard.scss';
import useLicensesStore from '../../../store/LicensesStore';
import { HiExclamationCircle } from 'react-icons/hi2';
import { ReactComponent as InfoCircleIcon } from '../../../assets/images/Info-circle.svg';
import { RxCross1 } from 'react-icons/rx';
import { devicesMQTTStore } from '../../../store/DevicesMQTTStore';
import arrowLeft from "../../../assets/images/icons/Arrowleft.svg";
import {
  getAllDevicesData,
  // getAllMqttDataFromResponse,
  getSelectedOrganization,
  // resetAllMqttDataFromResponse,
  setAllDevicesData,
} from '../../../store/AccountStoreIDB';
import { observerInstance } from '../../../store/indexDB/observer';
import { usePoliciesStore } from '../../../store/policiesStore';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

const DeviceDashboard = () => {
  const navigate = useNavigate();
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const accountId = useSelector(getAccountId);
  let [searchParams] = useSearchParams();
  const deviceId = searchParams.get('deviceId');
  // const orgDetails = useSelector(getSelectedOrganization);
  const [orgDetails, setOrgDetails] = useState({});
  const deviceDetails = useSelector(getDeviceInformation);
  // const devicesDetails = useSelector(getAllDevicesData);
  const [devicesDetails, setDevicesDetails] = useState([]);
  const dispatch = useDispatch();
  const [show, setShow] = useState(null);
  const [showAddLicenseModal, setShowAddLicenseModal] = useState(false);
  const [showDetachLicenseModal, setShowDetachLicenseModal] = useState(false);
  const [activeSection, setActiveSection] = useState(0);
  const [saveDeviceSettingsFlag, setSaveDeviceSettingsFlag] = useState(false);
  const [saveTabSettingsDataFlag, setSaveTabSettingsDataFlag] = useState(false);
  const [canAttachLicense, setCanAttachLicense] = useState(
    !deviceDetails?.claimed
  );
  const [moveLocationData, setMoveLocationData] = useState({});
  const [showDeviceRemoveModal, setShowDeviceRemoveModal] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const platformDetails = useSelector(getPlatformInfo);
  const { setLicenses } = useLicensesStore();
  const publishTopic = platformDetails?.mqtt?.topic_details?.publish?.settings;
  const { getState } = devicesMQTTStore;
  const state = getState();
  const sessionId = state.getSessionId();
  const [parent, setParent] = useState({});
  const deviceState = deviceDetails?.deviceStatus;
  const connectionState = deviceDetails?.connectionStatus;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const getLoggedInUserUUID = useLoggedInUserData(
    (state) => state.getLoggedInUserUUID
  );
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();
  const devicePublishId = Utils.replaceStringValues(
    publishTopic[0],
    '${deviceId}',
    `${deviceDetails?.gatewayId}`
  );
  const breadList = [
    {
      url: '/customers/manage.html',
      title: (loggedInUser.role !== roles.ROLE2PORTAL && loggedInUser.role !== roles.ROLE7PORTAL) ?  constants.MANAGE_ORG_PAGE_TITLE : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/landing-page.html?orgId=${orgDetails?.orgId}&orgName=${orgDetails?.orgName}&fromPush=true`,
      title: `${orgDetails?.orgName}`,
    },
    {
      url: `/devices/listing.html?orgId=${orgDetails?.orgId}`,
      title: constants.DEVICES_PAGE_TITLE,
    },
    {
      url: '/devices/dashboard.html?deviceId',
      title: `${deviceDetails?.deviceName}`,
    },
  ];
	const mobileBreadList=[
    {
  url: `/devices/listing.html?orgId=${orgDetails?.orgId}`,
  title: constants.DEVICES_PAGE_TITLE,
   },

]
const loadSelectedOrgData = useCallback(async () => {
  const org = await getSelectedOrganization();
  setOrgDetails(org || {});
}, []);

const debouncedLoadSelectedOrgData = useDebouncedCallback(
  loadSelectedOrgData,
  1000
);

useEffect(() => {
  const handleUpdate = async (data) => {
    if (data.key === 'selectedOrganization') {
      await debouncedLoadSelectedOrgData();
    }
    // if (data.key === 'allMqttDataFromResponse') {
    //   await loadAllMqttData();
    // }
  };
  observerInstance.addObserver(handleUpdate);
  debouncedLoadSelectedOrgData();
  // loadAllMqttData();

  return () => {
    observerInstance.removeObserver(handleUpdate);
  };
}, [debouncedLoadSelectedOrgData]);

useEffect(() => {
  const fetchDevices = async () => {
    const devices = await getAllDevicesData();
    setDevicesDetails(devices);
  };
  fetchDevices();
  loadSelectedOrgData();
}, []);

useEffect(() => {
  if (orgDetails?.orgId) {
    getLicensesCall();
  }
}, [orgDetails?.orgId]);

  const getLicensesCall = async () => {
    await setLicenses(`/user/orgs/${orgDetails?.orgId}/licenses`);
  };

  useEffect(() => {
    const responseDeviceId = allMqttData?.msg?.resource
      ?.toString()
      ?.split('/')?.[1];
    const resource = allMqttData?.msg?.resource
      ?.toString()
      ?.split(`${deviceId}/`)?.[1];
    if (getCurrentEventResource(resource) && deviceId === responseDeviceId) {
      updateDeviceStore(
        allMqttData?.msg?.properties,
        true,
        allMqttData?.msg?.action !== 'error'
      );
    }
  }, [allMqttData]);

  const updateDeviceStore = async (properties, isFromMqtt, mqttStatus) => {
    let openDeviceDetails = JSON.parse(JSON.stringify(deviceDetails));
    let deficeInfoProperty = openDeviceDetails?.properties;
    mqttStatus &&
      localStorage.setItem(
        'localStoreProperty',
        JSON.stringify({ ...deficeInfoProperty })
      );
    let localStoreProperty = JSON.parse(
      localStorage.getItem('localStoreProperty')
    );
    deficeInfoProperty = mqttStatus
      ? { ...deficeInfoProperty, ...properties }
      : localStoreProperty && {
          ...deficeInfoProperty,
          ...localStoreProperty,
        };
    openDeviceDetails = {
      ...openDeviceDetails,
      properties: deficeInfoProperty,
    };
    let updatedList = devicesDetails?.map((alldevice, index) => {
      if (alldevice.deviceId === openDeviceDetails.deviceId) {
        return {
          ...openDeviceDetails,
        };
      } else {
        return { ...alldevice };
      }
    });
    let dList = await Promise.all(updatedList);
    await setAllDevicesData(dList);
    dispatch(setDeviceInformation(openDeviceDetails));
    isFromMqtt && dispatch(resetAllMqttDataFromResponse());
  };

  useEffect(() => {
    // if (devicesDetails?.length) {
    //   const deviceInfo = devicesDetails?.find(
    //     (device) => device.deviceId === deviceId
    //   );
    //   dispatch(setDeviceInformation(deviceInfo));
    //   setCanAttachLicense(deviceInfo?.claimed);
    // }
    setCanAttachLicense(deviceDetails?.claimed);
    setShow('LIVE');
    dispatch(setRemoteStream(null));
    dispatch(setLiveStream(null));
  }, [deviceId, JSON.stringify(deviceDetails)]);

  useEffect(() => {
    if (orgDetails?.orgId) {
      axios
        .get(`/partner/orgs/${orgDetails?.orgId}/cdn`, {
          withCredentials: true,
          ...Utils.requestHeader(),
        })
        .then((response) => {
          if (response?.data?.data) {
            dispatch(setCDNInfo(response?.data?.data?.cdn));
            // TODO: Delete later
            console.log('signed cookkie updated');
          }
        });
    }
  }, [orgDetails?.orgId]);

  useEffect(() => {
    if (deviceDetails && devicesDetails?.length) {
      const url = deviceDetails?.entitlement?.url;
      if (url) {
        fetchData(url);
      }
      const parent = devicesDetails?.find(
        (device) => device.deviceId === deviceDetails.gatewayId
      );
      setParent(parent);
    }
  }, [
    deviceDetails?.entitlement?.url,
    deviceDetails?.gatewayId,
    devicesDetails,
  ]);

  const fetchData = async (url) => {
    fetch(url, {
      withCredentials: true,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          const expiryDate = data?.term?.expiry;
          const durationInDays = data?.term?.data?.durationInDays;
          const startDate =
            expiryDate - Utils.daysToMilliseconds(durationInDays);
          dispatch(setStartDate(startDate));
          const liveView = data.entitlements?.find(
            (item) => item.type === constants.LIVE_VIEW_TYPE
          );
          if (liveView) {
            dispatch(setIsLiveViewEntitlementExist(true));
            dispatch(
              setIsLiveViewEntitlementExpired(
                liveView.expiry < new Date().getTime()
              )
            );
          }
        }
      });
  };

  const handleClick = (detail) => {
    // Todo: Delete later
    console.log('handle click', detail);
    if (detail === constants.DEVICES_SWITCH_TIMELINE_TITLE) {
      setShow(constants.DEVICES_SWITCH_TIMELINE_TITLE);
    } else if (detail === constants.DEVICES_SWITCH_LIVE_TITLE) {
      setShow(constants.DEVICES_SWITCH_LIVE_TITLE);
    }
  };

  const setSectionBlock = (index) => {
    setSaveTabSettingsDataFlag(false);
    setActiveSection(index);
  };

  const addLicenseButtonHandler = () => {
    setShowAddLicenseModal(true);
  };

  const saveDeviceInfoSettingsButtonHandler = () => {
    setSaveTabSettingsDataFlag(true);
  };
  const saveDeviceMoveLocationAreaData = (locationData) => {
    setMoveLocationData(locationData);
  };
  const updateStoreDeviceSetting = (properties) => {
    updateDeviceStore(properties, false, true);
  };
  const moveDeviceLocation = async () => {
    try {
      const orgID = deviceDetails?.orgId;
      const locationId = moveLocationData?.oldLocationId
        ? moveLocationData?.oldLocationId
        : deviceDetails?.locationId;
      const areaId = moveLocationData?.oldAreaId
        ? moveLocationData?.oldAreaId
        : deviceDetails?.areaId;
      const reqBody = {
        newAreaId: moveLocationData?.newAreaId
          ? moveLocationData?.newAreaId
          : deviceDetails?.areaId,
        deviceName: moveLocationData?.deviceName
          ? moveLocationData?.deviceName
          : deviceDetails?.deviceName,
      };
      const res = await axios.put(
        `/device/orgs/${orgID}/locations/${locationId}/areas/${areaId}/devices/${deviceId} `,
        reqBody,
        Utils.requestHeader()
      );
      if (res?.data?.meta?.code === 200) {
        await axios
          .get(`device/orgs/${orgID}/devices`, Utils.requestHeader())
          .then((response) => {
            let resultData = response.data;
            if (resultData) {
              let responseMeta = resultData.meta;
              let responseData = resultData.data;
              if (parseInt(responseMeta?.code) === 200) {
                getCapabiltiesFromDeviceList(responseData);
              }
            }
          });
      } else {
        console.error('ERROR: ', res?.data);
      }
    } catch (error) {
      console.error('ERROR: ', error);
    }
  };

  const getCapabiltiesFromDeviceList = async (deviceData) => {
    let deviceList = [...deviceData];
    let newDeviceList = deviceList?.map(async (device, index) => {
      const deviceInfo = devicesDetails?.find(
        (deviceStore) => deviceStore?.deviceId === device?.deviceId
      );
      // let isCapabilityCall =
      //   devicesDetails?.length === 0 ||
      //   deviceInfo?.capDetails === undefined ||
      //   deviceInfo?.capability?.version !== device?.capability?.version;
      // if (device?.capability && isCapabilityCall) {
      //   let response = await fetch(device.capability.url);
      //   if (response.status === 200) {
      //     let responseJson = await response.json();
      //     return { ...device, capDetails: responseJson };
      //   } else {
      //     return { ...device };
      //   }
      // } else 
      if (
        device?.capability &&
        deviceInfo?.capability?.version === device?.capability?.version
      ) {
        return { ...device, capDetails: deviceInfo?.capDetails };
      } else {
        return { ...device };
      }
    });
    let deviceCapability = await Promise.all(newDeviceList);
    if (deviceCapability?.length) {
      const currentDeviceDetail = deviceCapability?.find(
        (device) => device.deviceId === deviceId
      );
      dispatch(setDeviceInformation(currentDeviceDetail));
    }
    await setAllDevicesData(deviceCapability);
  };

  // sectionBlock and buttonGroup must have the same number of keys.
  // Ensure entries in buttonGroup relate to keys in sectionBlock
  const sectionBlock = {
    0: (
      <DeviceInfoBlock
        orgId={orgDetails?.orgId}
        orgName={orgDetails?.orgName}
        deviceId={deviceId}
        deviceInfos={deviceDetails}
        saveDeviceSettingsFlag={saveTabSettingsDataFlag}
        accountId={accountId}
        uuid={getLoggedInUserUUID()}
        devicePublishId={devicePublishId}
        saveDeviceSettingsCallback={(value) => {
          saveDeviceInfoSettingsButtonHandler(value);
        }}
        moveDeviceDetailCallback={(locationData) => {
          saveDeviceMoveLocationAreaData(locationData);
        }}
        updateStore={(publishData) => {
          updateStoreDeviceSetting(publishData);
        }}
        updateFirmware={() => {
          navigate(
            `/devices/channel-details.html?deviceId=${deviceDetails.gatewayId}`,
            {
              state: {
                isRedirectToNetwork: true,
              },
            }
          );
        }}
      />
    ),
    1: (
      <DeviceNetworkBlock
        orgId={orgDetails?.orgId}
        orgName={orgDetails?.orgName}
        deviceId={deviceId}
        deviceInfos={deviceDetails}
        accountId={accountId}
        sessionId={sessionId}
        devicePublishId={devicePublishId}
        parent={parent}
        updateStore={(publishData) => {
          updateStoreDeviceSetting(publishData);
        }}
      />
    ),
    2: (
      <DeviceSettingsBlock
        orgId={orgDetails?.orgId}
        orgName={orgDetails?.orgName}
        deviceId={deviceId}
        accountId={accountId}
        saveDeviceSettingsFlag={saveDeviceSettingsFlag}
        deviceInfos={deviceDetails}
        allDeviceData={devicesDetails}
        devicePublishId={devicePublishId}
        uuid={getLoggedInUserUUID()}
        saveDeviceSettingsCallback={(value) => {
          setSaveDeviceSettingsFlag(value);
        }}
        updateStore={(publishData) => {
          updateStoreDeviceSetting(publishData);
        }}
      />
    ),
    3: (
      <FocusSettingBlock
        orgId={orgDetails?.orgId}
        orgName={orgDetails?.orgName}
        deviceId={deviceId}
        deviceInfos={deviceDetails}
        saveAudioSettingsFlag={saveTabSettingsDataFlag}
        saveAudioSettingsCallback={(value) => {
          setSaveTabSettingsDataFlag(value);
        }}
        devicePublishId={devicePublishId}
        uuid={getLoggedInUserUUID()}
        accountId={accountId}
        saveDeviceSettingsCallback={(value) => {
          setSaveDeviceSettingsFlag(value);
        }}
        updateStore={(publishData) => {
          updateStoreDeviceSetting(publishData);
        }}
      />
    ),
    4: (
      <AudioSettingsBlock
        orgId={orgDetails?.orgId}
        orgName={orgDetails?.orgName}
        deviceId={deviceId}
        accountId={accountId}
        deviceInfos={deviceDetails}
        saveAudioSettingsFlag={saveTabSettingsDataFlag}
        devicePublishId={devicePublishId}
        uuid={getLoggedInUserUUID()}
        saveAudioSettingsCallback={(value) => {
          setSaveTabSettingsDataFlag(value);
        }}
        updateStore={(publishData) => {
          updateStoreDeviceSetting(publishData);
        }}
      />
    ),
    5: (
      <LicensesBlock
        orgId={orgDetails?.orgId}
        orgName={orgDetails?.orgName}
        deviceId={deviceId}
        deviceName={deviceDetails?.deviceName}
        showAddModal={showAddLicenseModal}
        hideAddModalCallback={setShowAddLicenseModal}
        showRemoveModal={showDetachLicenseModal}
        hideRemoveModalCallback={setShowDetachLicenseModal}
      />
    ),
    6: (
      <AnalyticsSettings
        accountId={accountId}
        orgId={orgDetails?.orgId}
        orgName={orgDetails?.orgName}
        deviceId={deviceId}
        deviceInfos={deviceDetails}
        devicePublishId={devicePublishId}
        uuid={getLoggedInUserUUID()}
        updateStore={(publishData) => {
          updateStoreDeviceSetting(publishData);
        }}
        saveAudioSettingsFlag={saveTabSettingsDataFlag}
      />
    ),
  };

  const deleteDeviceCall = async () => {
    try {
      const res = await axios.delete(
        `/device/orgs/${orgDetails.orgId}/devices/${deviceId}/v2`,
        Utils.requestHeader()
      );
      if (parseInt(res?.status) === 202) {
        hideDeviceRemoveModel();
        navigate(`/devices/listing.html?orgId=${orgDetails?.orgId}`);
      } else {
        setUserMsg(res?.meta?.userMsg || res?.data?.meta?.userMsg);
      }
    } catch (error) {
      // TODO : to print the error will update this later
      console.error(error);
    }
  };

  const onDeleteDevice = () => {
    setShowDeviceRemoveModal(true);
  };

  const hideDeviceRemoveModel = () => {
    setShowDeviceRemoveModal(false);
    setUserMsg('');
  };

  const buttonGroup = {
    0: (
      <>
       {deviceDetails.deviceType !== 'ipcam' && (
          <PrimaryButton
            type="button"
            width="128px"
            fontSize="14px"
            height="44px"
            lineHeight="16px"
            borderColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--error_64')}
            hoverBorderColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--error_64')}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--error_64'
            )}
            hoverColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--brand_white')}
            hoverBackgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--error_64')}
            backgroundColor="transparent"
            disabled={
                curDeviceStatus !== constants.DEVICES_ONLINE_CONNECTION_STATUS
            }
            onClick={onDeleteDevice}
          >
            {constants.DEVICES_REMOVE_DEVICE}
          </PrimaryButton>
        )}
        <PrimaryButton
          type="button btn-primary"
          width="150px"
          className="device-btn-space"
          disabled={
            connectionState !== constants.DEVICES_ONLINE_CONNECTION_STATUS ||
            deviceState === constants.DEVICES_DEACTIVATED_DEVICE_STATUS
          }
          onClick={() => moveDeviceLocation()}
        >
          {constants.DEVICES_SAVE_CHANGES_BUTTON}
        </PrimaryButton>
      </>
    ),
    // 4: canAttachLicense ? (
    //   <PrimaryButton
    //     type="button"
    //     width="165px"
    //     className="device-btn-space"
    //     onClick={() => addLicenseButtonHandler()}
    //   >
    //     {constants.DEVICES_TAB_LICENSES_ATTACH_NEW_LICENSE_LABEL}
    //   </PrimaryButton>
    // ) : (
    //   <Button
    //     variant="primary"
    //     size="sm"
    //     className="attach-license-btn disabled"
    //   >
    //     {constants.DEVICES_TAB_LICENSES_ATTACH_NEW_LICENSE_LABEL}
    //   </Button>
    // ),
  };

  const tabs = [
    {
      icon: <HiOutlineInformationCircle size={22} />,
      label: constants.DEVICES_TAB_DEVICE_INFO,
      clickHandler: (index) => setSectionBlock(index),
    },
    {
      icon: <TbNetwork size={22} />,
      label: constants.DEVICES_TAB_NETWORK,
      clickHandler: (index) => setSectionBlock(index),
    },
    // {
    //   icon: <HiOutlineVideoCamera size={22} />,
    //   label: constants.DEVICES_TAB_VIDEO_SETTINGS,
    //   clickHandler: (index) => setSectionBlock(index),
    // },
    // {
    //   icon: <TbFocusCentered size={22} />,
    //   label: constants.DEVICES_TAB_FOCUS,
    //   clickHandler: (index) => setSectionBlock(index),
    // },
    // {
    //   icon: <TbHeadphones size={22} />,
    //   label: constants.DEVICES_TAB_AUDIO_SETTINGS,
    //   clickHandler: (index) => setSectionBlock(index),
    // },
    // {
    //   icon: <TbFileCertificate size={22} />,
    //   label: constants.DEVICES_TAB_LICENSES,
    //   clickHandler: (index) => setSectionBlock(index),
    // },
    // {
    //   icon: <TbDeviceAnalytics size={22} />,
    //   label: constants.DEVICES_TAB_ANALYTICS_SETTINGS,
    //   clickHandler: (index) => setSectionBlock(index),
    // },
  ];

  return (
    <div className="App">
      <Header currentDeviceId={deviceId} />
      <div className="breadcrumbsResponsive mobile w-100">
              <div className="text-start me-auto navbar-brand arrow">
                <img src={arrowLeft} alt=""></img>
                <spam
                  className="backText"
                   onClick={() => navigate(mobileBreadList[0]?.url)}

                >
                {constants.BREADCRUMB_BACK}

                </spam>
              </div>
            </div>
      <PageWrapper className="mw-100">
        <div className="page-header mb-3">
          <Container className="container-top-offset" lg={8}>
            {/* <Row className="g-4 mb-3"> */}
              <Col md={6} lg={6} xl={6} xs={12}>
                <BreadcrumbList list={Utils.CheckBreadcrumbForUserRole(breadList)} />
              </Col>
            {/* </Row> */}
            <Row>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="page-title text-start"
              >
                {deviceDetails?.deviceName}

                <p className="page-title-note">{orgDetails?.orgName}</p>
              </Col>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="d-none d-md-flex align-items-md-center justify-content-center justify-content-md-end"
              >
                {buttonGroup[activeSection]}
              </Col>
            </Row>
            <Row className="device-details-tabs device-tabs settingsTabs">
              <Col>
                <Tabs defaultActive={activeSection} tabCollection={tabs} />
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className={`${
                  show === constants.DEVICES_SWITCH_LIVE_TITLE ? 'mt-2' : ''
                }`}
              >
                {show === constants.DEVICES_SWITCH_TIMELINE_TITLE && (
                  <Playback handleClick={handleClick} />
                )}
                {show === constants.DEVICES_SWITCH_LIVE_TITLE && (
                  <Live
                    page="settings"
                    handleClick={handleClick}
                    isStopStreamClick={true}
                  />
                )}
              </Col>
              <Col md={6} lg={6} xl={6} xs={12}>
                <div
                  className={`details-section-container${
                    activeSection !== 0 && activeSection !== 5
                      ? ' inner-view'
                      : ''
                  }`}
                >
                  {sectionBlock[activeSection]}
                </div>
              </Col>
              	{/* For Mobile View Button */}
							<Col md={12} className="d-flex d-md-none detalsSecButton">
								{buttonGroup[activeSection]}
							</Col>
            </Row>
          </Container>
        </div>
        {/* Want to remove device - modal  */}
        <SiteModal
          modalTitle={
            constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_TITLE
          }
          showModal={showDeviceRemoveModal}
          hideModal={hideDeviceRemoveModel}
          classes="device-settings-restart-required"
        >
          {userMsg && userMsg.length > 0 && (
            <div className="error-container">
              <div className="info-icon-container">
                <InfoCircleIcon />
              </div>
              <div className="error-body">
                <div className="error-content">{userMsg}</div>
                <div className="cross-icon-container">
                  <RxCross1 onClick={() => setUserMsg('')} />
                </div>
              </div>
            </div>
          )}
          <TextBlock
            className="mb-3"
            fontWeight="400"
            fontSize="1rem"
            lineHeight="24px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--greyscale_56'
            )}
          >
            {
              constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_CONTENT
            }
          </TextBlock>

          <>
            <div className="text-center">
              <HiExclamationCircle
                className="mb-4 icon-warning-color"
                size={96}
              />
            </div>
            <PrimaryButton
              className="btn btn-primary-outline mb-4"
              type="button"
              width="100%"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              backgroundColor="transparent"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              onClick={hideDeviceRemoveModel}
            >
              {
                constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE
              }
            </PrimaryButton>
            <PrimaryButton
              className="btn btn-primary"
              type="button"
              width="100%"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              backgroundColor="transparent"
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              borderWidth="1.5px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              onClick={() => {
                deleteDeviceCall();
              }}
            >
              {constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_MODAL_BUTTON_TITLE}
            </PrimaryButton>
          </>
        </SiteModal>
      </PageWrapper>
    </div>
  );
};

export default DeviceDashboard;
