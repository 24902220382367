import React, { useEffect, useState } from 'react';
import { SiteModal, PrimaryButton } from '../../components/common';
import { RxCross1 } from 'react-icons/rx';
import { step1of2IconLicense, step2of2IconLicense } from '../../assets/images';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { SelectField, TextField } from '../../components/forms';
import { Utils, constants } from '../../helpers';
import { Col } from 'react-bootstrap';
import { success, error } from '../../assets/images';
import './LdapAuthentication.scss';
import { PiWarningCircleBold } from 'react-icons/pi';
import axios from 'axios';
import { useOrganizations } from '../../store/OrganizationsStore';
import { getCustomerOrgData, setCustomerOrgData } from '../../store/OrganizationsStoreIDB';

const LdapAuthentication = ({  isChangeLdap, orgId, ...props }) => {

  const validate1 = Yup.object({
    connectionUrl: Yup.string().required(constants.ORGANIZATION_LDAP_URL_REQUIRED),
    bindDn: Yup.string().required(constants.ORGANIZATION_LDAP_BIND_DN_REQUIRED),
    bindCredential: Yup.string().required(constants.ORGANIZATION_LDAP_BIND_CREDENTIAL_REQUIRED),
    usersDn: Yup.string().required(constants.ORGANIZATION_LDAP_USER_DN_REQUIRED),
  });

  const validate2 = Yup.object({
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string(),
    phoneNumber: Yup.string(),
    profilePicture: Yup.string(),
  });

  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  // const setCustomerOrgData = useOrganizations(
  //   (state) => state.setCustomerOrgData
  // );

  const [showConnectionDetailsModel, setShowConnectionDetailsModel] = useState(false);
  const [testConnectionStatus, setTestConnectionStatus] = useState('-');
  const [testConnection, setTestConnection] = useState(false);
  const [testConnectionObj, setTestConnectionObj] = useState({});
  const [authenticationSucess, setAuthenticationSucess] = useState('-');
  const [connectionAttributes, setConnectionAttributes] = useState([]);

  useEffect(() => {
    if(isChangeLdap){
      setShowConnectionDetailsModel(true);
    }
  }, [isChangeLdap]);

  const handleClose = () => {
    setShowConnectionDetailsModel(false);
    setTestConnection(false);
    setTestConnectionObj({});
    setTestConnectionStatus('-');
    setAuthenticationSucess('-');
    if(authenticationSucess === true){
      props.onCloseLdap(true);
    }else{
      props.onCloseLdap(false);
    }
    setConnectionAttributes([]);
  };
  return (
    <>
      <SiteModal
        modalTitle={constants.ORGANIZATION_LDAP_AUTHENTICATION}
        showModal={showConnectionDetailsModel}
        hideModal={() => handleClose()}
      >
        <div className="ctn-box innner">
        {!testConnection ?
        <>
          <div className="stepIcon">
            <img src={step1of2IconLicense} alt="step1Icon" />
          </div>
          <div className="steptext">{constants.ORGANIZATION_LDAP_STEP_1_OF_2}</div>
          { testConnectionStatus === '-' ?
            <div>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  connectionUrl: testConnectionObj?.connectionUrl ? testConnectionObj?.connectionUrl: '',
                  bindDn: testConnectionObj?.bindDn ? testConnectionObj?.bindDn: '',
                  bindCredential: testConnectionObj?.bindCredential ? testConnectionObj?.bindCredential :'',
                  usersDn: testConnectionObj?.usersDn ? testConnectionObj?.usersDn :'',
                }}
                validationSchema={validate1}
                onSubmit={(values, { setSubmitting }) => {
                  if (values) {
                    setSubmitting(true);
                    axios
                    .post(
                      `/partner/ldap/orgs/${orgId}/attributes`,
                      values,
                      Utils.requestHeader()
                    )
                    .then((res) => {
                      let response = res?.data;
                      if (response?.meta?.code === 200) {
                        setTestConnectionObj(values);
                        setTestConnectionStatus(true);
                        setSubmitting(false);
                        let options = [];
                        response?.data?.attributes?.forEach(element => {
                          let obj = {};
                          obj['label'] = element;
                          obj['value'] = element;
                          options.push(obj);
                        });
                        setConnectionAttributes(options);
                      } else {
                        setTestConnectionStatus(false);
                        setSubmitting(false);
                      }
                    })
                    .catch(function (error) {
                      setSubmitting(false);
                    });
                  }
                }}
              >
                {({
                  values,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form className="modal-form" onSubmit={handleSubmit}>

                    <div className="titlename">
                      {constants.ORGANIZATION_LDAP_CONNECTION_URL}
                    </div>
                    <div>
                      <TextField
                        placeholder={'URL'}
                        required={true}
                        name="connectionUrl"
                        type="text"
                        value={values.connectionUrl}
                      />
                    </div>
                    <div>
                      <TextField
                        placeholder={'Bind DN'}
                        required={true}
                        name="bindDn"
                        type="text"
                        value={values.bindDn}
                      />
                    </div>
                    <div>
                      <TextField
                        placeholder={'Bind Credentials'}
                        required={true}
                        name="bindCredential"
                        type="text"
                        value={values.bindCredential}
                      />
                    </div>
                    <div className="titlename mt-3">
                    {constants.ORGANIZATION_LDAP_USER_DN}
                    </div>
                    <div>
                      <TextField
                        placeholder={'User DN'}
                        required={true}
                        name="usersDn"
                        type="text"
                        value={values.usersDn}
                        removebottommargin="true"

                      />
                      <div className='steptext px-1 d-flex'>
                        <div className='px-1'>
                          <PiWarningCircleBold
                            color={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--greyscale_56')}
                            size={16}
                          />
                        </div>
                        {constants.ORGANIZATION_LDAP_USER_DN_HELPER}
                      </div>
                    </div>
                    <Col>
                      <PrimaryButton
                        className="btn btn-primary w-100 mt-3"
                        type="submit"
                        disabled={
                          !values.usersDn ||
                          !values.connectionUrl ||
                          !values.bindDn ||
                          !values.bindCredential
                        }
                        loader={isSubmitting}
                        height="56px"
                        fontSize="1.125rem"
                      >
                        {constants.ORGANIZATION_LDAP_TEST_CONNECTION}
                      </PrimaryButton>
                    </Col>
                  </Form>
                )}
              </Formik>
            </div>
            : testConnectionStatus === true ?
              <div className='success-container'>
                <img src={success} alt="" className='m-4'/>
                <div className='success-title'>{constants.ORGANIZATION_LDAP_SUCCESS_TEXT1}</div>
                <div className='success-subtitle'>{constants.ORGANIZATION_LDAP_SUCCESS_TEXT2}</div>
                <PrimaryButton
                  className="btn btn-primary w-100 mt-4"
                  type="submit"
                  height="56px"
                  fontSize="1.125rem"
                  onClick={() => setTestConnection(true)}
                >
                  {constants.ORGANIZATION_LDAP_CONTINUE}
                </PrimaryButton>
                <PrimaryButton
                  className="btn btn-outline-secondary w-100 mt-2"
                  type="submit"
                  height="56px"
                  fontSize="1.125rem"
                  backgroundColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_white')}
                  borderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  borderWidth="1.5px"
                  hoverBorderWidth="1.5px"
                  hoverColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  hoverBackgroundColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_white')}
                  hoverBorderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  onClick={() => handleClose()}
                >
                  {constants.ORGANIZATION_LDAP_CANCEL}
                </PrimaryButton>
              </div>
            : testConnectionStatus === false ?
              <div className='success-container'>
                <img src={error} alt="" className='m-4'/>
                <div className='success-title'>{constants.ORGANIZATION_LDAP_UNABLE}</div>
                <div className='success-title'>{constants.ORGANIZATION_LDAP_UNABLE_TRY_AGAIN}</div>
                <PrimaryButton
                  className="btn btn-primary w-100 mt-4"
                  type="submit"
                  height="56px"
                  fontSize="1.125rem"
                  onClick={() => setTestConnectionStatus('-')}
                >
                  {constants.ORGANIZATION_LDAP_TRY_AGAIN}
                </PrimaryButton>
                <PrimaryButton
                  className="btn btn-outline-secondary w-100 mt-2"
                  type="submit"
                  height="56px"
                  fontSize="1.125rem"
                  backgroundColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_white')}
                  borderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  borderWidth="1.5px"
                  hoverBorderWidth="1.5px"
                  hoverColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  hoverBackgroundColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_white')}
                  hoverBorderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  onClick={() => handleClose()}
                >
                  {constants.ORGANIZATION_LDAP_CANCEL}
                </PrimaryButton>
              </div>
            : null
          }
        </>
        :
        <>
          <div className="stepIcon">
            <img src={step2of2IconLicense} alt="step1Icon" />
          </div>
          <div className="steptext">{constants.ORGANIZATION_LDAP_STEP_2_OF_2}</div>
          { authenticationSucess === '-' ?
            <div>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  phoneNumber: '',
                  profilePicture: '',
                }}
                validationSchema={validate2}
                onSubmit={(values, { setSubmitting}) => {
                  if (values) {
                    let request = {...testConnectionObj, ...values}
                    setSubmitting(true);
                    axios
                    .post(
                      `/partner/idp/orgs/${orgId}/ldap`,
                      request,
                      Utils.requestHeader()
                    )
                    .then(async (res) => {
                      let response = res?.data;
                      if (response?.meta?.code === 200) {
                        const custOrgData = await getCustomerOrgData();
                        const updatedData = custOrgData.map(element => {
                          if (element.orgId === orgId) {
                            return { ...element, ldapEnabled: true };
                          }                        
                          return element;
                        });
                        await setCustomerOrgData(updatedData);
                        setAuthenticationSucess(true);
                        setSubmitting(false);
                      } else {
                        setAuthenticationSucess(false);
                        setSubmitting(false);
                      }
                    })
                    .catch(function (error) {
                      setSubmitting(false);
                    });
                  }
                }}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  dirty,
                  errors,
                }) => (
                  <Form className="modal-form" onSubmit={handleSubmit}>
                    <div className="titlename">
                    {constants.ORGANIZATION_LDAP_MAP_ACCOUNT}
                    </div>
                    <div>
                      <SelectField
                        name="firstName"
                        key="firstName"
                        options={connectionAttributes}
                        defaultOptionLabel={
                          'First Name'
                        }
                        classes={'mt-2 mb-1'}
                      />
                    </div>
                    <div>
                      <SelectField
                        name="lastName"
                        key="lastName"
                        options={connectionAttributes}
                        defaultOptionLabel={
                          'Last Name'
                        }
                        classes={'mt-0 mb-1'}
                      />
                    </div>
                    <div>
                      <SelectField
                        name="email"
                        key="email"
                        options={connectionAttributes}
                        defaultOptionLabel={
                          'Email'
                        }
                        classes={'mt-0 mb-1'}
                      />
                    </div>
                    <div>
                      <SelectField
                        name="phoneNumber"
                        key="phoneNumber"
                        options={connectionAttributes}
                        defaultOptionLabel={
                          'Phone Number'
                        }
                        classes={'mt-0 mb-1'}
                      />
                    </div>
                    <div>
                      <SelectField
                        name="profilePicture"
                        key="profilePicture"
                        options={connectionAttributes}
                        defaultOptionLabel={
                          'Profile Picture'
                        }
                        classes={'mt-0 mb-1'}
                      />
                    </div>
                    <Col className="authenticateLDAPStyle">
                      <PrimaryButton
                        className="btn btn-primary w-100 mt-3"
                        type="submit"
                        disabled={!(dirty && isValid) ||
                          !values.firstName ||
                          !values.lastName ||
                          !values.email }
                        loader={isSubmitting}
                        height="56px"
                        fontSize="1.125rem"
                      >
                        {constants.ORGANIZATION_LDAP_SAVE}
                      </PrimaryButton>
                      <PrimaryButton
                        className="btn btn-outline-secondary w-100 mt-2"
                        type="submit"
                        height="56px"
                        fontSize="1.125rem"
                        backgroundColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--brand_white')}
                        borderColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--primary_40')}
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--primary_40')}
                        borderWidth="1.5px"
                        hoverBorderWidth="1.5px"
                        hoverColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--primary_40')}
                        hoverBackgroundColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--brand_white')}
                        hoverBorderColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--primary_40')}
                        onClick={() => {
                          setTestConnection(false);
                          setTestConnectionStatus('-');
                          setConnectionAttributes([]);
                        }}
                      >
                        {constants.ORGANIZATION_LDAP_GO_BACK}
                      </PrimaryButton>
                    </Col>
                  </Form>
                )}
              </Formik>
            </div>
            : authenticationSucess === true ?
              <div className='success-container'>
                <img src={success} alt="" className='m-4'/>
                <div className='success-title w-100'>
                {constants.ORGANIZATION_LDAP_SUCCESS}
                </div>
                <PrimaryButton
                  className="btn btn-primary w-100 mt-4"
                  type="submit"
                  height="56px"
                  fontSize="1.125rem"
                  onClick={() => {
                      setShowConnectionDetailsModel(false)
                      setAuthenticationSucess('-');
                      setTestConnectionStatus('-');
                      setTestConnection(false);
                      setTestConnectionObj({});
                      props.onCloseLdap(true);
                      setConnectionAttributes([]);
                    }
                  }
                >
                  {constants.ORGANIZATION_LDAP_COMPLETE}
                </PrimaryButton>
              </div>
            : authenticationSucess === false ?
              <div className='success-container'>
                <img src={error} alt="" className='m-4'/>
                <div className='success-title'>{constants.ORGANIZATION_LDAP_UNABLE}</div>
                <div className='success-title'>{constants.ORGANIZATION_LDAP_UNABLE_TRY_AGAIN}</div>
                <PrimaryButton
                  className="btn btn-primary w-100 mt-4"
                  type="submit"
                  height="56px"
                  fontSize="1.125rem"
                  onClick={() => setAuthenticationSucess('-')}
                >
                  {constants.ORGANIZATION_LDAP_TRY_AGAIN}
                </PrimaryButton>
                <PrimaryButton
                  className="btn btn-outline-secondary w-100 mt-2"
                  type="submit"
                  height="56px"
                  fontSize="1.125rem"
                  backgroundColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_white')}
                  borderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  borderWidth="1.5px"
                  hoverBorderWidth="1.5px"
                  hoverColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  hoverBackgroundColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_white')}
                  hoverBorderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  onClick={() => handleClose()}
                >
                  {constants.ORGANIZATION_LDAP_CANCEL}
                </PrimaryButton>
              </div>
            : null
          }
        </>
        }
        </div>
      </SiteModal>
    </>
  );
};

export default LdapAuthentication;

