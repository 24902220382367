import { useState } from 'react';
import { IoEllipsisHorizontalSharp } from 'react-icons/io5';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';

import './ContextMenu.scss';

const ContextMenu = ({
  iconButton = <IoEllipsisHorizontalSharp />,
  menuId = null,
  menuItems,
  menuMinWidth = '160px',
  customItemIconClass,
  customItemTextClass,
  customData,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (!event) return;
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e, cb, index) => {
    if (!e || !cb) return;

    if (customData) {
      cb(e, customData, index);
    } else {
      cb(e, index);
    }
    handleClose();
  };

  return (
    <div key={`more-icon-container-${menuId}`}>
      <IconButton
        aria-label="more"
        id={`more-button-${menuId}`}
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => {
          e?.preventDefault();
          e?.stopPropagation();
          handleClick(e);
        }}
      >
        {iconButton}
      </IconButton>
      <Menu
        id={`menu-${menuId}`}
        key={`menu-${menuId}`}
        open={open}
        onClose={(e) => {
          e?.preventDefault();
          e?.stopPropagation();
          handleClose();
        }}
        sx={{ width: 320, maxWidth: '100%' }}
        className="context-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
          style: { minWidth: menuMinWidth },
          className: 'context-menu-list',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
      >
        {Array.isArray(menuItems) &&
          menuItems.map((menuItem, menuItemIndex) => (
            <MenuItem
              variant="menu"
              key={`menu-item-wrapper-${menuId}-${menuItemIndex}`}
              data-index={menuItemIndex}
              onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
                handleMenuItemClick(e, menuItem?.clickHandler, menuItemIndex);
              }}
            >
              <ListItemIcon className={`menu-item-icon ${customItemIconClass}`}>
                {menuItem?.icon}
              </ListItemIcon>
              <ListItemText className={`menu-item-text ${customItemTextClass}`}>
                {menuItem?.text}
              </ListItemText>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export default ContextMenu;
