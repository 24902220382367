import PropTypes from 'prop-types';

const ToggleInput = ({
  classes,
  label,
  name,
  value,
  disabled,
  changeHandler,
  isAddEdit,
  labelColor = false,
  ...props
}) => {
  return (
    <div className={`toggle-field-wrap ${classes ? classes : ''}`}>
      <div
        className={`toggle-field-container${isAddEdit ? ' add-edit-item' : ''}`}
      >
        {label && (
          <div className="toggle-field-label-container">
            <label
              className={`toggle-field-label ${
                labelColor ? 'firmware-label' : ''
              }`}
            >
              {label}
            </label>
          </div>
        )}
        <label className="toggle-switch">
          <input
            type="checkbox"
            name={name}
            value={value}
            checked={!!value}
            onChange={changeHandler}
            disabled={disabled}
          />
          <span className="toggle-slider round"></span>
        </label>
      </div>
    </div>
  );
};

ToggleInput.propTypes = {
  label: PropTypes.node,
  description: PropTypes.node,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default ToggleInput;
