import { useState, useEffect } from 'react';
import { isNullString } from '../helpers/commonUtils';

const OtpInputField = ({ errorStyle, numOfInputs, getResultData }) => {
  let [otp, setOtp] = useState({});

  //=== On Change OTP not set data
  useEffect(() => {
    getResultData(getOTPData());
  }, [otp]);

  //=== Handle input changes
  const handleChange = (value1, event) => {
    const { name, value } = event.target;
    setOtp((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //=== Handle input focus on value changes
  const inputfocus = (elmnt) => {
    if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1 && elmnt.target.value === '') {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        const ele = document.getElementById(`otp${next}`);
        if(ele && isNullString(elmnt.target.value)){
          ele.focus();
        } else {
          document.getElementById(`otp${next + 1}`).focus();
        }      
      }
    }
  };

  //=== Handle input paste event
  const handlePaste = (event) => {
    let clipboardData, pastedData;
 
    // Get pasted data via clipboard API
    clipboardData = event.clipboardData || window.clipboardData;
    pastedData = clipboardData
      .getData('text')
      .toString()
      .replace(/\t/g, '')
      .split('');
 
    const newData = [];
    pastedData.forEach((data) => {
      if (data && data.trim() !== '') {
        newData.push(data);
      }
    });
 
    if (Array.isArray(newData) && newData.length > 0) {
      newData.forEach((data, index) => {
        setOtp((prevState) => ({
          ...prevState,
          [`otp${index + 1}`]: data,
        }));
      });
      document.getElementById(`otp${numOfInputs}`).focus();
    }
  };

  //=== get the result data after input change
  const getOTPData = () => {
    if (Object.keys(otp).length === numOfInputs) {
      return Object.values(otp).join('');
    }
    return null;
  };

  //=== Input html generation
  const addInputsOnDom = () => {
    let curElm = 0;
    return Array.from(Array(numOfInputs), (e, i) => {
      curElm = i + 1;
      return (
        <input
          key={curElm}
          id={`otp${curElm}`}
          name={`otp${curElm}`}
          type="text"
          autoComplete="off"
          className={`otpInput ${errorStyle}`}
          value={otp[`otp${curElm}`] || ''}
          onChange={(e) => handleChange(`otp${curElm}`, e)}
          onPaste={(e) => handlePaste(e)}
          tabIndex={curElm}
          maxLength="1"
          onKeyDown={(e) => inputfocus(e)}
        />
      );
    });
  };

  return <div className="otpContainer">{addInputsOnDom()}</div>;
};
export default OtpInputField;
