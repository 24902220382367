import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import axios from 'axios';
import { Utils } from '../helpers';

export const useLoggedInUserData = create(
  persist(
    (set, get) => ({
      loggedInUserData: [],
      accountId: '',
      UUID: '',
      loggedInUserAccountData: [],
      pingApiCallTime:0,

      setPingApiCallTime: (pingApiCallTime) => {
        if (!pingApiCallTime) return;

        set({
          pingApiCallTime: pingApiCallTime,
        });
      },

      getPingApiCallTime: () => {
        return get().pingApiCallTime;
      },

      getLoggedInUserAccountData: async (route) => {
        if (!route) return;

        const res = await axios.get(
          route,
          Utils.requestHeader()
        );
        const response = res?.data;
        let result;

        // Set organizations if request is successful
        if (response?.meta?.code === 200) {
          let accountData = response?.data;

          if (accountData) {
            set({
              loggedInUserAccountData: accountData,
            });
          }

          result = {
            status: 'success',
            msg: 'organizations listing data retrieved`',
          };
        } else {
          if (res?.code) {
            result = { status: 'failure', msg: `${res.code}: ${res.message}` };
          } else if (response?.data) {
            result = { status: 'failure', msg: response?.data?.userMsg };
          } else {
            result = { status: 'failure', msg: 'Unknown internal API error' };
          }
        }
        return result;
      },

      setLoggedInUserData: (userData) => {
        if (!userData) return;

        set({
          loggedInUserData: userData,
        });
      },

      setLoggedInUserUUID: (UUID) => {
        if (!UUID) return;

        set({
          UUID: UUID,
        });
      },

      getLoggedInUserUUID: () => {
        return get().UUID;
      },

      getLoggedInUserData: () => {
        return get().loggedInUserData;
      },

      getLoggedInUserAccountDataState: () => {
        return get().loggedInUserAccountData;
      },
      
      resetLoggedInUserData: () =>
        set((state) => {
          return {
            ...state,
            loggedInUserData: [],
            pingApiCallTime:0,
            accountId: '',
            UUID: '',
            loggedInUserAccountData: [],
          };
        }),
    }),
    {
      name: 'net.duclo.vms.loggedinuserdata',
      version: '1.0',
    }
  )
);
