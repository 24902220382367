import { Col, Row, Alert } from 'react-bootstrap';
import { constants, Utils } from '../../../../helpers';
import './DeviceInfoBlock.scss';
import { ToggleInput, TextField } from '../../../../components/forms';
import { useState, useEffect,useRef } from 'react';
import { Plus } from '../../../../assets/images';
import {
  PrimaryButton,
  SiteModal,
  TextBlock,
} from '../../../../components/common';
import ReactSlider from 'react-slider';
import moment from 'moment';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import publishDeviceSetting from '../shared/PublishDeviceSettings';
import { Formik, Field } from 'formik';
import { MdOutlineEdit } from 'react-icons/md';
import Form from 'react-bootstrap/Form';
import { devicesMQTTStore } from '../../../../store/DevicesMQTTStore';
import {
  mqttPublish,
} from '../../../../utils/connection/mqttConnection';
import { HiOutlineExclamationCircle } from 'react-icons/hi';


const DeviceNetworkBlock = ({
  deviceId,
  deviceInfos,
  updateStore,
  saveDeviceNetworkSettingsCallback,
  saveDeviceNetworkSettingsFlag,
  accountId,
  sessionId,
  devicePublishId,
  parent,
}) => {

  const capabilityList = deviceInfos?.capDetails?.capabilities;
  const deviceState = deviceInfos?.deviceStatus;
  const connectionState = deviceInfos?.connectionStatus;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const resourceList = Utils.getDeviceSettingResource(5);
  const resourceMaxBr = Utils.getDeviceSettingResource(9);
  const resourceListRecord = Utils.getDeviceSettingResource(8);
  const videoProfileUrl =  Utils.getDeviceSettingResource(1);
  const videoCapProps =  Utils.getCurrentTabProperty(
    capabilityList,
    videoProfileUrl[2]
  );
  const min = Number(videoCapProps?.['bit-rate']?.min)
  const max = Number(videoCapProps?.['bit-rate']?.max)
  const timezone = parent?.properties?.timezone.split('-')?.[0];
  const capNetwork = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[0]
  );
  const neworkProperty = deviceInfos?.properties;
  const [NetworkMaximumModal, setNetworkMaximumModal] = useState(false);
  const [NetworkContinueModal, setNetworkContinueModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [daysList, setDaysList] = useState(
    Utils.getWeekDays(['0'])
  );
  const dayDefault =
  selectedIds?.length > 1 && selectedIds?.length < 7
    ? constants.UPDATE_FIRMWARE_MULTIPLE
    : selectedIds?.length === 7
    ? constants.UPDATE_FIRMWARE_ALL_DAYS
    : selectedIds?.length === 1
    ? Utils.getWeekDays(selectedIds)?.find(
        (day) => day?.id?.toString() === selectedIds[0]
      )?.day
    : 'Sunday';
  const [selectDay, setselectDay] = useState(dayDefault);
  const [updateDayStaus, setUpdateDayStaus] = useState(false);
  const [ToggleStatus, setToggleStatus] = useState({});
  const defalutDateStart = new Date();
  defalutDateStart.setHours(0);
  defalutDateStart.setMinutes(0);
  defalutDateStart.setSeconds(0);
  const defalutDateEnd = new Date();
  defalutDateEnd.setHours(0);
  defalutDateEnd.setMinutes(5);
  defalutDateEnd.setSeconds(0);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(5);
  const [startTimeMoment, setStartTimeMoment] = useState(moment(defalutDateStart));
  const [endTimeMoment, setEndTimeMoment] = useState(moment(defalutDateEnd));
  const [bitrateToggleStatus, setBitrateToggleStatus] = useState({'mbr-enable': true});
  const [kbpsValue, setkbpsValue] = useState();
  const [isEditMode, setIsEditMode] = useState(null);
  const [allBrs, setAllBrs] = useState([]);
  const tid = Math.floor(new Date().getTime() / 1000.0);
  const appTopic = `a/notify/${deviceInfos.gatewayId}`;
  const formRef = useRef();
  const { getState } = devicesMQTTStore;
  const state = getState();
  const [selectedDaysValues, setSelectedDaysValues] = useState([]);
  const [isOverlaping, setIsOverlaping] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [variant, setVariant] = useState('danger');

  const bitrateProps = {
    'mbr-enable': bitrateToggleStatus['mbr-enable'],
    'mbr-kbps': kbpsValue,
    'mbr-day': selectedIds?.join(','),
    'mbr-st': startTime,
    'mbr-et': endTime,
  };

  const publishPayload = {
    transactionID: tid,
    accountId: accountId,
    deviceId: deviceId,
    uuid: sessionId,
    devicePublishId: devicePublishId,
    hubId: deviceInfos.gatewayId,
  };

  const resetProps = () => {
    setDaysList(Utils.getWeekDays(['0']));
    setStartTime(0);
    setEndTime(5)
    setkbpsValue(min);
    setStartTimeMoment(moment(defalutDateStart))
    setEndTimeMoment(moment(defalutDateEnd))
    setselectDay(dayDefault)
    setSelectedDaysValues([]);
    setSelectedIds([])
    setBitrateToggleStatus({'mbr-enable': true})
  }

  const selectedDay = (dayData) => {
    const dayListfirm = [...daysList];
    dayListfirm.map((data, i) => {
      if (dayData.id == data.id) {
        if (data.isSelected && selectedIds?.length != 1) {
          daysList[i].isSelected = false;
        } else {
          daysList[i].isSelected = true;
        }
      }
    });
    setDaysList(dayListfirm);
    setUpdateDayStaus(true);
  };

  useEffect(() => {
    const dayIds = [];
    const defaultSelectedValue = [];
    daysList &&
      daysList.forEach((days) => {
        if (days.isSelected) {
          dayIds.push(days.id);
          defaultSelectedValue.push(days.shortName);

        }
      });
    if (dayIds?.length > 1 && dayIds?.length < 7) {
      setselectDay(constants.UPDATE_FIRMWARE_MULTIPLE);
    } else if (dayIds?.length === 7) {
      setselectDay(constants.UPDATE_FIRMWARE_ALL_DAYS);
    } else if (dayIds?.length === 1) {
      const day =
        dayIds?.length === 1
          ? Utils.getWeekDays(dayIds)?.find((day) => day?.id === dayIds[0])
              ?.day
          : 'Sunday';
      setselectDay(day);
    }
    setSelectedIds(dayIds);
    setUpdateDayStaus(false);
    setSelectedDaysValues(defaultSelectedValue);
}, [updateDayStaus]);

useEffect(() => {
  getAllBitRates();
}, []);

useEffect(() => {
  setAllBrs(deviceInfos?.properties?.['max-br'] ? deviceInfos?.properties?.['max-br'] : [])
}, [deviceInfos]);

useEffect(() => {
  setIsOverlaping(false);
  setShowMsg(false);
  checkOverlaping()
}, [selectedIds, startTime, endTime]);

const checkOverlaping = () => {
  if (!selectedIds.length){
    return;
  }
  if(startTime >= endTime) {
    setIsOverlaping(true);
    setShowMsg(true)
    return;
  }
  allBrs.map((br) => {
    if(isEditMode !== br['mbr-index']){
      let isCommonDay = false;
      selectedIds?.forEach((id) => {
        if(br['mbr-day'].includes(id)){
          isCommonDay = true;
        }
      })

      if( isCommonDay && (br['mbr-st'] === startTime || startTime === br['mbr-et'] ||
      (br['mbr-st'] < startTime && startTime < br['mbr-et']))){
        setIsOverlaping(true);
        setShowMsg(true);
      }
      if( isCommonDay && (br['mbr-st'] === endTime || endTime === br['mbr-et'] ||
        (br['mbr-st'] < endTime && endTime < br['mbr-et']))){
        setIsOverlaping(true)
        setShowMsg(true);
      }
      if( isCommonDay && (startTime < br['mbr-st'] && br['mbr-st'] < endTime)){
        setIsOverlaping(true);
        setShowMsg(true);
      }
      if( isCommonDay && (startTime < br['mbr-et'] && br['mbr-et'] < endTime)){
        setIsOverlaping(true)
        setShowMsg(true);
      }
    }
  })
}
const setStartEndTime = (epochValue, isTimeStart) => {
  if (isNaN(epochValue)) return;
    const date = new Date(epochValue);
    const calculatedTime = (date.getHours() * 60) + date.getMinutes();
    if (isTimeStart) {
      setStartTime(calculatedTime);
      setStartTimeMoment(epochValue)
    } else {
      setEndTime(calculatedTime);
      setEndTimeMoment(epochValue)
    }
    checkOverlaping(calculatedTime)
  };

  const commaSeparatedDays = () => {
    return selectedDaysValues.join(', ');
  };

  useEffect(() => {
    if (formRef?.current && saveDeviceNetworkSettingsFlag) {
      formRef?.current?.handleSubmit();
    }
  }, [saveDeviceNetworkSettingsFlag]);

  const getCheckPropertyStatus = (value, key) => {
     return {
      [key]: value
        ? constants.PROPERTY_STATUS_TRUE
        : constants.PROPERTY_STATUS_FALSE,
    };
  };

  const renderTimePicker = (timeValue, isStart) => {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopTimePicker
          onChange={(newValue) => {
            setStartEndTime(newValue, isStart);
          }}
          value={timeValue}
          renderInput={(params) => <TextField />}
        />
      </LocalizationProvider>
    );
  };

  const renderReactSlider = (props, state) => {
     setkbpsValue(state.value);
    return (
      <div {...props}>
        {state?.index === 0 && (
          <span className="range-slider-start-value">{min}</span>
        )}
        {state?.index === 1 && (
          <span className="range-slider-last-value">{max}</span>
        )}
      </div>
    );
  };

  const getAllBitRates = () => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();
    if (!accountId) {
      return;
    }
    // Send the request
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${deviceInfos.gatewayId}`,
        from: `${accountId}`,
        msg: {
          action: 'get',
          resource: `ch/${deviceId}/${resourceMaxBr[0]}`,
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
     mqttPublish(context);
  };

  const deleteMaxStrRequest = () => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();
    if (!accountId) {
      return;
    }
    // Send the request
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${deviceInfos.gatewayId}`,
        from: `${accountId}`,
        msg: {
          resource: `ch/${deviceId}/${resourceMaxBr[0]}`,
          action: 'delete',
          properties: {
            'mbr-index': isEditMode,
          },
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
     mqttPublish(context);
     setIsEditMode(null)
     setNetworkContinueModal(false);
  };

  const addMaxStrRequest = () => {
    setNetworkContinueModal(false);
    const sessionId = state.getSessionId();
    if (!accountId) {
      return;
    }
   // Send the request
   const tid = Math.floor(new Date().getTime());
   const context = {
       topic: appTopic,
       payload: JSON.stringify({
       tid: `${tid}`,
       to: `${deviceInfos.gatewayId}`,
       from: `${accountId}`,
       msg: {
         action: 'add',
         resource: `ch/${deviceId}/${resourceMaxBr[0]}`,
         properties:bitrateProps,
       },
       publish: `d/notify/${accountId}/${sessionId}`,
     }),
     qos: 0,
   };
   mqttPublish(context);
   const props = [...deviceInfos.properties['max-br'] ? deviceInfos.properties['max-br'] : [], ...[bitrateProps] ]
   updateStore({'max-br':  props});
   resetProps();
 };

 const preUpdateKBPSRequest = (status, br, index) => {

  const newList  = JSON.parse(JSON.stringify(allBrs))
  newList[index]['mbr-enable'] = status['mbr-enable'];
  setAllBrs(newList);

  const props = {
    'mbr-index': br?.['mbr-index'],
    'mbr-enable': status?.['mbr-enable'],
    'mbr-kbps': br?.['mbr-kbps'],
    'mbr-day': br?.['mbr-day'],
    'mbr-st': br?.['mbr-st'],
    'mbr-et': br?.['mbr-et'],
  };
  updateMaxBRRequest(props)
 }

 const preUpdateRequest = () => {
  const newList  = JSON.parse(JSON.stringify(allBrs));
  let index = -1;

  allBrs.forEach((br, ind) => {
    if(br['mbr-index'] === isEditMode){
      index = ind ;
    }
  })
  bitrateProps['mbr-index'] = isEditMode;
  newList[index] = bitrateProps;
  setAllBrs(newList);
  updateMaxBRRequest(bitrateProps)
 }

 const updateMaxBRRequest = (props) => {
  setIsEditMode(null)
   const sessionId = state.getSessionId();
  if (!accountId) {
    return;
  }
  // Send the request
  const tid = Math.floor(new Date().getTime());
  const context = {
    topic: appTopic,
    payload: JSON.stringify({
      tid: `${tid}`,
      to: `${deviceInfos.gatewayId}`,
      from: `${accountId}`,
      msg: {
        action: 'set',
        resource: `ch/${deviceId}/${resourceMaxBr[0]}`,
        properties:props,
      },
      publish: `d/notify/${accountId}/${sessionId}`,
    }),
    qos: 0,
  };
  mqttPublish(context);
  setIsEditMode(null)
  setNetworkContinueModal(false);
  updateStore({'max-br':  allBrs});
};

 const getSelectedDays = (br) => {
  let days = []
  br['mbr-day'].split(',')?.forEach((day) => {
    const dayData  = Utils.getWeekDays([day])?.find((dayObj) => dayObj?.id === Number(day))
    days.push(dayData?.day)
  })
  return days.join(', ');
 }

 const onEditBr = (br) => {
  setIsEditMode(br['mbr-index'])
  const startD = new Date();
  const endD = new Date();
  const dayList = Utils.getWeekDays(br['mbr-day'].split(','));
  startD.setHours(Math.trunc(br['mbr-st']/60));
  startD.setMinutes(Math.trunc(br['mbr-st']%60));
  endD.setHours(Math.trunc(br['mbr-et']/60));
  endD.setMinutes(Math.trunc(br['mbr-et']%60));
  daysList?.forEach((days) => {
    selectedDay(days)
  });
  setDaysList(dayList);
  setStartTime(br['mbr-st']);
  setEndTime(br['mbr-et']);
  setStartTimeMoment(moment(startD));
  setEndTimeMoment(moment(endD));
  setkbpsValue(br['mbr-kbps']);
  setBitrateToggleStatus({'mbr-enable' : br['mbr-enable']})
  setNetworkContinueModal(true);
 }

 const timeConversion = (time) => {
  const hours = Math.trunc(time/60);
  const pref = hours > 11  ? 'PM' : 'AM' ;
  const hr = hours > 12 ? hours - 12 : hours;
  const hbr = hr ? hr : 12;
  const mins = time % 60;
  const finalMin = mins?.toString()?.length === 1 ? `0${mins}` : mins;
  const finalHr = hbr?.toString()?.length === 1 ? `0${hbr}` : hbr;
  return `${finalHr}.${finalMin} ${pref}`
 }

  return (
    <div className="text-start device-network-settings-block">
      <div className="tab-title">
        <label>{constants.DEVICES_TAB_NETWORK}</label>
      </div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          deviceRecord: deviceInfos?.properties?.['recEventBased'] === constants.PROPERTY_STATUS_TRUE,
        }}
        onSubmit={(values, { setSubmitting }) => {
          // TODO : Handle form submit use this later
          setSubmitting(true);
          setSubmitting(false);
          saveDeviceNetworkSettingsCallback(false);
        }}
      >
      {({ values, handleSubmit, setFieldValue }) => (
          <Form className="form" onSubmit={handleSubmit}>
      <div className="network-settings-content">
        {Utils.getPropertyShowStatus(
          curDeviceStatus,
          'ip-type',
          capNetwork
        ) && (
          <Row className="settings-block">
            <Col>
              <div className="settings-info-block-heading">
                {constants.DEVICES_TAB_NETWORK_IP_TYPE_TITLE}
              </div>
              <div className="settings-info-block-content">
                {neworkProperty ? neworkProperty?.['ip-type'] : '--'}
              </div>
            </Col>
          </Row>
        )}
        {Utils.getPropertyShowStatus(
          curDeviceStatus,
          'ip-address',
          capNetwork
        ) && (
          <Row className="settings-block">
            <Col>
              <div className="settings-info-block-heading">
                {constants.DEVICES_TAB_NETWORK_IP_ADDRESS_TITLE}
              </div>
              <div className="settings-info-block-content">
                {neworkProperty ? neworkProperty?.['ip-address'] : '--'}
              </div>
            </Col>
          </Row>
        )}
        <Row className="settings-block">
          <Col>
            <div className="settings-info-block-heading">
              {constants.DEVICES_MAC_ADDRESS_TEXT}
            </div>
            <div className="settings-info-block-content">
              {deviceInfos?.macAddress}
            </div>
          </Col>
        </Row>
        {Utils.getPropertyShowStatus(
          curDeviceStatus,
          'subnet-mask',
          capNetwork
        ) && (
          <Row className="settings-block">
            <Col>
              <div className="settings-info-block-heading">
                {constants.DEVICES_TAB_NETWORK_SUBNET_MASK_TITLE}
              </div>
              <div className="settings-info-block-content">
                {neworkProperty ? neworkProperty?.['subnet-mask'] : '--'}
              </div>
            </Col>
          </Row>
        )}
        {Utils.getPropertyShowStatus(
          curDeviceStatus,
          'primary-dns',
          capNetwork
        ) && (
          <Row className="settings-block">
            <Col>
              <div className="settings-info-block-heading">
                {constants.DEVICES_TAB_NETWORK_DNS1_TITLE}
              </div>
              <div className="settings-info-block-content">
                {neworkProperty ? neworkProperty?.['primary-dns'] : '--'}
              </div>
            </Col>
          </Row>
        )}
        {Utils.getPropertyShowStatus(
          curDeviceStatus,
          'secondary-dns',
          capNetwork
        ) && 
          neworkProperty?.['secondary-dns'] && (
          <Row className="settings-block">
            <Col>
              <div className="settings-info-block-heading">
                {constants.DEVICES_TAB_NETWORK_DNS2_TITLE}
              </div>
              <div className="settings-info-block-content">
                {neworkProperty ? neworkProperty?.['secondary-dns'] : '--'}
              </div>
            </Col>
          </Row>
        )}
        {/* <Row className="settings-block">
          <Col>
            <ToggleInput
              label={constants.DEVICES_TAB_NETWORK_RECORD_EVENTS}
              name="deviceRecord"
              value={values.deviceRecord}
              classes={curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS
                ? ' editable-off-offline'
              : ''}
              changeHandler={() => {
                setFieldValue('deviceRecord', !values.deviceRecord);
                const status = getCheckPropertyStatus(
                  !values.deviceRecord,
                  'recEventBased'
                );
                setToggleStatus(status);
                updateStore(status);
                publishDeviceSetting(
                  status,
                  resourceListRecord[0],
                  publishPayload
                );
              }}
            />
          </Col>
        </Row> */}
        {/* <div className="tab-title focus-tab">
          <label>{constants.DEVICES_TAB_MAXIMUM_STREAMING_BITRATE_TITLE}</label>
        </div>
        <div className="settings-info-block-heading">
          {constants.DEVICES_TAB_MAXIMUM_STREAMING_BITRATE_TEXT}
        </div> */}
        {/* <Row className="settings-block">
          <Col>
            {
              allBrs?.map((br, index) => (
                <div className='toggle-field-wrap mb-2'>
                  <ToggleInput
                    label={`${br['mbr-kbps']} kbps`}
                    name="kbps"
                    value={br['mbr-enable']}
                    classes={'kbps p-0'}
                    changeHandler={() => {
                      const status = {
                        'mbr-enable' : !br['mbr-enable'],
                      };
                      setBitrateToggleStatus(status);
                      preUpdateKBPSRequest(status, br, index)
                    }}
                    labelClass= {'kbps'}
                  />
                  <div className='d-flex flex-row justify-content-between align-items-center'>
                  <div className='d-flex flex-column'>

                  <div className='d-flex'>

                    <div className="label-value">{getSelectedDays(br)}</div>

                  </div>
                  <div>
                    {timeConversion(br['mbr-st'])} -
                    {' '}{timeConversion(br['mbr-et'])} {timezone}
                  </div>
                  </div>
                  <div><MdOutlineEdit size={20} onClick={() => onEditBr(br)}/></div>
                </div>
              </div>
              ))
            }
          </Col>
        </Row>
        {allBrs.length < 3 ?
          <Row className="settings-block ">
            <Col>
              <div
                 className={`setting-info-network ${curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS
                    ? ' editable-off-offline'
                    : ''}`}
                onClick={() => setNetworkMaximumModal(true)}>
                <div className="setting-plus-icon">
                  <img src={Plus} alt="plusicon icon" />
                </div>
                {constants.DEVICES_TAB_MAXIMUM_STREAMING_BITRAT_SET}
              </div>
            </Col>
          </Row> :
          <></>
        } */}
      </div>
      </Form>
        )}
          </Formik>
      <SiteModal
        modalTitle={constants.DEVICES_TAB_NETWORK_MAXIMUM_STREAMING_MODAL_TITLE}
        showModal={NetworkMaximumModal}
        hideModal={() => {
          setIsEditMode(null)
          setNetworkMaximumModal(false);
        }}
        classes="device-settings-restart-required"
      >
        <TextBlock
          className="mb-3"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_56'
          )}
        >
          <div className="contentTitle">
            {constants.DEVICES_TAB_NETWORK_MAXIMUM_STREAMING_MODAL_CONTENT}
          </div>

          <div className="contentSubTitle">
            {' '}
            {
              constants.DEVICES_TAB_NETWORK_MAXIMUM_STREAMING_MODAL__SUB_CONTENT
            }{' '}
          </div>
        </TextBlock>
        <PrimaryButton
          className="btn mb-4"
          type="button"
          width="100%"
          borderWidth="1.5px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_primary')}
          height="56px"
          fontSize="1rem"
          lineHeight="24px"
          onClick={() => {
            resetProps()
            setIsEditMode(null)
            setNetworkMaximumModal(false);
            setNetworkContinueModal(true);
          }}
        >
          {constants.DEVICES_CONTINUE_TEXT}
        </PrimaryButton>
        <Row className="settings-block">
          <Col>
            <PrimaryButton
              className="btn btn-primary-outline"
              type="button"
              width="100%"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              backgroundColor="transparent"
              hoverBackgroundColor="transparent"
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              borderWidth="1.5px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              onClick={() => {
                setNetworkMaximumModal(false);
              }}
            >
              {
                constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE
              }
            </PrimaryButton>
          </Col>
        </Row>
      </SiteModal>
      <SiteModal
        modalTitle={constants.DEVICES_TAB_MAXIMUM_STREAMING_BITRAT_SET}
        showModal={NetworkContinueModal}
        hideModal={() => {
          setNetworkContinueModal(false);
        }}
        classes="device-settings-restart-required streamingSetting"
      >
        <div>
        { showMsg ?
          <Alert
            variant={variant}
            onClose={() => setShowMsg(false)}
            dismissible
          >
            <Row>
              <Col md={1}>
                <HiOutlineExclamationCircle
                  size={15}
                  stroke={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--error_24')}
                />
              </Col>
              <Col>
                <p>{constants.DEVICES_TIME_OVERLAPPING_MESSAGE}</p>
              </Col>
            </Row>
          </Alert> : <></>
        }

          <div className="settings-block settings-block-range">
            <div className="range-field-wrap">
              <div className="range-field-container">
                <div class="choose-day">
                  {
                    constants.DEVICES_TAB_NETWORK_MAXIMUM_STREAMING_BITRATE_MODAL_SUB_CONTENT
                  }
                </div>
                <ReactSlider
                  name="test"
                  value={kbpsValue}
                  defaultValue={min}
                  min={min}
                  max={max}
                  className="range-slider"
                  trackClassName="range-slider-track"
                  thumbClassName="range-slider-thumb"
                  renderTrack={renderReactSlider}
                  renderThumb={(props, state) => (
                    <div {...props}>
                      <span className="range-slider-thumb-value">
                        {state.valueNow}
                      </span>
                    </div>
                  )}
                />
              </div>
              {/* <div className="range-fiel-label d-flex justify-content-between">
                <span>

                  {
                    constants.DEVICES_TAB_MAXIMUM_STREAMING_BITRAT_LD
                  }
                </span>
                <span>
                  {
                    constants.DEVICES_TAB_MAXIMUM_STREAMING_BITRAT_SD
                  }
                </span>
                <span>
                  {
                    constants.DEVICES_TAB_MAXIMUM_STREAMING_BITRAT_HD
                  }
                </span>
                <span>
                  {
                    constants.DEVICES_TAB_MAXIMUM_STREAMING_BITRAT_USD
                  }
                </span>
              </div> */}
            </div>
          </div>
          <div className="week-header mb-0 p-0">
            <div className="choose-day">
              {
                constants.DEVICES_TAB_NETWORK_MAXIMUM_STREAMING_BITRATE_MODAL_DAY
              }
            </div>
          </div>
          <div className={`select-day-container ${showMsg ? 'error' : ''}`}>
            {daysList?.map((days) => {
              return (
                <div
                  onClick={() => selectedDay(days)}
                  key={days.id}
                  className={`day-blocks ${days.isSelected ? 'selected' : ''}`}
                >
                  <div className="label-value">{days.dayPrefix}</div>
                </div>
              );
            })}
          </div>
          <div className="sm-lable selectd-day"> {commaSeparatedDays()}</div>
          <div className="week-header choose-time p-0 mb-2">
            <div className="choose-day site-text-block d-block mb-0">
              {constants.CHOOSE_TIME_RANGE}
              <br />
              <p className="sm-lable mb-0">
                {constants.CHOOSE_TIME_RANGE_SUB_CONTENT}{' '}{timezone}
              </p>
            </div>
          </div>
          <div className="time-container">
            <div className={`timer-start-end ${showMsg ? 'error' : ''}`}>
              {renderTimePicker(startTimeMoment, true)}
            </div>
            <div className="seprator">-</div>
            <div className={`timer-start-end ${showMsg ? 'error' : ''}`}>
              {renderTimePicker(endTimeMoment, false)}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end mt-4">
          {isEditMode !== null ?
            <PrimaryButton
              className="btn btn-primary-outline"
              type="button"
              width="auto"
              height="44px"
              fontSize="14PX"
              lineHeight="24px"
              borderWidth="1.5px"
              backgroundColor="transparent"
              hoverBackgroundColor="transparent"
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              color={getComputedStyle(document.documentElement).getPropertyValue(
                '--error_64'
              )}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              onClick={() => deleteMaxStrRequest()}

            >
              {constants.DEVICES_TAB_NETWORK_MAXIMUM_DELETE}
            </PrimaryButton> :
           null }
          <PrimaryButton
            className="btn ms-2"
            width="auto"
            type="button"
            borderWidth="1.5px"
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--brand_primary')}
            height="44PX"
            fontSize="14PX"
            lineHeight="24px"
            disabled={(!selectedIds.length) || isOverlaping}
            onClick={() =>  isEditMode !== null ? preUpdateRequest() : addMaxStrRequest() }
            >
            {isEditMode !== null ? constants.DEVICES_TAB_NETWORK_MAXIMUM_UPDATE :
            constants.DEVICES_TAB_NETWORK_MAXIMUM_ADD}
          </PrimaryButton>
        </div>
      </SiteModal>
    </div>
  );
};

export default DeviceNetworkBlock;
