import { useState, useEffect } from 'react';
import One from './Steps/StepOneEventType';
import Two from './Steps/StepTwoEventTrigger';
import Three from './Steps/StepThreeDevices';
import Four from './Steps/StepFourSheduled';
import Five from './Steps/StepFiveUsers';
import Six from './Steps/StepSixNotiType';
import { useOrganizations } from '../../../store/OrganizationsStore';
import { useSelector } from 'react-redux';
// import { getAllDevicesData } from '../../../store/reducers/AccountReducer';
import { useLoggedInUserData } from '../../../store/LoggedInAccountStore';
import { useCustomerOrgLocations } from '../../../store/CustomerOrgLocationsStore';
import { AppDefaults, roles } from '../../../helpers';
import { isObject } from 'lodash';
import { useNotificationStore } from '../../../store/CreateNotificationStore';
import { getLoggedInUserRole } from '../../../store/reducers/OrganizationsReducer';
import { getAllDevicesData } from '../../../store/AccountStoreIDB';

const NotificationSteps = (props) => {
  const [step, setstep] = useState(props.step);
  const hideModal = () => props.hideModal();
  // const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  const loggedInUserRole = useSelector(getLoggedInUserRole);
  // const devicesDetails = useSelector(getAllDevicesData);
  const [devicesDetails, setDevicesDetails] = useState([]);
  const { getSelectedLocation } = useCustomerOrgLocations();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const getEditFlowStatus = useNotificationStore(
    (state) => state.getEditFlowStatus
  );
  const selectedLocation = getSelectedLocation(loggedInUserData?.accountId);
  const [currentLocationDevices, setCurrentLocationDevices] = useState([]);
  const [stepData, setStepData] = useState([]);

  useEffect(() => {
    const fetchDevices = async () => {
      const devices = await getAllDevicesData();
      setDevicesDetails(devices);
    };

    fetchDevices();
  }, []);

  const nextStep = (data) => {
    if (data != undefined) {
      setStepData(data);
    }
    setstep(step + 1);
  };
  const prevStep = () => {
    setstep(step - 1);
  };

  useEffect(() => {
    setstep(step);
  }, [step]);

  useEffect(() => {
    if (devicesDetails?.length > 0) {
      setCurrentLocationDevices(devicesDetails);
    }
  }, [getSelectedLocation(loggedInUserData?.accountId), devicesDetails?.length]);

  // const getCurrentLocationDevices = () => {
  //   const orgDevice = devicesDetails;
  //   return orgDevice;
  // };

  switch (loggedInUserRole) {
    case roles.ROLE2PORTAL:
    case roles.ROLE7PORTAL:
      switch (step) {
        case 1:
          return (
            <>
              <One
                hideModal={() => hideModal()}
                nextStep={nextStep}
                step={step}
                isEdit={getEditFlowStatus()}
              />
            </>
          );
        case 2:
          return (
            <>
              <Two
                hideModal={() => hideModal()}
                nextStep={nextStep}
                prevstep={prevStep}
                step={step}
                isEdit={getEditFlowStatus()}
              />
            </>
          );
        case 3:
          return (
            <>
              <Three
                hideModal={() => hideModal()}
                nextStep={nextStep}
                prevstep={prevStep}
                step={step}
                currentLocationDevices={currentLocationDevices}
                stepData={stepData}
                isEdit={getEditFlowStatus()}
              />
            </>
          );
        case 4:
          return (
            <>
              <Four
                hideModal={() => hideModal()}
                nextStep={nextStep}
                prevstep={prevStep}
                step={step}
                stepData={stepData}
                role={roles.ROLE2PORTAL}
                isEdit={getEditFlowStatus()}
              />
            </>
          );
        case 5:
          return (
            <>
              <Five
                hideModal={() => hideModal()}
                nextStep={nextStep}
                prevstep={prevStep}
                step={step}
                stepData={stepData}
                isEdit={getEditFlowStatus()}
              />
            </>
          );
        case 6:
          return (
            <>
              <Six
                hideModal={() => hideModal()}
                prevstep={prevStep}
                step={step}
                stepData={stepData}
                isEdit={getEditFlowStatus()}
              />
            </>
          );

        default:
          return null;
      }
    case roles.ROLE3PORTAL:
      switch (step) {
        case 1:
          return (
            <>
              <Two
                hideModal={() => hideModal()}
                nextStep={nextStep}
                step={step}
                isEdit={getEditFlowStatus()}
              />
            </>
          );
        case 2:
          return (
            <>
              <Three
                hideModal={() => hideModal()}
                nextStep={nextStep}
                prevstep={prevStep}
                step={step}
                currentLocationDevices={currentLocationDevices}
                stepData={stepData}
                isEdit={getEditFlowStatus()}
              />
            </>
          );
        case 3:
          return (
            <>
              <Four
                hideModal={() => hideModal()}
                nextStep={nextStep}
                prevstep={prevStep}
                step={step}
                stepData={stepData}
                role={roles.ROLE3PORTAL}
                isEdit={getEditFlowStatus()}
              />
            </>
          );
        case 4:
          return (
            <>
              <Six
                hideModal={() => hideModal()}
                prevstep={prevStep}
                step={step}
                stepData={stepData}
                isEdit={getEditFlowStatus()}
              />
            </>
          );
        default:
          return null;
      }
    default:
      return null;
  }
};

export default NotificationSteps;
