import axios from "axios";
import { Utils } from "../../../helpers";

export const addDeviceAPI = async (orgDetails, values, info) => {
	if (!values?.deviceName) {
		values.deviceName = info.serialNo;
	}
	if (info?.macAddress?.search("-") > 0) {
		info.macAddress = info.macAddress.replaceAll("-", ":");
	}
	let merged = { ...info, ...values };
	try {
		const response = await axios.post(`device/orgs/${orgDetails?.orgId}/devices/v2`, merged, Utils.requestHeader());
		const data = await response.data;
		if (data.error) {
			throw new Error("Error!");
		}
		if (data.meta.code === 200 || data.meta.code === 2303) {
			return data;
		} else {
			return data.meta;
		}
	} catch (err) {
		throw new Error(err);
	}
};

export const getDefaultLocation = (locationsData) => {
	const location = locationsData?.filter((location) => location.isDefault === "true");
	return location[0]?.locationId || locationsData[0].locationId;
};

export const getDefaultArea = (locationsData) => {
	const location = locationsData?.filter((location) => location.isDefault === "true");
	if (location.length) {
		const area = location[0]?.areas?.filter((area) => area.isDefault);
		return area[0]?.areaId;
	}
};

export const addDeviceToHubAPI = async (orgDetails, info, hubId) => {
	try {
		const response = await axios.post(`device/orgs/${orgDetails?.orgId}/devices/${hubId}/v2`, info, Utils.requestHeader());
		const data = await response.data;
		if (data.error) {
			throw new Error("Error!");
		}
		if (data.meta.code === 200 || data.meta.code === 2303) {
			return data;
		} else {
			return data.meta;
		}
	} catch (err) {
		throw new Error(err);
	}
};

export const moveDeviceToHubAPI = async (orgDetails, payload, hubId) => {
	try {
		const response = await axios.put(`device/orgs/${orgDetails?.orgId}/devices/${hubId}/move/v2`, payload, Utils.requestHeader());
		const data = await response.data;
		if (data.error) {
			throw new Error("Error!");
		}
		if (data.meta.code === 200 || data.meta.code === 2303) {
			return data;
		} else {
			return data.meta;
		}
	} catch (err) {
		throw new Error(err);
	}
};
