import { useState, useCallback } from 'react';
import axios from 'axios';
import { Utils, constants, roles } from '../../helpers';
import { HiOutlineBell, HiOutlinePencil, HiOutlineUser } from 'react-icons/hi';
import { SlArrowUp, SlArrowDown } from 'react-icons/sl';
import { TbDeviceComputerCamera } from 'react-icons/tb';
import { BsFillPeopleFill, BsClock, BsPeople, BsPerson, BsShare } from 'react-icons/bs';
import CreateNotification from './CreateNotification/CreateNotification';
import './mynotification.scss';
import { useNotificationStore } from '../../store/CreateNotificationStore';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useEffect } from 'react';
import motion from '../../assets/images/motion-notification.svg';
import { TbDeviceMobile } from 'react-icons/tb';
import { LuGlobe2 } from 'react-icons/lu';
import { BsTruck } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { PiCornersInBold } from 'react-icons/pi';
import { AiOutlineCalendar } from 'react-icons/ai';
import {
  ContextMenu,
  PrimaryButton,
  SiteModal,
  SiteSpinner,
  TextBlock,
} from '../../components/common';
import { MdDelete, MdNotificationsNone } from 'react-icons/md';
import { useSelector } from 'react-redux';
// import { getAllDevicesData } from '../../store/reducers/AccountReducer';
import { IoNotificationsOffOutline } from 'react-icons/io5';
import { RiDeleteBinLine } from 'react-icons/ri';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { getAllDevicesData } from '../../store/AccountStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import { getLoggedInUserRole } from '../../store/reducers/OrganizationsReducer';

const MyNotification = () => {
  const [createNotification, showCreateNotification] = useState(false);
  const [userData, setUserData] = useState([]);
  const [deleteItem, setDeleteItem] = useState({});
  const [editFlow, showEditFlow] = useState(false);
  const [showStep, setShowStep] = useState(1);
  const setStepData = useNotificationStore((state) => state.setStepsData);
  const [deleteRule, setDeleteRule] = useState(false);
  const [pauseNotification, setPauseNotification] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const timeStamap = 95649119999000;
  const setEditFlowStatus = useNotificationStore(
    (state) => state.setEditFlowStatus
  );

  const resetNotificationData = useNotificationStore(
    (state) => state.resetStepData
  );
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  // const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  const loggedInUserRole = useSelector(getLoggedInUserRole);

  // const devicesDetails = useSelector(getAllDevicesData);
  const [devicesDetails, setDevicesDetails] = useState([]);

  // let custOrg = getCustomerOrgData();
  // const custOrgData = custOrg[0];
  const [custOrgData, setCustOrgData] = useState();  
  const [notificationList, setNotificationList] = useState([]);
  const [editListMenu, setEditListMenu] = useState([
    {
      icon: <PiCornersInBold color={getComputedStyle(document.documentElement).getPropertyValue('--brand_black')} size="1.25em" />,
      text: constants.EDIT_NOTIFICATION_EVENTS,
      clickHandler: (e, data) => {
        editFlowSteps(data, constants.EDIT_NOTIFICATION_EVENTS);
      },
    },
    {
      icon: <TbDeviceComputerCamera color={getComputedStyle(document.documentElement).getPropertyValue('--brand_black')} size="1.25em" />,
      text: constants.EDIT_NOTIFICATION_CAMERAS,
      clickHandler: (e, data) => {
        editFlowSteps(data, constants.EDIT_NOTIFICATION_CAMERAS);
      },
    },
    {
      icon: <AiOutlineCalendar color={getComputedStyle(document.documentElement).getPropertyValue('--brand_black')} size="1.25em" />,
      text: constants.EDIT_NOTIFICATION_SCHEDULED,
      clickHandler: (e, data) => {
        editFlowSteps(data, constants.EDIT_NOTIFICATION_SCHEDULED);
      },
    },
    {
      icon: <HiOutlineUser color={getComputedStyle(document.documentElement).getPropertyValue('--brand_black')} size="1.25em" />,
      text: constants.EDIT_NOTIFICATION_USERS,
      clickHandler: (e, data) => {
        editFlowSteps(data, constants.EDIT_NOTIFICATION_USERS);
      },
    },
    {
      icon: <HiOutlineBell color={getComputedStyle(document.documentElement).getPropertyValue('--brand_black')} size="1.25em" />,
      text: constants.EDIT_NOTIFICATION_TYPES,
      clickHandler: (e, data) => {
        editFlowSteps(data, constants.EDIT_NOTIFICATION_TYPES);
      },
    },
    {
      icon: <IoNotificationsOffOutline color={getComputedStyle(document.documentElement).getPropertyValue('--brand_black')} size="1.25em" />,
      text: constants.PAUSE_NOTIFICATION_TEXT,
      clickHandler: (e, data) => {
        setShowLoader(true)
        pauseIndividualNotification(data)
      },
    },
    {
      icon: <RiDeleteBinLine color={getComputedStyle(document.documentElement).getPropertyValue('--brand_black')} size="1.25em" />,
      text: constants.DELETE_NOTICATION_MENU,
      clickHandler: (e, data) => {
       !isSelfCreated(data) && deleteRuleCall(data)
      },
    },
  ]);

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustOrgData(orgs?.[0] || {});
  }, []);
  
  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    const fetchDevices = async () => {
      const devices = await getAllDevicesData();
      setDevicesDetails(devices);
    };

    fetchDevices();
  }, []);

  const editFlowSteps = async (data, stepEdit) => {
    const deviceIds = [];
    data?.devices?.map((itemDevice) => {
      deviceIds.push(itemDevice?.deviceId);
    });
    const conditions = [];
    data?.conditions?.map((item) => {
      conditions.push({
        startTime: item?.startTime,
        endTime: item?.endTime,
        days: item?.days,
        isEnabled: true,
      });
    });

    const subscribers = [];
    data?.subscribers?.map((item) => {
      subscribers.push(item?.accountId);
    });

    const selectedType = data?.triggers?.filter(
      (event) => event?.type !== 'op'
    );
    const selectedEventsList = selectedType?.map((events, index) => {
      return {
        ...events,
        resource: getEventNameFromType(events?.type)?.resource,
      };
    });
    let selectedEventsTypes = await Promise.all(selectedEventsList);

    const actionsType = data?.actions?.filter((type) => type !== 'wb');
    let selectLoc;
    devicesDetails?.map((deviceItem,index)=>{
      const deviIdExist = deviceIds?.find(
        (item) => item === deviceItem?.deviceId
      );
      if(deviIdExist){
        selectLoc = deviceItem
      }
    })

    const stepData = {
      triggers: data?.triggers,
      selectedEvents: selectedEventsTypes,
      editItem: data,
      devices: deviceIds,
      conditions: conditions,
      actions: actionsType,
      subscribers: subscribers,
    };
    if(selectLoc != undefined && selectLoc != null){
      stepData['locationId'] = selectLoc?.locationId
    }
    localStorage.setItem('titleNotification', data?.name);
    setStepData(stepData);

    switch (stepEdit) {
      case constants.EDIT_NOTIFICATION_EVENTS:
        setShowStep((loggedInUserRole === roles.ROLE2PORTAL || 
          loggedInUserRole === roles.ROLE7PORTAL) ? 1 : 1);
        break;
      case constants.EDIT_NOTIFICATION_CAMERAS:
        setShowStep((loggedInUserRole === roles.ROLE2PORTAL || 
          loggedInUserRole === roles.ROLE7PORTAL) ? 3 : 2);
        break;
      case constants.EDIT_NOTIFICATION_SCHEDULED:
        setShowStep((loggedInUserRole === roles.ROLE2PORTAL || 
          loggedInUserRole === roles.ROLE7PORTAL) ? 4 : 3);
        break;
      case constants.EDIT_NOTIFICATION_USERS:
        setShowStep((loggedInUserRole === roles.ROLE2PORTAL || 
          loggedInUserRole === roles.ROLE7PORTAL) ? 5 : 0);
        break;
      case constants.EDIT_NOTIFICATION_TYPES:
        setShowStep((loggedInUserRole === roles.ROLE2PORTAL || 
          loggedInUserRole === roles.ROLE7PORTAL) ? 6 : 4);
        break;
    }
    setEditFlowStatus(true);
    showEditFlow(true);
  };

  useEffect(() => {
    if (editFlow) {
      showCreateNotification(true);
    }
  }, [editFlow]);

  const updateExpandedItem = (item, rowIndex) => {
    const list = [...notificationList];
    list.map((data, i) => {
      if (i == rowIndex) {
        if (data.isExpanded) {
          list[i].isExpanded = false;
        } else {
          list[i].isExpanded = true;
        }
      } else {
        list[i].isExpanded = false;
      }
    });
    setNotificationList(list);
  };

  useEffect(() => {
    console.log("ruleList",notificationList)
    if (notificationList?.length > 0) {
      const list = [...notificationList];
      let isAllPaused = true;
      list.map((data, i) => {
        const subscriber = data?.subscribers;
        subscriber?.map((subscriberData) => {
          if (subscriberData?.accountId === loggedInUserData?.accountId) {
            if (subscriberData?.pauseExpiry === -1) {
              isAllPaused = false;
            }
          }
        });
      });
      setPauseNotification(isAllPaused)
    }
  }, [notificationList]);

  const hideModal = () => {
    resetNotificationData();
    showCreateNotification(false);
    getNotification();
  };

  const createNotificationCall = () => {
    resetNotificationData();
    localStorage.removeItem('titleNotification');
    showCreateNotification(true);
  };

  const pauseResetNotification = () =>{
    setShowLoader(true)
    setPauseNotification(!pauseNotification)
    pauseAll(!pauseNotification ? timeStamap : -1)
  }

  useEffect(() => {
    if (custOrgData?.orgId) {
      fetchUser();
      getNotification();
      if (loggedInUserRole === roles.ROLE3PORTAL) {
        const editList = editListMenu?.filter(function (element) {
          return element.text !== constants.EDIT_NOTIFICATION_USERS;
        });
        setEditListMenu(editList);
      }
    }
  }, [custOrgData?.orgId]);

  const getEventNameFromType = (type) => {
    switch (type) {
      case 'ai.duclo.PersonDetected':
        return {
          triggerName: constants.NOTIFICATION_CREATE_NOTIFICATION_PERSON,
          icon: <BsPeople size={22} color={getComputedStyle(document.documentElement).getPropertyValue('--greyscale_40')} />,
          resource: Utils.getNotificationResources().person,
        };
      case 'ai.duclo.VehicleDetected':
        return {
          triggerName: constants.NOTIFICATION_CREATE_NOTIFICATION_VEHICLE,
          icon: <BsTruck size={22} color={getComputedStyle(document.documentElement).getPropertyValue('--greyscale_40')} />,
          resource: Utils.getNotificationResources().vehicle,
        };
      case 'ai.duclo.CameraStatus':
        return {
          triggerName: constants.NOTIFICATION_CREATE_NOTIFICATION_CAMERA,
          icon: (
            <TbDeviceComputerCamera size={22} color={getComputedStyle(document.documentElement).getPropertyValue('--greyscale_40')} />
          ),
          resource: Utils.getNotificationResources().camera,
        };
      case 'ai.duclo.Loitering':
        return {
          triggerName: constants.NOTIFICATION_CREATE_NOTIFICATION_LOITERING,
          icon: (
            <TbDeviceComputerCamera size={22} color={getComputedStyle(document.documentElement).getPropertyValue('--greyscale_40')} />
          ),
          resource: Utils.getNotificationResources().loitering,
        };
      case 'ai.duclo.Tampering':
        return {
          triggerName: constants.NOTIFICATION_CREATE_NOTIFICATION_TAMPERING,
          icon: (
            <TbDeviceComputerCamera size={22} color={getComputedStyle(document.documentElement).getPropertyValue('--greyscale_40')} />
          ),
          resource: Utils.getNotificationResources().tempering,
        };
      case 'ai.duclo.SoundRecognition':
        return {
          triggerName: constants.NOTIFICATION_CREATE_NOTIFICATION_GEBS,
          icon: (
            <TbDeviceComputerCamera size={22} color={getComputedStyle(document.documentElement).getPropertyValue('--greyscale_40')} />
          ),
          resource: Utils.getNotificationResources().sound,
        };
      case 'ai.duclo.MotionDetected':
        return {
          triggerName: constants.NOTIFICATION_CREATE_NOTIFICATION_MOTION,
          icon: motion,
          resource: Utils.getNotificationResources().motion,
        };
      default:
        break;
    }
  };

  const getNotificationType = (type) => {
    switch (type) {
      case 'pn':
        return {
          typeName: constants.NOTIFICATION_CREATE_STEP_SIX_PUSH,
          icon: <TbDeviceMobile size={22} color={getComputedStyle(document.documentElement).getPropertyValue('--greyscale_40')} />,
        };
      case 'em':
        return {
          typeName: constants.NOTIFICATION_CREATE_STEP_SIX_EMAIL,
          icon: <HiOutlineMail size={22} color={getComputedStyle(document.documentElement).getPropertyValue('--greyscale_40')} />,
        };
      case 'wb':
        return {
          typeName: constants.NOTIFICATION_CREATE_STEP_SIX_WEB,
          icon: <LuGlobe2 size={22} color={getComputedStyle(document.documentElement).getPropertyValue('--greyscale_40')} />,
        };
      default:
        break;
    }
  };

  const getDeviceName = (device) => {
    const deviceData = custOrgData?.devices.find(
      (item) => item.deviceId === device?.deviceId
    );
    return deviceData;
  };

  const getNotification = async () => {
    try {
      const response = await axios.get(
        `partner/orgs/${custOrgData?.orgId}/rules`,
        Utils.requestHeader()
      );
      if (response) {
        setShowLoader(false);
        const notiList = response?.data?.data;
        setNotificationList(notiList);
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      // TODO : to print the error will update this later
      console.error(error);
    }
  };

  const getSelectedDaysFromId = (daysId) => {
    const daysValue = [];
    const fillterDevice = Utils.getWeekDays(daysId);
    fillterDevice?.map((item, index) => {
      if (item?.isSelected) {
        daysValue.push(item?.shortName);
      }
    });
    return daysValue?.join(',');
  };

  const fetchUser = async () => {
    try {
      await axios
        .get(`partner/orgs/${custOrgData?.orgId}/accounts`,
        Utils.requestHeader())
        .then((response) => {
          let resultData = response?.data?.data;
          setUserData(resultData);
        });
    } catch (error) {
      // TODO : to print the error will update this later
      console.error(error);
    }
  };

  const isSelfCreated = (item) => {
    let isAdmin = false;
    const userList = item?.subscribers;
    userList?.map((pushData) => {
      if (pushData?.accountId === loggedInUserData?.accountId) {
        if (item?.owner) {
          isAdmin = true;
        }
      }
    });
    return isAdmin;
  };

  const isNotificationPaused = (item) => {
    let isPaused = true;
    const userList = item?.subscribers;
    userList?.map((pushData) => {
      if (pushData?.accountId === loggedInUserData?.accountId) {
        if (pushData?.pauseExpiry === -1) {
          isPaused = false;
        }
      }
    });
    return isPaused;
  };

  const deleteRuleCall = (item) => {
    setDeleteItem(item);
    setDeleteRule(true);
  };
  const deleteRuleApiCall = async () => {
    try {
      const response = await axios.delete(
        `partner/orgs/${custOrgData?.orgId}/rules/${deleteItem?.ruleId}`,
        Utils.requestHeader()
      );
      if (response) {
        setDeleteRule(false);
        getNotification();
      }
    } catch (error) {
      // TODO : to print the error will update this later
      console.error(error);
    }
  };

  const updateEditListMenu = (item) => {
    const itemToAdd = {
      icon: !isNotificationPaused(item) ? (
        <IoNotificationsOffOutline
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--brand_black'
          )}
          size="1.25em"
        />
      ) : (
        <MdNotificationsNone
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--brand_black'
          )}
          size="1.25em"
        />
      ),
      text: !isNotificationPaused(item)
        ? constants.PAUSE_NOTIFICATION_TEXT
        : constants.RESUME_NOTIFICATION_TEXT,
      clickHandler: (e, data) => {
        setShowLoader(true);
        pauseIndividualNotification(data);
      },
    };
    const editList = editListMenu?.filter(function (element) {
      return element;
    });
    const menuItemList = editList?.map((u) =>
      u.text === constants.PAUSE_NOTIFICATION_TEXT ||
      u.text === constants.RESUME_NOTIFICATION_TEXT
        ? itemToAdd
        : u
    );
    setEditListMenu(menuItemList)
  };

  const pauseIndividualNotification = async (item) => {
    try {
      const reqBody = {
        pauseExpiry: !isNotificationPaused(item) ? timeStamap  : -1
      }
      const response = await axios.put(
        `partner/orgs/${custOrgData?.orgId}/rules/${item?.ruleId}/pause`, reqBody,
        Utils.requestHeader()
      );
      if (response) {
       getNotification();
      }
      else{
        setShowLoader(false)
      }
    } catch (error) {
      setShowLoader(false)
    }
  };

  const pauseAll = async (pauseExpiryValue) => {
    try {
      const reqBody = {
        pauseExpiry: pauseExpiryValue
      }
      const response = await axios.put(
        `partner/orgs/${custOrgData?.orgId}/rules/pause`, reqBody,
        Utils.requestHeader()
      );
      if (response) {
        getNotification();
      }
      else{
        setShowLoader(false)
      }
    } catch (error) {
      setShowLoader(false)
    }
  };

  return (
    <div className="my-notification">
      <div className="header-layout ">
        <div className="my-notification-label">
          {constants.NOTIFICATION_MY_NOTIFICATION}
          {showLoader && <SiteSpinner height="40px" width="40px"></SiteSpinner>}
        </div>

        <div className="right-container-btn">
          <PrimaryButton
            className="adddevice-btn btn2 setup-new-noti-btn"
            fontSize="14px"
            height="44px"
            color={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            width="auto"
            type="submit"
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--brand_white')}
            borderColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            onClick={() => pauseResetNotification()}
          >
            {pauseNotification
              ? constants.RESUME_NOTIFICATION_BTN_LABEL
              : constants.PAUSE_NOTIFICATION_BTN_LABEL}
          </PrimaryButton>
          <PrimaryButton
            className="adddevice-btn btn2 setup-new-noti-btn"
            fontSize="14px"
            height="44px"
            color="#FFFFFF"
            width="auto"
            type="submit"
            onClick={() => createNotificationCall()}
          >
            {constants.NOTIFICATION_SETUP_NEW}
          </PrimaryButton>
        </div>
      </div>

      <div className="inner-card">
        {Array.isArray(notificationList) && notificationList?.length > 0 ? (
          notificationList?.map((item, rowIndex) => (
            <div key={rowIndex} className="notification-item-container">
              <div className="list-item">
                <div className="left-layout">
                  {item?.name}
                  {isNotificationPaused(item) && (
                    <div className="paused-title">
                      <IoNotificationsOffOutline
                        size={16}
                        markerEnd={5}
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--error_24')}
                      />
                      <div className="text-label">{constants.PAUSED_TEXT}</div>
                    </div>
                  )}
                  {isSelfCreated(item) && (
                    <div className="shared-title">
                      <BsShare
                        size={16}
                        markerEnd={5}
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--primary_32')}
                      />
                      <div className="text-label">{constants.SHARED_TEXT}</div>
                    </div>
                  )}
                  <div
                    className={`header-title ${
                      isSelfCreated(item) ? '' : 'admin'
                    }`}
                  ></div>
                </div>
                <div className="right-layout">
                  {!isSelfCreated(item) && (
                    <div onMouseOver={()=>updateEditListMenu(item) }
                      className="icon-header"
                    >
                      <ContextMenu
                        iconButton={<HiOutlinePencil />}
                        menuId={item?.ruleId}
                        menuItems={editListMenu}
                        menuMinWidth="180px"
                        customData={item}
                      />
                    </div>
                  )}
                  <div
                    onClick={() => updateExpandedItem(item, rowIndex)}
                    className="icon-header"
                  >
                    {item.isExpanded ? <SlArrowUp /> : <SlArrowDown />}
                  </div>
                </div>
              </div>
              {item.isExpanded && (
                <div className="expanded-layout">
                  <div className="heading-item">
                    {constants.NOTIFICATION_TRIGGER_EVENTS}
                  </div>
                  {item?.triggers?.map((trigger, rowIndex) => {
                    return (
                      trigger?.type !== 'op' && (
                        <div
                          key={trigger?.type}
                          className="list-item-container"
                        >
                          {trigger?.type === 'ai.duclo.MotionDetected' ? (
                            <img
                              src={getEventNameFromType(trigger?.type)?.icon}
                              className="icon-item"
                            ></img>
                          ) : (
                            getEventNameFromType(trigger?.type)?.icon
                          )}

                          <div className="item-title">
                            {getEventNameFromType(trigger?.type)?.triggerName}
                          </div>
                        </div>
                      )
                    );
                  })}
                  <div className="heading-item margin-heading">
                    {constants.NOTIFICATION_CAMERAS}
                  </div>
                  {item?.devices?.map((device, rowIndex) => {
                    return (
                      <div key={rowIndex} className="list-item-container">
                        <TbDeviceComputerCamera
                          size={22}
                          color={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--greyscale_40')}
                        />
                        <div className="item-title">
                          {getDeviceName(device)?.deviceName}
                        </div>
                      </div>
                    );
                  })}

                  <div className="heading-item margin-heading">
                    {constants.NOTIFICATION_DURATION_WHEN}
                  </div>
                  {item?.conditions?.map((duration, rowIndex) => {
                    return (
                      <div
                        key={rowIndex}
                        className="list-item-container duration"
                      >
                        <BsClock
                          size={22}
                          color={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--greyscale_40')}
                        />
                        <div className="duration-inner">
                          <div className="item-title">{`${Utils.getHoursFromValue(
                            duration?.startTime
                          )} -
                        ${Utils.getHoursFromValue(duration?.endTime)}`}</div>
                          <div className="sub-title">
                            {getSelectedDaysFromId(duration?.days)}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="heading-item margin-heading">
                    {constants.NOTIFICATION_USER}
                  </div>
                  {item?.subscribers?.map((users, rowIndex) => {
                    return (
                      <div key={rowIndex} className="list-item-container">
                        <BsPerson
                          size={22}
                          color={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--greyscale_40')}
                        />
                        <div className="item-title">
                          {`${
                            userData?.find(
                              (item) => item?.accountId === users?.accountId
                            )?.firstName
                          } - ${
                            userData?.find(
                              (item) => item?.accountId === users?.accountId
                            )?.email
                          }`}
                        </div>
                      </div>
                    );
                  })}

                  <div className="heading-item margin-heading">
                    {constants.NOTIFICATION_TYPE}
                  </div>
                  {item?.actions?.map((type, rowIndex) => {
                    return (
                      <div key={rowIndex} className="list-item-container">
                        {getNotificationType(type)?.icon}
                        <div className="item-title">
                          {getNotificationType(type)?.typeName}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="no-data-container">
            <HiOutlineBell
              size={50}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--grayscale_32')}
            />
            <div className="no-result">
              {constants.NOTIFICATION_NO_DATA_TITLE}
            </div>
            <div
              onClick={() => createNotificationCall()}
              className="message-no-result"
            >
              {constants.NOTIFICATION_SETUP_NEW}
            </div>
          </div>
        )}
      </div>

      {createNotification && (
        <CreateNotification
          createNotification={createNotification}
          setHideModal={(status) => {
            setShowStep(1);
            showEditFlow(false);
            setEditFlowStatus(false);
            hideModal(status);
          }}
          stepShow={showStep}
        />
      )}

      <SiteModal
        modalTitle={constants.DELETE_NOTIFICATION_RULE_MESSAGE}
        showModal={deleteRule}
        hideModal={() => {
          setDeleteRule(false);
        }}
        classes="delete-modal"
      >
        <div className="circle-container">
          <div className="circle-layout">
            <div className="warning">!</div>
          </div>
        </div>

        <PrimaryButton
          className="btn btn-primary-outline mb-4"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => setDeleteRule(false)}
        >
          {constants.EDIT_CUSTOMER_REMOVE_USER_CONFIRM_CANCEL_BUTTON_TITLE}
        </PrimaryButton>
        <PrimaryButton
          className="btn btn-primary-outline text-capitalize mt-1"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          borderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          hoverBorderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--error_64'
          )}
          hoverColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_white')}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          backgroundColor="transparent"
          onClick={() => deleteRuleApiCall()}
        >
          {constants.NOTIFICATION_CREATE_DELETE_BTN}
        </PrimaryButton>
      </SiteModal>
    </div>
  );
};

export default MyNotification;
