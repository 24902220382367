import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { constants, Utils } from '../../helpers';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { useOrganizations } from '../../store/OrganizationsStore';
import {
  getMQTTConnection,
  setCDNInfo,
} from '../../store/reducers/StreamingReducer';
import {
  checkMQTTConnection,
  connectWithMQTT,
  subscribeWithOrgIds,
} from '../../utils/connection/mqttConnection';
import './ManageOrganizations.scss';
import { useKeycloak } from '@react-keycloak/web';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import { SiteSpinner } from '../../components/common';

function CustomerLandingPage() {
  const { keycloak } = useKeycloak();
  const token = keycloak.token;
  const navigate = useNavigate();

  //=== Store get/set actions
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const dispatch = useDispatch();
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  const getUserPushData = useOrganizations((state) => state.getUserPushData);
  const mqttConnection = useSelector(getMQTTConnection);
  const [custOrgData, setCustOrgData] = useState();

  // const fetchCustomerOrgData = useCallback(
  //   () => getCustomerOrgData(),
  //   [getCustomerOrgData]
  // );

  // BRAWN-4046: Redirect immediately to video wall
//   useEffect(() => {
//     navigate(
//       `/cameras/video-wall.html?orgId=${getCustomerOrgData()[0]?.orgId}${Object.keys(getUserPushData()).length > 0 && '&fromPush=true'}`
//     );
//   }, []);
  // BRAWN-4046: Redirect immediately to landling page

  const loadCustomerOrgData = useCallback(async () => {
    const customerOrgData = await getCustomerOrgData();
    setCustOrgData(customerOrgData?.[0] || {});

    if (Array.isArray(customerOrgData)) {
      //=== Subscribe the topics for all added customer organizations
      subscribeWithOrgIds(customerOrgData);
    }
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    if(loggedInUserData && loggedInUserData?.accountStatus !== 'INACTIVE' && custOrgData?.orgId){
        navigate(
            `/landing-page.html?orgId=${custOrgData?.orgId}&orgName=${custOrgData?.orgName}`
        )
    }
  }, [custOrgData?.orgId]);

  //   useEffect(() => {
  //   const customerOrgData = fetchCustomerOrgData();

  //   if (Array.isArray(customerOrgData)) {
  //     //=== Subscribe the topics for all added customer organizations
  //     subscribeWithOrgIds(customerOrgData);
  //   }
  // }, [fetchCustomerOrgData]);

  return (
    <div className="App customer-landing-page">
      {/* <div className="page-header">
        <Container>
          <Row className="g-4">
            <Col className="page-title text-start">
              {loggedInUserData?.firstName &&
                Utils.replaceStringValues(
                  constants.MANAGE_ORG_CUSTOMER_LANDING_PAGE_TITLE,
                  '$firstName',
                  `${loggedInUserData?.firstName}`
                )}
            </Col>
          </Row>
        </Container>
      </div> */}
      <div className='position-absolute top-50 l-48'>
        <SiteSpinner height="50px" width="50px" />
        <div className='mt-2 text-dark'>{constants.LOADING}</div>
      </div>
    </div>
  );
}

export default CustomerLandingPage;
