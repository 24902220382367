const SiteSpinner = (props) => {
  const {
    backgroundColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--brand_primary'),
  } = props;
  return (
    <div
      className="site-spinner"
      style={{
        backgroundColor: backgroundColor,
        width: props.width,
        height: props.height,
      }}
    ></div>
  );
};

SiteSpinner.defaultProps = {
  width: '40px',
  height: '40px',
};

export default SiteSpinner;
