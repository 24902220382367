import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Utils } from '../helpers'

let cancelToken;

export const useCustomerOrgDevices = create(
  persist(
    (set, get) => ({
      customerOrgDevices: [],
      userLocationDevices: [],
      liveViewDevices: [],
      commonDevicesfillter: [],


      // fetchCustomerOrgDevices: async (route) => {
      //   if (!route) return;

      //   // Check if there are any previous pending requests
      //   if (typeof cancelToken != typeof undefined) {
      //     cancelToken.cancel('Operation canceled due to new request.');
      //   }

      //   // Save the cancel token for the current request
      //   cancelToken = axios.CancelToken.source();

      //   try {
      //     const requestId = uuidv4();
      //     // Get location and areas first
      //     const res = await axios.get(route, {
      //       params: {
      //         requestTime: Date.now(),
      //         requestId: requestId,
      //       },
      //       ...Utils.requestHeader(requestId),
      //       timeout: 30000,
      //       credentials: 'include',
      //       withCredentials: true,
      //       cancelToken: cancelToken.token,
      //     });
      //     const response = res?.data;
      //     let result;

      //     // Set edit customer org devices if request is successful
      //     if (response?.meta?.code === 200) {
      //       let devicesData = response?.data;
      //       if (devicesData) {
      //         let deviceList = [...devicesData];
      //         let newDeviceList = deviceList?.map(async (device, index) => {
      //           if (device?.capability) {
      //             let response = await fetch(device?.capability?.url);
      //             if (response.status === 200) {
      //               let responseJson = await response.json();
      //               return { ...device, capDetails: responseJson };
      //             } else {
      //               return { ...device };
      //             }
      //           } else {
      //             return { ...device };
      //           }
      //         });
      //         let deviceCapability = await Promise.all(newDeviceList);
      //         set({
      //           customerOrgDevices: deviceCapability,
      //         });
      //       }

      //       result = {
      //         status: 'success',
      //         msg: 'get customer organizations -> devices data.',
      //       };
      //     } else {
      //       if (res?.code) {
      //         result = {
      //           status: 'failure',
      //           msg: `${res.code}: ${res.message}`,
      //         };
      //       } else if (response?.data) {
      //         result = { status: 'failure', msg: response?.data?.userMsg };
      //       } else {
      //         result = { status: 'failure', msg: 'Unknown internal API error' };
      //       }
      //     }
      //     return result;
      //   } catch (err) {
      //     console.error(err);
      //   }
      // },

      // getCustomerOrgDevices: () => {
      //   return get().customerOrgDevices;
      // },

      // setCustomerOrgDevices: (devicesData) => {
      //   if (!devicesData) return;

      //   set({
      //     customerOrgDevices: devicesData,
      //   });
      // },

      getUserLocationDevices: () => {
        return get().userLocationDevices;
      },

      setUserLocationDevices: (devicesData) => {
        if (!devicesData) return;

        set({
          userLocationDevices: devicesData,
        });
      },

      resetAllCustomerOrgDevices: () =>
        set((state) => {
          return {
            ...state,
            customerOrgDevices: [],
          };
        }),

      // getLiveViewDevices: () => {
      //   return get().liveViewDevices;
      // },

      // setLiveViewDevices: (devicesData) => {
      //   if (!Array.isArray(devicesData) || devicesData.length === 0) {
      //     return;
      //   }

      //   set({
      //     liveViewDevices: devicesData,
      //   });
      // },

      // getSelectedDeviceFromFillter: () => {
      //   return get().commonDevicesfillter;
      // },

      // setSelectedDeviceFromFillter: (commonDevicesfillter) => {
      //   if (!commonDevicesfillter) return;

      //   set({
      //     commonDevicesfillter: commonDevicesfillter,
      //   });
      // },

      // resetLiveViewDevices: () =>
      //   set((state) => {
      //     return {
      //       ...state,
      //       liveViewDevices: [],
      //     };
      //   }),
    }),
    {
      name: 'net.duclo.vms.devices',
      version: '1.3',
    }
  )
);
