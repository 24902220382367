import { openDB } from 'idb';
import { observerInstance } from './observer';
import { Utils } from '../../helpers';

const DB_NAME = 'portalDB';
const STORE_NAME = 'accountStore';

const dbPromise = openDB(DB_NAME, 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains(STORE_NAME)) {
      db.createObjectStore(STORE_NAME);
    }
  },
});

const cloneSerializable = (data) => {
  try {
    return JSON.parse(JSON.stringify(data));
  } catch (error) {
    Utils.vmsLogger().error('Failed to clone data:', error);
    return null;
  }
};

export const setItem = async (key, value) => {
  const db = await dbPromise;
  const serializableValue = cloneSerializable(value);
  if (serializableValue) {
    const result = await db.put(STORE_NAME, serializableValue, key);
    observerInstance.notifyObservers({ key, value: serializableValue });
    return result;
  } else {
    Utils.vmsLogger().error(
      'Non-serializable data detected, skipping store operation.',
      value
    );
    return null;
  }
};

export const getItem = async (key) => {
  const db = await dbPromise;
  return db.get(STORE_NAME, key);
};

export const getAllItems = async () => {
  const db = await dbPromise;
  return db.getAll(STORE_NAME);
};

export const deleteItem = async (key) => {
  const db = await dbPromise;
  const result = await db.delete(STORE_NAME, key);
  observerInstance.notifyObservers({ key, value: null });
  return result;
};
