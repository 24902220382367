import { useState, useEffect } from 'react';
import './SignUp.scss';
import { Container, Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { useLocation } from 'react-router-dom';
import { Header, PrimaryButton, TextBlock } from '../../components/common';
import { constants } from '../../helpers';

function SuccessPartner() {
  const [userAgent, setUserAgent] = useState('');
  const location = useLocation();

  //=== Set token and load the partner account requests list
  useEffect(() => {
    const uagent = navigator.userAgent.toLowerCase();
    if (
      uagent.search('iphone') > -1 ||
      uagent.search('ipod') > -1 ||
      uagent.search('ipad') > -1
    ) {
      setUserAgent('ios');
    } else if (
      uagent.search('android') > -1 ||
      uagent.search('blackberry') > -1
    ) {
      setUserAgent('android');
    } else if (uagent.search('webos') > -1) {
      setUserAgent('web');
    } else {
      setUserAgent('web');
    }
  });

  return (
    <>
      <div className="marketing-pages">
        <Header isAccountScreen={true} showNavigation="false" />
        <section className="main-page">
          <Container>
            <Row className="justify-content-md-center">
              <Col xs lg="11">
                <Container className="main-page-content mt-5 text-center">
                  <Row>
                    <Col className="remove-padding-responsive">
                      <div className="card main-card">
                        <div className="card-body text-left">
                          <div className="text-center mb-1">
                            <TextBlock
                              fontWeight="500"
                              fontSize="1.25rem"
                              lineHeight="28px"
                            >
                              {constants.PARTNER_THANK_YOU_PAGE_HEADING}
                            </TextBlock>
                          </div>

                          <div className="text-center mb-3">
                            <TextBlock
                              fontWeight="700"
                              fontSize="1.5rem"
                              lineHeight="32px"
                              color="#111418"
                            >
                              {constants.PARTNER_THANK_YOU_PAGE_SUB_HEADING}
                            </TextBlock>
                          </div>

                          <div className="text-center mb-4">
                            <TextBlock
                              fontWeight="500"
                              fontSize="0.875rem"
                              lineHeight="20px"
                            >
                              {
                                constants.PARTNER_THANK_YOU_PAGE_FIELDS_INFO_HEADING
                              }
                            </TextBlock>
                          </div>

                          <Row>
                            <Col className="remove-padding-responsive">
                              <div className="card customer-success-container remove-padding-responsive">
                                <div className="card-body remove-padding-responsive text-start">
                                  <Container>
                                    <Row>
                                      <Col className="became-partner-app-detail">
                                        {constants.APPLICATION_DETAIL}
                                      </Col>
                                    </Row>
                                    <Row
                                      className={`success-block-text ${
                                        location?.state?.orgName ? '' : 'd-none'
                                      }`}
                                    >
                                      <Col>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.75rem"
                                          lineHeight="16px"
                                        >
                                          {constants.BUSSINESS_NAME_FIELD_LABEL}
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {location?.state?.orgName &&
                                            location?.state?.orgName}
                                        </TextBlock>
                                      </Col>
                                    </Row>
                                    <Row
                                      className={`success-block-text ${
                                        location?.state?.stepPartnerId
                                          ? ''
                                          : 'd-none'
                                      }`}
                                    >
                                      <Col>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.75rem"
                                          lineHeight="16px"
                                        >
                                          {constants.STEP_PARTNER_CODE}
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {location?.state?.stepPartnerId &&
                                            location?.state?.stepPartnerId}
                                        </TextBlock>
                                      </Col>
                                    </Row>
                                    <Row
                                      className={`success-block-text ${
                                        location?.state?.salesPersonName
                                          ? ''
                                          : 'd-none'
                                      }`}
                                    >
                                      <Col>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.75rem"
                                          lineHeight="16px"
                                        >
                                          {constants.SALES_PERSON_NAME}
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {location?.state?.salesPersonName &&
                                            location?.state?.salesPersonName}
                                        </TextBlock>
                                      </Col>
                                    </Row>
                                    <Row
                                      className={`success-block-text ${
                                        location?.state?.addressLine1
                                          ? ''
                                          : 'd-none'
                                      }`}
                                    >
                                      <Col>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.75rem"
                                          lineHeight="16px"
                                        >
                                          {
                                            constants.BUSSINESS_ADDRESS_FIELD_LABEL
                                          }
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {location?.state?.addressLine1 &&
                                            location?.state?.addressLine1}
                                        </TextBlock>
                                      </Col>
                                    </Row>
                                    <Row
                                      className={`success-block-text ${
                                        location?.state?.firstName ||
                                        location?.state?.lastName
                                          ? ''
                                          : 'd-none'
                                      }`}
                                    >
                                      <Col>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.75rem"
                                          lineHeight="16px"
                                        >
                                          {
                                            constants.BUSSINESS_CONTACT_FIELD_LABEL
                                          }
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {location?.state?.firstName &&
                                            location?.state?.firstName}{' '}
                                          {location?.state?.lastName &&
                                            location?.state?.lastName}
                                        </TextBlock>
                                      </Col>
                                    </Row>
                                    <Row
                                      className={`success-block-text ${
                                        location?.state?.emailId ? '' : 'd-none'
                                      }`}
                                    >
                                      <Col>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.75rem"
                                          lineHeight="16px"
                                        >
                                          {
                                            constants.BUSSINESS_EMAIL_ADDRESS_FIELD_LABEL
                                          }
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {location?.state?.emailId &&
                                            location?.state?.emailId}
                                        </TextBlock>
                                      </Col>
                                    </Row>
                                    <Row
                                      className={`success-block-text ${
                                        location?.state?.phoneNumber
                                          ? ''
                                          : 'd-none'
                                      }`}
                                    >
                                      <Col>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.75rem"
                                          lineHeight="16px"
                                        >
                                          {
                                            constants.BUSSINESS_PHONE_FIELD_LABEL
                                          }
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {location?.state?.phoneNumber &&
                                            location?.state?.phoneNumber}
                                        </TextBlock>
                                      </Col>
                                    </Row>
                                    <Row
                                      className={`success-block-text ${
                                        location?.state?.orgWebsite
                                          ? ''
                                          : 'd-none'
                                      }`}
                                    >
                                      <Col>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.75rem"
                                          lineHeight="16px"
                                        >
                                          {
                                            constants.BUSSINESS_WEBSITE_NAME_FIELD_LABEL
                                          }
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {location?.state?.orgWebsite &&
                                            location?.state?.orgWebsite}
                                        </TextBlock>
                                      </Col>
                                    </Row>
                                    <Row
                                      className={`success-block-text ${
                                        location?.state?.orgLinkedInProfile
                                          ? ''
                                          : 'd-none'
                                      }`}
                                    >
                                      <Col>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.75rem"
                                          lineHeight="16px"
                                        >
                                          {
                                            constants.BUSSINESS_LINKEDIN_PAGE_FIELD_LABEL
                                          }
                                        </TextBlock>
                                        <TextBlock
                                          fontWeight="500"
                                          fontSize="0.875rem"
                                          lineHeight="20px"
                                          color="#000000"
                                        >
                                          {location?.state
                                            ?.orgLinkedInProfile &&
                                            location?.state?.orgLinkedInProfile}
                                        </TextBlock>
                                      </Col>
                                    </Row>
                                  </Container>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row className="mt-4 mb-2">
                            <Col className="remove-padding-responsive">
                              <PrimaryButton
                                className="btn btn-primary w-100"
                                onClick={() => {
                                  if (userAgent === 'web') {
                                    window.location.replace(
                                      process.env
                                        .REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL
                                    );
                                  } else if (userAgent === 'android') {
                                    window.location.replace(
                                      `${process.env.REACT_APP_MOBILE_URL}login`
                                    );
                                  } else if (userAgent === 'ios') {
                                    window.location = `${process.env.REACT_APP_MOBILE_URL}login`;
                                  } else {
                                    window.location.replace(
                                      process.env
                                        .REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL
                                    );
                                  }
                                }}
                                type="submit"
                              >
                                {constants.BACK_TO_HOME_BUTTON_TITLE}
                              </PrimaryButton>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>{' '}
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
}

export default SuccessPartner;
