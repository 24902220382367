import { getItem, setItem } from './indexDB/db';

export const getSnapshotImages = async () => {
  const result = await getItem('snapshotImages');
  return result || {};
};

export const setSnapshotImages = async (data) => {
  const result = await getItem('snapshotImages');
  return await setItem('snapshotImages', {
    ...result,
    [data.id]: data.snapshotImage,
  });
};
