import React, { useEffect, useRef } from 'react';
import 'moment-timezone';
import '../../assets/css/timeline.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLiveStream,
  setIsRemoteStreamPlay,
} from '../../store/reducers/StreamingReducer';

const PlaybackControlsView = ({ muteAudio }) => {
  const remoteStream = useSelector(getLiveStream);
  const dispatch = useDispatch();
  const remoteVideoRef = useRef();

  useEffect(() => {
    dispatch(setIsRemoteStreamPlay(false));
  }, []);

  useEffect(() => {
    const setStreamPlay = () => {
      dispatch(setIsRemoteStreamPlay(true));
    };
    if (remoteStream?.active) {
      const remoteVideo = remoteVideoRef.current;
      remoteVideo.srcObject = remoteStream;
      remoteVideoRef.current.addEventListener('play', setStreamPlay);
    }
    return () => {
      remoteVideoRef?.current?.removeEventListener('play', setStreamPlay);
    };
  }, [remoteStream]);

  return (
    remoteStream?.active && (
      <div className="remote-view-wrapper">
        <video
          id="video2"
          width="764"
          height="450"
          ref={remoteVideoRef}
          autoPlay={true}
          playsInline={true}
          muted={muteAudio}
        />
        <canvas id="canvas2" width="570" height="450"></canvas>
      </div>
    )
  );
};

export default PlaybackControlsView;
