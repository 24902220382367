import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { HiOutlineCalendar } from 'react-icons/hi';
import moment from 'moment';

import {
  ListBlock,
  PrimaryButton,
  SiteModal,
} from '../../../../components/common';
import { constants, Utils } from '../../../../helpers';
import useLicensesStore from '../../../../store/LicensesStore';
import AddLicenseForm from './AddLicenseForm';
import DetachLicenseForm from './DetachLicenseForm';
import { CiWarning } from "react-icons/ci";

import './LicensesBlock.scss';

const DATE_FORMAT = 'DD MMM YYYY';

const LicensesBlock = ({
  orgId,
  orgName,
  deviceId,
  deviceName,
  showAddModal,
  hideAddModalCallback,
  showRemoveModal,
  hideRemoveModalCallback,
}) => {
  const { licenses, getLicenses, setLicenses } = useLicensesStore();
  const navigate = useNavigate();
  const [deviceLicenses, setDeviceLicenses] = useState([]);
  const [licenseName, setLicenseName] = useState('');
  const [licenseKey, setLicenseKey] = useState('');
  const [lastExpiryDate, setLastExpiryDate] = useState();
  const [availableLicenses, setAvailableLicenses] = useState([...licenses]);
  const [showAddLicenseModal, setShowAddLicenseModal] = useState(showAddModal);
  const [showDetachLicenseModal, setShowDetachLicenseModal] =
    useState(showRemoveModal);

  let lastExpirationDate;

  useEffect(() => {
    try {
      if (!Array.isArray(licenses) || licenses.length < 1) {
        fetchLicenses();
      } else {
        setDeviceLicenses([
          ...licenses.filter((license) => license.deviceId === deviceId),
        ]);
      }
    } catch (error) {
      console.error('ERROR: ', error);
    }
  }, []);

  useEffect(() => {
    setAvailableLicenses([...licenses]);
  }, [licenses]);

  useEffect(() => {
    setShowAddLicenseModal(showAddModal);
  }, [showAddModal]);

  const fetchLicenses = async () => {
    let licensesLoadSuccess = await setLicenses(`/user/orgs/${orgId}/licenses`);

    if (licensesLoadSuccess?.status === 'success') {
      const custLicenses = getLicenses();
      await new Promise((resolve, reject) => {
        setAvailableLicenses([...custLicenses]);
        setDeviceLicenses([
          ...custLicenses.filter((license) => license.deviceId === deviceId),
        ]);
        resolve();
      });
    } else {
      // TODO: show error in an alert
    }
  };

  const detachLicenseHandler = (detachLicenseName, detachLicenseKey) => {
    if (!detachLicenseName || !detachLicenseKey) return;

    setLicenseName(detachLicenseName);
    setLicenseKey(detachLicenseKey);
    setShowDetachLicenseModal(true);
  };

  return (
    <>
      <ListBlock
        className="list-block license-block"
        contentWrapperClass="text-start"
        label={constants.ORG_DASHBOARD_LICENSES_SECTION_TITLE}
      >
        <Row>
          <Col>
            {/* to do : change later when work on locations part <Mapimage /> */}
            <ul className="license-features">
              {Array.isArray(deviceLicenses) && deviceLicenses.length > 0 ? (
                deviceLicenses.map((deviceLicense, dlIndex) => {
                  const today = moment().unix() * 1000;
                  if (!lastExpirationDate) {
                    if (deviceLicense?.offeringType === constants.LICENSE_ONBOARDING_STATUS) {
                      lastExpirationDate = today;
                    } else {
                      lastExpirationDate = deviceLicense.expiryDate;
                    }
                  } else if (lastExpirationDate < deviceLicense.expiryDate) {
                    lastExpirationDate = deviceLicense.expiryDate;
                  }

                  return (
                    <>
                      <li className="d-none d-md-flex" key={`devLicense${dlIndex}`}>
                        <Container>
                          <Row>
                            <Col>
                              <Row>
                                <Col>
                                  <div className="license-prop">
                                    {
                                      constants.DEVICES_TAB_LICENSES_LICENSE_TYPE
                                    }
                                  </div>
                                  <div className="license-prop-value">
                                    {deviceLicense.productDisplayName}
                                  </div>
                                </Col>
                                <Col>
                                  <div
                                    className={`license-action${
                                      deviceLicense?.offeringType === constants.LICENSE_ONBOARDING_STATUS ? " hidden" : ""
                                    }`}
                                    onClick={() =>
                                      detachLicenseHandler(
                                        deviceLicense.productDisplayName,
                                        deviceLicense.licenseKey
                                      )
                                    }
                                  >
                                    {
                                      constants.DEVICES_TAB_LICENSES_DETACH_LICENSE
                                    }
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="license-prop">
                                    {
                                      constants.DEVICES_TAB_LICENSES_LICENSE_START_DATE
                                    }
                                  </div>
                                  <div className="license-prop-value">
                                    <HiOutlineCalendar size={20} />{" "}
                                    <span className="value-wrapper">
                                      {moment(deviceLicense.activeDate).format(
                                        DATE_FORMAT
                                      )}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <div className="spacer"></div>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="license-prop">
                                    {
                                      constants.DEVICES_TAB_LICENSES_LICENSE_EXPIRATION_DATE
                                    }
                                  </div>
                                  <div className="license-prop-value">
                                    <HiOutlineCalendar size={20} />{" "}
                                    <span className="value-wrapper">
                                      {moment(deviceLicense.expiryDate).format(
                                        DATE_FORMAT
                                      )}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Container>
                      </li>
                      {/* For Mobile View Button */}
                      <li className="d-md-none" key={`devLicense${dlIndex}`}>
                        <div className="device-info-section">
                          <div className="settings-block">
                            <Col>
                              <div className="settings-info-block-heading license-prop">
                                {constants.DEVICES_TAB_LICENSES_LICENSE_TYPE}
                              </div>
                              <div className="settings-info-block-content">
                                {" "}
                                {deviceLicense.productDisplayName}
                              </div>
                            </Col>
                          </div>
                          <Row className="settings-block">
                            <Col>
                              <div className="settings-info-block-heading license-prop">
                                {
                                  constants.DEVICES_TAB_LICENSES_LICENSE_START_DATE
                                }
                              </div>
                              <div className="w-100 settings-info-block-content license-prop-value">
                                <HiOutlineCalendar size={20} />{" "}
                                <span className="value-wrapper">
                                  {moment(deviceLicense.activeDate).format(
                                    DATE_FORMAT
                                  )}
                                </span>
                              </div>
                            </Col>
                            <Col>
                              <div className="settings-info-block-heading license-prop">
                                {
                                  constants.DEVICES_TAB_LICENSES_LICENSE_EXPIRATION_DATE
                                }
                              </div>
                              <div className="w-100 settings-info-block-content license-prop-value expiry-color">
                                {" "}
                                <CiWarning size={20} />{" "}
                                <span className="value-wrapper">
                                  {moment(deviceLicense.expiryDate).format(
                                    DATE_FORMAT
                                  )}
                                </span>
                              </div>
                            </Col>
                          </Row>
                          <Row className="settings-block">
                            <div
                              className={`license-action${
                                deviceLicense?.offeringType === constants.LICENSE_ONBOARDING_STATUS ? " hidden" : ""
                              }`}
                              onClick={() =>
                                detachLicenseHandler(
                                  deviceLicense.productDisplayName,
                                  deviceLicense.licenseKey
                                )
                              }
                            >
                              {constants.DEVICES_TAB_LICENSES_DETACH_LICENSE}
                            </div>
                          </Row>
                        </div>
                        {/* <Container>
                      <div className="device-info-section">
                      <Row className="settings-block">
										<Col>
											<div className="settings-info-block-heading">{'License Type'}</div>
											<div className="settings-info-block-content">{'1Year 30 Days Analytics'}</div>
										</Col>
                   </Row>
                   <Row>
                   <Col xs={6} sm={6} md={6} lg={4}>
                     <div className='settings-info-block-heading'>Start Date</div>
                     <div className='settings-info-block-content'>15 May 2023</div>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={4}>
                     <div className='settings-info-block-heading'>Expiration Date</div>
                     <div className='settings-info-block-content'>15 May 2023</div>
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={12} sm={12} md={6} lg={4}>
                    
                    </Col>
                    </Row>
                        </div>
                      </Container> */}
                      </li>
                    </>
                  );
                })
              ) : Array.isArray(availableLicenses) &&
                availableLicenses.length < 1 ? (
                <li>
                  <Container>
                    <Row>
                      <Col>
                        <Row>
                          <div className="no-license-attached">
                            {constants.DEVICES_TAB_LICENSES_NO_LICENSE_ATTACHED}
                          </div>
                        </Row>
                        <Row>
                          <div className="no-licenses-available">
                            {
                              constants.DEVICES_TAB_LICENSES_NO_LICENSE_AVAILABLE
                            }
                          </div>
                        </Row>
                        {/* <Row>
                          <div className="purchase-button-container">
                            <PrimaryButton
                              width="200px"
                              onClick={() => {
                                navigate(
                                  `/products/listing.html?orgId=${orgId}&orgName=${orgName}`
                                );
                              }}
                            >
                              {constants.DEVICES_TAB_LICENSES_PURCHASE_LICENSE}
                            </PrimaryButton>
                          </div>
                        </Row> */}
                      </Col>
                    </Row>
                  </Container>
                </li>
              ) : (
                <li>{constants.DEVICES_TAB_LICENSES_NO_LICENSE_ATTACHED}</li>
              )}
            </ul>
          </Col>
        </Row>
      </ListBlock>
      {/* MODALS */}
      {/* Attach License Modal */}
      <SiteModal
        modalTitle={Utils.replaceStringValues(
          constants.DEVICES_TAB_LICENSES_MODAL_TITLE,
          '$deviceName',
          deviceName
        )}
        showModal={showAddLicenseModal}
        hideModal={() => {
          setShowAddLicenseModal(false);
          if (
            hideAddModalCallback &&
            hideAddModalCallback.constructor !== String &&
            hideAddModalCallback.constructor !== Number &&
            hideAddModalCallback.constructor !== Object &&
            hideAddModalCallback.constructor !== Array
          ) {
            hideAddModalCallback();
          }
        }}
        classes="add-license-modal"
      >
        <AddLicenseForm
          deviceId={deviceId}
          orgId={orgId}
          expiryDate={lastExpirationDate}
          callBack={() => {
            fetchLicenses();
            setShowAddLicenseModal(false);
          }}
        />
      </SiteModal>
      {/* Detach License */}
      <SiteModal
        modalTitle={Utils.replaceStringValues(
          constants.DEVICES_TAB_LICENSES_DETACH_LICENSE_MODAL_TITLE,
          '$licenseName',
          licenseName
        )}
        showModal={showDetachLicenseModal}
        hideModal={() => {
          setShowDetachLicenseModal(false);
          if (
            hideRemoveModalCallback &&
            hideRemoveModalCallback.constructor !== String &&
            hideRemoveModalCallback.constructor !== Number &&
            hideRemoveModalCallback.constructor !== Object &&
            hideRemoveModalCallback.constructor !== Array
          ) {
            hideRemoveModalCallback();
          }
        }}
        classes="detach-license-modal"
      >
        <DetachLicenseForm
          deviceId={deviceId}
          licenseKey={licenseKey}
          orgId={orgId}
          callBack={() => {
            fetchLicenses();
            setShowDetachLicenseModal(false);
          }}
        />
      </SiteModal>
    </>
  );
};

export default LicensesBlock;
