import { useState, useEffect } from "react";
import { PrimaryButton } from "../../../components/common";
import { ccimg, step3Icon, TickOutlined } from "../../../assets/images";
import { useContext } from "react";
import { DataContext } from "./DataContextProvider";
import { Utils, constants } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { getAllMqttDataFromResponse } from "../../../store/reducers/AccountReducer";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { DetailsContext } from "./DetailsContextProvider";
import { getPlatformInfo } from "../../../store/reducers/StreamingReducer";
import { addDeviceAPI } from "./addDeviceAPI";
import { mqttSubscribe } from "../../../utils/connection/mqttConnection";
import { DeviceTypeEnum } from "../../../helpers/enums";
import {
	// getAllMqttDataFromResponse,
	getSelectedOrganization,
	setAllDevicesData,
	getPassedDeviceInfoFromDeviceList,
	setPassedDeviceInfoFromDeviceList,
  } from '../../../store/AccountStoreIDB';
import { useTranslation } from "react-i18next";

const Status = {
	CLAIMING: 1,
	CLAIMED: 2,
	NOT_CLAIMED: 3,
};

const Three = (props) => {
	const expiredTime = 300;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	// const orgDetails = useSelector(getSelectedOrganization);
	const [orgDetails, setOrgDetails] = useState();
	const allMqttData = useSelector(getAllMqttDataFromResponse);
	const passedDeviceInfoFromDeviceList = useSelector(getPassedDeviceInfoFromDeviceList);
	const { info } = useContext(DetailsContext);
	localStorage.setItem("modalOpen", 1);
	const { datavalues } = useContext(DataContext);
	const [showLoader, setShowLoader] = useState(false);
	const [statusProperties, setStatusProperties] = useState({
		connectionStatus: 'offline',
		deviceStatus: 'PENDING_CLAIM'
	});
	const [currentDeviceStatus, setCurrentDeviceStatus] = useState(Status.CLAIMING);
	const [remainingTime, setRemainingTime] = useState(expiredTime);
	const [isStoppedTimer, setIsStoppedTimer] = useState(false);
	const platformDetails = useSelector(getPlatformInfo);
	const subscribeTopic = platformDetails?.mqtt?.topic_details?.subscribe?.settings;

	const goToDeviceList = (e) => {
		e.preventDefault();
		props.reloadData();
		props.hideModal();
	};

	useEffect(() => {
		if (passedDeviceInfoFromDeviceList) {
			handleTryAgainClick();
			dispatch(setPassedDeviceInfoFromDeviceList(null));
		}
	}, []);
	useEffect(()=>{
		window.scrollTo({
		  top: 0,
		  left: 0,
		  behavior: 'instant',
		})
	  },[])
    useEffect(() => {
		if (!passedDeviceInfoFromDeviceList) {
			const timerInterval = setInterval(() => {
				if (remainingTime > 0) {
					setRemainingTime((prevTime) => prevTime - 1);
				} else {
					if(!isStoppedTimer) {
						setCurrentDeviceStatus(Status.NOT_CLAIMED);
					}
					clearInterval(timerInterval);
				}
			}, 1000);
			return () => {
				clearInterval(timerInterval);
			};
		}
	}, [remainingTime]);

	useEffect(() => {
	  if(statusProperties.connectionStatus?.toUpperCase() === constants.DEVICES_RETURN_ONLINE_STATUS.toUpperCase() 
	  	&& statusProperties.deviceStatus?.toUpperCase() === constants.DEVICES_CLAIMED_DEVICE_STATUS.toUpperCase()) {
		setCurrentDeviceStatus(Status.CLAIMED);
		setIsStoppedTimer(true);
		setRemainingTime(0);
	  }
	}, [statusProperties])
	

	useEffect(() => {
		const resource = allMqttData?.msg?.resource;
		if (resource?.includes("device/") || resource?.includes("devices/")) {
			const connectionStatus = allMqttData?.msg?.properties?.connectionStatus;
  			const deviceStatus = allMqttData?.msg?.properties?.deviceStatus;
			const deviceAttributes = resource.split("/");
			if (Array.isArray(deviceAttributes)) {
				const deviceId = deviceAttributes[deviceAttributes.length - 1];
				if(deviceId === info?.deviceId) {
					if(connectionStatus?.toUpperCase() === constants.DEVICES_RETURN_ONLINE_STATUS.toUpperCase()) {
						setStatusProperties({...statusProperties, connectionStatus: constants.DEVICES_RETURN_ONLINE_STATUS});
					} else if (deviceStatus?.toUpperCase() === constants.DEVICES_CLAIMED_DEVICE_STATUS.toUpperCase()) {
						setStatusProperties({...statusProperties, deviceStatus: constants.DEVICES_CLAIMED_DEVICE_STATUS});
					}
				}
			}
		}
	}, [allMqttData]);

	const addOtherDevice = (e) => {
		e.preventDefault();
		axios.get(`device/orgs/${orgDetails?.orgId}/devices`, Utils.requestHeader()).then(async (response) => {
			const resultData = response.data;
			if (resultData) {
				const responseMeta = resultData.meta;
				const responseData = resultData.data;
				if (responseMeta && (responseMeta.code === 200 || responseMeta.code === "200")) {
					await setAllDevicesData(responseData);
				}
			}
		});
		props.firstStep();
	};

	const handleTryAgainClick = async (e) => {
		e?.preventDefault();
		setShowLoader(true);
		setRemainingTime(expiredTime - 1);
		setCurrentDeviceStatus(Status.CLAIMING);
		const information = { ...info };
		const { step2info } = information;
		delete information.step2info;
		delete information.channelCount;
		const deviceResponse = await addDeviceAPI(orgDetails, step2info, information);
		if (deviceResponse?.userMsg) {
			setShowLoader(false);
		} else {
			setShowLoader(false);
			if (Array.isArray(subscribeTopic) && subscribeTopic.length > 0) {
				const deviceSubscription = {
					topic: Utils.replaceStringValues(subscribeTopic[0], "${deviceId}", `${deviceResponse?.data?.deviceId}`),
					qos: 0,
				};
				mqttSubscribe(deviceSubscription);
			}
		}
	};

	const getAddDeviceButtonProps = () => {
		// if (currentDeviceStatus === Status.NOT_CLAIMED) {
		//   return { onClick: handleTryAgainClick };
		// }
		if (info.deviceType === DeviceTypeEnum.NVR || info.deviceType === DeviceTypeEnum.DMSERVER) {
			return {
				disabled: currentDeviceStatus !== Status.CLAIMED,
				onClick: () => {
					props.onDiscoverDevices();
					props.hideModal();
				},
			};
		} else {
			return { onClick: addOtherDevice };
		}
	};

	const getAddDeviceButtonText = () => {
		// if (currentDeviceStatus === Status.NOT_CLAIMED) {
		//   return constants.TRY_AGAIN_BUTTON_TITLE;
		// } else
		if (info.deviceType === DeviceTypeEnum.NVR || info.deviceType === DeviceTypeEnum.DMSERVER) {
			return constants.DEVICES_CONTINUE_TEXT;
		} else {
			return constants.ADD_ANOTHER_CAMERA;
		}
	};

	const getBackToDevicesListButtonText = () => {
		if ((info.deviceType === DeviceTypeEnum.NVR || info.deviceType === DeviceTypeEnum.DMSERVER) && currentDeviceStatus !== Status.NOT_CLAIMED) {
			return constants.DEVICES_SETUP_LATER_TEXT;
		} else {
			return constants.BACK_TO_DEVICES;
		}
	};

	return (
		<>
			<div className="ctn-box innner">
				<div className="mb-3">
					<div className="headerbox">
						<div className="stepIcon">
							<img src={step3Icon} alt="step3Icon" />
						</div>
						<div className="steptext">{constants.DEVICES_STEP_THREE_OF_THREE}</div>
					</div>

					<div className="qr-block">
						<div className="qr-image">
							<img src={datavalues[6] ? datavalues[6] : ccimg} alt="Device" className="qr-image" />
						</div>
					</div>
					{currentDeviceStatus === Status.CLAIMING && (
						<>
							<div className="titlename">{constants.DEVICE_CLAIMING_TEXT}</div>
							<div className="subtext">{t("DEVICE_STATUS_MESSAGE")}</div>
						</>
					)}
					{currentDeviceStatus === Status.CLAIMED && (
						<div className="titlename">
							{constants.DEVICE_CLAIMED_TITLE} <img src={TickOutlined} alt="TickOutlined" />
						</div>
					)}
					{currentDeviceStatus === Status.NOT_CLAIMED && (
						<>
							<div className="titlename">{constants.DEVICE_NOT_CLAIMED_TEXT}</div>
							<div className="subtext">{constants.DEVICE_NOT_CLAIMED_STATUS_MESSAGE}</div>
						</>
					)}
				</div>
				<PrimaryButton className="adddevice-btn" fontSize="0.875rem" backgroundColor={getComputedStyle(document.documentElement).getPropertyValue("--primary_40")} height="44px" color={getComputedStyle(document.documentElement).getPropertyValue("--brand_white")} type="submit" loader={showLoader} {...getAddDeviceButtonProps()}>
					{getAddDeviceButtonText()}
				</PrimaryButton>
				<PrimaryButton className="adddevice-btn" fontSize="0.875rem" backgroundColor={getComputedStyle(document.documentElement).getPropertyValue("--brand_white")} height="44px" color={getComputedStyle(document.documentElement).getPropertyValue("--primary_40")} type="submit" onClick={(e) => goToDeviceList(e)}>
					{getBackToDevicesListButtonText()}
				</PrimaryButton>
			</div>
		</>
	);
};

export default Three;
