import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const TagStore = create(
  persist(
    (set, get) => ({
      tagsData: {},
      tagsList:[],
      tagsSlected:{},

      getSelectedTags: () => {
        return get().tagsSlected;
      },

      setSelectedTags: (tagsSlected) => {
        if (!tagsSlected) return;

        set({
            tagsSlected: tagsSlected,
        });
      },

      getTagsData: () => {
        return get().tagsData;
      },

      setTagsData: (tagsData) => {
        if (!tagsData) return;

        set({
            tagsData: tagsData,
        });
      },

      getTagsList: () => {
        return get().tagsList;
      },

      setTagsList: (tagsList) => {
        if (!tagsList) return;

        set({
            tagsList: tagsList,
        });
      },

      resetTagsList: () =>
      set((state) => {
        return {
          ...state,
          tagsList: [],
        };
      }),

      resetTagsData: () =>
        set((state) => {
          return {
            ...state,
            tagsData: {},
          };
        }),
    }),
    {
        name: 'net.duclo.vms.tags',
        version: '1.0',
      }
  )
);
