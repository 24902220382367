import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HiOutlineExclamation, HiOutlineCalendar } from 'react-icons/hi';
import { FaCircle } from 'react-icons/fa';
import { Button, Col, Container, Row, NavDropdown, Nav } from 'react-bootstrap';
import moment from 'moment';
import {
  BreadcrumbList,
  Header,
  SiteSpinner,
  SiteToast,
} from '../../components/common';
import { ListTable } from './';
import { constants, Utils } from '../../helpers/';
import useLicensesStore from '../../store/LicensesStore';
import PageWrapper from '../PageWrapper';
import AddLicense from './addLicense/AddLicense';
import arrowLeft from '../../assets/images/icons/Arrowleft.svg';
import dotshorizontal from '../../assets/images/icons/Dotshorizontal.svg';
import { ReactComponent as LicensesIconNoRecord } from '../../assets/images/LicensesIconNoRecord.svg';
import { usePoliciesStore } from '../../store/policiesStore';
import './ManageLicenses.scss';
import { getSelectedOrganization } from '../../store/AccountStoreIDB';

const gridFields = [
  {
    field: 'productDisplayName',
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_NAME,
  },
  {
    field: 'licenseKey',
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_LICENSE_KEY,
  },
  {
    field: 'licenseStatus',
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_STATUS,
  },
  {
    field: 'deviceName',
    displayName:
      constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_ASSIGNED_DEVICE,
  },
  {
    field: 'locationName',
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_LOCATION,
  },
  {
    field: 'activeDate',
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_START_DATE,
  },
  {
    field: 'expiryDate',
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_END_STATE,
  },
];

const getColDefs = () => {
  if (!Array.isArray(gridFields)) return;

  return gridFields.map((gridField, gridFieldIndex) => ({
    id: gridFieldIndex,
    field: gridField.field,
    displayName: gridField.displayName,
  }));
};

const DATE_FORMAT = 'MMM Do YYYY';

const ManageLicenses = () => {
  const [licensesStatus, setLicensesStatus] = useState(false);
  const [licensesUpdated, setLicensesUpdated] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [columnDefs] = useState([...getColDefs()]);
  const [rowData, setRowData] = useState([]);
  const [orgLicenses, setOrgLicenses] = useState([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const orgId = searchParams.get('orgId');
  // const orgName = searchParams.get('orgName');
  const [showClaimLicenseModal, setShowClaimLicenseModal] = useState(false);
  const [isOpenMobilePopup, setIsOpenMobilePopup] = useState(false);

  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );
  const [orgData, setOrgData] = useState();
  const [orgNameBread, setOrgNameBread] = useState('');

  const breadList = [
    {
      url: Utils.CheckLoggedInUserRole(orgId, orgNameBread)?.url,
      title: Utils.CheckLoggedInUserRole(orgId, orgNameBread)?.title,
    },
    {
      url: `/landing-page.html?orgId=${orgId}&orgName=${orgNameBread}&fromPush=true`,
      title: `${orgNameBread}`,
    },
    {
      url: `/licenses/listing.html?orgId=${orgId}&orgName=${orgNameBread}`,
      title: constants.LICENSES_PAGE_TITLE,
    },
  ];

  let { getLicenses, setLicenses, licenses } = useLicensesStore();
  const mobileBreadList = [
    {
      url: `/landing-page.html?orgId=${orgId}&orgName=${orgNameBread}&fromPush=true`,
    },
  ];

  
  useEffect(() => {
    const setOrg = async () => {
      const org = await getSelectedOrganization();
      setOrgData(org || {});
      setOrgNameBread(org?.orgName);
    };
    setOrg();
  }, []);

  useEffect(() => {
    setShowLoader(true);
    fetchData();
  }, []);

  useEffect(() => {
    if (licensesUpdated === true) {
      fetchData();
      setLicensesUpdated(false);
    }
  }, [licensesUpdated]);

  // FRED: I commented this out because it creates an infinite
  // loop.  The licenses are already being retrieved in fetchData()
  // in the useEffect above.  Please delete this block of code
  // when confirmed that everything is working properly.
  // useEffect(() => {
  //   const licenses = getLicenses(false, true);
  //   const tableRowData = Array.isArray(licenses) ? getRowData(licenses) : [];
  //   setOrgLicenses(licenses);
  //   setRowData(tableRowData);
  // }, [licenses]);

  const mobileManageLicenses = () => {
    setIsOpenMobilePopup(true);
  };

  const calculateAdjustedExpiryDate = (deviceLicense) => {
    if(deviceLicense?.expiryDate && deviceLicense?.gracePeriod){
      const calcData = moment(deviceLicense?.expiryDate - (deviceLicense?.gracePeriod * 24 * 60 * 60 * 1000));
      return deviceLicense.expiryDate = calcData;    
    }
  };

  const transformedLicenses = (filteredPaidLicenses) => 
    filteredPaidLicenses.map(license => {
      if (license?.gracePeriod > 0 && license?.expiryDate) {
        license.expiryDate = calculateAdjustedExpiryDate(license);
      }
      return {
        ...license
      };
    });
  

  // Get Licenses
  const fetchData = async () => {
    try {
      let filteredPaidLicenses;
      setShowLoader(true);
      await setLicenses(`/user/orgs/${orgId}/licenses`);
      // const licenses = getLicenses(false, true);
      filteredPaidLicenses = transformedLicenses(getLicenses(false, true));
      const tableRowData = Array.isArray(filteredPaidLicenses) ? getRowData(filteredPaidLicenses) : [];
      setOrgLicenses(licenses);
      setRowData(tableRowData);
      tableRowData?.length <= 0
        ? setLicensesStatus(true)
        : setLicensesStatus(false);
    } catch (error) {
      console.error('ERROR: ', error);
      setUserMsg(error);
      setShowToast(true);
    } finally {
      setShowLoader(false);
    }
  };

  const statusMap = {
    UNASSIGNED: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE,
    ASSIGNED_ACTIVATION_PENDING:
      constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVATION_PENDING,
    ACTIVE: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVE,
    ACTIVE_UNASSIGNED: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE,
    EXPIRING_SOON: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRING_SOON,
    EXPIRED: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRED,
  };

  const getRowData = (licenses) => {
    let licenseProps = {};
    const rowData = [];
    const licenseStatusMap = {
      UNASSIGNED: (
        <div className="status-field">
          <FaCircle className="status-indicator status-available" />
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE}
        </div>
      ),
      ASSIGNED_ACTIVATION_PENDING: (effectiveStartUnixTime) => (
        <div className="status-field">
          <FaCircle className="status-indicator status-inactive" />
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVATION_PENDING +
            ' ' +
            moment(effectiveStartUnixTime).format(DATE_FORMAT)}
        </div>
      ),
      ACTIVE: (
        <div className="status-field">
          <FaCircle className="status-indicator status-active" />
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVE}
        </div>
      ),
      ACTIVE_UNASSIGNED: (
        <div className="status-field">
          <FaCircle className="status-indicator status-available" />
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE}
        </div>
      ),
      EXPIRING_SOON: (
        <div className="status-field">
          <FaCircle className="status-indicator status-expiring-soon" />
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRING_SOON}
        </div>
      ),
      EXPIRED: (
        <div className="status-field">
          <FaCircle className="status-indicator status-expired" />
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRED}
        </div>
      ),
    };

    if (!Array.isArray(licenses)) return;

    licenses.forEach((license) => {
      columnDefs.forEach((def) => {
        switch (def?.field?.toUpperCase()) {
          case 'LICENSESTATUS':
            // Cannot rely on the EXPIRED and EXPIRING_SOON statuses
            // So the date comparison is best used when determining
            // if the license has expired or about to expire
            if (
              license?.expiryDate &&
              Utils.getDateDifferenceInDays(
                license.expiryDate,
                moment().valueOf()
              ) <= 0
            ) {
              // Expired status
              licenseProps[def?.field] = licenseStatusMap['EXPIRED'];
            } else {
              if (license[def?.field] === 'ASSIGNED_ACTIVATION_PENDING') {
                // Activation Pending status
                licenseProps[def.field] = licenseStatusMap[
                  license[def.field].toUpperCase()
                ](license.activeDate);
              } else if (
                license[def?.field] !== 'UNASSIGNED' &&
                license.expiringSoonInDays &&
                Utils.getDateDifferenceInDays(
                  license.expiryDate,
                  moment().valueOf()
                ) <= license.expiringSoonInDays &&
                Utils.getDateDifferenceInDays(
                  license.expiryDate,
                  moment().valueOf()
                ) >= 0
              ) {
                // Expiring Soon status
                licenseProps[def?.field] = licenseStatusMap['EXPIRING_SOON'];
              } else {
                // All other statuses
                // TODO: delete later - for testing purposes
                //   licenseStatusMap['ASSIGNED_ACTIVATION_PENDING'];
                //   licenseStatusMap['ACTIVE'];
                //   licenseStatusMap['ACTIVE_UNASSIGNED'];
                //   licenseStatusMap['EXPIRED'];
                //   licenseStatusMap['EXPIRING_SOON'];
                licenseProps[def?.field] =
                  licenseStatusMap[license[def?.field].toUpperCase()];
              }
            }
            break;

          case 'DEVICENAME':
          case 'LOCATIONNAME':
            if (def?.field in license) {
              licenseProps[def.field] = license[def.field];
            } else {
              licenseProps[def.field] =
                constants.LICENSES_PAGE_GRID_ASSIGNED_DEVICE_LOCATION_FIELD_DEFAULT;
            }
            break;

          case 'ACTIVEDATE':
            licenseProps[def?.field] = (
              <div className="date-field">
                <HiOutlineCalendar className="calendar-icon" />
                {def?.field in license
                  ? moment(license?.activeDate).format(DATE_FORMAT)
                  : constants.LICENSES_PAGE_GRID_DATE_FIELD_DEFAULT}
              </div>
            );
            break;

          case 'EXPIRYDATE':
            if (
              license?.expiryDate &&
              license.licenseStatus &&
              license.licenseStatus !== 'EXPIRED' &&
              Utils.getDateDifferenceInDays(
                license?.expiryDate,
                moment().valueOf()
              ) <= license.expiringSoonInDays
            ) {
              licenseProps[def?.field] = (
                <div className="date-field">
                  <HiOutlineExclamation className="exclamation-icon" />
                  <span className="warning-date">
                    {moment(license.expiryDate).format(DATE_FORMAT)}
                  </span>
                </div>
              );
            } else {
              licenseProps[def?.field] = (
                <div className="date-field">
                  <HiOutlineCalendar className="calendar-icon" />
                  {def?.field in license
                    ? moment(license.expiryDate).format(DATE_FORMAT)
                    : constants.LICENSES_PAGE_GRID_DATE_FIELD_DEFAULT}
                </div>
              );
            }
            break;

          default:
            if (def?.field in license) {
              licenseProps[def?.field] = license[def?.field];
            }
        }
      });

      rowData.push(licenseProps);
      licenseProps = {};
    });

    return rowData;
  };

  return (
    <div className="App manage-licenses">
      <Header showHeader={true} className="desktop" />
      <div className="breadcrumbsResponsive mobile">
        <div className="text-start me-auto navbar-brand arrow">
          <img src={arrowLeft} alt=""></img>
          <spam
            className="backText"
            onClick={() => navigate(mobileBreadList[0]?.url)}
          >
            {constants.BREADCRUMB_BACK}
          </spam>
        </div>
        {getCustomerOrgPolicies()?.add_license_customer_org && (
          <Nav>
            <NavDropdown
              className="mobile"
              title={
                <div>
                  <img className="dots" src={dotshorizontal} alt=""></img>
                </div>
              }
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item href="#" onClick={mobileManageLicenses}>
                <div>{constants.LICENSE_ADD_LICENSE_TITLE}</div>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        )}
      </div>
      <PageWrapper className="container-top-offset mw-100 container-fluid">
        <Row className="mb-5">
          <Col>
            <Container className="container-top-offset mw-100" fluid>
              {
                <div className="page-header">
                  <Row xs={1} md={2} className="g-4">
                    <Col md={6} lg={6} xl={6} xs={12}>
                      <BreadcrumbList
                        list={Utils.CheckBreadcrumbForUserRole(breadList)}
                      />
                    </Col>
                    {Utils.getModuleStatus()?.PRODUCT_LISTING && (
                      <Col md={6} lg={6} xl={6} xs={12} className="text-end">
                        <Button
                          variant="primary"
                          size="md"
                          className={`purchase-button`}
                          onClick={() => {
                            navigate(
                              `/products/listing.html?orgId=${orgId}&orgName=${orgNameBread}`
                            );
                          }}
                        >
                          <span className="purchase-button-label">
                            {constants.PURCHASE_SERVICES_BUTTON_LABEL}
                          </span>
                        </Button>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <div className="toast-wrapper px-0 col-md-10">
                      <SiteToast
                        customCss="licenses-list-toast"
                        position="top-end"
                        show={showToast}
                        title="Uh-oh!"
                        body={userMsg}
                        delay={5000}
                      />
                      <span className="page-title">
                        {constants.LICENSES_PAGE_TITLE}
                      </span>
                    </div>
                    <div className="end-align col-md-2 buttonClass">
                      <AddLicense
                        isOpenMobilePopup={isOpenMobilePopup}
                        handleOpenMobilePopup={setIsOpenMobilePopup}
                        setShowClaimLicenseModal={setShowClaimLicenseModal}
                        showClaimLicenseModal={showClaimLicenseModal}
                        reloadData={() => {
                          setLicensesUpdated(true);
                        }}
                      />
                    </div>
                  </Row>
                  <Row>
                    <Col>
                      <div className="licenses-table-container">
                        {rowData.length > 0 ? (
                          <ListTable
                            colsDef={columnDefs}
                            colSorting={true}
                            rowData={rowData}
                            statusMap={statusMap}
                            cssClasses="licenses-table"
                          />
                        ) : showLoader ? (
                          <div className="no-data-available">
                            <div className="position-absolute l-48 t-52">
                              <SiteSpinner height="50px" width="50px" />
                              <div className="mt-2 text-dark">
                                {constants.LOADING}
                              </div>
                            </div>
                          </div>
                        ) : (
                          licensesStatus && (
                            <div className="no-devices-container">
                              <div className="image-wrapper">
                                <div className="license-icon">
                                  <LicensesIconNoRecord></LicensesIconNoRecord>
                                </div>
                              </div>
                              <div>{constants.NO_LICENSE_TITLE}</div>
                            </div>
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              }
            </Container>
          </Col>
        </Row>
      </PageWrapper>
    </div>
  );
};

export default ManageLicenses;
