import { useRef, useEffect } from 'react';

const LocalVideoView = ({ remoteStream }) => {
  const remoteVideoRef = useRef();
  const canvasVideoRef = useRef();

  useEffect(() => {
    if (remoteStream) {
      const remoteVideo = remoteVideoRef.current;
      const canvasVideo = canvasVideoRef.current;
      // remoteVideo.srcObject = remoteStream;
      if ('srcObject' in remoteVideo) {
        remoteVideo.srcObject = remoteStream;
      } else {
        // Avoid using this in new browsers, as it is going away.
        remoteVideo.src = URL.createObjectURL(remoteStream);
      }
      canvasVideo.srcObject = remoteStream;
    }
  }, [remoteStream]);

  return (
    <div className="remote-view-wrapper">
      <video
        id="video"
        width="764"
        height="450"
        ref={remoteVideoRef}
        autoPlay={true}
        playsInline={true}
        muted={false}
      />
      <canvas
        id="canvas"
        width="764"
        height="450"
        ref={canvasVideoRef}
      ></canvas>
    </div>
  );
};

export default LocalVideoView;
