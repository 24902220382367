const roles = {
  ROLE0PORTAL: 'role0#portal',
  ROLE1PORTAL: 'role1#portal',
  ROLE2PORTAL: 'role2#portal',
  ROLE3PORTAL: 'role3#portal',
  ROLE4PORTAL: 'role4#portal',
  ROLE5PORTAL: 'role5#portal',
  ROLE6PORTAL: 'role6#portal',
  ROLE7PORTAL: 'role7#portal'
}

export default roles;
