import { PrimaryButton } from '../components/common';
import { constants } from '../helpers';
import { warningCircle } from '../assets/images';

const AccountNotVerifiedModal = ({ onClickLogIn }) => {
  return (
    <>
      <div className="account-status-modal-wrapper">
        <div className="header-container">
          <div className="header-image">
            <img src={warningCircle} alt="" />
          </div>
        </div>
        <div className="account-status-modal-title">
          {constants.ACCOUNT_NOT_ACTIVATED_TITLE}
        </div>
        <div className="account-status-modal-description">
          {constants.ACCOUNT_NOT_ACTIVATED_SUBTITLE}
        </div>
        <PrimaryButton
          className="btn mb-3"
          type="submit"
          width="100%"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          borderWidth="1.5px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--brand_white'
          )}
          onClick={() => onClickLogIn()}
        >
          {constants.LOGIN_BUTTON_CONTENT}
        </PrimaryButton>
      </div>
    </>
  );
};

export default AccountNotVerifiedModal;