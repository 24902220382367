import { getUnixDate } from '../../helpers/commonUtils';
import * as webRTCHandler from '../webRTC/webRTCwsHandler';
import { v4 as uuidv4 } from 'uuid';
import { setWSSConnection } from '../../store/reducers/StreamingReducer';
import store from '../../store/Store';
let wsClient;
let msg;
let serverDetails;

const uuid = uuidv4();
const handleIncomingError = (error) => {
  console.log('error', error);
};

export const connectWithWebSocket = (cdnDetails) => {
  serverDetails = cdnDetails;
  const playbackServer = serverDetails?.timeline_server;
  wsClient = new WebSocket(
    `${playbackServer.protocol}://${playbackServer.host}:${playbackServer.port}`
  );
  // TODO: Delete later - Added below URL for testing purpose
  //wsClient = new WebSocket(`wss://35.83.74.253:8443`);
  wsClient.binaryType = 'arraybuffer';
  wsClient.onerror = function (error) {
    // TODO: Delete later
    console.log('Connection Error', error);
  };

  wsClient.onopen = function () {
    store.dispatch(setWSSConnection(true));
    const uuid = uuidv4();
    if (wsClient.readyState === 1) {
      wsClient.send(`REGISTER ${uuid}`);
    }
  };

  wsClient.onclose = function (e) {
    store.dispatch(setWSSConnection(false));
    sendPauseCVR();
  };

  wsClient.onmessage = function (event) {
    switch (event.data) {
      case 'REGISTERED':
        return;
      case 'READY':
        webRTCHandler.getLocalStream(serverDetails.p2p_server);
        return;
      default:
        if (event.data.startsWith('ERROR')) {
          handleIncomingError(event.data);
          return;
        }
        // Handle incoming JSON SDP and ICE messages
        try {
          msg = JSON.parse(event.data);
        } catch (e) {
          if (e instanceof SyntaxError) {
            handleIncomingError('Error parsing incoming JSON: ' + event.data);
          } else {
            handleIncomingError(
              'Unknown error parsing response: ' + event.data
            );
          }
          return;
        }

        if (msg.sdp != null) {
          webRTCHandler.handleAnswer(msg);
        } else if (msg.ice != null) {
          webRTCHandler.handleCandidate(msg);
        } else {
          handleIncomingError('Unknown incoming JSON: ' + msg);
        }
        return;
    }
  };
};

export const sendPlayCVR = (time, id) => {
  const actualTime = getUnixDate(time);
  if (wsClient && wsClient.readyState === WebSocket.OPEN) {
    wsClient.send(`PLAY ${id} ${actualTime}000`);
  }
};

export const sendPauseCVR = () => {
  webRTCHandler.handleLeaveCall();
  if (wsClient) {
    wsClient.send('STOP');
  }
};

export const sendWebRTCOffer = (data) => {
  wsClient.send(JSON.stringify(data));
};

export const sendWebRTCCandidate = (data) => {
  wsClient.send(JSON.stringify(data));
};

export const disconnectWithWebSocket = () => {
  if (wsClient) {
    wsClient.send('STOP');
    wsClient.close();
  }
  webRTCHandler.handleLeaveCall();
};

export const checkWSSConnection = () => {
  if (wsClient) return true;
};
