import store from '../../store/Store';
import { setLiveStream } from '../../store/reducers/StreamingReducer';
import * as wsConnection from '../connection/wssConnection';

let peerConnection;

export const getLocalStream = (serverDetails) => {
  createPeerConnection(serverDetails);
};

const createPeerConnection = (serverDetails) => {
  const configuration = {
    iceServers: [
      {
        urls: `${serverDetails.stun.protocol}:${serverDetails.stun.host}:${serverDetails.stun.port}`,
      },
      {
        urls: `${serverDetails.turn.protocol}:${serverDetails.turn.host}:${serverDetails.turn.port}?transport=tcp`,
        username: `${serverDetails.turn.userName}`,
        credential: `${serverDetails.turn.password}`,
      },
    ],
    iceTransportPolicy: 'all',
    rtcpMuxPolicy: 'require',
    bundlePolicy: 'balanced',
  };

  console.log('configuration----', configuration);
  peerConnection = new RTCPeerConnection(configuration);

  const transceiver = peerConnection.addTransceiver('video', {
    direction: 'recvonly',
  });
  const capabilities = RTCRtpSender.getCapabilities('video').codecs;
  let h264_codecs = [];
  // iterate over supported codecs and pull out the codecs we want
  capabilities.forEach((codec, idx) => {
    if (
      codec.mimeType === 'video/H264' &&
      codec.sdpFmtpLine.includes('packetization-mode=1')
    ) {
      h264_codecs.push(codec);
    }
  });
  transceiver.setCodecPreferences(h264_codecs);
  const transceiver_a = peerConnection.addTransceiver('audio', {
    direction: 'recvonly',
  });
  const capabilities_a = RTCRtpSender.getCapabilities('audio').codecs;
  let audio_codecs = [];
  // iterate over supported codecs and pull out the codecs we want
  capabilities_a.forEach((codec, idx) => {
    if (
      codec.mimeType === 'audio/PCMU' ||
      codec.mimeType === 'audio/PCMA' ||
      codec.mimeType === 'audio/opus'
    ) {
      audio_codecs.push(codec);
    }
  });
  transceiver_a.setCodecPreferences(audio_codecs);

  sendOffer();

  peerConnection.ontrack = ({ streams: [stream] }) => {
    store.dispatch(setLiveStream(stream));
  };

  peerConnection.onicecandidate = (event) => {
    if (event.candidate) {
      wsConnection.sendWebRTCCandidate({
        ice: event.candidate,
      });
    }
  };

  peerConnection.onconnectionstatechange = (event) => {
    // TODO: delete later
    console.log('connection state', peerConnection.connectionState);
    if (peerConnection.connectionState === 'connected') {
      // TODO: delete later
      console.log('succesfully connected with other peer');
    } else if (peerConnection.connectionState === 'failed') {
      createPeerConnection();
    }
  };
};

const sendOffer = async () => {
  console.log('offer send 2');
  const offer = await peerConnection.createOffer({
    offerToReceiveAudio: true,
    offerToReceiveVideo: true,
  });

  await peerConnection.setLocalDescription(offer);
  wsConnection.sendWebRTCOffer({
    sdp: { type: 'offer', sdp: offer.sdp },
  });
};

export const handleAnswer = async (data) => {
  await peerConnection
    .setRemoteDescription(new RTCSessionDescription(data.sdp))
    .then((data) => {})
    .catch((error) => console.error('error', error));
};

export const handleCandidate = async (data) => {
  try {
    if (peerConnection.localDescription) {
      var candidate = new RTCIceCandidate(data.ice);
      peerConnection.addIceCandidate(candidate);
    }
  } catch (err) {
    console.error(
      'error occured when trying to add received ice candidate',
      err
    );
  }
};

export const handleLeaveCall = async () => {
  store.dispatch(setLiveStream(null));
  if (peerConnection) {
    peerConnection.close();
    peerConnection.onicecandidate = null;
    peerConnection.ontrack = null;
    peerConnection = null;
  }
};
