import { useState } from 'react';
import One from './StepOne';
import Two from './StepTwo';

const FormSteps = ({ buttonClickValue, hideModal, setLicensesAdded }) => {
  const [valueForFlow1, setValueForFlow1] = useState(null);
  const [valueForFlow2, setvalueForFlow2] = useState(null);
  const [valueOfManualLicense, setValueOfManualLicense] = useState(null);
  const [licenseValuesForSelectedOrder, setLicenseValuesForSelectedOrder] =
    useState(null);
  const [recipientEmailAddress, setRecipientEmailAddress] = useState(null);
  const [step, setstep] = useState(1);
  localStorage.setItem('modalOpen', 1);

  const nextStep = () => {
    setstep(step + 1);
  };

  const setLicenseResponseSuccessValue = () => {
    setValueForFlow1(true);
    setvalueForFlow2(false);
  };

  const setLicenseResponseSuccessFlag = () => {
    setValueForFlow1(false);
    setvalueForFlow2(true);
  };

  const setLicenseResponseData = (arg) => {
    setValueOfManualLicense(arg);
    setLicensesAdded();
  };

  const setSelectedOrderNumber = (arg) => {
    setLicenseValuesForSelectedOrder(arg);
  };

  const prevStep = () => {
    setstep(step - 1);
  };
  const firstStep = () => {
    setstep(step - 2);
  };

  switch (step) {
    case 1:
      return (
        <>
          <One
            hideModal={() => hideModal()}
            licenseResponseSuccessValue={setLicenseResponseSuccessValue}
            licenseResponseSuccessFlag={setLicenseResponseSuccessFlag}
            licenseResponseData={setLicenseResponseData}
            selectedOrderNumber={setSelectedOrderNumber}
            setRecipientEmailAddress={setRecipientEmailAddress}
            flowValue={buttonClickValue}
            nextstepEvent={nextStep}
          />
        </>
      );
    case 2:
      return (
        <>
          <Two
            hideModal={() => hideModal()}
            valueForFirstFlow={valueForFlow1}
            valueForSecondFlow={valueForFlow2}
            valueFromOneOfManualLicense={valueOfManualLicense}
            licenseValuesForSelectedOrderNumber={licenseValuesForSelectedOrder}
            recipientEmailAddress={recipientEmailAddress}
            flowValue={buttonClickValue}
            nextstep={nextStep}
            prevstep={prevStep}
            setLicensesAdded={setLicensesAdded()}
          />
        </>
      );

    default:
      return null;
  }
};

export default FormSteps;
