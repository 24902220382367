import { Outlet } from 'react-router-dom';
import CameraList from './CameraList';
import CameraDashboard from './CameraDashboard';

const Cameras = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export { CameraList, CameraDashboard, Cameras };
