import { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextField, ToggleInput } from '../../components/forms';
import { constants, Utils, roles } from '../../helpers';
import { PrimaryButton, SiteToast } from '../../components/common';
import { useOrganizations } from '../../store/OrganizationsStore';
import { usePoliciesStore } from '../../store/policiesStore';
import axios from 'axios';
import { getPartnerOrgData } from '../../store/reducers/OrganizationsReducer';
import { useSelector } from 'react-redux';

// Schema for yup
const validationSchema = Yup.object().shape({
  firstName: Yup.string().trim()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .matches(/^[a-zA-Z ]+$/, constants.ONLY_ALPHABETS_ERROR_MESSAGE)
    .required(constants.NAME_REQUIRED_ERROR_MESSAGE),
  lastName: Yup.string().trim()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .matches(/^[a-zA-Z ]+$/, constants.ONLY_ALPHABETS_ERROR_MESSAGE)
    .required(constants.NAME_REQUIRED_ERROR_MESSAGE),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      constants.EMAIL_VALID_ERROR_MESSAGE
    )
    .required(constants.EMAIL_REQUIRED_ERROR_MESSAGE),
    phoneNumber: Yup.string()
    .min(0, constants.VALID_PHONE_NUMBER_ERROR_MESSAGE)
    .max(99999999999, constants.VALID_PHONE_NUMBER_ERROR_MESSAGE)
    .matches(/^\d+$/, constants.PHONE_NUMERIC_ALPHA_ERROR)
    .typeError(constants.VALID_PHONE_NUMBER_ERROR_MESSAGE),
});

function NewPartnerUsers(props) {
  const [variant, setVariant] = useState('error');
  const [userMsg, setUserMsg] = useState('');
  const [roleChange, setRolechange] = useState(false);

  const partnerOrgData = useSelector(getPartnerOrgData);
  // const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  
  const partnerOrgId = partnerOrgData?.orgId;
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies
  );
  
  return (
    <section className="modal-main-content">
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          role: roles.ROLE5PORTAL,
          accountLanguage: 'en_US',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);
          setUserMsg('');
          if (partnerOrgId !== null) {
            values.role = roleChange ? roles.ROLE4PORTAL : roles.ROLE5PORTAL;
            axios
              .post(`partner/orgs/${partnerOrgId}/accounts`, values, Utils.requestHeader())
              .then((res) => {
                let response = res.data;
                if (response.meta.code === 200) {
                  setSubmitting(false);
                  props.reloadData();
                  props.hideModal();
                } else {
                  setVariant('error');
                  setUserMsg(response?.meta?.userMsg);
                  setSubmitting(false);
                }
              })
              .catch(function (error) {
                setVariant('error');
                setUserMsg(error?.message);
                setSubmitting(false);
              });
          } else setSubmitting(false);
        }}
      >
        {({ handleSubmit, isSubmitting, isValid, dirty, setFieldValue }) => (
          <>
            <Form className="modal-form" onSubmit={handleSubmit}>
              {/* TODO: We can put the placehomder contents in the en.js file when we change the form structure */}
              <TextField
                required={true}
                removebottommargin="true"
                removetopmargin="true"
                label=""
                placeholder={constants.FIRST_NAME_FIELD}
                name="firstName"
                type="text"
              />
              <TextField
                required={true}
                removebottommargin="true"
                removetopmargin="true"
                label=""
                placeholder={constants.LAST_NAME_FIELD}
                name="lastName"
                type="text"
              />
              <TextField
                removebottommargin="true"
                removetopmargin="true"
                label=""
                placeholder={constants.PHONE_NUMBER_FIELD}
                name="phoneNumber"
                type="text"
                maxLength={11}
                onChange={(e) => {
                  setFieldValue('phoneNumber', e.target.value);
                }}
              />
              <TextField
                required={true}
                removebottommargin="true"
                removetopmargin="true"
                label=""
                placeholder={constants.EMAIL_ADDRESS_FIELD}
                name="email"
                type="text"
              />
              {getLoggedInUserPolicies()?.create_partner_admin_role && (
                <ToggleInput
                label={constants.ADMINISTRATOR_FIELD}
                name="role"
                value={roleChange}
                changeHandler={(evebr) => {
                  setRolechange(evebr.target.checked);
                }}
                />
              )}
              <Form.Group as={Row} className="mt-3">
                <Col>
                  <PrimaryButton
                    className="btn btn-primary w-100"
                    type="submit"
                    disabled={!(dirty && isValid)}
                    loader={isSubmitting}
                    height="44px"
                    fontSize="14px"
                  >
                    {constants.PARTNER_NEW_USER_SUBMIT_BUTTON_TITLE}
                  </PrimaryButton>
                </Col>
              </Form.Group>
            </Form>

            {/* Show error messages */}
            <SiteToast
              title={
                variant === 'error'
                  ? constants.ERROR_TOAST_TITLE
                  : constants.SUCCESS_TOAST_TITLE
              }
              show={!!userMsg}
              body={userMsg}
              variant={variant}
              position="top-center"
            />
          </>
        )}
      </Formik>
    </section>
  );
}

export default NewPartnerUsers;
