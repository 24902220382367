import '../stepsnotification.scss';
import { constants } from '../../../../helpers';
import StepIndicator from '../StepIndicator';
import systemEvent from '../../../../assets/images/system-event.svg';
import customEvent from '../../../../assets/images/custom-event.svg';
import { PrimaryButton } from '../../../../components/common';

const One = (props) => {
  const onHandleChange = (e) => {
    if (e != undefined) {
    }
  };
  return (
    <div className="create-notification">
      <StepIndicator {...props} />
      <div className="event-type-step-one">
        <div className="step-title">
          {constants.NOTIFICATION_CREATE_NOTIFICATION_EVENT_TYPE}
        </div>
        <div className="system-custom-event-conatiner">
          <img className="event-icon" alt="" src={systemEvent}></img>
          <div className="title-discription-conatiner">
            <div className="event-title">
              {constants.NOTIFICATION_CREATE_NOTIFICATION_SYSTEM_EVENT}
            </div>
            <div className="event-discription">
              {constants.NOTIFICATION_CREATE_NOTIFICATION_SYSTEM_EVENT_TYPES}
            </div>
          </div>
          <input
            checked={true}
            className="input-radio-events"
            type="radio"
            name="radio"
            onChange={(e) => onHandleChange(e)}
          />
        </div>
        <div className="system-custom-event-conatiner disabled">
          <img className="event-icon" alt="" src={customEvent}></img>
          <div className="title-discription-conatiner">
            <div className="event-title">
              {constants.NOTIFICATION_CREATE_NOTIFICATION_CUSTOM_EVENT}
            </div>
            <div className="event-discription">
              {constants.NOTIFICATION_CREATE_NOTIFICATION_CUSTOM_EVENT_TYPES}
            </div>
          </div>
          <input
            disabled={true}
            className="input-radio-events"
            type="radio"
            name="radio"
            onChange={(e) => onHandleChange(e)}
          />
        </div>
      </div>

      <PrimaryButton
        className="adddevice-btn"
        fontSize="14px"
        backgroundColor={getComputedStyle(
          document.documentElement
        ).getPropertyValue('--primary_40')}
        height="44px"
        color="#FFFFFF"
        type="submit"
        onClick={() => props.nextStep()}
      >
        {constants.DEVICES_CONTINUE_TEXT}
      </PrimaryButton>
    </div>
  );
};

export default One;
