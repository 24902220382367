import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Utils } from '../../helpers';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { SiteSpinner } from '../../components/common';

function PartnerInvitation() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showLoader, setShowLoader] = useState(true);
  let partnerStatus = searchParams.get('isAccepted');
  let invitationId = searchParams.get('invitationId');
  let inviteCode = searchParams.get('code');
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );

  const apiCallInvitation = async () => {
    const reqBody = {
      orgId: loggedInUserData.orgId,
      invitationId: invitationId,
      code: inviteCode,
      isAccepted: partnerStatus,
    };
    const resposne = await axios.post(
      `/partner/orgs/${loggedInUserData.orgId}/invitation`,
      reqBody,
      Utils.requestHeader()
    );
    gotoOrgList(resposne?.data);
  };

  const gotoOrgList = (resposne) => {
    setShowLoader(false);
    localStorage.removeItem('partnerInvite');
    localStorage.setItem('inviteData',JSON.stringify(resposne));
    navigate(`/customers/manage.html`);
  };

  useEffect(() => {
    const tokenVms = localStorage.getItem('vmsAuthToken');
    const partnerInvite = localStorage.getItem('partnerInvite');
    if (partnerStatus != null) {
      const partnerInviteData = {
        invitationId: invitationId,
        orgId: loggedInUserData.orgId,
        code: inviteCode,
        isAccepted: partnerStatus === 'true' ? true : false,
      };
      localStorage.setItem('partnerInvite', JSON.stringify(partnerInviteData));
    }
    if (tokenVms == 'null' || tokenVms == 'undefined') {
      setShowLoader(false);
      navigate(`/`);
    } else if(partnerInvite && tokenVms) {
      apiCallInvitation();
    }
    else{
      navigate(`/`);
    }
  }, []);

  return (
    <div className="App">
      {showLoader && (
        <div className="app-margin-patner-invite">
          <SiteSpinner height="100px" width="100px"></SiteSpinner>
        </div>
      )}
    </div>
  );
}

export default PartnerInvitation;
