import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { HiOutlineChevronDown, HiOutlineLocationMarker } from "react-icons/hi";
import { MdOutlineMyLocation } from "react-icons/md";
import { isObject } from "lodash";
import { useCustomerOrgLocations } from "../../../store/CustomerOrgLocationsStore";
import { AppDefaults, constants } from "../../../helpers";
import "../listDevice/DiscoveredDevices.scss";
import { useLocation } from "react-router-dom";

const LocationAreaDeviceList = ({
  orgId,
  locations,
  sendSelectedLocationId,
  sendSelectedAreaId,
  selectedLocationId,
  selectedAreaId,
}) => {
  const [locationAreas, setLocationAreas] = useState([]);
  const urlLocation = useLocation();
  const params = new URLSearchParams(urlLocation.search);
  const locationIdFromURL = params.get("locationId");
  const locationNameFromURL = params.get("locationName");
  const [updatedLocations, setUpdatedLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(
    constants.LOCATION_DROPDOWN_ALL_LOCATIONS_LABEL
  );
  const [selectedArea, setSelectedArea] = useState(
    constants.LOCATION_DROPDOWN_ALL_AREAS_LABEL
  );
  const { getCustomerOrgLocations, getCustomerOrgLocationsData } =
    useCustomerOrgLocations();

  let custOrgLocations = getCustomerOrgLocationsData();

  const fetchLocations = async () => {
    try {
      await getCustomerOrgLocations(`partner/orgs/${orgId}/locations`);
      if (getCustomerOrgLocationsData()?.length > 0) {
        custOrgLocations = getCustomerOrgLocationsData();
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchLocations();
  }, [orgId]);

  useEffect(() => {
    if (locationIdFromURL && locationNameFromURL) {
      if (locationIdFromURL && locationNameFromURL) {
        setSelectedLocation(locationNameFromURL);
        const locationData = getLocationData(locationIdFromURL);
        if (locationData) {
          setLocationAreas(locationData.areas);
          sendSelectedLocationId([locationIdFromURL]);
          sendSelectedAreaId(locationData.areas.map((area) => area.areaId));
        }
      }
    }
  }, [locationIdFromURL, locationNameFromURL]);

  useEffect(() => {
    if (!locationIdFromURL && !locationNameFromURL) {
      let selectedLocationData;
      if (selectedLocationId?.length === 1) {
        selectedLocationData = getLocationData(selectedLocationId[0]);
        setSelectedLocation(selectedLocationData?.locationName);
        if (selectedAreaId?.length === 1) {
          const areadata = selectedLocationData?.areas?.find(
            (item) => item.areaId === selectedAreaId[0]
          );
          setSelectedArea(areadata?.areaName);
        }
      }
      if (isObject(custOrgLocations) && custOrgLocations?.locationName) {
        setSelectedLocation(custOrgLocations?.locationName);
      }
      filterAreasByLocation(
        selectedLocationId?.length === 1
          ? selectedLocationId[0]
          : AppDefaults.ALL_LOCATIONS_ID
      );
    }
  }, [locations]);

  const areAllLocationIdsSame = (areas) => {
    if (!areas || areas.length === 0) return false;
    const firstLocationId = areas[0].locationId;
    return areas.every((area) => area.locationId === firstLocationId);
  };

  const filterAreasByLocation = (locationId, selectedArea, singleArea) => {
    if (!locationId) return;
    if (locationId !== AppDefaults.ALL_LOCATIONS_ID) {
      if (selectedArea?.length > 0) {
        if (selectedArea?.length > 1 && areAllLocationIdsSame(selectedArea)) {
          setUpdatedLocations([]);
          const selectedLocationData = getLocationData(
            selectedArea[0]?.locationId
          );
          setSelectedLocation(selectedLocationData?.locationName);
          setLocationAreas(selectedLocationData?.areas);
          const data = [];
          data.push(selectedArea[0]?.locationId);
          sendSelectedLocationId(data);
          sendSelectedAreaId(
            selectedLocationData?.areas?.map((item) => item.areaId)
          );
        } else {
          setSelectedLocation(constants.LOCATION_DROPDOWN_ALL_LOCATIONS_LABEL);
          const updatedLocationsData = locations?.filter((item) =>
            selectedArea
              ?.map((item) => item.locationId)
              ?.includes(item.locationId)
          );
          if (updatedLocationsData?.length > 0) {
            setUpdatedLocations(updatedLocationsData);
          }
          sendSelectedLocationId(selectedArea.map((item) => item.locationId));
          sendSelectedAreaId(selectedArea?.map((item) => item.areaId));
        }
      } else {
        setUpdatedLocations([]);
        const selectedLocationData = getLocationData(locationId);
        setSelectedLocation(selectedLocationData?.locationName);
        setLocationAreas(selectedLocationData?.areas);
        const data = [];
        data.push(locationId);
        sendSelectedLocationId(data);
        if (selectedArea === 0) {
          const selectedAreaId = selectedLocationData?.areas
            ?.filter((item) => item.areaId === singleArea[0]?.areaId)
            ?.map((item) => item.areaId);
          sendSelectedAreaId(selectedAreaId);
        } else {
          sendSelectedAreaId(
            selectedLocationData?.areas?.map((item) => item.areaId)
          );
        }
      }
    } else {
      setUpdatedLocations([]);
      sendSelectedLocationId([]);
      sendSelectedAreaId([]);
      let areaList = [];
      locations?.forEach((location) => {
        areaList = [...areaList, ...location?.areas];
      });
      setLocationAreas(areaList);
      setSelectedLocation(constants.LOCATION_DROPDOWN_ALL_LOCATIONS_LABEL);
    }
  };

  const onSelectLocationHandler = (evtKey) => {
    if (!evtKey) return;
    filterAreasByLocation(evtKey);
    setSelectedArea(constants.LOCATION_DROPDOWN_ALL_AREAS_LABEL);
  };

  const onSelectAreaHandler = (evtKey) => {
    if (!evtKey) return;
    if (evtKey !== constants.LOCATION_DROPDOWN_ALL_AREAS_ID) {
      let selectedAreaObj = locationAreas?.find(
        (area) => area.areaId === evtKey
      );
      const selectedArea = locationAreas.filter(
        (area) =>
          area.areaId === evtKey || area.areaName === selectedAreaObj?.areaName
      );
      setSelectedArea(selectedArea[0].areaName);
      if (selectedArea?.length > 1) {
        filterAreasByLocation(selectedArea[0].locationId, selectedArea);
      } else {
        if (selectedArea?.length === 1) {
          filterAreasByLocation(selectedArea[0].locationId, 0, selectedArea);
        } else {
          filterAreasByLocation(selectedArea[0].locationId);
        }
      }
    } else {
      if (selectedLocationId?.length) {
        const selectedLocationData = getLocationData(selectedLocationId[0]);
        sendSelectedAreaId(
          selectedLocationData?.areas?.map((item) => item.areaId)
        );
      } else {
        sendSelectedAreaId([]);
      }
      setSelectedArea(constants.LOCATION_DROPDOWN_ALL_AREAS_LABEL);
    }
  };

  const getLocationData = (locationId) => {
    if (!locationId) return;

    if (locationId === AppDefaults.ALL_LOCATIONS_ID) {
      return constants.LOCATION_DROPDOWN_ALL_LOCATIONS_LABEL;
    }
    return locations.find((location) => location.locationId === locationId);
  };

  const locationDropdownContent =
    (updatedLocations?.length > 0 ? updatedLocations : locations)?.length >
    0 ? (
      (updatedLocations?.length > 0 ? updatedLocations : locations)
        ?.sort((a, b) => a.locationName.localeCompare(b.locationName))
        .map((location) => (
          <Dropdown.Item
            key={`location-${location.locationId}`}
            className={
              `area-selector-menu-item` +
              (selectedLocation === location.locationName ? " active" : "")
            }
            eventKey={location.locationId}
          >
            {location.locationName}
          </Dropdown.Item>
        ))
    ) : (
      <Dropdown.Item
        key={`location-${0}`}
        className={`area-selector-menu-item`}
        eventKey={undefined}
      >
        {constants.LOCATION_DROPDOWN_NO_LOCATION_DATA_TEXT}
      </Dropdown.Item>
    );

  const uniqueAreas = Array.from(
    new Set(locationAreas?.map((area) => area.areaName))
  ).map((areaName) => locationAreas.find((area) => area.areaName === areaName));

  const areaDropdownContent =
    uniqueAreas?.length > 0 ? (
      uniqueAreas
        ?.sort((a, b) => a.areaName.localeCompare(b.areaName))
        ?.map((area) => (
          <Dropdown.Item
            key={`area-${area.areaId}`}
            className={
              `area-selector-menu-item` +
              (selectedArea === area.areaName ? " active" : "")
            }
            eventKey={area.areaId}
          >
            {area.areaName}
          </Dropdown.Item>
        ))
    ) : (
      <Dropdown.Item
        key={`area-${0}`}
        className={`area-selector-menu-item`}
        eventKey={undefined}
      >
        {constants.LOCATION_DROPDOWN_NO_AREA_DATA_TEXT}
      </Dropdown.Item>
    );

  return (
    <div className="selection-wrapper-location-area">
      <Dropdown
        className="area-selector mr-3"
        onSelect={onSelectLocationHandler}
      >
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-location"
          className="area-selector-toggle"
        >
          <div className="area-selected-wrapper">
            <HiOutlineLocationMarker size={20} />
            <span className="area-selected-name">{selectedLocation}</span>
          </div>
          <HiOutlineChevronDown size={20} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="area-selector-menu">
          <Dropdown.Item
            className={
              `area-selector-menu-item` +
              (!selectedLocation ||
              selectedLocation ===
                constants.LOCATION_DROPDOWN_ALL_LOCATIONS_LABEL ||
              selectedLocation === AppDefaults.ALL_LOCATIONS_ID
                ? " active"
                : "")
            }
            eventKey={AppDefaults.ALL_LOCATIONS_ID}
          >
            {constants.LOCATION_DROPDOWN_ALL_LOCATIONS_LABEL}
          </Dropdown.Item>
          {locationDropdownContent}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="area-selector" onSelect={onSelectAreaHandler}>
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-area"
          className="area-selector-toggle"
        >
          <div className="area-selected-wrapper">
            <MdOutlineMyLocation size={20} />
            <span className="area-selected-name">{selectedArea}</span>
          </div>
          <HiOutlineChevronDown size={20} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="area-selector-menu">
          <Dropdown.Item
            className={
              `area-selector-menu-item` +
              (!selectedLocation ||
              selectedLocation ===
                constants.LOCATION_DROPDOWN_ALL_AREAS_LABEL ||
              selectedLocation === constants.LOCATION_DROPDOWN_ALL_AREAS_ID
                ? " active"
                : "")
            }
            eventKey={constants.LOCATION_DROPDOWN_ALL_AREAS_ID}
          >
            {constants.LOCATION_DROPDOWN_ALL_AREAS_LABEL}
          </Dropdown.Item>
          {areaDropdownContent}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default LocationAreaDeviceList;
