import { useEffect, useState, useCallback } from 'react';
import { ArrowRight } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { constants, Utils } from '../../../helpers';
import {
  getSnapshotImage,
  // getSnapshotImages,
  setIsRemoteStreamPlay,
} from '../../../store/reducers/StreamingReducer';
import { getSnapshotImages } from '../../../store/StreamingStoreIDB';
import { observerInstance } from '../../../store/indexDB/observer';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

const ImageFetch = ({
  customClass,
  time,
  cdnValue,
  deviceId,
  liveSnapshot,
  moveTimeline,
  camera,
  isFromSearchDashboard = false,
  deviceName,
  onLiveClick,
}) => {
  const dispatch = useDispatch();
  const [newSrc, setNewSrc] = useState('');
  const [snapshot, setSnapshot] = useState(null);
  const snapshotImage = useSelector(getSnapshotImage);
  // const snapshotImages = useSelector(getSnapshotImages);
  const [snapshotImages, setSnapshotImages] = useState({});
  const [moveTimelineInt, setMovetimelineInt] = useState(false);
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);

  const loadAllSnapshots = useCallback(async () => {
    const allSnapshots = await getSnapshotImages();
    setSnapshotImages(allSnapshots);
  }, []);

  const debouncedLoadAllSnapshots = useDebouncedCallback(loadAllSnapshots, 100);

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'snapshotImages') {
        await debouncedLoadAllSnapshots();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadAllSnapshots();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadAllSnapshots]);

  useEffect(() => {
    dispatch(setIsRemoteStreamPlay(false));
  }, []);

  useEffect(() => {
    setMovetimelineInt(moveTimeline);
  }, [moveTimeline]);

  useEffect(() => {
    const newTime = Utils.getUnixDate(Utils.getDate(time / 1000));

    if (deviceId && newTime && cdnValue?.protocol && cdnValue?.host) {
      const bucket = (cdnValue?.bucket).replace('${deviceId}', deviceId);
      fetch(
        `${cdnValue?.protocol}://${cdnValue?.host}/${bucket}/${newTime}.jpg`,
        {
          credentials: 'include',
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          setNewSrc(URL.createObjectURL(blob));
        });
      // TODO: Below code is kept for observation
      // .then((response) => {
      //   if (response.ok) {
      //     setNewSrc(response?.url);
      //     if (!moveTimelineInt) {
      //       setTimeout(() => {
      //         setNewSrc(response?.url);
      //       }, 200);
      //     }
      //   } else {
      //     if (!moveTimelineInt) {
      //       setTimeout(() => {
      //         setNewSrc(noVideo);
      //       }, 100);
      //     }
      //   }
      // })
      // .catch(() => null);
    }
  }, [time, deviceId]);

  useEffect(() => {
    if (camera) {
      setSnapshot(snapshotImages[deviceId]);
    } else {
      setSnapshot(snapshotImage);
    }
  }, [snapshotImage, deviceId, camera]);

  return (
    <div
      className={`image-wrapper ${customClass ? customClass : ''}`}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    >
      {deviceName && (
        <>
          <span className={hovered ? 'hovered device-name' : 'device-name'}>
            {deviceName}
          </span>
          <span
            onClick={() => onLiveClick()}
            className={hovered ? 'hovered detail-camera' : 'detail-camera'}
          >
            {constants.CAMERA_DETAIL}
            <ArrowRight width={18} />
          </span>
        </>
      )}
      {liveSnapshot ? (
        <img
          src={snapshot}
          alt=""
          className="live-snapshot"
          onLoad={(event) => {
            dispatch(setIsRemoteStreamPlay(true));
          }}
          onError={(event) => (event.target.style.display = 'none')}
        />
      ) : (
        <img
          className={`${isFromSearchDashboard ? ' img-event' : ''}`}
          src={newSrc}
          alt=""
          onLoad={(event) => {
            event.target.style.display = 'inline-block';
            dispatch(setIsRemoteStreamPlay(true));
          }}
          onError={(event) => (event.target.style.display = 'none')}
        />
      )}
    </div>
  );
};

export default ImageFetch;
