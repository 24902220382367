import { useEffect, useState, useCallback } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
// import { getSelectedOrganization } from '../../../../store/reducers/AccountReducer';
import { ReactComponent as InfoCircleIcon } from '../../../../assets/images/Info-circle.svg';

import {
  DateSelector,
  ListBlock,
  PrimaryButton,
  SiteToast,
} from '../../../../components/common';
import { constants, Utils, ServiceTypeEnum } from '../../../../helpers';
import useLicensesStore from '../../../../store/LicensesStore';
import { getSelectedOrganization } from '../../../../store/AccountStoreIDB';
import { observerInstance } from '../../../../store/indexDB/observer';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';

import './AddLicenseForm.scss';

const AddLicenseForm = ({ deviceId, orgId, expiryDate, callBack }) => {
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [availableLicenses, setAvailableLicenses] = useState([]);
  const [productNames, setProductNames] = useState([]);
  const [groupedLicenses, setGroupedLicenses] = useState({});
  const [targetDeviceId, setTargetDeviceId] = useState(deviceId);
  const [licenseKey, setLicenseKey] = useState(null);
  const [activationDate, setActivationDate] = useState(
    moment.utc(expiryDate).valueOf()
  );
  const [showLoader, setShowLoader] = useState(false);

  const { licenses, getLicenses, setLicenses } = useLicensesStore();
  // const orgDetails = useSelector(getSelectedOrganization);
  const [orgDetails, setOrgDetails] = useState();

  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'selectedOrganization') {
        await debouncedLoadSelectedOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadSelectedOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadSelectedOrgData]);

  useEffect(() => {
    const filterByProductName = (productName) => {
      if (availableLicenses.length < 1) {
        return [];
      }

      return availableLicenses.filter(
        (license) => license.productDisplayName === productName
      );
    };

    const generateAvailableLicenses = async () => {
      let productNamesSet = new Set();
      let licensesByName = {};
      let unassignedLicenses, productSet;

      if (!Array.isArray(licenses) || licenses.length < 1) {
        await setLicenses(`/user/orgs/${orgId}/licenses`);
      }

      // Filter for unassigned licenses (available)
      unassignedLicenses = licenses.filter(
        (license) =>
        (license.licenseStatus === 'UNASSIGNED' || license.licenseStatus === 'ACTIVE_UNASSIGNED') &&
        license.serviceId === ServiceTypeEnum.VMS_SERVICE
      );

      unassignedLicenses.forEach((license) =>
        productNamesSet.add(license.productDisplayName)
      );

      productSet = [...productNamesSet];

      productSet.forEach((productName) => {
        licensesByName[productName] = unassignedLicenses.filter(
          (license) => license.productDisplayName === productName
        );
      });

      await new Promise((resolve) => {
        setGroupedLicenses(licensesByName);
        resolve();
      });
    };

    generateAvailableLicenses();
  }, []);

  const renderAccordions = () => {
    let licenseProductNames = Object.keys(groupedLicenses);
    const accordions = [];

    licenseProductNames.forEach((licenseProductName, idx) => {
      accordions.push(
        <Accordion className="product-accordion" key={`accordion${idx}`}>
          <AccordionSummary
            className="product-summary"
            expandIcon={<ExpandMoreIcon size={16} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div>
              <Typography className="product-name">
                {licenseProductName}
              </Typography>
              <div className="num-of-licenses">
                {Utils.replaceStringValues(
                  constants.ORG_DASHBOARD_LICENSES_ADD_LICENSE_NUMBER_OF_LICENSES_AVAILABLE,
                  '$numOfLicenses',
                  groupedLicenses[licenseProductName].length
                )}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className="product-details">
            <ul className="product-list">
              {groupedLicenses[licenseProductName].map(
                (license, licenseIndex) => (
                  <li key={`license${licenseIndex}`}>
                    <div className="product-list-item-wrapper">
                      <div className="product-list-item">
                        <div className="product-list-item-name">
                          {license?.licenseKey}
                        </div>
                        <div>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={'value'}
                            onChange={() => {}}
                          >
                            <Radio
                              checked={licenseKey === license?.licenseKey}
                              onChange={(e) => {
                                console.log('here');
                                setLicenseKey(license?.licenseKey);
                              }}
                              value={license?.licenseKey}
                              name="radio-buttons"
                              inputProps={{ 'aria-label': 'A' }}
                            />
                          </RadioGroup>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              )}
            </ul>
          </AccordionDetails>
        </Accordion>
      );
    });

    return accordions;
  };

  const convertEpochToUTC = (epochValue) => {
    let utcFormattedValue;

    if (isNaN(epochValue)) return;

    utcFormattedValue = moment.unix(epochValue / 1000).startOf('day');

    setActivationDate(utcFormattedValue.valueOf());
  };

  const addLicenseToDevice = async () => {
    try {
      const reqBody = {
        licenses: [
          {
            deviceId: targetDeviceId,
            licenseKey: licenseKey,
            activeDate: activationDate,
          },
        ],
      };

      setShowLoader(true);
      const res = await axios.put(
        `/user/orgs/${orgDetails?.orgId}/licenses/assign`,
        reqBody,
        Utils.requestHeader()
      );

      if (res?.data?.meta?.code === 200) {
        await setLicenses(`/user/orgs/${orgId}/licenses`);
        setShowLoader(false);
        callBack();
      } else {
        setShowToast(true);
        setUserMsg(res?.data?.meta.userMsg);
      }
    } catch (error) {
      setShowToast(true);
      setUserMsg(error.msg);
    } finally {
      setShowLoader(false);
    }
    };

  const isLicenseAvailable = () => {
    return Object.keys(groupedLicenses).length > 0;
  };

  return (
    <>
      <Container>
        <Row>
        <Col>
            {!isLicenseAvailable() ? (
              <div className="mb-4 warning-container">
                <div className="warning-message">
                  <InfoCircleIcon />
                  {constants.DEVICES_TAB_LICENSES_NO_LICENSE_AVAILABLE}
                </div>
              </div>
            ) : (
              <>
                {
                  constants.ORG_DASHBOARD_LICENSES_ADD_LICENSE_SELECT_LICENSE_TITLE
                }
              </>
            )}
          </Col>
        </Row>
        {isLicenseAvailable() && (
          <Row>
            <Col>
              <ListBlock
                className="list-block license-block"
                contentWrapperClass="text-start"
              >
                {renderAccordions()}
              </ListBlock>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <div>
              {
                constants.ORG_DASHBOARD_LICENSES_ADD_LICENSE_LICENSE_ACTIVATION_TITLE
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {/* TODO: put the style in a class name */}
            <div
              className="date-selector-wrapper"
              style={{ paddingTop: '10px', paddingBottom: '10px' }}
            >
              <DateSelector
                value={moment.utc(expiryDate)}
                minDate={moment.utc(expiryDate)}
                handleChange={(epochValue) => convertEpochToUTC(epochValue)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {/* TODO: put the style in a class name */}
            <div
              className="add-license-button-wrapper"
              style={{ paddingTop: '10px', paddingBottom: '10px' }}
            >
              <PrimaryButton
                className="confirm-btn"
                onClick={() => addLicenseToDevice()}
                loader={showLoader}
                disabled={
                    !targetDeviceId || !licenseKey || !activationDate ? true : false
                }
              >
                {constants.ORG_DASHBOARD_LICENSES_ADD_LICENSE_CONFIRM_LABEL}
              </PrimaryButton>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddLicenseForm;
