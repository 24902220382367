import { Avatar, PrimaryButton, TextBlock } from '../../components/common';
import { Utils, constants, roles } from '../../helpers';
import { warninground } from '../../assets/images';
import './ManageOrganizations.scss';
import axios from 'axios';
import { useEffect, useState } from 'react';
import useLicensesStore from '../../store/LicensesStore';
import { useOrganizations } from '../../store/OrganizationsStore';
import SiteToast from '../../components/common/SiteToast';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector } from 'react-redux';
import { getLoggedInUserRole } from '../../store/reducers/OrganizationsReducer';

const RemoveOrganization = (props) => {
  const { getLicenses } = useLicensesStore();
  const [activeLicenseMessage, setActiveLicenseMessage] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [variant, setVariant] = useState('error');
  // const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  const loggedInUserRole = useSelector(getLoggedInUserRole);
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    const activeLicenses = getLicenses()?.filter(
      (license) =>
        license.licenseStatus === 'ACTIVE' ||
        license.licenseStatus === 'ACTIVE_UNASSIGNED' ||
        license.licenseStatus === 'ASSIGNED_ACTIVATION_PENDING' ||
        license.licenseStatus === 'UNASSIGNED'
    );

    if (activeLicenses?.length > 0) {
      setActiveLicenseMessage(true);
    }
  }, []);

  const invalidateSessionApi = () => {
    const reqBody = {
      refresh_token: keycloak?.refreshToken,
    };
    axios
      .post("/user/token/invalidate/app", reqBody, Utils.requestHeader())
      .then((res) => {
        if (res?.data?.meta?.code === 200) {
          keycloak.logout({
            redirectUri: process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL,
          });
        }
      });
  };


  const removeOrganization = async () => {
    try {
      const response = await axios.delete(
        `/partner/orgs/${props?.editUser?.orgId}`,
        Utils.requestHeader()
      );
      if (response?.data?.meta?.code === 200) {
        props?.hideRemoveOrgModal();
        setVariant('success');
        setUserMsg(response?.data?.meta?.userMsg);
        if (loggedInUserRole === roles.ROLE2PORTAL || loggedInUserRole === roles.ROLE3PORTAL || loggedInUserRole === roles.ROLE7PORTAL) {
          invalidateSessionApi();
        } else {
          navigate('/customers/manage.html');
        }
      }else{
        props?.handleAPIFailure(response?.data);
      }
    } catch (err) {
      setVariant('error');
    }
  };

  return (
    <div className="d-flex flex-column align-items-center gap-4 text-center">
      {activeLicenseMessage ? (
        <TextBlock
          fontWeight="400"
          fontSize="0.875rem"
          lineHeight="1.25rem"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_56'
          )}
        >
          {`${constants.ACTIVE_LICENSE_HELPER_MESSAGE}`}
        </TextBlock>
      ) : null}
      <TextBlock
        fontWeight="400"
        fontSize="0.875rem"
        lineHeight="1.25rem"
        color={getComputedStyle(document.documentElement).getPropertyValue(
          '--greyscale_56'
        )}
      >
        {`${constants.REMOVE_ORGANIZATION_TEXT} ${props?.editUser?.orgName}?`}
      </TextBlock>

      <Avatar
        value={warninground}
        valueType="icon"
        size="extraLarge"
        style="roundedCircle"
      />

      <div className="w-100">
         <PrimaryButton
          className="btn btn-primary w-100"
          type="button"
          height="44px"
          fontSize="0.875rem"
          width="100%"
          onClick={() => props?.hideRemoveOrgModal()}
        >
          {constants.CANCEL_BUTTON_TITLE}
        </PrimaryButton>

        <PrimaryButton
          className="btn btn-primary-outline w-100 mt-2"
          type="button"
          width="auto"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          borderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          hoverBorderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          color={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          hoverColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_white')}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          backgroundColor="transparent"
          onClick={() =>  removeOrganization()}
        >
          {constants.REMOVE_ORGANIZATION}
        </PrimaryButton>
      </div>
      {/* Error message */}
      <SiteToast
        title={
          variant === 'error'
            ? constants.ERROR_TOAST_TITLE
            : constants.SUCCESS_TOAST_TITLE
        }
        show={!!userMsg}
        body={userMsg}
        variant={variant}
        position="top-center"
      />
    </div>
  );
};

export default RemoveOrganization;
