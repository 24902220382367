import React from 'react';
import Timeline from 'react-visjs-timeline';
import moment from 'moment';
import 'moment-timezone';
import '../../../assets/css/timeline.scss';
import { eventIcons } from '../../../helpers';
import { play, noVideo, pause, mute, unmute } from '../../../assets/images';
import videoProcessing from '../../../assets/images/cameras/videoProcessing.svg';
import { connect } from 'react-redux';
import {
  setMetaData,
  setCDNInfo,
  setLiveStream,
  setLocalStream,
  setRemoteStream,
  setSnapshotImage,
} from '../../../store/reducers/StreamingReducer';
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import { getTimelineData } from '../../../helpers/timelineData';
import constants from '../../../helpers/en';
import timezones from '../../../data/support/timezone.json';
import {
  sendPauseCVR,
  sendPlayCVR,
} from '../../../utils/connection/wssConnection';
import { Utils } from '../../../helpers';
import { disconnectWithMQTT } from '../../../utils/connection/mqttConnection';
import ImageFetch from '../../../pages/cameras/components/ImageFetch';
import TimelineControlsLive from '../../../components/streaming/TimelineControlsLive';
import PlaybackControlsView from '../../../components/streaming/PlaybackControlsView';

class EventTimelineControls extends React.Component {
  constructor(props) {
    super(props);
    this.timeline = React.createRef();
    const currentTime = new Date();
    const deviceStart = Utils.getDate(props?.streaming?.deviceStartDate / 1000);
    this.state = {
      minsOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: false,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: false,
        orientation: 'top',
        timeAxis: { scale: 'second', step: 6 },
        format: {
          majorLabels: {
            second: 'hh:mm',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment().subtract({ minutes: 2, seconds: 20 }).toDate(),
        end: moment(new Date(currentTime))
          .add({ minutes: 2, seconds: 20 })
          .toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime))
          .add({ minutes: 2, seconds: 20 })
          .toDate(),
        selectable: false,
      },
      selectedOption: 'Mins',
      activeTime: new Date(currentTime),
      timeZone: 'America/New_York',
      timezoneValue: moment.tz.guess(),
      offset: '-5',
      unixTime: moment(new Date(currentTime)).unix(),
      currentTimeNow: currentTime,
      posts: [],
      cdn: {},
      activeImage: '',
      metaData: [],
      minsView: true,
      secsView: false,
      daysView: false,
      clickAction: true,
      loading: false,
      cvrMode: true,
      CVRSnapMode: false,
      metaDataHere: [],
      minsMetaData: [],
      daysMetaData: [],
      secsMetaData: [],
      liveStreamMode: false,
      manualClicked: false,
      fullscreen: false,
      muteAudio: false,
      deviceId: null,
      startDate: null,
      eventsDataCheck: [],
      eventCategory: [],
      eventTimestamp: null,
      liveSnapshot: false,
      moveTimelineImage: false,
      internalEventFromSearch: false,
      indexContent: '',
    };
  }

  componentDidMount = () => {
    this.updateTimeZone();
    this.moveTimeline();
    setInterval(() => {
      this.setDynamicTimelineMove();
    }, 1000);
  };

  componentDidUpdate = (props) => {
    if (this.state.deviceId !== props.deviceId) {
      this.setState({
        deviceId: props?.deviceId,
      });
      this.props.setSnapshotImage(null);
      this.updateTimeZone();
    }
    if (
      this.state.eventTimestamp !== props.eventTimestamp ||
      this.state.indexContent !== props.indexContent
    ) {
      this.setState({
        loading: true,
      });
      if (this.state.cvrMode) {
        sendPauseCVR();
      }
      this.setState({
        eventTimestamp: props?.eventTimestamp,
      });
      if (this.CVRAutoPlay) {
        clearInterval(this.CVRAutoPlay);
      }
      setTimeout(() => {
        this.goToEvent(props?.eventTimestamp);
      }, 1000);
      console.log('index here', props.indexContent);
      this.setState({
        indexContent: props.indexContent,
      });
    }
  };

  goToEvent = (timestamp) => {
    setTimeout(() => {
      if (this.state.activeTime !== Utils.getDate(timestamp / 1000)) {
        this.timeline.current.$el.moveTo(Utils.getDate(timestamp / 1000), [
          { duration: 0 },
        ]);
      }
      if (!this.state.CVRSnapMode) {
        this.moveTimeline();
      }
    }, 1500);

    setTimeout(() => {
      this.setState({
        loading: false,
        clickAction: false,
      });
    }, 2000);

    if (this.state.liveStreamMode || this.state.cvrMode) {
      this.setState({
        cvrMode: true,
        liveStreamMode: false,
        liveSnapshot: false,
        CVRSnapMode: false,
      });
      sendPlayCVR(Utils.getDate(timestamp / 1000), this.state?.deviceId);
    } else {
      this.setState({
        CVRSnapMode: true,
        liveSnapshot: false,
      });
    }
    this.updateDots();
  };

  updateTimeZone = () => {
    const getLocationSelected = timezones?.data?.find(
      (zone) => zone.value === this.props?.timezone
    );
    const zone = getLocationSelected?.location || moment.tz.guess();

    const UTCDate = moment(new Date()).tz(zone).format();

    if (zone) {
      this.setState({
        timeZone: zone,
        offset: UTCDate,
      });
    }
  };

  moveTimeline = () => {
    if (this.state.liveStreamMode) {
      this.setState({
        activeTime: new Date(),
      });
    }
    if (this.state.selectedOption === 'Days') {
      this.CVRAutoPlay = setInterval(() => {
        this.setState({
          activeTime: moment(this.state.activeTime)
            .add({ seconds: 1 })
            .toDate(),
        });
      }, 1000);
    } else {
      this.CVRAutoPlay = setInterval(() => {
        this.timeline.current.$el.moveTo(
          moment(this.state.activeTime).add({ seconds: 1 }).toDate()
        );
      }, 1000);
    }
  };

  componentWillUnmount() {
    clearInterval(this.CVRAutoPlay);
    if (this.state.cvrMode) {
      sendPauseCVR();
    }
  }

  shouldComponentUpdate = () => {
    return true;
  };

  setDynamicTimelineMove = () => {
    const { offset } = this.state;
    this.timeline?.current?.$el.setOptions({
      moment: function (date) {
        return moment(date).utcOffset(offset);
      },
    });
    if (this.state.minsView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ minutes: 2, seconds: 21 }).toDate(),
      });
    } else if (this.state.daysView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ days: 1, hours: 6, seconds: 1 }).toDate(),
      });
    } else if (this.state.secsView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ seconds: 41 }).toDate(),
      });
    }
  };

  updateDots = () => {
    this.timeline?.current?.$el?.setItems([
      {
        start: moment(
          Utils.getDate(this.props?.eventTimestamp / 1000)
        ).toDate(),
        type: 'point',
      },
    ]);
  };

  rangeChangeHandler = (event) => {
    let timeInMin = moment(event.start)
      .add({ minutes: 2, seconds: 20 })
      .toDate();
    let timeInDay = moment(event.start).add({ days: 1, hours: 6 }).toDate();
    let timeInSec = moment(event.start).add({ seconds: 40 }).toDate();
    if (event.byUser) {
      if (
        this.state.selectedOption === 'Mins' &&
        Utils.getUnixDate(timeInMin) !==
          Utils.getUnixDate(this.state.activeTime)
      ) {
        this.setState({
          activeTime: timeInMin,
        });
      } else if (
        this.state.selectedOption === 'Days' &&
        timeInDay !== this.state.activeTime
      ) {
        this.setState({
          activeTime: timeInDay,
        });
      } else if (
        this.state.selectedOption === 'Secs' &&
        timeInSec !== this.state.activeTime
      ) {
        this.setState({
          activeTime: timeInSec,
        });
      }
      if (
        moment(this.state.activeTime).unix() !==
          moment(this.currentTime).unix() &&
        !this.state.clickAction
      ) {
        this.currentTime = this.state.activeTime;
      }
      this.setState({
        CVRSnapMode: true,
        liveSnapshot: false,
        moveTimelineImage: true,
      });
      if (this.state.liveStreamMode) {
        disconnectWithMQTT();
        this.setState({
          liveStreamMode: false,
        });
      }
      sendPauseCVR();
    } else {
      if (
        this.state.minsView &&
        Utils.getUnixDate(timeInMin) !==
          Utils.getUnixDate(this.state.activeTime)
      ) {
        this.setState({
          activeTime: moment(event.start)
            .add({ minutes: 2, seconds: 20 })
            .toDate(),
        });
      } else if (this.state.daysView) {
        this.setState({
          activeTime: moment(event.start).add({ days: 1, hours: 6 }).toDate(),
        });
      } else if (this.state.secsView) {
        this.setState({
          activeTime: moment(event.start).add({ seconds: 40 }).toDate(),
        });
      }
    }
  };

  rangeChangedHandler = (event) => {
    if (event.byUser) {
      if (this.state.cvrMode || !this.state.manualClicked) {
        this.setState({
          CVRSnapMode: false,
        });
        console.log('this.state.deviceId', this.state.deviceId);
        sendPlayCVR(this.state.activeTime, this.state.deviceId);
      }
      this.setState({
        moveTimelineImage: false,
      });

      if (!this.state.manualClicked) {
        this.setState({
          cvrMode: true,
        });

        if (this.state.manualClicked && this.state.cvrMode) {
          this.moveTimeline();
        }
      }
    }
  };

  onMuteIconClick = () => {
    this.setState({
      muteAudio: !this.state.muteAudio,
    });
  };

  onPauseCVR = (e) => {
    if (this.state.cvrMode) {
      sendPauseCVR();
      this.setState({
        cvrMode: false,
        CVRSnapMode: true,
      });
    }
    if (this.state.liveStreamMode) {
      disconnectWithMQTT();
      this.setState({
        CVRSnapMode: true,
      });
      this.setState({
        liveStreamMode: false,
        liveSnapshot: true,
      });
    }
    this.setState({
      manualClicked: true,
    });
    clearInterval(this.CVRAutoPlay);
  };

  onPlayCVR = () => {
    this.setState({
      cvrMode: true,
      liveStreamMode: false,
      CVRSnapMode: false,
    });
    sendPlayCVR(this.state.activeTime, this.state.deviceId);

    this.moveTimeline();
  };

  getOptions = () => {
    if (this.state.minsView) {
      return this.state.minsOptions;
    } else if (this.state.daysView) {
      return this.state.daysOptions;
    } else if (this.state.secsView) {
      return this.state.secsOptions;
    }
  };

  getItemsMetaData = (data) => {
    const minsAdjustment = { minutes: 2, seconds: 20 };
    const daysAdjustment = { days: 1, hours: 6 };
    const secsAdjustment = { seconds: 40 };

    const minsData = getTimelineData(data, minsAdjustment);
    const daysData = getTimelineData(data, daysAdjustment);
    const secsData = getTimelineData(data, secsAdjustment);

    this.setState({
      minsMetaData: [...minsData, ...this.state.eventsDataCheck],
      daysMetaData: daysData,
      secsMetaData: secsData,
    });
  };

  render() {
    const deviceInfo = this.props?.modalContent?.device[0];
    const { isRemoteStreamPlay } = this.props.streaming;

    return (
      <>
        <div className="events-icons-wrapper">
          <span
            className={`icons-image previous-icon ${
              this.state.clickAction ? 'disabled' : ''
            }`}
            onClick={() => {
              this.setState({ clickAction: true });
              this.props.onNextPreviousClick('previous');
            }}
          >
            <ArrowLeft width={18} />
          </span>
          <span
            className={`icons-image next-icon ${
              this.state.clickAction || this.state.indexContent === 0
                ? 'disabled'
                : ''
            }`}
            onClick={() => {
              this.setState({ clickAction: true });
              this.props.onNextPreviousClick('next');
            }}
          >
            <ArrowRight width={18} />
          </span>
        </div>
        <div className="wrapper-app live-streaming">
          {this.state.liveStreamMode && (
            <div className="stream-timeline-wrapper liveview">
              {this.state.loading && (
                <img
                  className="video-processing"
                  src={videoProcessing}
                  alt="video processing"
                />
              )}
              <TimelineControlsLive muteAudio={this.state.muteAudio} />
            </div>
          )}

          {!this.state.liveStreamMode && (
            <div className="stream-timeline-wrapper">
              <div className="scrubber-image-view">
                {this.state.loading && (
                  <img
                    className="video-processing"
                    src={videoProcessing}
                    alt="video processing"
                  />
                )}
                {this.state.activeTime &&
                  this.state.CVRSnapMode &&
                  !this.state.loading && (
                    <div
                      className={`active-image ${
                        this.state.activeImage === noVideo ? 'novideo' : ''
                      }`}
                    >
                      <ImageFetch
                        moveTimeline={this.state.moveTimelineImage}
                        liveSnapshot={this.state.liveSnapshot}
                        time={this.state.activeTime}
                        cdnValue={this.props?.streaming?.cdnInfo}
                        deviceId={this.state?.deviceId}
                      />
                    </div>
                  )}

                {!this.state.CVRSnapMode && (
                  <PlaybackControlsView muteAudio={this.state.muteAudio} />
                )}
              </div>
            </div>
          )}

          <div className="stream-timeline-wrapper timeline-controls-page search-page-event">
            <div className={`scrubber-wrapper`}>
              <div className="timelines-controls-wrapper">
                {this.state.liveStreamMode && (
                  <>
                    <div className="timeline-controls-icons">
                      {!(this.state.cvrMode || this.state.liveStreamMode) && (
                        <span
                          onClick={() => this.onPlayCVR()}
                          className="icons-image"
                        >
                          <img width={16} src={play} alt="icon" />
                        </span>
                      )}

                      {(this.state.cvrMode || this.state.liveStreamMode) && (
                        <span
                          onClick={(e) => this.onPauseCVR(e)}
                          className="icons-image"
                        >
                          <img width={16} src={pause} alt="icon" />
                        </span>
                      )}
                      <span
                        className="icons-image"
                        onClick={() => this.onMuteIconClick()}
                      >
                        <img
                          width={16}
                          src={this.state.muteAudio ? mute : unmute}
                          alt="icon"
                        />
                      </span>
                    </div>
                    <div className={`active_time`}>
                      {isRemoteStreamPlay &&
                        moment
                          .tz(
                            moment(this.state.activeTime),
                            this.state.timeZone
                          )
                          .format('MMM DD, YYYY - hh:mm:ss A z')}
                    </div>
                  </>
                )}
                {!this.state.liveStreamMode && (
                  <>
                    <div className="timeline-controls-icons">
                      {!(
                        this.state.cvrMode ||
                        this.state.liveStreamMode ||
                        !this.state.manualClicked
                      ) && (
                        <span
                          onClick={() => this.onPlayCVR()}
                          className="icons-image"
                        >
                          <img width={16} src={play} alt="icon" />
                        </span>
                      )}

                      {(this.state.cvrMode ||
                        this.state.liveStreamMode ||
                        !this.state.manualClicked) && (
                        <span
                          onClick={(e) => this.onPauseCVR(e)}
                          className="icons-image"
                        >
                          <img width={16} src={pause} alt="icon" />
                        </span>
                      )}

                      <span
                        className="icons-image"
                        onClick={() => this.onMuteIconClick()}
                      >
                        <img
                          width={16}
                          src={this.state.muteAudio ? mute : unmute}
                          alt="icon"
                        />
                      </span>
                    </div>
                    <div className={`active_time`}>
                      {isRemoteStreamPlay &&
                        moment
                          .tz(
                            moment(this.state.activeTime),
                            this.state.timeZone
                          )
                          .format('MMM DD, YYYY - hh:mm:ss A z')}
                    </div>
                  </>
                )}
              </div>
              {this.state.metaDataHere && (
                <div
                  className={`scrubber ${
                    this.state.clickAction ? 'visiblity-hidden' : ''
                  }`}
                >
                  <Timeline
                    ref={this.timeline}
                    options={this.getOptions()}
                    rangechangeHandler={(event) =>
                      this.rangeChangeHandler(event)
                    }
                    rangechangedHandler={(event) =>
                      this.rangeChangedHandler(event)
                    }
                  />
                </div>
              )}
            </div>
            {this.state.liveStreamMode && (
              <div className={`timeline-icons golive`}>
                <button
                  onClick={() => this.onGoLive()}
                  className="golive-button"
                >
                  {constants.TOP_HEADER_LIVE_NAV_TITLE}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="search-events-modal-footer">
          <div className="modal-footer-content">
            <div className="footer-modal-description">
              <div className="footer-modal-description-location">
                {deviceInfo?.locationName} - {deviceInfo?.areaName} -{' '}
                {deviceInfo?.deviceName}
              </div>
              <div className="footer-modal-description-class">
                {eventIcons(this.props?.modalContent?.class)}
                <span className="event-name">
                  {this.props?.modalContent?.class}
                </span>
              </div>
            </div>
            <div
              className="footer-modal-redirection"
              onClick={() =>
                this.props.onCameraDetailsClick(
                  this.props?.modalContent,
                  this.state.activeTime,
                  this.state.cvrMode
                )
              }
            >
              <span>Camera Details</span> <ArrowRight width={18} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  setMetaData,
  setCDNInfo,
  setLiveStream,
  setRemoteStream,
  setLocalStream,
  setSnapshotImage,
};

const mapStoreStateToProps = (state) => ({
  account: state.accounts,
  streaming: state.streaming,
});

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(EventTimelineControls);
