// import { useState } from 'react'; TODO : use later
import Modal from 'react-bootstrap/Modal';
// import { SiteToast } from './'; TODO : use later
import { RxCross1 } from 'react-icons/rx';
import { constants } from "../../helpers/";

import arrowLeft from "../../assets/images/icons/Arrowleft.svg";


function SiteModal({
  showModal,
  hideModal,
  classes,
  modalFooter = '',
  modalTitle = '',
  children,
  errorMsg,
  modalHeader = false,
  wrapperClass,
  isFromLocation = false,
  size,
  ...props
}) {
  // TODO - will work on this after demo 2 for showing error messages globally
  // const [errorMsgData, setErrorMsgData] = useState(errorMsg);
  return (
    <div className={`modal-wrapper${wrapperClass ? ' ' + wrapperClass : ''}`}>
      <Modal
        show={showModal?.toString() === 'true' ? showModal : false}
        onHide={hideModal}
        size={size || 'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className={classes}
        {...props}
      >
        {!modalHeader && (
          <Modal.Header>
            <div className="breadcrumbsResponsive mobile w-100">
              <div className="text-start me-auto navbar-brand arrow">
                <img src={arrowLeft} alt=""></img>
                <spam
                  className="backText"
                   onClick={hideModal}
                >
                  {constants.BREADCRUMB_BACK}
                   
                </spam>
              </div>
            </div>
            <Modal.Title
              className={`modal-title${isFromLocation ? ' location-font' : ''}`}
            >
              {modalTitle}
            </Modal.Title>
            <RxCross1
              className="site-modal-close"
              size={20}
              onClick={hideModal}
            />
          </Modal.Header>
        )}
        <Modal.Body className='modal-body-responsive'>{children}</Modal.Body>
        {modalFooter && <Modal.Footer>{modalFooter}</Modal.Footer>}
      </Modal>
      {/* TODO - will work on this after demo 2 for showing error messages globally 
       <SiteToast title="Error" show={!!errorMsg} body={errorMsg} onDelay={() => } /> */}
    </div>
  );
}

export default SiteModal;
