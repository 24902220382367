import { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import axios from 'axios';

import { Utils } from '../../helpers/';

import { useOrganizations } from '../../store/OrganizationsStore';

import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

import { HiPlus } from 'react-icons/hi';
import { IoCloseOutline } from 'react-icons/io5';

import './Tags.scss';
import mockTags from '../../data/mocks/tags.json';
const USE_MOCKED_DATA = true;

const Tags = () => {
  const [tags, setTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [orgDetails, setOrgDetails] = useState();

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        let responseData;

        if (!USE_MOCKED_DATA && orgDetails?.orgId) {
          const res = await axios.get(
            `/incident/orgs/${orgDetails?.orgId}/tags`,
            Utils.requestHeader()
          );
          responseData = res?.data;
        } else {
          responseData = mockTags;
        }

        // Set tags if request is successful
        if (responseData?.meta?.code === 200) {
          setTags([...responseData.data?.tags]);
          setFilteredTags([...responseData.data?.tags]);
        } else {
          if (responseData?.meta?.code) {
            console.error(
              `${responseData?.meta?.code}: ${responseData?.meta?.message}`
            );
          } else if (responseData?.data) {
            console.error(responseData?.data?.userMsg);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchTags();
  }, [orgDetails?.orgId]);

  const addTag = (selectedTag) => {
    const currentTags = [...selectedTags];

    if (!selectedTag) return;

    currentTags.push(selectedTag);
    setSelectedTags([...currentTags]);
  };

  const removeTag = (selectedTag) => {
    const currentTags = [...selectedTags];

    if (!selectedTag) return;

    const tagIndex = currentTags.findIndex(
      (tag) => tag.tagId === selectedTag.tagId
    );

    if (tagIndex !== -1) {
      currentTags.splice(tagIndex, 1);
      setSelectedTags([...currentTags]);
    }
  };

  const filterTags = (e) => {
    const filter = e?.target?.value;
    const newTags = tags.filter((tag) => tag.name.startsWith(filter));
    setFilteredTags([...newTags]);
  };

  const debouncedOnChange = debounce(filterTags, 500);

  return (
    <div className="tags-wrapper">
      {selectedTags.length > 0 && (
        <div className="tags-selected-wrapper">
          {selectedTags.map((tag) => (
            <div key={tag.tagId} className="tag-added-item">
              <span className="tag-label">{tag.name}</span>
              <span
                className="tag-delete-icon"
                onClick={(e) => {
                  e.preventDefault();
                  removeTag(tag);
                }}
              >
                <IoCloseOutline size={'1.2rem'} />
              </span>
            </div>
          ))}
        </div>
      )}
      <div className="tag-filter">
        <input
          className="tag-filter-input"
          type="text"
          placeholder="Enter Tag"
          onChange={debouncedOnChange}
        />
      </div>
      <div className="tags-list-wrapper">
        {filteredTags?.length > 0 ? (
          <>
            {filteredTags.map((filteredTag) => {
              const tagIndex = selectedTags.findIndex(
                (tag) => tag.tagId === filteredTag.tagId
              );

              if (tagIndex !== -1) {
                return '';
              }

              return (
                <div className="tag-item">
                  <span className="tag-label">{filteredTag.name}</span>
                  <span
                    className="tag-add-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      addTag(filteredTag);
                    }}
                  >
                    <HiPlus size={'0.875rem'} />
                  </span>
                </div>
              );
            })}
            <div className="tag-load-more">Load more</div>
            <div className="tag-add-new">
              <HiPlus size={'0.875rem'} />
              <span className="tag-add-new-label">Add New Tag</span>
            </div>
          </>
        ) : (
          <>
            <span>No tags available</span>
            <div className="tag-add-new">
              <HiPlus size={'0.875rem'} />
              <span className="tag-add-new-label">Add New Tag</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Tags;
