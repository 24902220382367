import Image from 'react-bootstrap/Image';

const AvatarStyles = {
  rounded: {
    small: {
      height: '24px',
      width: '24px',
      lineHeight: '24px',
      borderRadius: '6px',
      fontSize: '0.75rem',
    },
    medium: {
      height: '32px',
      width: '32px',
      lineHeight: '32px',
      borderRadius: '8px',
    },
    large: {
      height: '48px',
      width: '48px',
      lineHeight: '48px',
      borderRadius: '12px',
    },
  },
  roundedCircle: {
    small: {
      height: '24px',
      width: '24px',
      lineHeight: '24px',
      borderRadius: '50%',
      fontSize: '0.75rem',
    },
    medium: {
      height: '32px',
      width: '32px',
      lineHeight: '32px',
      borderRadius: '50%',
    },
    semiLarge: {
      height: '40px',
      width: '40px',
      lineHeight: '40px',
      borderRadius: '50%',
    },
    large: {
      height: '48px',
      width: '48px',
      lineHeight: '48px',
      borderRadius: '50%',
    },
    extraLarge: {
      height: '88px',
      width: '88px',
      lineHeight: '88px',
      borderRadius: '50%',
    },
  },
};

const Avatar = (props) => {
  const {
    color = getComputedStyle(document.documentElement).getPropertyValue(
      '--primary_40'
    ),
    backgroundColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--primary_96'),
  } = props;
  let styleData = Object.assign(
    { backgroundColor: backgroundColor, color: color },
    getAvatarStyles(props?.size, props?.avatarStyle)
  );

  return (
    <div className="site-avatars large-avatar">
      {props.valueType === 'icon' && (
        <Image
          src={props.value}
          alt="Avatar"
          style={getAvatarStyles(props?.size, props?.avatarStyle)}
        />
      )}
      {props.valueType === 'text' && (
        <div className={`site-avatar-number`} style={styleData}>
          {props.value}
        </div>
      )}
    </div>
  );
};

const getAvatarStyles = (size, style) => {
  if (!size || !style || !AvatarStyles[style]) {
    return null;
  }

  return AvatarStyles[style][size];
};

Avatar.defaultProps = {
  size: 'large',
};

export default Avatar;
