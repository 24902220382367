import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PrimaryButton } from '../../../components/common';
import { constants } from '../../../helpers';

const DiscoveredDevciesDelete = ({
  cancelDeleting,
  deleteDiscoveredDevices,
}) => {
  const [showLoader, setShowLoader] = useState(false);

  return (
    <>
      <Row>
        <div className="delete-device-modal-description">
          {constants.DISCOVERED_DEVICES_DELETE_MESSAGE}
        </div>
      </Row>
      <Row>
        <Col>
          <div className="delete-device-button-wrapper">
            <PrimaryButton
              className="btn"
              type="submit"
              width="100%"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              borderWidth="1.5px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              onClick={() => cancelDeleting()}
            >
              {constants.DISCOVERED_DEVICES_DELETE_CANCEL}
            </PrimaryButton>
            <PrimaryButton
              className="btn btn-outline-secondary w-100 mt-2"
              type="submit"
              loader={showLoader}
              loaderClassName="delete-device-loader"
              height="56px"
              fontSize="1.125rem"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_96')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              onClick={() => {
                setShowLoader(true);
                deleteDiscoveredDevices();
              }}
            >
              {constants.DISCOVERED_DEVICES_DELETE}
            </PrimaryButton>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DiscoveredDevciesDelete;
