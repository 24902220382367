import { mqttPublish } from "../../../../utils/connection/mqttConnection";

export default function publishDeviceSetting(resolutionObj, resource, deviceInfo, isRestart = false) {
	const tid = Math.floor(new Date().getTime());
	const context = {
		topic: deviceInfo.devicePublishId,
		payload: JSON.stringify({
			tid: `${tid}`,
			to: `${deviceInfo.hubId}`,
			from: `${deviceInfo.accountId}`,
			msg: {
				action: "set",
				resource: `ch/${deviceInfo.deviceId}/${resource}`,
				...(!isRestart && { properties: { ...resolutionObj } }),
			},
			publish: `d/notify/${deviceInfo.accountId}/${deviceInfo.uuid}`,
		}),
		qos: 0,
	};
	mqttPublish(context);
}

export function publishHubDeviceSetting(resolutionObj, resource, deviceInfo, isRestart = false) {
	const tid = Math.floor(new Date().getTime());
	const context = {
		topic: deviceInfo.devicePublishId,
		payload: JSON.stringify({
			tid: `${tid}`,
			to: `${deviceInfo.hubId}`,
			from: `${deviceInfo.accountId}`,
			msg: {
				action: "set",
				resource: `ch/${deviceInfo.hubId}/${resource}`,
				...(!isRestart && { properties: { ...resolutionObj } }),
			},
			publish: `d/notify/${deviceInfo.accountId}/${deviceInfo.uuid}`,
		}),
		qos: 0,
	};
	mqttPublish(context);
}

export function publishFirmwareDeviceSetting(resolutionObj, resource, deviceInfo, isRestart = false) {
	const tid = Math.floor(new Date().getTime());
	const context = {
		topic: deviceInfo.devicePublishId,
		payload: JSON.stringify({
			tid: `${tid}`,
			to: `${deviceInfo.hubId}`,
			from: `${deviceInfo.accountId}`,
			msg: {
				action: "set",
				resource: `${resource}`,
				...(!isRestart && { properties: { ...resolutionObj } }),
			},
			publish: `d/notify/${deviceInfo.accountId}/${deviceInfo.uuid}`,
		}),
		qos: 0,
	};
	mqttPublish(context);
}
