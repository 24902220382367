import { useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from '../../../../components/forms';
import { DeviceStatusEnum, DeviceTypeEnum, Utils, constants } from '../../../../helpers';
import { addDeviceToHubAPI } from '../addDeviceAPI';
import { PrimaryButton } from '../../../../components/common';
import DummyImageIcon from '../../../../assets/images/dummy-snapshot.svg';
import {
  getLocationsData,
} from '../../../../store/reducers/AccountReducer';
import axios from 'axios';
import {
  getManufacturerSKUList,
} from '../../../../store/reducers/NVRDeviceReducer';
import { useEffect } from 'react';
import {
  getAllScannedDevices,
  setDevicesListOfCurrOrg,
} from '../../../../store/NVRDeviceStoreIDB';
import { getSelectedOrganization } from '../../../../store/AccountStoreIDB';
import { observerInstance } from '../../../../store/indexDB/observer';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';

const AddAndUpdateDeviceControl = ({
  selectedDevice,
  nvrDeviceData,
  hubId,
  isAddButtonDisabled,
  deviceSelectHandler,
  selectedDeviceLoaderHandler,
  errorMsgHandler,
  fetchDeviceListForOrg,
  hideModal,
  isMobilePopup,
  devicesListOfCurrOrgList,
  scanDeviceRequestHandler,
  updateDeviceHandler,
}) => {
  const formikRef = useRef();
  const [orgDetails, setOrgDetails] = useState();
  const [allScannedDevicesList, setAllScannedDevicesList] = useState();
  const allLocations = useSelector(getLocationsData);
  const manufacturerSKUList = useSelector(getManufacturerSKUList);
  const isEditMode =
    selectedDevice?.deviceAuthStatus === DeviceStatusEnum.CON_OFFLINE ||
    selectedDevice?.deviceAuthStatus === DeviceStatusEnum.ADDED_N_CLAIMED ||
    selectedDevice?.deviceAuthStatus === DeviceStatusEnum.ADDED_NOT_CLAIMED;
  const getAreas = () => {
    const location = allLocations?.find(
      (location) => location.locationId === nvrDeviceData?.locationId
    );
    return location?.areas || [];
  };
  const locationsData = getAreas();

  const validateAuthenticated = Yup.object({
    deviceName: Yup.string()
      .max(40, constants.DEVICE_NAME_MAX_LENGTH_MESSAGE)
      .required(constants.DEVICE_NAME_REQUIRED_ERROR_MESSAGE),
    areaId: Yup.string().required(),
  });

  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);

  const loadAllScannedDevices = useCallback(async () => {
    const allDevices = await getAllScannedDevices();
    setAllScannedDevicesList(allDevices || []);
  }, []);

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000
  );
  const debouncedLoadAllScannedDevices = useDebouncedCallback(
    loadAllScannedDevices,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'selectedOrganization') {
        await debouncedLoadSelectedOrgData();
      }
      if (data.key === 'allScannedDevices') {
        await debouncedLoadAllScannedDevices();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadSelectedOrgData();
    debouncedLoadAllScannedDevices();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [
    debouncedLoadSelectedOrgData,
    debouncedLoadAllScannedDevices,
  ]);

  useEffect(() => {
    formikRef?.current?.resetForm();
  }, [selectedDevice?.channel]);

  const getChannelPayload = (device) => ({
    channel: device?.channel,
    serialNo: device?.serialNumber ? device?.serialNumber : device?.serialNo ,
    macAddress: device?.macAddress.replaceAll("-", ":"),
    manufacturer: manufacturerSKUList?.find(
      (item) => item?.model === device?.modelNumber
    )?.manufacturer,
    model: device?.modelNumber,
    locationId: nvrDeviceData?.locationId,
    timezone: nvrDeviceData?.properties?.['timezone'],
    deviceType: device?.deviceType || 'onvifcam' ,
    requestedApps: [],
    chIndex: device?.chIndex,
  });

  const handleAddAndClaimDeviceToHub = async (values) => {
    try {
      errorMsgHandler("");
      let payload = [];
      if (nvrDeviceData?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR) {
        const parentDevice = allScannedDevicesList?.find(
          (a) => a.macAddress && 
                 selectedDevice.macAddress && 
                 a.macAddress.toUpperCase() === selectedDevice.macAddress.toUpperCase()
        );
        payload = [
          {
            ...getChannelPayload(parentDevice),
            deviceName: parentDevice.serialNumber ? parentDevice.serialNumber : parentDevice?.serialNo ,
            areaId: values.areaId,
            channels: [
              {
                ...getChannelPayload(selectedDevice),
                deviceName: values.deviceName || selectedDevice.serialNumber,
                areaId: values.areaId,
              },
            ],
          },
        ];
      } else {
        payload = [
          {
            ...getChannelPayload(selectedDevice),
            deviceName: values.deviceName || selectedDevice.serialNumber,
            areaId: values.areaId,
            channels: selectedDevice?.channels?.map((ch) => ({
              ...getChannelPayload(ch),
              deviceName: ch.serialNumber,
              areaId: values.areaId,
            })),
          },
        ];
      }
      selectedDeviceLoaderHandler(true);
      const deviceResponse = await addDeviceToHubAPI(
        orgDetails,
        payload,
        hubId
      );
      if (deviceResponse?.meta?.code === 200) {
        const data = deviceResponse?.data;
        const device = data.devices?.[0].device;
        const channels = data.devices?.[0].channels;
        const devicesToUpdate = [device, ...channels];
        if (device) {
          // const updatedDevices = [...devicesListOfCurrOrgList, device, ...channels];
          const newEntries = devicesToUpdate
            .map((d) => {
              const foundDevice = devicesListOfCurrOrgList?.find(
                (x) =>
                  x.macAddress === d.macAddress && x.serialNo === d.serialNo
              );
              return foundDevice ? undefined : d;
            })
            ?.filter((x) => x);
          let updatedDevices;
          if (nvrDeviceData?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR) {
            updatedDevices = [...devicesListOfCurrOrgList, ...newEntries].map(
              (d) => {
                const foundDevice = devicesToUpdate.find(
                  (x) =>
                    x.macAddress === d.macAddress && x.serialNo === d.serialNo
                );
                return foundDevice ? foundDevice : d;
              }
            );
          } else {
            updatedDevices = [...devicesListOfCurrOrgList, ...newEntries].map(
              (d) => {
                const foundDevice = devicesToUpdate.find(
                  (x) =>
                    x.macAddress === d.macAddress &&
                    x.serialNo === d.serialNo &&
                    x.deviceId === d.deviceId
                );
                return foundDevice ? foundDevice : d;
              }
            );
          }
          // dispatch(setAddedAndClaimedDeviceId(channels[0].deviceId));
          // await setDevicesListOfCurrOrg(updatedDevices);
          updateDeviceHandler(updatedDevices);         
          deviceSelectHandler(channels[0].deviceId);
        }
      } else {
        errorMsgHandler(deviceResponse?.userMsg);
      }
      selectedDeviceLoaderHandler(false);
      if(isMobilePopup){
        hideModal();
      }
       
    } catch (error) {
      console.error("ERROR: ", error);
      selectedDeviceLoaderHandler(false);
    }
  };

  const handleUpdateDeviceToHub = async (values) => {
      try {
      errorMsgHandler('');
      selectedDeviceLoaderHandler(true);
      const orgID = nvrDeviceData?.orgId;
      const locationId = selectedDevice?.locationId;
      const areaId = selectedDevice?.areaId;
      const reqBody = {
        newAreaId: values?.areaId,
        deviceName: values?.deviceName,
      };
      const res = await axios.put(
        `/device/orgs/${orgID}/locations/${locationId}/areas/${areaId}/devices/${selectedDevice.deviceId}`,
        reqBody,
        Utils.requestHeader()
      );
      if (res?.data?.meta?.code === 200) {
        const data = res?.data?.data;
        const updatedDevices = devicesListOfCurrOrgList?.map((item) => {
          if (item.deviceId === selectedDevice.deviceId) {
            return {
              ...item,
              ...data,
              deviceName: data.deviceName,
              areaId: data.newAreaId,
            };
          } else {
            return item;
          }
        });
        await setDevicesListOfCurrOrg(updatedDevices);
        updateDeviceHandler(updatedDevices);
      } else {
        errorMsgHandler(res?.data?.meta?.userMsg);
      }
      selectedDeviceLoaderHandler(false);
      if(isMobilePopup){
        hideModal();
      }
    } catch (error) {
      selectedDeviceLoaderHandler(false);
      console.error('ERROR: ', error);
    }
  };

  const handleRemoveClick = (deviceId) => {
    removeDevice(deviceId);
  };

  const removeDevice = async (deviceId) => {
      try {
      errorMsgHandler('');
      selectedDeviceLoaderHandler(true);
      const res = await axios.delete(
        `/device/orgs/${orgDetails?.orgId}/devices/${deviceId}/v2`,
        Utils.requestHeader()
      );
      if (
        parseInt(res?.status) === 202 ||
        parseInt(res?.data?.meta?.code) === 200
      ) {
        deviceSelectHandler(deviceId);
        // dispatch(setAddedAndClaimedDeviceId(deviceId));
        fetchDeviceListForOrg();
        if (nvrDeviceData?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR) {
          scanDeviceRequestHandler();
        }
      } else {
        errorMsgHandler(res?.data?.meta?.userMsg);
      }
      selectedDeviceLoaderHandler(false);
    } catch (error) {
      selectedDeviceLoaderHandler(false);
      // TODO : to print the error will update this later
      console.error(error);
    }
  };

  const getAreaId = () => {
    if (isEditMode) {
      return selectedDevice.areaId;
    } else {
      const location = locationsData.find((l) => l.isDefault);
      return location ? location.areaId : locationsData[0]?.areaId || '';
    }
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        deviceName: isEditMode ? selectedDevice.deviceName : '',
        areaId: getAreaId(),
      }}
      validationSchema={validateAuthenticated}
      onSubmit={async (values) => {
        if (isEditMode) {
          
          handleUpdateDeviceToHub(values);
        } else {
        
          handleAddAndClaimDeviceToHub(values);
        }
      }}
      innerRef={formikRef}
    >
      {({ dirty, isValid, values, handleSubmit, handleChange }) => (
        <Form className="modal-form" onSubmit={handleSubmit}>
          <div className="device-image-wrapper">
            <img src={DummyImageIcon} alt="DummyImageIcon" />
          </div>
          <div className="fieldTitle mb-1 mt-3">
            {constants.DEVICES_DEVICE_NAME_TEXT}
          </div>
          <div className="">
            <TextField
              placeholder={constants.DEVICES_DEVICE_NAME_TEXT}
              name="deviceName"
              type="text"
              removebottommargin="true"
              removetopmargin="true"
              onChange={handleChange}
            />
          </div>
          <div className="fieldTitle mb-1 mt-3">
            {constants.DEVICES_ASSIGN_DEVICE_AREA_TEXT}
          </div>
          <div className="radio-wrapper">
            {locationsData?.map((location) => (
              <div className="radiotitle" key={location.areaId}>
                <div className="area-name">{location.areaName}</div>
                <div className="makingBig">
                  <Field
                    type="radio"
                    name="areaId"
                    value={location.areaId}
                    onChange={handleChange}
                  />
                </div>
              </div>
            ))}
            </div>
            {/* <div className="fieldTitle mb-1 mt-3">{constants.DEVICE_INFO_ASSIGN_APPLICATION}</div>
            <div className="">
            <input className="form-control" disabled type="text" value={getKeysArray(nvrDeviceData?.apps).join(', ')}  readonly />
            </div> */}
          <PrimaryButton
            className="mt-4 btn-primary"
            fontSize="14px"
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            height="44px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--brand_white'
            )}
            disabled={
              !dirty || 
              !values.deviceName || 
              !isValid || 
              isAddButtonDisabled ||
              selectedDevice?.deviceAuthStatus === 
                DeviceStatusEnum.ADDED_NOT_CLAIMED
            }
          >
            {selectedDevice?.deviceAuthStatus === DeviceStatusEnum.AUTHENTICATED
              ? constants.ADD_AND_CLAIM_DEVICE_BUTTON_TEXT
              : constants.DEVICES_UPDATE_DEVICE_BUTTON_TEXT}
          </PrimaryButton>
          {selectedDevice?.deviceAuthStatus !==
            DeviceStatusEnum.AUTHENTICATED && (
            <PrimaryButton
              className="adddevice-btn"
              fontSize="0.875rem"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_48')}
              height="44px"
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_48')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_48')}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_48')}
              type="button"
              onClick={() => handleRemoveClick(selectedDevice.deviceId)}
            >
              {constants.DEVICES_REMOVE_DEVICE_BUTTON}
            </PrimaryButton>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AddAndUpdateDeviceControl;
