import React, { createContext, useState } from 'react';

export const DetailsContext = createContext(null);

const DetailsContextProvider = ({ children }) => {
  return (
    <DetailsContext.Provider>
      {children}
    </DetailsContext.Provider>
  );
};

export default DetailsContextProvider;