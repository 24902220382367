import { useState, useEffect } from "react";
import FormSteps from "./FormSteps";
import DetailsContextProvider from "./DetailsContextProvider";
import DataContextProvider from "./DataContextProvider";
import { SiteModal, PrimaryButton } from "../../../components/common/";
import { PlusIcon } from "../../../assets/images";
import { constants } from "../../../helpers";
import DiscoveredDevices from "./DiscoveredDevices";
import { useDispatch } from "react-redux";
import { resetData } from "../../../store/reducers/NVRDeviceReducer";
import { setDoNotRefreshDeviceList } from "../../../store/reducers/AccountReducer";
import DiscoveredDevicesMobile from "./DiscoveredDevicesMobile";
import { RxPlus } from "react-icons/rx";

const AddDevice = (props) => {
	const {mobileView,handleMobilePopup} = props;
    const reloadData = () => props.reloadData();
	const dispatch = useDispatch();
	const setShowClaimDeviceModal = () => props.setShowClaimDeviceModal();
	const [showAddOrgModal, setShowAddOrgModal] = useState(false);
	const [showDiscoveredDevicesModel, setShowDiscoveredDevicesModel] = useState(false);
	const [showDiscoveredDevicesMobileModel, setShowDiscoveredDevicesMobileModel] = useState(false);

	const handleShow = () => setShowAddOrgModal(true);
	localStorage.setItem("modalOpen", 0);
 	const handleClose = () => {
		setShowAddOrgModal(false);
		setShowClaimDeviceModal(false);
		handleMobilePopup(false);
	};

	useEffect(() => {
		showHideDiscovedDevicesPopup(props.isDirectToScannedPopup);
	}, [props.isDirectToScannedPopup]);
    
	useEffect(() => {
		showHideDiscovedDevicesMobilePopup(props.isDirectToScannedMobilePopup);
	}, [props.isDirectToScannedMobilePopup]);

	useEffect(() => {
		setShowAddOrgModal(props.showClaimDeviceModal);
	}, [props.showClaimDeviceModal]);

	const DeviceModal = () => {
 		return (
			<SiteModal showModal={showAddOrgModal} 
			hideModal={() => handleClose()}
			classes="add-devices-mediaQuery"
 			 modalTitle={constants.DEVICES_ADD_DEVICE_TITLE}>
			<FormSteps reloadData={() => reloadData()} 
			hideModal={() => handleClose()}
			 onDiscoverDevices={() => showHideDiscovedDevicesPopup(true)} />
			</SiteModal>
		);
	};
    //mobile device
	const MobileDeviceModal = () => {
 		return (
			<SiteModal showModal={mobileView} 
			hideModal={() => handleClose()}
			classes="add-devices-mediaQuery"
 			modalTitle={constants.DEVICES_ADD_DEVICE_TITLE}>
			<FormSteps reloadData={() => reloadData()} 
			hideModal={() => handleClose()}
			onDiscoverDevices={() => showHideDiscovedDevicesMobilePopup(true)} />
			</SiteModal>
		);
	};
	const showHideDiscovedDevicesPopup = (value) => {
		setShowDiscoveredDevicesModel(value);
		dispatch(setDoNotRefreshDeviceList(value));
	}
	//for mobile responsive device
	const showHideDiscovedDevicesMobilePopup = (value) => {
		setShowDiscoveredDevicesMobileModel(value);
		dispatch(setDoNotRefreshDeviceList(value));
	}

	const handleCloseDiscoveredDeviceModel = () => {
		showHideDiscovedDevicesPopup(false);
		dispatch(resetData());
		reloadData();
	};

	const DiscoveredDevicesModal = () => {
	    return (
			<SiteModal modalHeader={false} modalTitle={constants.DISCOVERED_DEVICES_MODEL_HEADER} showModal={showDiscoveredDevicesModel} hideModal={handleCloseDiscoveredDeviceModel} classes="discovered-devices modalBackButton" size="lg" backdropClassName="noBackDrop">
				<DiscoveredDevices hideModalHandler={handleCloseDiscoveredDeviceModel} />
			</SiteModal>
		);
	};
    // for mobile responsive
	const DiscoveredDevicesMobileModal = () => {
 		return (
			<SiteModal modalHeader={false} modalTitle={constants.DISCOVERED_DEVICES_MODEL_HEADER} showModal={showDiscoveredDevicesMobileModel} hideModal={handleCloseDiscoveredDeviceModel} classes="discovered-devices modalBackButton" size="lg" backdropClassName="noBackDrop">
				<DiscoveredDevicesMobile hideModalHandler={handleCloseDiscoveredDeviceModel} />
			</SiteModal>
		);
	};

	return (
		<DetailsContextProvider>
			<DataContextProvider>
				{/* <PrimaryButton className="addDeviceBtnStyle d-none d-md-block" type="button" width="auto" height="44px" fontSize="14px" outline="none" onClick={handleShow}>
				<RxPlus
                    className="plus-icon-btn"
                    strokeWidth={"1.5px"}
                    size={13.33}
                  />
					{constants.DEVICES_ADD_DEVICE_TITLE}
				</PrimaryButton> */}
				<DeviceModal />
				<MobileDeviceModal />
				{/* <DiscoveredDevicesModal /> */}
				<SiteModal modalHeader={false} modalTitle={constants.DISCOVERED_DEVICES_MODEL_HEADER} showModal={showDiscoveredDevicesModel} hideModal={handleCloseDiscoveredDeviceModel} classes="discovered-devices modalBackButton" size="lg" backdropClassName="noBackDrop">
					<DiscoveredDevices hideModalHandler={handleCloseDiscoveredDeviceModel} />
				</SiteModal>
				<DiscoveredDevicesMobileModal />
			</DataContextProvider>
		</DetailsContextProvider>
	);
};

export default AddDevice;
