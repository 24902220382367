import {
  People,
  CarFront,
  Sunglasses,
  Dropbox,
  TrainFront,
} from 'react-bootstrap-icons';

export const eventIcons = (obj) => {
  switch (obj) {
    case 'person':
    case 'top':
    case 'hat':
    case 'bottom':
      return (
        <div className="event-image">
          <People height="16" width="16" />
        </div>
      );
    case 'car':
    case 'vehicle':
    case 'truck':
    case 'motorcycle':
    case 'bicycle':
      return (
        <div className="event-image">
          <CarFront height="16" width="16" />
        </div>
      );
    case 'glasses':
      return (
        <div className="event-image">
          <Sunglasses height="16" width="16" />
        </div>
      );
    case 'train':
      return (
        <div className="event-image">
          <TrainFront height="16" width="16" />
        </div>
      );
    default:
      return (
        <div className="event-image">
          <Dropbox height="16" width="16" />
        </div>
      );
  }
};
