import { useState, useEffect, useCallback, useMemo } from 'react';
import FormSteps from './FormSteps';
import DetailsContextProvider from './DetailsContextProvider';
import DataContextProvider from './DataContextProvider';
import { SiteModal, PrimaryButton } from '../../../components/common/';
import { constants } from '../../../helpers';
import { usePoliciesStore } from '../../../store/policiesStore';
import useLicensesStore from '../../../store/LicensesStore';
import { RxPlus } from 'react-icons/rx';
import { useLocation } from 'react-router-dom';
const AddLicense = (props) => {
  const { isOpenMobilePopup, handleOpenMobilePopup, reloadData } = props;
  const setShowClaimLicenseModal = useCallback(
    () => props.setShowClaimLicenseModal,
    [props]
  );
  const [showAddOrgModal, setShowAddOrgModal] = useState(false);
  const [buttonClickValue, setButtonClickValue] = useState(null);
  const [showDiscoveredLicensesModel, setShowDiscoveredLicensesModel] =
    useState(false);
  const [licensesAdded, setLicensesAdded] = useState(false);
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const orgId = query.get('orgId');
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies
  );
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );
  const { setLicenses } = useLicensesStore();

  const handleButtonClick = useCallback((value) => {
    setButtonClickValue(value);
  }, []);

  const handleShow = useCallback(
    (flow) => {
      setShowAddOrgModal(true);
      handleButtonClick(flow);
    },
    [handleButtonClick]
  );

  const handleClose = (event) => {
    if (licensesAdded === true) {
      reloadData && reloadData();
      setLicensesAdded(false);
    }
    setShowAddOrgModal(false);
    setShowClaimLicenseModal && setShowClaimLicenseModal(false);
    handleOpenMobilePopup(false);
  };

  // const handleKeyDown = useCallback(async () => {
  //   await setLicenses(`/user/orgs/${orgId}/licenses`);
  // }, [orgId, setLicenses]);

  // useEffect(() => {
  //   if (!showAddOrgModal) {
  //     handleKeyDown();
  //   }
  // }, [showAddOrgModal, handleKeyDown]);

  useEffect(() => {
    if (props.showClaimLicenseModal) {
      setShowAddOrgModal(true);
    }
  }, [props.showClaimLicenseModal]);

  const LicenseModal = useMemo(
    () => (
      <SiteModal
        modalHeader={true}
        showModal={isOpenMobilePopup || showAddOrgModal}
        hideModal={() => {
          handleClose();
        }}
      >
        <FormSteps
          buttonClickValue={buttonClickValue}
          hideModal={handleClose}
          onDiscoverLicenses={() => setShowDiscoveredLicensesModel(true)}
          setLicensesAdded={() => {
            setLicensesAdded(true);
          }}
        />
      </SiteModal>
    ),
    [isOpenMobilePopup, showAddOrgModal, buttonClickValue, handleClose]
  );

  return (
    <DetailsContextProvider>
      <DataContextProvider>
        {getCustomerOrgPolicies()?.add_license_customer_org && (
          <PrimaryButton
            className="addLicenseBtnStyle d-md-block d-none btn-primary"
            type="button"
            width="auto"
            height="44px"
            fontSize="14px"
            onClick={() => handleShow('Flow1')}
          >
            <RxPlus
              className="plus-icon-btn"
              strokeWidth={'1.5px'}
              size={13.33}
            />
            {constants.LICENSE_ADD_LICENSE_TITLE}
          </PrimaryButton>
        )}
        {LicenseModal}
      </DataContextProvider>
    </DetailsContextProvider>
  );
};

export default AddLicense;
