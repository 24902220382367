import {
  DevicesFilter,
  DropdownList,
  Header,
  SiteModal,
  SiteSpinner,
} from '../../components/common';
import { Col, Container, Row } from 'react-bootstrap';
import {
  useEffect,
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
} from 'react';
import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { useDispatch, useSelector } from 'react-redux';
import { getCDNInfo } from '../../store/reducers/StreamingReducer';
import useEventsStore from '../../store/EventsStore';
import { AppDefaults, constants, Utils } from '../../helpers';
import axios from 'axios';
import moment from 'moment';
import StickyBox from 'react-sticky-box';
import InfiniteScroll from 'react-infinite-scroller';
import SearchEventsWrapper from './SearchEventsWrapper';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import { useNavigate } from 'react-router-dom';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useSearchParams } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import { BsFillMicFill } from 'react-icons/bs';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import './allevents.scss';
import Tags from './Tags';
import { TagStore } from '../../store/TagsStore';
import EventTimelineControls from './eventPreview/EventTimelineControls';
import './eventPreview/eventModal.scss';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
// import { setAllDevicesData } from '../../store/reducers/AccountReducer';
import { isObject } from 'lodash';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { setAllDevicesData } from '../../store/AccountStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import {
  fetchCustomerOrgDevices,
  getCustomerOrgDevices,
} from '../../store/CustomerOrgDevicesStoreIDB';
import {
  setSelectedDeviceFromFillter,
} from "../../store/CustomerOrgDevicesStoreIDB";

const AllEvents = ({}) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  let [searchParams] = useSearchParams();
  const searchParam = searchParams.get('searchtext');
  const itemsPerPage = 50;
  const [hasMoreItems, sethasMoreItems] = useState(true);
  const [showEventPreviewModal, setShowEventPreviewModal] = useState(false);
  const [searchtext, setSearchtext] = useState(searchParam);
  const [records, setrecords] = useState(itemsPerPage);
  const [eventsData, setEventsData] = useState([]);
  const cdnValue = useSelector(getCDNInfo);
  const getTagsData = TagStore((state) => state.getTagsData);
  const setTagsData = TagStore((state) => state.setTagsData);
  const getTagsList = TagStore((state) => state.getTagsList);
  const setTagsList = TagStore((state) => state.setTagsList);
  const setSelectedTags = TagStore((state) => state.setSelectedTags);
  const {
    getEvents,
    setEvents,
    removeEvents,
    setEventTotal,
    getEventTotal,
    setEventCategory,
    setSelectedEventStore,
    getSelectedEvent,
    setEventFromSearch,
    setModalEventContent,
    getModalEventContent,
    getModalIndexContent,
    setEventCVRMode,
    getEventCVRMode,
    getEventsLoading,
  } = useEventsStore();
  const [filterDate, setFilterDate] = useState([]);
  const [showCustomRange, setShowCustomRange] = useState(false);
  const [tagRemove, setTagRemove] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const calendarRef = useRef();
  const [timezone, setTimezone] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [startTimeEvent, setStartTimeEvent] = useState(null);
  const [cdnValueInfo, setCDNValueInfo] = useState(null);
  const [nextPreviousIcon, setNextPreviousIcon] = useState(null);
  const [selectedTime, setSelectedTime] = useState(0);
  const [devices, setDevices] = useState([]);
  const [updatedDevice, setUpdatedDevice] = useState([]);
  const [deviceFilterList, setDeviceFilterList] = useState([]);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [allCamera, setAllCameraLabel] = useState(
    constants.ANALYTICS_DASHBOARD_ALL_CAMERAS_LINK
  );

  // const { fetchCustomerOrgDevices, getCustomerOrgDevices } =
  //   useCustomerOrgDevices();
  const [searchLabelText, setSearchLabelText] = useState(
    constants.SEARCH_DASHBOARD_ALL_RESULTS
  );
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  const [timeOptions, setTimeOptions] = useState(Utils.getTimeFilters());
  const [eventCount, setEventCounts] = useState(0);
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  // let custOrg = getCustomerOrgData();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  // const { setSelectedDeviceFromFillter, getSelectedDeviceFromFillter } =
  //   useCustomerOrgDevices();

  const {
    getSelectedLocation,
    getCustomerOrgLocationsData,
    getCustomerOrgLocations,
  } = useCustomerOrgLocations();
  const selectedLocation = getSelectedLocation(loggedInUserData?.accountId);
  // const custOrgData = custOrg[0];
  const [custOrgData, setCustOrgData] = useState();

  const navigate = useNavigate();
  const deviceTimezone = useEventsStore((state) => state.deviceTimezone);
  const modalContent = getModalEventContent();
  const indexContent = getModalIndexContent();

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustOrgData(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    setCDNValueInfo(cdnValue);
  }, [cdnValue]);

  useEffect(() => {
    setEventCounts(getEventTotal());
  }, [getEventTotal()]);

  const fetchDevices = useCallback(async () => {
    try {
      // Get all locations for customer's organization
      await getCustomerOrgLocations(
        `partner/orgs/${custOrgData?.orgId}/locations`
      );

      // Fetch the list of devices associated with
      // the user
      let fetchResult = await fetchCustomerOrgDevices(
        `device/orgs/${custOrgData?.orgId}/devices`
      );

      const orgLocations = getCustomerOrgLocationsData();
      const locationIds = new Set();
      let deviceArea, currentArea, cameraDevice;
      if (fetchResult.status === 'success') {
        const cameraDevices = await getCustomerOrgDevices();
        const locationAreas = [];

        // Retrieve the location name for each device
        for (let i = 0; i < cameraDevices.length; i++) {
          cameraDevice = cameraDevices[i];
          locationIds.add(cameraDevice?.locationId);

          currentArea = orgLocations.find(
            (orgLocation) => orgLocation.locationId === cameraDevice?.locationId
          );

          if (
            locationAreas.findIndex(
              (locationArea) =>
                locationArea.locationId === cameraDevice?.locationId
            ) === -1
          ) {
            deviceArea = {
              deviceId: cameraDevice?.deviceId,
              areas: [...currentArea?.areas],
            };

            locationAreas.push({
              locationId: cameraDevice?.locationId,
              areas: [deviceArea],
            });
          }

          cameraDevices[i].locationName = currentArea?.locationName;

          cameraDevices[i].areas = [
            ...locationAreas.find(
              (locationArea) =>
                locationArea.locationId === cameraDevices[i].locationId
            ).areas[0].areas,
          ];

          cameraDevices[i].style = null;
        }
        await new Promise((resolve) => {
          setUpdatedDevice(cameraDevices?.length > 0 ? [...cameraDevices] : []);
          setAllDevicesData(
            cameraDevices?.length > 0 ? [...cameraDevices] : []
          )
          resolve();
        });

        let filteredDevices;

        if (selectedLocation && isObject(selectedLocation)) {
          if (selectedLocation?.locationId !== AppDefaults.ALL_LOCATIONS_ID) {
            filteredDevices = cameraDevices.filter(
              (device) => device?.locationId === selectedLocation?.locationId
            );
          } else {
            filteredDevices =
              cameraDevices?.length > 0 ? [...cameraDevices] : [];
          }
        }
        await new Promise((resolve) => {
          setDevices(
            filteredDevices != undefined && filteredDevices?.length > 0
              ? [...filteredDevices]
              : [...cameraDevices]
          );
        });
      } else {
        setShowToast(true);
        setUserMsg(fetchResult.msg);
      }
    } catch (err) {
      setShowToast(true);
      setUserMsg(err.msg);
    }
  }, []);

  useLayoutEffect(() => {
    if (custOrgData?.orgId) {
      fetchDevices();
    }
  }, [custOrgData?.orgId]);

  const filterContent = (
    <>
      <DevicesFilter
        devices={devices}
        filterDescription={
          constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_DESCRIPTION
        }
        applyButtonLabel={
          constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_APPLY_FILTER_LABEL
        }
        disableBackgroundColor={true}
        clearFilterPlacement="bottom"
        callback={(deviceData, status) => {
          updateList(deviceData, status);
          setShow(false);
        }}
      />
    </>
  );

  const updateList = async (deviceDataList, status) => {
    if (deviceDataList?.length > 0) {
      if (status) {
        setDeviceFilterList(devices);
        setDevices(devices);
        await setSelectedDeviceFromFillter([]);
      } else {
        if (deviceDataList?.length !== 0) {
          let deviceData = [];
          updatedDevice?.map((deviceItem, index) => {
            const deviIdExist = deviceDataList?.find(
              (item) => item === deviceItem?.deviceId
            );
            if (deviIdExist) {
              deviceData.push(deviceItem);
            }
          });
          setDeviceFilterList(deviceData);
          await setSelectedDeviceFromFillter(deviceDataList);
        }
      }
    } else {
      let deviceData = [];
      updatedDevice?.map((deviceItem, index) => {
        deviceData.push(deviceItem?.deviceId);
      });
      setDeviceFilterList(devices);
      await setSelectedDeviceFromFillter(deviceData);
    }
  };

  const getDeviceId = (deviceList) => {
    let deviceID = '';
    const orgDevice =
      deviceList && deviceList?.length === 0
        ? custOrgData?.devices
        : deviceList;
    const location = selectedLocation;
    if (location) {
      const devicesList =
        location?.locationId === AppDefaults.ALL_LOCATIONS_ID
          ? orgDevice
          : orgDevice?.filter(
              (device) => device.locationId === location?.locationId
            );
      const fillterList =
        deviceFilterList?.length > 0 ? deviceFilterList : devicesList;
      fillterList &&
        fillterList.length > 0 &&
        fillterList?.forEach((device, i) => {
          deviceID += '&deviceIds=' + device.deviceId;
        });
      return deviceID;
    } else {
      const fillterList =
        deviceFilterList?.length > 0 ? deviceFilterList : orgDevice;
      fillterList &&
        fillterList.length > 0 &&
        fillterList?.forEach((device, i) => {
          deviceID += '&deviceIds=' + device.deviceId;
        });
      return deviceID;
    }
  };

  useEffect(() => {
    // if(deviceFilterList?.length > 0){
    //   setDeviceFilterList([])
    // }
    allEventCall();
  }, [selectedLocation, devices, deviceFilterList]);

  const allEventCall = () => {
    removeEvents([]);
    const endTime = Utils.getUnixDate(
      moment(new Date()).subtract({ hours: 0, minutes: 0 })
    );
    const isSearch = searchtext !== null && searchtext !== '';
    setSelectedEventStore(null);
    setFilterDate(new Date());
    setTagsList([]);
    setSelectedTags({});
    if (endTime && !isSearch) {
      setEventsData(getEvents());
      setStoreEvents(selectedTime);
      setSearchLabelText(constants.SEARCH_DASHBOARD_ALL_RESULTS);
    } else {
      setSearchLabelText(constants.SEARCH_DASHBOARD_SEARCH_RESULTS);
    }
    if (searchtext !== null && searchtext !== '') {
      searchByParam(searchtext, selectedTime);
    }
  };

  const setStoreEvents = (startTime) => {
    if (!checkDeviceIdStatus()) {
      setEventsData([]);
      return;
    }
    setTagsData({});
    setSearchtext('');
    setEvents(
      `timeline/orgs/${custOrgData?.orgId}/events/all?${
        startTime !== 0 ? `startTime=${startTime}&` : ''
      }endTime=${new Date().getTime()}&size=50${getDeviceId(devices)}`
    );
  };

  useEffect(() => {
    const isSearch = searchtext !== null && searchtext !== '';
    if (!isSearch && getEvents() && getEvents().length > 0) {
      setIsLoadingEvents(true);
      setEventsData(getEvents());
      setIsLoadingEvents(false);
    }
  }, [getEvents()]);

  useEffect(() => {
    const deviceList = getCurrentLocationDevices();
    if (deviceList?.length > 0) {
      setDevices(deviceList);
    } else {
      setDevices([]);
    }
  }, [selectedLocation]);

  const getCurrentLocationDevices = () => {
    const orgDevice = updatedDevice;
    const location = selectedLocation;
    if (isObject(location)) {
      const devicesList =
        location?.locationId === AppDefaults.ALL_LOCATIONS_ID
          ? orgDevice
          : orgDevice?.filter(
              (device) => device.locationId === location?.locationId
            );
      return devicesList;
    } else {
      return orgDevice;
    }
  };

  const checkDeviceIdStatus = () => {
    let isDeviceExist = false;
    if (getDeviceId(devices) !== '') {
      isDeviceExist = true;
    }
    return isDeviceExist;
  };

  const searchByParam = async (searchtext, startTime) => {
    if (!checkDeviceIdStatus()) {
      setEventsData([]);
      return;
    }
    setIsLoadingEvents(true);
    setTagsData({});
    let response = await axios.get(
      `timeline/orgs/${custOrgData?.orgId}/events/search?${
        startTime !== 0 ? `startTime=${startTime}&` : ''
      }endTime=${new Date().getTime()}${getDeviceId(
        devices
      )}&size=15&searchText=${searchtext}&tags=`,
      Utils.requestHeader()
    );
    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
      setEventsData(responseData?.data?.events);
      setEventTotal(responseData?.data?.total);
      setIsLoadingEvents(false);
      const tagList = responseData?.data?.tagDetails;
      const addTag = tagList?.find((device) => device.category === 'addtag');
      if (!addTag) {
        tagList?.push({ category: 'addtag', text: 'Add Tags' });
      }
      setTagsList(tagList);
    }
  };

  useEffect(() => {
    setTimezone(deviceTimezone);
  }, [deviceTimezone]);

  const fetchRecords = async (endTime) => {
    if (!checkDeviceIdStatus()) {
      setEventsData([]);
      return;
    }
    let response = await axios.get(
      `timeline/orgs/${custOrgData?.orgId}/events/all?${
        selectedTime !== 0 ? `startTime=${selectedTime}&` : ''
      }endTime=${endTime}&size=50${getDeviceId(devices)}`,
      Utils.requestHeader()
    );
    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
      setEventsData([...eventsData, ...response?.data?.data?.events]);
      setEventTotal(response?.data?.data?.total);
    }
  };

  useEffect(() => {
    if (transcript != '') {
      setSearchtext(transcript);
    }
  }, [transcript]);

  const fetchRecordsViaCalendar = async (startTime, endTime) => {
    const isSearch = searchtext !== null && searchtext !== '';
    if (!isSearch) {
      setIsLoadingEvents(true);
      setShowToast(false);
      let response = await axios.get(
        `timeline/orgs/${custOrgData?.orgId}/events/all?${
          startTime !== 0 ? `startTime=${startTime}&` : ''
        }endTime=${endTime}&size=50${getDeviceId(devices)}`,
        Utils.requestHeader()
      );
      if (response?.data?.data && response?.data?.data?.events?.length) {
        setEventsData(response?.data?.data?.events);
        setEventTotal(response?.data?.data?.total);
      } else {
        setUserMsg('No events found during selected date');
        setShowToast(true);
      }
      setIsLoadingEvents(false);
    } else {
      setIsLoadingEvents(true);
      setTagsData({});
      let response = await axios.get(
        `timeline/orgs/${custOrgData?.orgId}/events/search?${
          startTime !== 0 ? `startTime=${startTime}&` : ''
        }endTime=${endTime}${getDeviceId(
          devices
        )}&size=15&searchText=${searchtext}&tags=`,
        Utils.requestHeader()
      );
      const responseData = response?.data;
      if (responseData?.meta?.code === 200) {
        setEventsData(responseData?.data?.events);
        setEventTotal(responseData?.data?.total);
        setIsLoadingEvents(false);
        const tagList = responseData?.data?.tagDetails;
        const addTag = tagList?.find((device) => device.category === 'addtag');
        if (!addTag) {
          tagList?.push({ category: 'addtag', text: 'Add Tags' });
        }
        setTagsList(tagList);
      }
    }
  };

  const addTimeStartEndRange = (startDateCal, endDateCal) => {
    let startDate = moment(new Date(startDateCal)).format('MMM DD');
    let endDate = endDateCal && moment(new Date(endDateCal)).format('MMM DD');
    const index = timeOptions.findIndex((e) => e.id === 8);
    if (index && index === 6) {
      if (endDateCal) {
        timeOptions[index] = { ...{ id: 8, value: startDate + '-' + endDate } };
      } else {
        timeOptions[index] = { ...{ id: 8, value: startDate } };
      }
    }
    setTimeOptions(timeOptions);
  };

  useEffect(() => {
    let startTime = 0;
    let endTime = 0;

    if (filterDate && filterDate[1] && filterDate[0]) {
      startTime = new Date(
        moment.tz(new Date(filterDate[0]), timezone)
      ).getTime();
      endTime = new Date(
        moment.tz(new Date(filterDate[1]), timezone)
      ).getTime();
      addTimeStartEndRange(filterDate[0], filterDate[1]);
    } else if (filterDate && filterDate[0]) {
      startTime = new Date(
        moment
          .tz(new Date(filterDate[0]), timezone)
          .format('MMM DD, YYYY 00:00:00')
      ).getTime();
      let eTime = new Date(
        moment
          .tz(new Date(filterDate[0]), timezone)
          .format('MMM DD, YYYY 23:59:59')
      ).getTime();
      if (eTime < new Date().getTime()) {
        endTime = eTime;
        addTimeStartEndRange(filterDate[0], false);
      } else {
        endTime = new Date().getTime();
        addTimeStartEndRange(new Date(), false);
      }
    }
    if (startTime && endTime && startTime !== 0 && endTime !== 0) {
      setShowCustomRange(false);
      setSelectedTime(startTime);
      fetchRecordsViaCalendar(startTime, endTime);
    }
  }, [filterDate]);

  const massagedData = (data) => {
    let finalArray = [];
    data.map((event) => {
      const time = getActualDate(event?.eventTimestamp);
      const index = finalArray.findIndex((e) => e.eventTimestamp === time);
      if (index > -1) {
        finalArray[index]?.events.push({ ...event });
      } else {
        let eventObject = {
          eventTimestamp: '',
          events: [],
        };
        eventObject.eventTimestamp = time;
        eventObject.events.push(event);
        finalArray.push(eventObject);
      }
    });
    return finalArray;
  };

  const formattedDate = (time) => {
    if (time === moment(new Date()).format('MMM DD, YYYY')) {
      return 'Today';
    } else if (
      time === moment(new Date()).subtract({ days: 1 }).format('MMM DD, YYYY')
    ) {
      return 'Yesterday';
    } else {
      return time;
    }
  };

  const showItems = (posts) => {
    const updatedData = massagedData(posts);
    if (updatedData.length) {
      return (
        <div className="">
          {updatedData?.map((event, i) => (
            <div key={i}>
              <StickyBox offsetTop={0}>
                <div className="sticky-header">
                  <div className="header">
                    {formattedDate(event?.eventTimestamp)}
                  </div>
                  <div className="fixed-icons-wrapper">
                    <div className="filterDate-image">
                      <DatePicker
                        ref={calendarRef}
                        range
                        minDate={
                          new Date(moment(new Date()).subtract({ days: 30 }))
                        }
                        maxDate={new Date()}
                        className="rmdp-mobile"
                        value={filterDate}
                        onChange={setFilterDate}
                        // onClose={calendarRef?.current?.closeCalendar()}
                        mobileButtons={[
                          {
                            type: 'button',
                            className: 'rmdp-button rmdp-action-button',
                            onClick: () => setFilterDate({}),
                          },
                        ]}
                        render={<CustomRangePicker openCalendar value />}
                        format="MM/DD/YYYY HH:mm A"
                        plugins={[<TimePicker position="bottom" hideSeconds />]}
                      />
                    </div>
                  </div>
                </div>
              </StickyBox>
              <div className="events-all-category-wrapper">
                <SearchEventsWrapper
                  data={event}
                  cdnValue={cdnValueInfo}
                  timezone={timezone}
                  selectedEvent={getSelectedEvent()}
                  handleNextPreviousIcon={nextPreviousIcon}
                  handleOnClick={(event, i) => {
                    setSelectedEventStore(event?.timestamp);
                    setEventFromSearch(true);
                    setShowEventPreviewModal(true);
                    setModalEventContent(event, i);
                    //navigate(`/cameras/dashboard.html?id=${device}`);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  const CustomRangePicker = ({ openCalendar, value }) => {
    if (value === new Date()) {
      return <div onClick={openCalendar}>Custom Range Picker</div>;
    } else {
      return <div onClick={openCalendar}></div>;
    }
  };

  useEffect(() => {
    if (showCustomRange) {
      calendarRef.current.openCalendar();
    }
  }, [showCustomRange]);

  const updateTags = (tagList) => {
    const data = [...getTagsList(), ...tagList];
    const valueTags = [];
    const fillter = data.filter(function (value, index, array) {
      let tagExist = valueTags.includes(value.text);
      if (!tagExist) {
        valueTags.push(value.text);
        if (value.category !== 'addtag') {
          return array.indexOf(value) === index;
        }
      }
    });
    const addTag = fillter?.find((device) => device.category === 'addtag');
    if (!addTag) {
      fillter.push({ category: 'addtag', text: 'Add Tags' });
    }
    if (fillter?.length > 0) {
      setTagsList(fillter);
    }
  };

  const loadTags = (tagList) => {
    if (getTagsList()?.length > 0 && tagList?.length > 0) {
      updateTags(tagList);
    } else {
      const addTag = tagList?.find((device) => device.category === 'addtag');
      if (!addTag) {
        tagList?.push({ category: 'addtag', text: 'Add Tags' });
      }
      setTagsList(tagList);
    }
  };

  const loadMore = async () => {
    if (
      eventsData[eventsData.length - 1]?.eventTimestamp <
      Utils.getUnixDate(moment(new Date()).subtract({ days: 30 })) * 1000
    ) {
      sethasMoreItems(false);
    } else {
      setTimeout(async () => {
        if (eventsData[eventsData.length - 1]?.eventTimestamp) {
          const requestData = Object.keys(getTagsData()).length !== 0;
          let startTime = selectedTime !== 0 ? selectedTime : false;
          if (requestData) {
            let response = await axios.post(
              `timeline/orgs/${custOrgData?.orgId}/events/searchByTag`,
              startTime
                ? {
                    ...getTagsData(),
                    startTime: startTime,
                    endTime: eventsData[eventsData.length - 1]?.eventTimestamp,
                  }
                : {
                    ...getTagsData(),
                    endTime: eventsData[eventsData.length - 1]?.eventTimestamp,
                  },
              Utils.requestHeader()
            );
            const responseData = response?.data;
            if (responseData?.meta?.code === 200) {
              setEventsData([...eventsData, ...responseData?.data?.events]);
              setEventTotal(responseData?.data?.total);
              const tagList = responseData?.data?.tagDetails;
              loadTags(tagList);
            }
          } else if (searchtext !== null && searchtext !== '') {
            let response = await axios.get(
              `timeline/orgs/${custOrgData?.orgId}/events/search?${
                selectedTime !== 0 ? `startTime=${selectedTime}&` : ''
              }endTime=${
                eventsData[eventsData.length - 1]?.eventTimestamp
              }${getDeviceId(devices)}&size=15&searchText=${searchtext}&tags=`,
              Utils.requestHeader()
            );
            const responseData = response?.data;
            if (responseData?.meta?.code === 200) {
              setEventsData([...eventsData, ...responseData?.data?.events]);
              setEventTotal(responseData?.data?.total);
              const tagList = responseData?.data?.tagDetails;
              loadTags(tagList);
            }
          } else {
            fetchRecords(eventsData[eventsData.length - 1]?.eventTimestamp);
          }
        }
      }, 100);
    }
  };

  const getActualDate = (date) => {
    const time = Utils.getDate(date / 1000);
    const actualTime = moment.tz(time, timezone).format('MMM DD, YYYY');
    return actualTime;
  };

  const removeUrlParameter = (paramKey) => {
    const url = window.location.href;
    var r = new URL(url);
    r.searchParams.delete(paramKey);
    const newUrl = r.href;
    window.history.pushState({ path: newUrl }, '', newUrl);
    setSearchtext('');
    const endTime = Utils.getUnixDate(
      moment(new Date()).subtract({ hours: 0, minutes: 0 })
    );
    if (endTime) {
      setStoreEvents(selectedTime);
    }
  };
  const onKeyDownChange = (e) => {
    if (e != undefined) {
      if (e.key === 'Enter') {
        if (
          searchtext !== null &&
          searchtext !== '' &&
          searchtext?.replace(/\s/g, '').length
        ) {
          searchByParam(searchtext, selectedTime);
          setSearchLabelText(constants.SEARCH_DASHBOARD_SEARCH_RESULTS);
        } else {
          setSearchLabelText(constants.SEARCH_DASHBOARD_ALL_RESULTS);
          removeUrlParameter('searchtext');
          setStoreEvents(selectedTime);
          setTagsList([]);
          setSelectedTags({});
        }
      }
    }
  };

  const onHandleChange = (e) => {
    if (e != undefined) {
      if (e?.target?.value === '') {
        resetTranscript();
      }
      setSearchtext(e?.target?.value);
    }
  };

  const handleListing = (e) => {
    handleReset(e);
    SpeechRecognition.startListening({
      interimResults: true,
    });
  };

  const handleReset = (e) => {
    e.preventDefault();
    e.stopPropagation();
    SpeechRecognition.stopListening();
    resetTranscript();
  };

  const onNextPreviousClick = (type) => {
    setNextPreviousIcon(type);
  };

  const onCameraDetailsClick = (content, time, cvrMode) => {
    setSelectedEventStore(Utils.getUnixDate(time) * 1000);
    setEventFromSearch(true);
    setEventCVRMode(cvrMode);
    navigate(`/cameras/dashboard.html`, {
      state: {
        id: content?.device[0]?.deviceId,
        cdnInfo: cdnValueInfo,
      },
    });
    // navigate(`/cameras/dashboard.html?id=${content?.device[0]?.deviceId}`);
  };

  useEffect(() => {
    setNextPreviousIcon(null);
  }, [nextPreviousIcon]);
  /*=== Generate selection options*/
  const generateOptions = () => {
    if (Array.isArray(timeOptions) && timeOptions.length > 0) {
      return timeOptions.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.value}
          </option>
        );
      });
    }
    return null;
  };

  const onHandleChangeTime = (e) => {
    if (e != undefined) {
      fillterByTime(e?.target?.value);
    }
  };

  const fillterByTime = async (value) => {
    let startTime = 0;
    if (
      parseInt(value) === 1 ||
      parseInt(value) === 7 ||
      parseInt(value) === 15 ||
      parseInt(value) === 30
    ) {
      setTimeOptions(Utils.getTimeFilters());
      setFilterDate(new Date());
      startTime = Utils.getHourPast(parseInt(value));
    } else if (parseInt(value) === 2) {
      setFilterDate(new Date());
      setTimeOptions(Utils.getTimeFilters());
      startTime = Utils.getHourPastByValue(1);
    } else if (parseInt(value) === 8) {
      setShowCustomRange(true);
      return;
    } else {
      setTimeOptions(Utils.getTimeFilters());
      setFilterDate(new Date());
      startTime = 0;
    }
    setSelectedTime(startTime);
    if (startTime !== 0 && startTime < new Date().getTime()) {
      const requestData = Object.keys(getTagsData()).length !== 0;
      if (requestData) {
        fillterByTagApiCall(startTime);
      } else {
        searchtext !== null && searchtext !== ''
          ? searchByParam(searchtext, startTime)
          : setStoreEvents(startTime);
      }
    } else {
      setSelectedTags({});
      setTagsList([]);
      setStoreEvents(startTime);
    }
  };

  const updateTagList = (tagList) => {
    setTagsList(tagList);
    setTimeout(() => {
      setTagRemove(true);
    }, 1000);
  };

  const fillterByTagApiCall = async (startTimeStamp) => {
    const requestData = Object.keys(getTagsData()).length !== 0;
    let startTime = startTimeStamp !== 0 ? startTimeStamp : false;
    if (requestData) {
      let response = await axios.post(
        `timeline/orgs/${custOrgData?.orgId}/events/searchByTag`,
        startTime
          ? {
              ...getTagsData(),
              startTime: startTime,
              endTime: new Date().getTime(),
            }
          : { ...getTagsData(), endTime: new Date().getTime() },
        Utils.requestHeader()
      );
      const responseData = response?.data;
      if (responseData?.meta?.code === 200) {
        setEventsData(responseData?.data?.events);
        setEventTotal(responseData?.data?.total);
        const tagList = responseData?.data?.tagDetails;
        const addTag = tagList?.find((device) => device.category === 'addtag');
        if (!addTag) {
          tagList?.push({ category: 'addtag', text: 'Add Tags' });
        }
        setTagsList(tagList);
      }
    }
  };

  return (
    <div className="App">
      <Header />
      <Container>
        <Row className="justify-content-md-center mt-5">
          <Col lg="12">
            <div className="search-dashboard-allevents">
              <div className="serach-box">
                <AiOutlineSearch
                  size={25}
                  fill={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--greyscale_40')}
                />
                <div className="textbox">
                  <input
                    value={searchtext}
                    onChange={(e) => onHandleChange(e)}
                    onKeyDown={(e) => onKeyDownChange(e)}
                    style={{ border: 'none', width: '100%' }}
                    className={`form-control require shadow-none ${
                      searchtext ? 'has-value' : ''
                    }`}
                    placeholder={constants.SEARCH_DASHBOARD_SEARCH_TEXT}
                    autoComplete="off"
                    beforeinputicon=""
                    afterinputicon=""
                  />
                </div>
              </div>
              {browserSupportsSpeechRecognition && (
                <div
                  onClick={(e) => handleListing(e)}
                  className="voice-conatiner-all-result"
                >
                  <div
                    className={`outer-view ${
                      listening ? 'voice-listening' : ''
                    }`}
                  >
                    <div className="mic-btn">
                      <BsFillMicFill
                        size={25}
                        fill={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--brand_white')}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {
              <Tags
                tagsDetailList={getTagsList()}
                updateTags={(tags) => {
                  updateTagList(tags);
                }}
                fillterByTag={(requestBody) => {
                  setDeviceFilterList([]);
                  setDevices(devices);
                  fillterByTagApiCall(0);
                }}
                custOrgData={custOrgData}
              ></Tags>
            }
          </Col>
        </Row>
      </Container>
      <div className="seprator-tags"></div>
      <Container>
        <Row className="justify-content-md-center">
          <Col lg="12">
            {eventsData && eventsData.length > 0 && (
              <div className="search-filter-category">
                <div className="left-conatainer">
                  <div className="all-result-text">{searchLabelText}</div>
                  <div className="event-count">{`${
                    eventCount >= 10000
                      ? `${eventCount?.toLocaleString('en-US')}+`
                      : eventCount?.toLocaleString('en-US')
                  } ${constants.SEARCH_TOTAL_COUNT_RESULTS}`}</div>
                </div>
                <div className="right-filter-box">
                  <div className="time-box">
                    <DropdownList
                      isFromSearch={true}
                      defaultLabel={allCamera}
                      hasSecondaryDropdown={true}
                      items={filterContent}
                      show={show}
                      setShow={(status) => {
                        setShow(status);
                      }}
                    />
                    <Row className="settings-block">
                      <Col>
                        <select
                          className="form-select"
                          // value={selectedTime}
                          onChange={(e) => onHandleChangeTime(e)}
                        >
                          {generateOptions()}
                        </select>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )}
            <div className="all-events-container">
              {isLoadingEvents || getEventsLoading() ? (
                <SiteSpinner height="50px" width="50px" />
              ) : eventsData && eventsData.length > 0 ? (
                <div className="scroll-container">
                  <InfiniteScroll
                    loadMore={loadMore}
                    hasMore={hasMoreItems}
                    loader={<div className="loader"></div>}
                    useWindow={false}
                    threshold={1000}
                  >
                    {eventsData && showItems(eventsData, records)}
                  </InfiniteScroll>
                </div>
              ) : (
                <div className="no-data-container">
                  <AiOutlineSearch
                    size={50}
                    fill={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--greyscale_40')}
                  />
                  <div className="no-result">
                    {constants.SEARCH_DASHBOARD_RESULT_NOT_FOUND}
                  </div>
                  <div className="message-no-result">
                    {constants.SEARCH_DASHBOARD_RESULT_NOT_FOUND_MESSAGE}
                  </div>
                  <div className="all-event-back-conatiner">
                    <div
                      onClick={() => removeUrlParameter('searchtext')}
                      className="click-here"
                    >
                      {constants.SEARCH_DASHBOARD_CLICK_HERE}
                    </div>
                    <div className="to-see-all-events">
                      {constants.SEARCH_DASHBOARD_TO_SEE}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <SiteModal
        modalTitle=""
        showModal={showEventPreviewModal}
        hideModal={() => {
          setShowEventPreviewModal(false);
        }}
        size="lg"
        classes="event-preview-modal"
      >
        {modalContent && modalContent?.device?.length && (
          <EventTimelineControls
            eventTimestamp={modalContent?.timestamp}
            timezone={modalContent?.device[0]?.timezone}
            deviceId={modalContent?.device[0]?.deviceId}
            onNextPreviousClick={onNextPreviousClick}
            modalContent={modalContent}
            indexContent={indexContent}
            onCameraDetailsClick={onCameraDetailsClick}
          />
        )}
      </SiteModal>
    </div>
  );
};

export default AllEvents;
