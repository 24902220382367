import { useEffect, useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import {
  HiOutlineExclamationCircle,
  HiOutlineCheckCircle,
} from 'react-icons/hi2';

const SiteToast = ({
  customCss,
  position,
  show,
  delay,
  title,
  body,
  onClickHandle = false,
  variant = 'error',
  setUserMsg
}) => {
  const [showModal, setShowModal] = useState(show);
  const [toastBody, setToastBody] = useState('');

  useEffect(() => {
    show && setShowModal(true);
  }, [show]);
  
  useEffect(() => {
    setShowModal(!!body);
  }, [body]);

  const callbackMethod = (e) => {
    e?.stopPropagation();
    onClickHandle && onClickHandle(1);
  };

  const onCloseModal = (e) => {
    e?.stopPropagation();
    setShowModal(false);
    setUserMsg && setUserMsg('')
  };

  return (
    <ToastContainer className={`m-1 ${customCss}`} position={position}>
      <Toast
        onClick={(e) => callbackMethod(e)}
        className={`m-1 w-100 ${
          variant === 'error' ? 'error-toast' : 'success-toast'
        }`}
        onClose={(e) => onCloseModal(e)}
        autohide
        show={showModal}
        delay={delay}
      >
        <Toast.Header>
          {variant === 'error' ? (
            <HiOutlineExclamationCircle
              size={15}
              fill={getComputedStyle(document.documentElement).getPropertyValue(
                '--error_64'
              )}
            />
          ) : (
            <HiOutlineCheckCircle
              size={15}
              fill={getComputedStyle(document.documentElement).getPropertyValue(
                '--success_48'
              )}
            />
          )}
          <span className="me-auto">{title}</span>
        </Toast.Header>
        <Toast.Body>{body}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default SiteToast;
