import { Col, Accordion } from "react-bootstrap";
import { Field } from "formik";
import { useSelector } from "react-redux";
import { getLocationsData } from "../../store/reducers/AccountReducer";

const DeviceLocationAreas = ({ defaultLocaionsId, defaultLocaionsData, updateLocationData, ...props }) => {
	const locationsData = useSelector(getLocationsData);
	const updatedLocationsData = locationsData.filter((l) => l.locationId === defaultLocaionsId);
	return (
		<>
			{Array.isArray(updatedLocationsData) &&
				updatedLocationsData?.map((location) => {
					if (location) {
						return (
							<Accordion key={location.locationId} defaultActiveKey={defaultLocaionsId ? defaultLocaionsId : 0}>
								<Accordion.Item eventKey={location.locationId} className="site-accordian-item">
									<div key={location.locationId} className="radio-wrapper location-area-container">
										{Array.isArray(location?.areas) && location?.areas?.length > 0 && (
											<div>
												{location?.areas?.map((area) => {
													if (area) {
														return (
															<div key={area.areaId} className="radiotitle">
																<Col>{area?.areaName}</Col>
																<Col xs={1} md={1} lg={1} className="makingBig">
																	<Field
																		type="radio"
																		name="areaId"
																		value={area?.areaId}
																		checked={defaultLocaionsData === area?.areaId}
																		onClick={(event) => {
																			updateLocationData({
																				areaId: event?.target?.value,
																				locationId: area.locationId,
																			});
																		}}
																	/>
																</Col>
															</div>
														);
													}
												})}
											</div>
										)}
									</div>
								</Accordion.Item>
							</Accordion>
						);
					}
				})}
		</>
	);
};

export default DeviceLocationAreas;
