import React from 'react';
import { Navigate } from 'react-router-dom';
import { Radio, RadioGroup } from '@mui/material';
import Timeline from 'react-visjs-timeline';
import { Rnd } from 'react-rnd';
import moment from 'moment';
import axios from 'axios';
import { dateClosest } from '../../helpers/dateClosest';
import {
  camera,
  fullscreen,
  mute,
  next,
  noVideo,
  pause,
  play,
  previous,
  success,
  unmute,
} from '../../assets/images';
import videoProcessing from '../../assets/images/cameras/videoProcessing.svg';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { PrimaryButton, SiteModal, TextBlock } from '../../components/common';
import { AppDefaults, constants, Utils } from '../../helpers/';
import {
  setCDNInfo,
  setLiveStream,
  setLocalStream,
  setMetaData,
  setRemoteStream,
  setSnapshotImage,
} from '../../store/reducers/StreamingReducer';
import {
  getEventsScubberData,
  getTimelineData,
  updatedTimelineData,
} from '../../helpers/timelineData';
import {
  sendPauseCVR,
  sendPlayCVR,
} from '../../utils/connection/wssConnection';
import {
  disconnectWithMQTT,
  downloadPlaybackStream,
  downloadStream,
} from '../../utils/connection/mqttConnection';

import TimelineControlsLive from './TimelineControlsLive';
import PlaybackControlsView from './PlaybackControlsView';
import useEventsStore from '../../store/EventsStore';
import ImageFetch from '../../pages/cameras/components/ImageFetch';

import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import {
  HiOutlineChevronLeft,
  HiOutlineDotsVertical,
  HiOutlineFolderAdd,
} from 'react-icons/hi';

import CreateClip from '../../pages/cameras/modalForms/CreateClip';
import timezones from '../../data/support/timezone.json';
import 'moment-timezone';
import '../../assets/css/timeline.scss';

const clipperDefaults = {
  clipper: {
    x: 518.5,
    y: 0,
    height: '30px',
    width: 102.9,
    defaultDurationInPixels: 102.9,
    minDurationInPixels: 0,
    maxDurationInPixels: 0,
    currentDurationInPixels: 102.9,
    currentDurationInSeconds:
      AppDefaults.INCIDENT_EVIDENCE_CLIP_DEFAULT_DURATION_IN_SECONDS,
  },
  StartTimeOffsetInSeconds: 15,
  EndTimeOffsetInSeconds: 15,
  clipStartTime: null,
  clipEndTime: null,
  showClipper: false,
};

class TimelineControls extends React.Component {
  constructor(props) {
    super(props);
    this.timeline = React.createRef();
    this.clipperMarkerRef = React.createRef();
    // delete later: keeping this for static timeline
    // const currentTime = "November 8, 2022 03:35:52";
    const currentTime = new Date();
    const deviceStart = Utils.getDate(props?.streaming?.deviceStartDate / 1000);
    this.state = {
      minsOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: false,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: false,
        orientation: 'top',
        timeAxis: { scale: 'second', step: 6 },
        format: {
          majorLabels: {
            second: 'hh:mm',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment().subtract({ minutes: 2, seconds: 20 }).toDate(),
        end: moment(new Date(currentTime))
          .add({ minutes: 2, seconds: 20 })
          .toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime))
          .add({ minutes: 2, seconds: 20 })
          .toDate(),
        selectable: false,
      },
      daysOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: true,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: false,
        orientation: 'top',
        timeAxis: { scale: 'hour', step: 2 },
        format: {
          majorLabels: {
            hour: 'D MMM',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment(new Date(currentTime))
          .subtract({ days: 1, hours: 6 })
          .toDate(),
        end: moment(new Date(currentTime)).add({ days: 1, hours: 6 }).toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime)).add({ days: 1, hours: 6 }).toDate(),
        selectable: false,
      },
      secsOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: true,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: false,
        orientation: 'top',
        timeAxis: { scale: 'second', step: 1 },
        format: {
          majorLabels: {
            second: 'mm:ss',
          },
          minorLabels: {
            second: 's',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment(new Date(currentTime)).subtract({ seconds: 40 }).toDate(),
        end: moment(new Date(currentTime)).add({ seconds: 40 }).toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime)).add({ seconds: 40 }).toDate(),
        selectable: false,
      },
      selectedOption: 'Mins',
      activeTime: new Date(currentTime),
      timeZone: 'America/New_York',
      timezoneValue: moment.tz.guess(),
      offset: '-5',
      unixTime: moment(new Date(currentTime)).unix(),
      currentTimeNow: currentTime,
      posts: [],
      cdn: {},
      activeImage: '',
      metaData: [],
      minsView: true,
      secsView: false,
      daysView: false,
      clickAction: false,
      loading: false,
      cvrMode: false,
      CVRSnapMode: false,
      metaDataHere: [],
      minsMetaData: [],
      daysMetaData: [],
      secsMetaData: [],
      liveStreamMode: true,
      manualClicked: false,
      fullscreen: false,
      muteAudio: false,
      deviceId: null,
      startDate: null,
      eventsDataCheck: [],
      eventCategory: [],
      eventTimestamp: null,
      liveSnapshot: false,
      moveTimelineImage: false,
      internalEventFromSearch: false,
      eventCVRMode: null,
      fakeTime: true,
      customDeviceMetaData: null,
      customEventsDotsData: null,
      holdEventsDotsData: [],
      currentIncidents: [],
      assignedIncidentId: null,
      confirmLoader: false,
      showAddToIncidentModal: false,
      showAddToIncidentError: false,
      showAddToIncidentConfirmationModal: false,
      showCreateClipModal: false,
      duration: 100,
      ...clipperDefaults,
      timelineWidth: 0,
      timelineMidpoint: 0,
      timelinePixelsPerSecond: 0,
      defaultActiveTime: new Date(currentTime),
      tempActiveTime: 0,
      proceedToIncident: false,
      canCreateClip: false,
      createClipRequestSubmitted: false,
      shouldSubmitCreateClipRequest: false,
    };
  }

  // Component Lifecycle Methods
  componentDidMount = () => {
    this.updateTimeZone();
    this.moveTimeline();
    this.fetchEventsData();
    this.updateEventCVRMode();
    this.fetchCurrentIncidents();
    setInterval(() => {
      this.setDynamicTimelineMove();
    }, 1000);
    this.initClipperProperties();
  };

  componentDidUpdate = (props) => {
    if (this.state.deviceId !== props.deviceId) {
      this.setState({
        deviceId: props?.deviceId,
      });
      this.props.setSnapshotImage(null);
      this.updateTimeZone();
      this.fetchEventsData();
      if (
        this.state.internalEventFromSearch === props.internalEventFromSearch
      ) {
        this.setState({
          loading: true,
        });
        this.onGoLive();
      }
    }
    if (this.state.startDate !== props.startDate && props?.startDate) {
      this.setState({
        startDate: props?.startDate,
      });
      this.fetchCVRMetaData();
    }

    if (this.state.timezoneValue !== props.timezone && props?.timezone) {
      this.setState({
        timezoneValue: props?.timezone,
      });
      this.updateTimeZone();
    }
    if (this.state.eventCategory !== props.category) {
      this.setState({
        eventCategory: props?.category,
      });
      this.updateEventsPlotting();
    }

    if (this.state.eventTimestamp !== props.eventTimestamp) {
      this.setState({
        loading: true,
      });
      if (this.state.cvrMode) {
        sendPauseCVR();
      }
      this.setState({
        eventTimestamp: props?.eventTimestamp,
      });
      if (this.CVRAutoPlay) {
        clearInterval(this.CVRAutoPlay);
      }

      if (
        this.state.internalEventFromSearch !== props.internalEventFromSearch
      ) {
        setTimeout(() => {
          disconnectWithMQTT();
          this.goToEvent(props?.eventTimestamp);
          this.setState({
            internalEventFromSearch: props.internalEventFromSearch,
          });
        }, 1000);
      } else {
        setTimeout(() => {
          this.goToEvent(props?.eventTimestamp);
        }, 1000);
      }
    }

    if (this.state.customEventsDotsData !== props.eventDotsData) {
      if (props.eventDotsData !== null && Array.isArray(props.eventDotsData)) {
        this.setState({
          customEventsDotsData: props.eventDotsData,
        });

        const output = props.eventDotsData?.map((item) => ({
          eventMeta: item.eventMeta,
          deviceId: item.src?.srcId,
          eventTimestamp: Number(item.t),
        }));

        const allData = [];

        output?.forEach((insideEvent, i) => {
          insideEvent?.eventMeta?.events?.forEach((actualEvent, ij) => {
            actualEvent?.event?.forEach((newEvent, index) => {
              if (
                insideEvent.deviceId ===
                this.props?.account?.deviceInformation?.deviceId
              ) {
                const eData = {
                  id: `${i}-${ij}-${index}`,
                  class: newEvent.eventClass.toLowerCase(),
                  timestamp: insideEvent?.eventTimestamp,
                };
                allData.push(eData);
              }
            });
          });
        });

        const eventData = allData.map(function (i) {
          return i.class;
        });
        const obj = {
          start: props?.eventDotsData[0]?.t,
          data: eventData,
          type: 'point',
        };

        this.setState({
          holdEventsDotsData: [...this.state.holdEventsDotsData, obj],
        });
      }
    }

    if (this.state.customDeviceMetaData !== props.customMetaData) {
      if (props.customMetaData !== null) {
        const newObj = {
          start: props.customMetaData.start * 1000,
          end: props.customMetaData.end * 1000,
        };
        this.setState({
          customDeviceMetaData: props.customMetaData,
        });

        this.plotUpdatedSlot(newObj);
      }
    }
  };

  componentWillUnmount() {
    this.setState({
      internalEventFromSearch: false,
    });
    clearInterval(this.CVRAutoPlay);
    if (this.state.cvrMode) {
      sendPauseCVR();
    }
  }

  shouldComponentUpdate = () => {
    return true;
  };

  // Getter Methods
  getOptions = () => {
    if (this.state.minsView) {
      return this.state.minsOptions;
    } else if (this.state.daysView) {
      return this.state.daysOptions;
    } else if (this.state.secsView) {
      return this.state.secsOptions;
    }
  };

  getItems = () => {
    if (this.state.minsView) {
      return this.state.minsMetaData;
    } else if (this.state.daysView) {
      return this.state.daysMetaData;
    } else if (this.state.secsView) {
      return this.state.secsMetaData;
    }
  };

  getItemsMetaData = (data) => {
    const minsAdjustment = { minutes: 0 };
    const daysAdjustment = { days: 0 };
    const secsAdjustment = { seconds: 0 };

    const minsData = getTimelineData(data, minsAdjustment);
    const daysData = getTimelineData(data, daysAdjustment);
    const secsData = getTimelineData(data, secsAdjustment);

    this.setState({
      minsMetaData: minsData,
      daysMetaData: daysData,
      secsMetaData: secsData,
    });
  };

  getAgesWithGreaterDifference = (arr, endTime) => {
    // Sort the array based on age in ascending order
    arr.sort((a, b) => a.start - b.start);

    // Initialize the result array with the first object
    const result = [arr[0]];

    // Iterate over the remaining objects
    for (let i = 1; i < arr.length; i++) {
      const currentAge = arr[i].start;
      const previousAge = result[result.length - 1].start;

      // Check if the difference is greater than or equal to 3
      if (currentAge - previousAge >= 3000 && currentAge < endTime) {
        result.push(arr[i]);
      }
    }

    return result;
  };

  fetchCVRMetaData = () => {
    this.props.setMetaData([]);
    const startTime = this.props?.streaming?.deviceStartDate;
    const endTime = Utils.getUnixDate(this.state.currentTimeNow);
    if (startTime && endTime) {
      axios
        .get(
            `timeline/device/${this.props?.account?.deviceInformation?.deviceId}/metadata?startTime=${startTime}&endTime=${endTime}000`,
            Utils.requestHeader()
        )
        .then((response) => {
          if (response?.data?.data) {
            const metaData = response.data.data;
            this.getItemsMetaData(metaData);
          }
        });
    }
  };

  // API GET Request Methods
  fetchEventsData = () => {
    const endTime = Utils.getUnixDate(this.state.currentTimeNow);
    const startTime = Utils.getUnixDate(
      moment(this.state.currentTimeNow).subtract({
        minutes: 3,
      })
    );
    if (
      startTime &&
      endTime &&
      this.props?.account?.deviceInformation?.orgId &&
      this.props?.account?.deviceInformation?.deviceId
    ) {
      axios
        .get(
          `timeline/orgs/${this.props?.account?.deviceInformation?.orgId}/events/agg/count?endTime=${endTime}000&startTime=${startTime}000&deviceIds=${this.props?.account?.deviceInformation?.deviceId}&ascOrder=false&aggBy=minute`, Utils.requestHeader()
        )
        .then((response) => {
          if (response?.data?.data) {
            const scrubberEvents = Object.entries(response?.data?.data?.result);
            const finalData =
              scrubberEvents?.map((event) => ({
                start: event[0],
                data: Object.keys(event[1]),
                type: 'point',
              })) || [];
            const minsAdjustment = { minutes: 0 };
            const massagedData = getEventsScubberData(
              finalData,
              minsAdjustment
            );
            this.setState({
              eventsDataCheck: massagedData,
            });
            this.updateEventsPlotting(massagedData);
          }
        });
    }
  };

  fetchEventsDataInRange = () => {
    const endTime = Utils.getUnixDate(
      moment(this.state.activeTime).add({
        minutes: 3,
      })
    );
    const startTime = Utils.getUnixDate(
      moment(this.state.activeTime).subtract({
        minutes: 3,
      })
    );
    if (
      startTime &&
      endTime &&
      this.props?.account?.deviceInformation?.orgId &&
      this.props?.account?.deviceInformation?.deviceId
    ) {
      axios
        .get(
          `timeline/orgs/${this.props?.account?.deviceInformation?.orgId}/events/agg/count?endTime=${endTime}000&startTime=${startTime}000&deviceIds=${this.props?.account?.deviceInformation?.deviceId}&ascOrder=false&aggBy=minute`, Utils.requestHeader()
        )
        .then((response) => {
          if (response?.data?.data) {
            const scrubberEvents = Object.entries(response?.data?.data?.result);
            const finalData =
              scrubberEvents?.map((event) => ({
                start: event[0],
                data: Object.keys(event[1]),
                type: 'point',
              })) || [];
            const minsAdjustment = { minutes: 0 };
            const massagedData = getEventsScubberData(
              finalData,
              minsAdjustment
            );
            this.setState({
              eventsDataCheck: massagedData,
              holdEventsDotsData: [],
            });
            this.updateEventsPlottingInRange(massagedData);
          }
        });
    }
  };

  fetchEventsDataInDays = () => {
    const endTime = Utils.getUnixDate(
      moment(this.state.currentTimeNow).add({
        days: 4,
      })
    );
    const startTime = Utils.getUnixDate(
      moment(this.state.currentTimeNow).subtract({
        days: 4,
      })
    );
    if (
      startTime &&
      endTime &&
      this.props?.account?.deviceInformation?.orgId &&
      this.props?.account?.deviceInformation?.deviceId
    ) {
      axios
        .get(
          `timeline/orgs/${this.props?.account?.deviceInformation?.orgId}/events/agg/count?endTime=${endTime}000&startTime=${startTime}000&deviceIds=${this.props?.account?.deviceInformation?.deviceId}&ascOrder=false&aggBy=day`, Utils.requestHeader()
        )
        .then((response) => {
          if (response?.data?.data) {
            const scrubberEvents = Object.entries(response?.data?.data?.result);
            const finalData =
              scrubberEvents?.map((event) => ({
                start: event[0],
                data: Object.keys(event[1]),
                type: 'point',
              })) || [];
            const daysAdjustment = { days: 0 };
            const massagedData = getEventsScubberData(
              finalData,
              daysAdjustment
            );
            this.setState({
              eventsDataCheck: massagedData,
            });
            this.updateEventsPlottingInRangeDays(massagedData);
          }
        });
    }
  };

  fetchEventsDataInSecs = () => {
    const endTime = Utils.getUnixDate(this.state.currentTimeNow);
    const startTime = Utils.getUnixDate(
      moment(this.state.currentTimeNow).subtract({
        seconds: 240,
      })
    );
    if (
      startTime &&
      endTime &&
      this.props?.account?.deviceInformation?.orgId &&
      this.props?.account?.deviceInformation?.deviceId
    ) {
      axios
        .get(
          `timeline/orgs/${this.props?.account?.deviceInformation?.orgId}/events/agg/count?endTime=${endTime}000&startTime=${startTime}000&deviceIds=${this.props?.account?.deviceInformation?.deviceId}&ascOrder=false&aggBy=sec`, Utils.requestHeader()
        )
        .then((response) => {
          if (response?.data?.data) {
            const scrubberEvents = Object.entries(response?.data?.data?.result);
            const finalData =
              scrubberEvents?.map((event) => ({
                start: event[0],
                data: Object.keys(event[1]),
                type: 'point',
              })) || [];
            const secsAdjustment = { seconds: 0 };
            const massagedData = getEventsScubberData(
              finalData,
              secsAdjustment
            );
            this.setState({
              eventsDataCheck: massagedData,
            });
            this.updateEventsPlottingInRangeSecs(massagedData);
          }
        });
    }
  };

  fetchCurrentIncidents = async () => {
    try {
      let incidents;

      const res = await axios.get(
        `/incident/orgs/${this.props?.account?.deviceInformation?.orgId}/incidents`,
        Utils.requestHeader()
      );

      const responseData = res?.data;

      // Set incidents if request is successful
      if (responseData?.meta?.code === 200) {
        incidents = responseData?.data?.incidents;
        if (Array.isArray(incidents) && incidents.length > 0) {
          this.setState({ currentIncidents: [...incidents] });
        }
      } else {
        if (res?.code) {
          console.error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          console.error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Todo: Delete later
  // currentTimeTickHandler = (event) => {console.log("currentTimeTickHandler: ", event)};
  // clickHandler = (event) => {console.log("clickHandler: ", event)};
  // mouseOverHandler = (event) => {console.log("mouseOverHandler: ", event)};
  // mouseMoveHandler = (event) => {console.log("mouseMoveHandler: ", event)};
  // timechangeHandler = (event) => {console.log("timechangeHandler: ", event)};
  // timechangedHandler = (event) => {console.log("timechangedHandler: ", event)};

  // Event HAndlers
  onClick = (dates, currentDate, type) => {
    const data = dateClosest(dates, currentDate);
    if (type === 'previous') {
      const previousTime = data.datesAfter[0].date;
      this.timeline.current.$el.moveTo(new Date(previousTime));
    } else {
      const nextTime = data.datesBefore[0].date;
      this.timeline.current.$el.moveTo(new Date(nextTime));
    }
  };

  onPreviousIconClick = () => {
    const newData = [];
    this.state.eventsDataCheck?.forEach((event) => {
      const found = event?.data.some(
        (r) => this.props?.category.indexOf(r) >= 0
      );
      if (found) {
        newData.push(event);
      }
    });
    const activeScrubber = Utils.getUnixDate(this.state.activeTime);

    this.setState({
      liveSnapshot: false,
      moveTimelineImage: false,
    });

    let result = newData?.map((a) => Utils.getUnixDate(a.start));
    const data = dateClosest(result, activeScrubber);
    if (data && data.datesAfter && data.datesAfter.length) {
      const nextTime = data.datesAfter[0].date;
      useEventsStore.setState({
        selectedTimestamp: Utils.getUnixDate(nextTime) * 1000,
      });
      this.timeline.current.$el.moveTo(new Date(nextTime));
    }
  };

  onNextIconClick = () => {
    const newData = [];
    this.state.eventsDataCheck?.forEach((event) => {
      const found = event?.data.some(
        (r) => this.props?.category.indexOf(r) >= 0
      );
      if (found) {
        newData.push(event);
      }
    });
    const activeScrubber = Utils.getUnixDate(this.state.activeTime);

    this.setState({
      liveSnapshot: false,
      moveTimelineImage: false,
    });

    let result = newData?.map((a) => Utils.getUnixDate(a.start));
    const data = dateClosest(result, activeScrubber);
    if (data && data.datesBefore && data.datesBefore.length) {
      const nextTime = data.datesBefore[0].date;
      useEventsStore.setState({
        selectedTimestamp: Utils.getUnixDate(nextTime) * 1000,
      });
      this.timeline.current.$el.moveTo(new Date(nextTime));
    }
  };

  onMuteIconClick = () => {
    this.setState({
      muteAudio: !this.state.muteAudio,
    });
  };

  onClickMins = () => {
    switch (this.state.selectedOption?.toUpperCase()) {
      case 'MINS':
        this.fetchEventsDataInDays();
        this.setState({
          minsView: false,
          daysView: true,
          secsView: false,
          selectedOption: 'Days',
          clickAction: true,
        });
        break;

      case 'DAYS':
        this.fetchEventsDataInSecs();
        this.setState({
          minsView: false,
          daysView: false,
          secsView: true,
          selectedOption: 'Secs',
          clickAction: true,
        });
        break;

      case 'SECS':
        // Switch back to "Mins" option
        this.enableMinutesOption();
        break;

      default: // there is no default option
    }

    setTimeout(() => {
      this.setState({
        clickAction: false,
      });
    }, 300);
  };

  onGoLive = () => {
    this.setState({
      liveStreamMode: true,
      activeTime: new Date(),
      manualClicked: false,
      loading: true,
    });

    if (this.state.cvrMode) {
      sendPauseCVR();
      this.setState({
        cvrMode: false,
      });
    }
    this.timeline.current.$el.moveTo(new Date());

    setTimeout(() => {
      if (this.state.activeTime !== new Date()) {
        this.timeline.current.$el.moveTo(new Date());
      }
      this.setState({
        loading: false,
      });
    }, 2500);

    if (this.state.CVRSnapMode) {
      this.moveTimeline();
      this.setState({
        CVRSnapMode: false,
      });
    }
    this.props.handleClick(constants.DEVICES_SWITCH_LIVE_TITLE);
    switch (this.state.selectedOption) {
      case 'Mins':
        this.fetchEventsDataInRange();
        this.fetchCVRMetaData();
        break;
      case 'Days':
        this.fetchEventsDataInDays();
        this.fetchCVRMetaData();
        break;
      case 'Secs':
        this.fetchEventsDataInSecs();
        this.fetchCVRMetaData();
        break;
      default:
    }
  };

  onPauseCVR = (e) => {
    if (this.state.cvrMode) {
      sendPauseCVR();
      this.setState({
        cvrMode: false,
        CVRSnapMode: true,
      });
    }
    if (this.state.liveStreamMode) {
      disconnectWithMQTT();
      this.setState({
        CVRSnapMode: true,
      });
      this.setState({
        liveStreamMode: false,
        liveSnapshot: true,
      });
    }
    this.setState({
      manualClicked: true,
    });
    clearInterval(this.CVRAutoPlay);
    this.fetchEventsDataInRange();
    this.fetchCVRMetaData();
  };

  onPlayCVR = () => {
    this.setState({
      cvrMode: true,
      liveStreamMode: false,
      CVRSnapMode: false,
      eventCVRMode: true,
    });
    sendPlayCVR(
      this.state.activeTime,
      this.props?.account?.deviceInformation?.deviceId
    );

    this.moveTimeline();
  };

  rangeChangeHandler = (event) => {
    let timeInMin = moment(event.start)
      .add({ minutes: 2, seconds: 20 })
      .toDate();
    let timeInDay = moment(event.start).add({ days: 1, hours: 6 }).toDate();
    let timeInSec = moment(event.start).add({ seconds: 40 }).toDate();
    if (event.byUser && this.state.fakeTime) {
      if (
        this.state.selectedOption === 'Mins' &&
        Utils.getUnixDate(timeInMin) !==
          Utils.getUnixDate(this.state.activeTime)
      ) {
        this.setState({
          activeTime: timeInMin,
          defaultActiveTime: timeInMin,
        });
      } else if (
        this.state.selectedOption === 'Days' &&
        timeInDay !== this.state.activeTime
      ) {
        this.setState({
          activeTime: timeInDay,
          defaultActiveTime: timeInDay,
        });
      } else if (
        this.state.selectedOption === 'Secs' &&
        timeInSec !== this.state.activeTime
      ) {
        this.setState({
          activeTime: timeInSec,
          defaultActiveTime: timeInSec,
        });
      }
      if (
        moment(this.state.activeTime).unix() !==
          moment(this.currentTime).unix() &&
        !this.state.clickAction
      ) {
        this.currentTime = this.state.activeTime;
      }
      this.setState({
        CVRSnapMode: true,
        liveSnapshot: false,
        moveTimelineImage: true,
      });
      if (this.state.liveStreamMode) {
        disconnectWithMQTT();
        this.setState({
          liveStreamMode: false,
        });
      }
      sendPauseCVR();
    } else {
      if (
        this.state.minsView &&
        Utils.getUnixDate(timeInMin) !==
          Utils.getUnixDate(this.state.activeTime)
      ) {
        this.setState({
          activeTime: moment(event.start)
            .add({ minutes: 2, seconds: 20 })
            .toDate(),
          defaultActiveTime: moment(event.start)
            .add({ minutes: 2, seconds: 20 })
            .toDate(),
        });
      } else if (this.state.daysView) {
        this.setState({
          activeTime: moment(event.start).add({ days: 1, hours: 6 }).toDate(),
          defaultActiveTime: moment(event.start)
            .add({ days: 1, hours: 6 })
            .toDate(),
        });
      } else if (this.state.secsView) {
        this.setState({
          activeTime: moment(event.start).add({ seconds: 40 }).toDate(),
          defaultActiveTime: moment(event.start).add({ seconds: 40 }).toDate(),
        });
      }
    }
  };

  rangeChangedHandler = (event) => {
    if (event.byUser) {
      const time = moment(event.end)
        .subtract({ minutes: 2, seconds: 20 })
        .toDate();

      if (
        this.state.cvrMode ||
        (!this.state.manualClicked && time < new Date())
      ) {
        this.setState({
          CVRSnapMode: false,
        });
        sendPlayCVR(
          this.state.activeTime,
          this.props?.account?.deviceInformation?.deviceId
        );
      }
      this.setState({
        moveTimelineImage: false,
      });

      if (!this.state.manualClicked) {
        this.setState({
          cvrMode: true,
        });

        if (this.state.manualClicked && this.state.cvrMode) {
          this.moveTimeline();
        }
      }
      switch (this.state.selectedOption) {
        case 'Mins':
          this.fetchEventsDataInRange();
          this.fetchCVRMetaData();
          break;
        case 'Days':
          this.fetchEventsDataInDays();
          this.fetchCVRMetaData();
          break;
        case 'Secs':
          this.fetchEventsDataInSecs();
          this.fetchCVRMetaData();
          break;
        default:
          break;
      }
      // TODO: Under observation
      // if (time > new Date() && this.state.fakeTime) {
      //   this.setState({
      //     fakeTime: false,
      //   });
      //   // if (this.state.cvrMode) {
      //   //   clearInterval(this.CVRAutoPlay);
      //   // }
      //   this.onGoLive();
      //   setTimeout(() => {
      //     this.setState({
      //       fakeTime: true,
      //     });
      //   }, 1500);
      // }
    }
  };

  toggleClipper = (e) => {
    if (!e) return;

    // Reset all clipper configurations to their defaults
    this.initClipperProperties();

    // If the clipper isn't already enabled, then do the following:
    if (this.state.showClipper === false) {
      // (1) Track the current selected mode of the player
      this.setState({ preClipperSelectedOption: this.state.selectedOption });
      // (2) Pause the video player if it's currently playing
      if (this.state.cvrMode === true || this.state.liveStreamMode === true) {
        this.onPauseCVR(e);
      }
      // (3) Ensure we are in "Mins" mode
      if (this.state.selectedOption?.toUpperCase() !== 'MINS') {
        this.setState({ selectedOption: 'Mins' });
        this.enableMinutesOption();
      }
      // (4) Reset the clickAction state
      setTimeout(() => {
        this.setState({
          clickAction: false,
        });
      }, 300);
    }

    // Toggle the clipper
    this.setState({ showClipper: !this.state.showClipper });
  };

  handleAddToIncident = async () => {
    let isClipAddedToIncident = false;

    try {
      const incidentId = this.state.assignedIncidentId;

      this.setState({ confirmLoader: true });

      if (!incidentId || !this.state.clipStartTime || !this.state.clipEndTime) {
        throw new Error('ERROR: missing parameters');
      }

      // Required fields.
      const reqBody = {
        deviceId: this.props?.account?.deviceInformation?.deviceId,
        clipStartTime: this.state.clipStartTime,
        clipEndTime: this.state.clipEndTime,
      };

      const res = await axios.post(
        `/incident/orgs/${this.props?.account?.deviceInformation?.orgId}/incidents/${incidentId}/clips`,
        reqBody,
        {
          ...Utils.requestHeader(),
          credentials: 'include',
          withCredentials: true,
        }
      );

      const responseData = res?.data;

      if (responseData.meta?.code === 200) {
        isClipAddedToIncident = true;
        this.setState({
          showAddToIncidentModal: false,
        });
        this.setState({
          showAddToIncidentConfirmationModal: true,
        });
        this.resetClipperProperties();
      } else {
        // TODO: show an error
        console.error(res?.meta?.userMsg);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ confirmLoader: false });
      return isClipAddedToIncident;
    }
  };

  handleCreateClip = () => {
    this.setState({ shouldSubmitCreateClipRequest: true });
  };

  resetClipperProperties = () => {
    this.setState({
      ...clipperDefaults,
    });
  };

  enableMinutesOption = () => {
    this.fetchEventsData();
    this.setState({
      minsView: true,
      daysView: false,
      secsView: false,
      selectedOption: 'Mins',
      clickAction: true,
    });
  };

  // Video Player Actions
  enterFullScreen = () => {
    this.setState({
      fullscreen: true,
    });
    this.props.enterFullScreen();
  };

  exitFullScreen = () => {
    this.setState({
      fullscreen: false,
    });
    this.props.exitFullScreen();
  };

  downloadLiveImage = () => {
    const deviceName = this.props?.account?.deviceInformation?.deviceName;
    const time = moment(this.state.activeTime).format(
      'YYYY-MM-DD - hh.mm.ss A'
    );
    downloadStream(`${deviceName} - ${time}`);
  };

  downloadPlaybackImage = async () => {
    const deviceName = this.props?.account?.deviceInformation?.deviceName;
    const time = moment(this.state.activeTime).format(
      'YYYY-MM-DD - hh.mm.ss A'
    );
    if (!this.state.CVRSnapMode) {
      downloadPlaybackStream(`${deviceName} - ${time}`);
    } else {
      fetch(this.state.activeImage, {
        credentials: 'include',
      })
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.download = `${deviceName} - ${time}`;
          a.href = blobUrl;
          document.body.appendChild(a);
          a.click();
        });
    }
  };

  // Timeline and Scrubber Methods
  initClipperProperties = () => {
    const timelineWidth = this.timeline.current?.$el?.props?.lastWidth;
    const pixelsPerSecond =
      timelineWidth / AppDefaults.CAMERA_VIDEO_TIMELINE_MAX_DURATION_IN_SECONDS;

    const clipperProps = {
      timelineWidth: timelineWidth,
      timelineMidpoint: timelineWidth / 2,
      timelinePixelsPerSecond: pixelsPerSecond,
      clipper: {
        x: timelineWidth / 2 + AppDefaults.INCIDENT_EVIDENCE_CLIP_HANDLE_WIDTH,
        width: 30 * pixelsPerSecond,
        defaultDurationInPixels:
          AppDefaults.INCIDENT_EVIDENCE_CLIP_DEFAULT_DURATION_IN_SECONDS *
          pixelsPerSecond,
        minDurationInPixels:
          AppDefaults.INCIDENT_EVIDENCE_CLIP_MIN_DURATION_IN_SECONDS *
          pixelsPerSecond,
        maxDurationInPixels:
          AppDefaults.INCIDENT_EVIDENCE_CLIP_MAX_DURATION_IN_SECONDS *
          pixelsPerSecond,
        currentDurationInPixels:
          AppDefaults.INCIDENT_EVIDENCE_CLIP_DEFAULT_DURATION_IN_SECONDS *
          pixelsPerSecond,
        currentDurationInSeconds:
          AppDefaults.INCIDENT_EVIDENCE_CLIP_DEFAULT_DURATION_IN_SECONDS,
      },
    };

    this.setState({ ...clipperProps });
  };

  plotUpdatedSlot = (newObj) => {
    if (newObj?.end && this.state.holdEventsDotsData.length) {
      const newArray = this.getAgesWithGreaterDifference(
        this.state.holdEventsDotsData,
        newObj?.end
      );
      const minsAdjustment = { minutes: 0 };
      const minsData = updatedTimelineData([newObj], minsAdjustment);
      const mergedItem = [...this.state.minsMetaData, ...minsData];
      this.setState({
        minsMetaData: mergedItem,
      });
      this.setState({
        holdEventsDotsData: [],
      });

      const massagedData = getEventsScubberData(newArray, minsAdjustment);
      const newData = [...this.state.eventsDataCheck, ...massagedData];
      this.setState({
        eventsDataCheck: newData,
      });
      setTimeout(() => {
        this.updateEventsPlotting(newData);
      }, 500);
      //this.timeline?.current?.$el?.setItems([...mergedItem]);
    } else if (newObj?.end) {
      const minsAdjustment = { minutes: 0 };
      const minsData = updatedTimelineData([newObj], minsAdjustment);
      this.setState({
        minsMetaData: [...this.state.minsMetaData, ...minsData],
      });
      this.updateEventsPlotting();
      // this.timeline?.current?.$el?.setItems([...mergedItem]);
    }
  };

  updateEventCVRMode = () => {
    if (!this.props?.eventCVRMode) {
      this.setState({
        CVRSnapMode: true,
        liveStreamMode: false,
        cvrMode: false,
        manualClicked: true,
      });
    }
  };

  goToEvent = (timestamp) => {
    setTimeout(() => {
      if (this.state.activeTime !== Utils.getDate(timestamp / 1000)) {
        this.timeline.current.$el.moveTo(Utils.getDate(timestamp / 1000), [
          { duration: 0 },
        ]);
      }
      if (!this.state.CVRSnapMode) {
        this.moveTimeline();
      }
      this.setState({
        loading: false,
      });
    }, 1500);
    if (this.state.liveStreamMode || this.state.cvrMode) {
      this.setState({
        cvrMode: true,
        liveStreamMode: false,
        liveSnapshot: false,
        CVRSnapMode: false,
      });
      sendPlayCVR(
        Utils.getDate(timestamp / 1000),
        this.props?.account?.deviceInformation?.deviceId
      );
    } else {
      this.setState({
        CVRSnapMode: true,
        liveSnapshot: false,
      });
    }
    switch (this.state.selectedOption) {
      case 'Mins':
        this.fetchEventsDataInRange();
        this.fetchCVRMetaData();
        break;

      case 'Days':
        this.fetchEventsDataInDays();
        break;

      case 'Secs':
        this.fetchEventsDataInSecs();
        break;

      default: // Do nothing
    }
  };

  updateTimeZone = () => {
    const getLocationSelected = timezones?.data?.find(
      (zone) =>
        zone.value ===
        this.props?.account?.deviceInformation?.properties?.timezone
    );
    const zone = getLocationSelected?.location || moment.tz.guess();

    const UTCDate = moment(new Date()).tz(zone).format();

    if (zone) {
      this.setState({
        timeZone: zone,
        offset: UTCDate,
      });
    }
  };

  moveTimeline = () => {
    if (this.state.liveStreamMode) {
      const newDate = new Date();
      this.setState({
        activeTime: newDate,
        defaultActiveTime: newDate,
      });
    }
    if (this.state.selectedOption === 'Days') {
      this.CVRAutoPlay = setInterval(() => {
        this.setState({
          activeTime: moment(this.state.activeTime)
            .add({ seconds: 1 })
            .toDate(),
          defaultActiveTime: moment(this.state.activeTime)
            .add({ seconds: 1 })
            .toDate(),
        });
      }, 1000);
    } else {
      this.CVRAutoPlay = setInterval(() => {
        this.timeline?.current?.$el?.moveTo(
          moment(this.state.activeTime).add({ seconds: 1 }).toDate()
        );
      }, 1000);
    }
  };

  setDynamicTimelineMove = () => {
    const { offset } = this.state;
    this.timeline?.current?.$el.setOptions({
      moment: function (date) {
        return moment(date).utcOffset(offset);
      },
    });
    if (this.state.minsView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ minutes: 2, seconds: 21 }).toDate(),
      });
    } else if (this.state.daysView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ days: 1, hours: 6, seconds: 1 }).toDate(),
      });
    } else if (this.state.secsView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ seconds: 41 }).toDate(),
      });
    }
  };

  updateEventsPlotting = (eventsData) => {
    let data = [];
    if (eventsData) {
      data = eventsData;
    } else {
      data = this.state.eventsDataCheck;
    }
    const newData = [];
    data?.forEach((event) => {
      const found = event?.data?.some(
        (r) => this.props?.category.indexOf(r) >= 0
      );
      if (found) {
        newData.push(event);
      }
    });

    const mergedItem = [...this.state.minsMetaData, ...newData];
    // this.setState({
    //   minsMetaData: mergedItem,
    // });
    const filteredArr = mergedItem.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    this.timeline?.current?.$el?.setItems(filteredArr);
  };

  updateEventsPlottingInRange = (eventsData) => {
    const newData = [];
    eventsData?.forEach((event) => {
      const found = event?.data.some(
        (r) => this.props?.category.indexOf(r) >= 0
      );
      if (found) {
        newData.push(event);
      }
    });

    // this.setState({
    //   minsMetaData: [...this.state.minsMetaData, ...newData],
    // });

    this.timeline?.current?.$el?.setItems([
      ...this.state.minsMetaData,
      ...newData,
    ]);
  };

  updateEventsPlottingInRangeDays = (eventsData) => {
    const newData = [];
    eventsData?.forEach((event) => {
      const found = event?.data.some(
        (r) => this.props?.category.indexOf(r) >= 0
      );
      if (found) {
        newData.push(event);
      }
    });

    const mergedItem = [...this.state.daysMetaData, ...newData];
    const filteredArr = mergedItem.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    this.timeline?.current?.$el?.setItems(filteredArr);
  };

  updateEventsPlottingInRangeSecs = (eventsData) => {
    const newData = [];
    eventsData?.forEach((event) => {
      const found = event?.data.some(
        (r) => this.props?.category.indexOf(r) >= 0
      );
      if (found) {
        newData.push(event);
      }
    });

    const mergedItem = [...this.state.secsMetaData, ...newData];
    const filteredArr = mergedItem.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    this.timeline?.current?.$el?.setItems(filteredArr);
  };

  render() {
    const { isRemoteStreamPlay } = this.props.streaming;

    if (this.state.proceedToIncident && this.state.assignedIncidentId) {
      let incidentId = this.state.assignedIncidentId;
      this.setState({ assignedIncidentId: null });

      return (
        <Navigate
          to={`/incidents/details.html?incidentId=${incidentId}&activeTab=0`}
        />
      );
    }

    return (
      <div className="wrapper-app live-streaming">
        {this.state.liveStreamMode && (
          <div className="stream-timeline-wrapper liveview">
            {this.state.loading && (
              <img
                className="video-processing"
                src={videoProcessing}
                alt="video processing"
              />
            )}
            <TimelineControlsLive muteAudio={this.state.muteAudio} />
          </div>
        )}

        {!this.state.liveStreamMode && (
          <div className="stream-timeline-wrapper">
            <div className="events-icons-wrapper">
              <span
                className="icons-image previous-icon"
                onClick={() => this.onPreviousIconClick()}
              >
                <ArrowLeft width={18} />
              </span>
              <span
                className="icons-image next-icon"
                onClick={() => this.onNextIconClick()}
              >
                <ArrowRight width={18} />
              </span>
            </div>
            <div className="scrubber-image-view">
              {this.state.loading && (
                <img
                  className="video-processing"
                  src={videoProcessing}
                  alt="video processing"
                />
              )}
              {this.state.activeTime &&
                this.state.CVRSnapMode &&
                !this.state.loading && (
                  <div
                    className={`active-image ${
                      this.state.activeImage === noVideo ? 'novideo' : ''
                    }`}
                  >
                    {this.state.CVRSnapMode &&
                      this.state.showClipper &&
                      !this.state.loading && (
                        <div className="add-to-incident-wrapper">
                          <div
                            className="back-to-live"
                            onClick={() => {
                              this.setState({
                                showClipper: !this.state.showClipper,
                              });
                            }}
                          >
                            <HiOutlineChevronLeft className="add-to-incident-left-arrow" />
                          </div>
                          <div
                            className="add-to-incident"
                            onClick={(e) => {
                              this.setState({ showAddToIncidentModal: true });
                            }}
                          >
                            <HiOutlineFolderAdd className="add-to-incident-folder" />
                            <span className="add-to-incident-label">
                              {
                                constants.CAMERAS_LIVE_PLAYBACK_ADD_TO_INCIDENT_TEXT
                              }
                            </span>
                          </div>
                        </div>
                      )}
                    <ImageFetch
                      moveTimeline={this.state.moveTimelineImage}
                      liveSnapshot={this.state.liveSnapshot}
                      time={this.state.activeTime}
                      cdnValue={this.props?.streaming?.cdnInfo}
                      deviceId={
                        this.props?.account?.deviceInformation?.deviceId
                      }
                    />
                  </div>
                )}
              {!this.state.CVRSnapMode && (
                <>
                  <PlaybackControlsView muteAudio={this.state.muteAudio} />
                </>
              )}
            </div>
            {/* Todo: Need below functionality later */}
            {/* <div
            className="previous-block"
            onClick={() =>
              this.onClick(dates, this.state.activeTime, 'previous')
            }
          ></div>
          <div
            className="next_block"
            onClick={() => this.onClick(dates, this.state.activeTime, 'next')}
          ></div> */}
          </div>
        )}

        <div className="stream-timeline-wrapper timeline-controls-page">
          <div className={`scrubber-wrapper`}>
            {this.state.showClipper ? (
              <div className="clipper-controls-wrapper">
                <div className="clipper-bubble clipper-duration">{`${Math.round(
                  this.state.clipper.currentDurationInSeconds
                )} ${
                  constants.CAMERAS_LIVE_PLAYBACK_CLIPPER_DURATION_UNIT
                }`}</div>
                <div className="clipper-active-time active_time">
                  {moment
                    .tz(moment(this.state.activeTime), this.state.timeZone)
                    .format('MMM DD, YYYY - hh:mm:ss A z')}
                </div>
                <div
                  className="clipper-bubble clipper-action"
                  onClick={() => this.setState({ showCreateClipModal: true })}
                >
                  {constants.CAMERAS_LIVE_PLAYBACK_CLIPPER_SAVE_BUTTON_LABEL}
                </div>
              </div>
            ) : (
              <div className="timelines-controls-wrapper">
                {this.state.liveStreamMode && (
                  <>
                    <div className="timeline-controls-icons">
                      {!(this.state.cvrMode || this.state.liveStreamMode) && (
                        <span
                          onClick={(e) => {
                            // Toggle off the clipper
                            this.setState({
                              showClipper: false,
                            });

                            this.onPlayCVR();
                          }}
                          className="icons-image"
                        >
                          <img width={16} src={play} alt="icon" />
                        </span>
                      )}

                      {(this.state.cvrMode || this.state.liveStreamMode) && (
                        <span
                          onClick={(e) => this.onPauseCVR(e)}
                          className="icons-image"
                        >
                          <img width={16} src={pause} alt="icon" />
                        </span>
                      )}
                      <span
                        className="icons-image"
                        onClick={() => this.onMuteIconClick()}
                      >
                        <img
                          width={16}
                          src={this.state.muteAudio ? mute : unmute}
                          alt="icon"
                        />
                      </span>
                      <span
                        className="icons-image"
                        onClick={() => this.downloadLiveImage()}
                      >
                        <img src={camera} alt="icon" />
                      </span>
                      {!(this.state.cvrMode || this.state.liveStreamMode) &&
                        !this.state.showClipper && (
                          <span
                            className="icons-image"
                            onClick={(e) => {
                              this.toggleClipper(e);
                            }}
                          >
                            <HiOutlineFolderAdd className="icons-image-folder-playing" />
                          </span>
                        )}
                      <button
                        onClick={() => {
                          if (this.state.showClipper === false) {
                            this.onClickMins();
                          }
                        }}
                        className={`mins ${
                          this.state.showClipper ? 'no-pointer' : ''
                        }`}
                      >
                        {this.state.selectedOption || ''}
                      </button>
                    </div>
                    <div className="live">Live</div>
                    {/* <div onClick={() => this.onGoLive()}>Go Live</div> */}
                    <div className={`active_time`}>
                      {isRemoteStreamPlay &&
                        moment
                          .tz(
                            moment(this.state.activeTime),
                            this.state.timeZone
                          )
                          .format('hh:mm:ss A z')}
                      <span
                        className="icons-image"
                        onClick={() =>
                          this.state.fullscreen
                            ? this.exitFullScreen()
                            : this.enterFullScreen()
                        }
                      >
                        <img src={fullscreen} alt="icon" />
                      </span>
                    </div>
                  </>
                )}
                {!this.state.liveStreamMode && (
                  <>
                    <div className="timeline-controls-icons">
                      {!(
                        this.state.cvrMode ||
                        this.state.liveStreamMode ||
                        !this.state.manualClicked
                      ) && (
                        <span
                          onClick={(e) => {
                            // Toggle off the clipper
                            this.setState({
                              showClipper: false,
                            });
                            this.onPlayCVR();
                          }}
                          className="icons-image"
                        >
                          <img width={16} src={play} alt="icon" />
                        </span>
                      )}
                      {(this.state.cvrMode ||
                        this.state.liveStreamMode ||
                        !this.state.manualClicked) && (
                        <span
                          onClick={(e) => this.onPauseCVR(e)}
                          className="icons-image"
                        >
                          <img width={16} src={pause} alt="icon" />
                        </span>
                      )}
                      <span
                        className="icons-image"
                        onClick={() => this.onPreviousIconClick()}
                      >
                        <img width={16} src={previous} alt="previous icon" />
                      </span>
                      <span
                        className="icons-image"
                        onClick={() => this.onNextIconClick()}
                      >
                        <img width={16} src={next} alt="next icon" />
                      </span>
                      <span
                        className="icons-image"
                        onClick={() => this.onMuteIconClick()}
                      >
                        <img
                          width={16}
                          src={this.state.muteAudio ? mute : unmute}
                          alt="icon"
                        />
                      </span>
                      <span
                        className="icons-image"
                        onClick={() => this.downloadPlaybackImage()}
                      >
                        <img src={camera} alt="icon" />
                      </span>
                      {!(
                        this.state.cvrMode ||
                        this.state.liveStreamMode ||
                        !this.state.manualClicked
                      ) &&
                        !this.state.showClipper && (
                          <span
                            className="icons-image"
                            onClick={(e) => {
                              this.toggleClipper(e);

                              const beforeAndAfterTimes =
                                Utils.getBeforeAndAfterUnixTimes(
                                  moment(this.state.activeTime).unix(),
                                  this.state.StartTimeOffsetInSeconds,
                                  false,
                                  this.state.EndTimeOffsetInSeconds,
                                  true
                                );

                              this.setState({
                                clipStartTime:
                                  beforeAndAfterTimes.beforeUnixTime,
                                clipEndTime: beforeAndAfterTimes.afterUnixTime,
                              });
                            }}
                          >
                            <HiOutlineFolderAdd className="icons-image-folder-paused" />
                          </span>
                        )}
                      <button
                        onClick={() => {
                          if (this.state.showClipper === false) {
                            this.onClickMins();
                          }
                        }}
                        className={`mins ${
                          this.state.showClipper ? 'no-pointer' : ''
                        }`}
                      >
                        {this.state.selectedOption || ''}
                      </button>
                    </div>
                    {/* <div className="live">Live</div>
                  <div onClick={() => this.onGoLive()}>Go Live</div> */}
                    <div className={`active_time`}>
                      {isRemoteStreamPlay &&
                        moment
                          .tz(
                            moment(this.state.activeTime),
                            this.state.timeZone
                          )
                          .format('MMM DD, YYYY - hh:mm:ss A z')}
                    </div>
                    <div className="golive-view">
                      <button className="live" onClick={() => this.onGoLive()}>
                        {constants.CAMERAS_LIVE_PLAYBACK_GO_LIVE_BUTTON_LABEL}
                      </button>
                      <span
                        className="icons-image"
                        onClick={() =>
                          this.state.fullscreen
                            ? this.exitFullScreen()
                            : this.enterFullScreen()
                        }
                      >
                        <img src={fullscreen} alt="icon" />
                      </span>
                    </div>
                  </>
                )}
              </div>
            )}
            {!this.state.clickAction && this.state.metaDataHere && (
              <>
                <div
                  className={`scrubber${
                    this.state.showClipper ? ' midpoint-hidden' : ''
                  }`}
                >
                  <Timeline
                    ref={this.timeline}
                    // items={this.getItems()}
                    options={this.getOptions()}
                    rangechangeHandler={(event) =>
                      this.rangeChangeHandler(event)
                    }
                    rangechangedHandler={(event) =>
                      this.rangeChangedHandler(event)
                    }
                  />
                </div>
                {this.state.CVRSnapMode &&
                  this.state.showClipper &&
                  !this.state.loading && (
                    <div className="clipper">
                      <Rnd
                        className="clipper-resizable-container"
                        size={{
                          width: this.state.clipper.width,
                          height: this.state.clipper.height,
                        }}
                        enableResizing={{
                          top: false,
                          right: true,
                          bottom: false,
                          left: true,
                          topRight: false,
                          bottomRight: false,
                          bottomLeft: false,
                          topLeft: false,
                        }}
                        resizeHandleWrapperClass="clipper-handle"
                        resizeHandleComponent={{
                          left: (
                            <HiOutlineDotsVertical className="three-dots left-handle" />
                          ),
                          right: (
                            <HiOutlineDotsVertical className="three-dots right-handle" />
                          ),
                        }}
                        minWidth={this.state.clipper.minDurationInPixels}
                        maxWidth={this.state.clipper.maxDurationInPixels}
                        maxHeight={30}
                        position={{ x: this.state.clipper.x, y: 0 }}
                        on
                        onDrag={(e, d) => {
                          const beforeAndAfterOffsets =
                            Utils.calculateBeforeAndAfterOffsets(
                              this.state.timelineMidpoint,
                              d?.x,
                              this.state.clipper.width
                            );

                          if (!beforeAndAfterOffsets) {
                            return;
                          }

                          // Set new clip start and end times
                          const beforeAndAfterTimes =
                            Utils.getBeforeAndAfterUnixTimes(
                              Utils.getUnixDate(this.state.defaultActiveTime),
                              Math.ceil(
                                beforeAndAfterOffsets?.startOffset /
                                  this.state.timelinePixelsPerSecond
                              ),
                              beforeAndAfterOffsets?.shouldAddStartOffset,
                              Math.ceil(
                                beforeAndAfterOffsets?.endOffset /
                                  this.state.timelinePixelsPerSecond
                              ),
                              beforeAndAfterOffsets?.shouldAddEndOffset
                            );

                          if (!beforeAndAfterTimes) {
                            return;
                          }

                          this.setState({
                            activeTime: Utils.getDate(
                              beforeAndAfterTimes.beforeUnixTime / 1000
                            ),
                          });
                        }}
                        onDragStart={(e, d) => {
                          // Toggle on highlight color
                          this.clipperMarkerRef.current.classList.add(
                            'highlight'
                          );

                          this.setState({
                            ...this.state.clipper,
                            x: d.x,
                            y: 0,
                          });
                        }}
                        onDragStop={(e, d) => {
                          // Toggle off highlight color
                          this.clipperMarkerRef.current.classList.remove(
                            'highlight'
                          );

                          this.setState({
                            clipper: {
                              ...this.state.clipper,
                              x: d.x,
                              y: 0,
                            },
                          });

                          const beforeAndAfterOffsets =
                            Utils.calculateBeforeAndAfterOffsets(
                              this.state.timelineMidpoint,
                              d?.x,
                              this.state.clipper.width
                            );

                          if (!beforeAndAfterOffsets) {
                            return;
                          }

                          const beforeAndAfterTimes =
                            Utils.getBeforeAndAfterUnixTimes(
                              moment(this.state.activeTime).unix(),
                              Math.ceil(
                                beforeAndAfterOffsets?.startOffset /
                                  this.state.timelinePixelsPerSecond
                              ),
                              beforeAndAfterOffsets?.shouldAddStartOffset,
                              Math.ceil(
                                beforeAndAfterOffsets?.endOffset /
                                  this.state.timelinePixelsPerSecond
                              ),
                              beforeAndAfterOffsets?.shouldAddEndOffset
                            );

                          if (!beforeAndAfterTimes) {
                            return;
                          }

                          this.setState({
                            clipStartTime: beforeAndAfterTimes.beforeUnixTime,
                            clipEndTime: beforeAndAfterTimes.afterUnixTime,
                          });
                        }}
                        dragAxis={'x'}
                        bounds=".clipper"
                        onResize={(e, direction, ref, delta, position) => {
                          const rawWidth = ref?.style?.width?.split('px')[0];

                          this.setState({
                            clipper: {
                              ...this.state.clipper,
                              currentDurationInPixels: rawWidth,
                              currentDurationInSeconds:
                                rawWidth / this.state.timelinePixelsPerSecond,
                            },
                          });

                          if (direction === 'left') {
                            this.clipperMarkerRef.current.classList.remove(
                              'engage-right'
                            );
                            this.clipperMarkerRef.current.classList.add(
                              'engage-left'
                            );
                          } else {
                            // Mouse pointer is on the right side
                            this.clipperMarkerRef.current.classList.remove(
                              'engage-left'
                            );
                            this.clipperMarkerRef.current.classList.add(
                              'engage-right'
                            );
                          }

                          // Toggle on highlight color
                          if (
                            rawWidth <
                              this.state.clipper.maxDurationInPixels - 1 &&
                            rawWidth > this.state.clipper.minDurationInPixels
                          ) {
                            this.clipperMarkerRef.current.classList.add(
                              'highlight'
                            );
                          } else {
                            this.clipperMarkerRef.current.classList.remove(
                              'highlight'
                            );
                          }

                          // START ---
                          const beforeAndAfterOffsets =
                            Utils.calculateBeforeAndAfterOffsets(
                              this.state.timelineMidpoint,
                              position?.x,
                              rawWidth
                            );

                          if (!beforeAndAfterOffsets) {
                            return;
                          }

                          // Set new clip start and end times
                          const beforeAndAfterTimes =
                            Utils.getBeforeAndAfterUnixTimes(
                              Utils.getUnixDate(this.state.defaultActiveTime),
                              Math.ceil(
                                beforeAndAfterOffsets?.startOffset /
                                  this.state.timelinePixelsPerSecond
                              ),
                              beforeAndAfterOffsets?.shouldAddStartOffset,
                              Math.ceil(
                                beforeAndAfterOffsets?.endOffset /
                                  this.state.timelinePixelsPerSecond
                              ),
                              beforeAndAfterOffsets?.shouldAddEndOffset
                            );

                          if (!beforeAndAfterTimes) {
                            return;
                          }

                          if (direction === 'left') {
                            this.setState({
                              activeTime: Utils.getDate(
                                beforeAndAfterTimes.beforeUnixTime / 1000
                              ),
                            });
                          } else {
                            this.setState({
                              activeTime: Utils.getDate(
                                beforeAndAfterTimes.afterUnixTime / 1000
                              ),
                            });
                          }

                          // END ---
                        }}
                        onResizeStart={(e, direction, ref) => {
                          const rawWidth = ref?.style?.width?.split('px')[0];
                          const rawHeight = ref?.style?.height?.split('px');

                          const newClipperValues = {
                            clipper: {
                              ...this.state.clipper,
                              width: rawWidth,
                              height: rawHeight,
                              y: 0,
                            },
                          };

                          this.setState(newClipperValues);
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          const rawWidth = ref?.style?.width?.split('px')[0];
                          const rawHeight = ref?.style?.height?.split('px');

                          // Revert to default active time
                          this.setState({
                            clipper: {
                              ...this.state.clipper,
                              currentDurationInPixels: rawWidth,
                              currentDurationInSeconds:
                                rawWidth / this.state.timelinePixelsPerSecond,
                            },
                          });

                          // Toggle off highlight color
                          this.clipperMarkerRef.current.classList.remove(
                            'highlight'
                          );
                          this.clipperMarkerRef.current.classList.remove(
                            'engage-left'
                          );
                          this.clipperMarkerRef.current.classList.remove(
                            'engage-right'
                          );

                          const newClipperValues = {
                            clipper: {
                              ...this.state.clipper,
                              ...position,
                              width: rawWidth,
                              height: rawHeight,
                              y: 0,
                            },
                          };

                          this.setState(newClipperValues);

                          const beforeAndAfterOffsets =
                            Utils.calculateBeforeAndAfterOffsets(
                              this.state.timelineMidpoint,
                              position?.x,
                              rawWidth
                            );

                          if (!beforeAndAfterOffsets) {
                            return;
                          }

                          // Set new clip start and end times
                          const beforeAndAfterTimes =
                            Utils.getBeforeAndAfterUnixTimes(
                              Utils.getUnixDate(this.state.defaultActiveTime),
                              Math.ceil(
                                beforeAndAfterOffsets?.startOffset /
                                  this.state.timelinePixelsPerSecond
                              ),
                              beforeAndAfterOffsets?.shouldAddStartOffset,
                              Math.ceil(
                                beforeAndAfterOffsets?.endOffset /
                                  this.state.timelinePixelsPerSecond
                              ),
                              beforeAndAfterOffsets?.shouldAddEndOffset
                            );

                          if (!beforeAndAfterTimes) {
                            return;
                          }

                          this.setState({
                            clipStartTime: beforeAndAfterTimes.beforeUnixTime,
                            clipEndTime: beforeAndAfterTimes.afterUnixTime,
                          });
                        }}
                      >
                        <div
                          ref={this.clipperMarkerRef}
                          className="clipper-resizable-marker"
                        ></div>
                      </Rnd>
                    </div>
                  )}
              </>
            )}
          </div>
          {this.state.liveStreamMode && !this.state.showClipper && (
            <div className={`timeline-icons golive`}>
              <button onClick={() => this.onGoLive()} className="golive-button">
                {constants.TOP_HEADER_LIVE_NAV_TITLE}
              </button>
            </div>
          )}
        </div>
        {/* Modals */}
        {/* Create Clip */}
        <SiteModal
          key={'create-clip-modal'}
          classes="create-clip-modal"
          wrapperClass="create-clip-modal-wrapper"
          modalTitle={
            constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_CREATE_CLIP_BUTTON_LABEL
          }
          showModal={this.state.showCreateClipModal}
          hideModal={() => {
            this.setState({ showCreateClipModal: false });
            this.setState({ createClipRequestSubmitted: false });
          }}
          modalFooter={
            this.state.createClipRequestSubmitted === true ? (
              <>
                <div className="mb-1 create-clip-button-wrapper">
                  <PrimaryButton
                    className="create-clip-watch-clip-btn"
                    type="button"
                    // disabled={!this.state.canCreateClip}
                    onClick={(e) => {
                      e?.preventDefault();
                    }}
                  >
                    {
                      constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_WATCH_CLIP_BUTTON_LABEL
                    }
                  </PrimaryButton>
                </div>
                <div className="mb-1 create-clip-button-wrapper">
                  <Button
                    className="create-clip-close-btn"
                    variant="outline-secondary"
                    loader={this.state.confirmLoader}
                    disabled={!this.state.canCreateClip}
                    onClick={(e) => {
                      e?.preventDefault();
                      // this.handleCreateClip();
                    }}
                  >
                    {
                      constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_CLOSE_BUTTON_LABEL
                    }
                  </Button>
                </div>
              </>
            ) : (
              <div className="mb-1 create-clip-button-wrapper">
                <PrimaryButton
                  className="create-clip-save-btn"
                  type="button"
                  loader={this.state.confirmLoader}
                  disabled={!this.state.canCreateClip}
                  onClick={(e) => {
                    e?.preventDefault();
                    this.handleCreateClip();
                  }}
                >
                  {
                    constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_CREATE_CLIP_BUTTON_LABEL
                  }
                </PrimaryButton>
              </div>
            )
          }
        >
          <CreateClip
            cdnValue={this.props?.streaming?.cdnInfo}
            deviceId={this.props?.account?.deviceInformation?.deviceId}
            deviceTimezone={
              this.props?.account?.deviceInformation?.properties?.timezone
            }
            startTime={this.state.clipStartTime}
            endTime={this.state.clipEndTime}
            canSubmitEnabler={(value) =>
              this.setState({ canCreateClip: value })
            }
            shouldSubmitCreateClipRequest={
              this.state.shouldSubmitCreateClipRequest
            }
            callback={() => {
              this.setState({ createClipRequestSubmitted: true });
            }}
          />
        </SiteModal>
        {/* Add to Incident */}
        <SiteModal
          key={'add-to-incident-modal'}
          classes="add-to-incident-modal"
          wrapperClass="add-to-incident-modal-wrapper"
          modalTitle={constants.CAMERAS_LIVE_PLAYBACK_ADD_TO_INCIDENT_TEXT}
          showModal={this.state.showAddToIncidentModal}
          hideModal={() => {
            this.setState({ showAddToIncidentModal: false });
          }}
          modalFooter={
            <>
              <div className="mb-1 add-to-incident-button-wrapper">
                <PrimaryButton
                  className="add-to-incident-save-btn"
                  type="button"
                  loader={this.state.confirmLoader}
                  onClick={(e) => {
                    e?.preventDefault();
                    this.handleAddToIncident();
                  }}
                >
                  {
                    constants.CAMERAS_LIVE_ADD_TO_INCIDENT_MODAL_CONFIRM_BUTTON_LABEL
                  }
                </PrimaryButton>
              </div>
              <div className="add-to-incident-button-wrapper">
                <Button
                  className="add-to-incident-create-new-btn"
                  variant="outline-secondary"
                  onClick={(e) => {
                    // TODO: implement new incident functionality
                  }}
                >
                  {
                    constants.CAMERAS_LIVE_ADD_TO_INCIDENT_MODAL_CREATE_NEW_INCIDENT_BUTTON_LABEL
                  }
                </Button>
              </div>
            </>
          }
        >
          <div
            className={`add-to-incident-error ${
              !this.state.showAddToIncidentError ? 'hidden' : ''
            }`}
          >
            <span className="add-to-incident-error-message">
              {constants.CAMERAS_LIVE_ADD_TO_INCIDENT_MODAL_ERROR}
            </span>
          </div>
          <RadioGroup
            row
            aria-labelledby="add-to-incident-form-control-label-placement"
            name="incident"
            defaultValue={''}
            className="add-to-incident-radio-group"
            onChange={(e) => {
              if (!e) return;

              this.setState({ assignedIncidentId: e?.target?.value });
            }}
          >
            {Array.isArray(this.state.currentIncidents) &&
              this.state.currentIncidents.map((incident) => (
                <TextBlock
                  key={incident?.incidentId}
                  className="d-flex justify-content-between align-items-center add-to-incident-radio-row"
                >
                  <div className="add-to-incident-summary">
                    <HiOutlineFolderAdd className="add-to-incident-folder" />
                    <span className="add-to-incident-summary-title">
                      {incident?.summary}
                    </span>
                  </div>
                  <Radio
                    value={incident?.incidentId}
                    className="add-to-incident-radio"
                  />
                </TextBlock>
              ))}
          </RadioGroup>
        </SiteModal>
        {/* Add to Incident Confirmation */}
        <SiteModal
          key={'add-to-incident-confirmation-modal'}
          classes="add-to-incident-confirmation-modal"
          wrapperClass="add-to-incident-confirmation-modal-wrapper"
          modalTitle={
            constants.CAMERAS_LIVE_ADD_TO_INCIDENT_CONFIRMATION_MODAL_TITLE
          }
          showModal={this.state.showAddToIncidentConfirmationModal}
          hideModal={() => {
            this.setState({ showAddToIncidentConfirmationModal: false });
          }}
          modalFooter={
            <>
              <div className="mb-1 add-to-incident-confirmation-button-wrapper">
                <PrimaryButton
                  className="add-to-incident-confirmation-go-to-incident-btn"
                  type="button"
                  onClick={(e) => {
                    e?.preventDefault();
                    this.setState({ proceedToIncident: true });
                    this.setState({
                      showAddToIncidentConfirmationModal: false,
                    });
                  }}
                >
                  {
                    constants.CAMERAS_LIVE_ADD_TO_INCIDENT_CONFIRMATION_MODAL_GO_TO_INCIDENT_BUTTON_LABEL
                  }
                </PrimaryButton>
              </div>
              <div className="add-to-incident-confirmation-button-wrapper">
                <Button
                  className="add-to-incident-confirmation-back-to-video-btn"
                  variant="outline-secondary"
                  onClick={(e) => {
                    this.setState({
                      showAddToIncidentConfirmationModal: false,
                    });
                  }}
                >
                  {
                    constants.CAMERAS_LIVE_ADD_TO_INCIDENT_CONFIRMATION_MODAL_BACK_TO_VIDEO_BUTTON_LABEL
                  }
                </Button>
              </div>
            </>
          }
        >
          <div className="add-to-incident-confirmation-description">
            {
              constants.CAMERAS_LIVE_ADD_TO_INCIDENT_CONFIRMATION_MODAL_DESCRIPTION
            }
          </div>
          <div className="add-to-incident-confirmation-success-icon">
            <img src={success} alt="green circled check mark" />
          </div>
        </SiteModal>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setMetaData,
  setCDNInfo,
  setLiveStream,
  setRemoteStream,
  setLocalStream,
  setSnapshotImage,
};

const mapStoreStateToProps = (state) => ({
  account: state.accounts,
  streaming: state.streaming,
});

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(TimelineControls);
