import StepIndicator from '../StepIndicator';
import { Utils, constants } from '../../../../helpers';
import { useState, useRef, useEffect, useCallback } from 'react';
import { PrimaryButton } from '../../../../components/common';
import { useOrganizations } from '../../../../store/OrganizationsStore';
import { IoIosCheckbox } from 'react-icons/io';
import { TbDeviceMobile } from 'react-icons/tb';
import { HiOutlineMail } from 'react-icons/hi';
import { LuGlobe2 } from 'react-icons/lu';
import axios from 'axios';
import '../stepsnotification.scss';
import { useNotificationStore } from '../../../../store/CreateNotificationStore';
import { getCustomerOrgData } from '../../../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../../../store/indexDB/observer';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';

const Six = (props) => {
  const [selectedType, setSelectedType] = useState([]);
  const getStepsData = useNotificationStore((state) => state.getStepsData);
  const scollToRef = useRef();
  const [input, setInput] = useState(
    localStorage.getItem('titleNotification') != null &&
      localStorage.getItem('titleNotification') !== ''
      ? true
      : false
  );
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  // let custOrg = getCustomerOrgData();
  // const custOrgData = custOrg[0];
  const [custOrgData, setCustOrgData] = useState();
  const [notiTypeList, setNotiTypeList] = useState([
    {
      title: constants.NOTIFICATION_CREATE_STEP_SIX_EMAIL,
      desc: constants.NOTIFICATION_CREATE_STEP_SIX_EMAIL_DESC,
      isChecked: false,
      key: 'em',
      icon: <HiOutlineMail size={22} />,
    },
    {
      title: constants.NOTIFICATION_CREATE_STEP_SIX_PUSH,
      desc: constants.NOTIFICATION_CREATE_STEP_SIX_PUSH_DESC,
      isChecked: false,
      key: 'pn',
      icon: <TbDeviceMobile size={22} />,
    },
    {
      title: constants.NOTIFICATION_CREATE_STEP_SIX_WEB,
      desc: constants.NOTIFICATION_CREATE_STEP_SIX_WEB_DESC,
      isChecked: false,
      key: 'wb',
      isDisabled: true,
      icon: <LuGlobe2 size={22} />,
    },
  ]);

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustOrgData(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    setTimeout(() => {
      scollToRef?.current?.scrollIntoView();
    }, 100);
  }, []);

  useEffect(() => {
    loadTypeFromStore();
  }, []);

  const loadTypeFromStore = async () => {
    if (getStepsData()?.actions?.length > 0) {
      let typeList = notiTypeList?.map((events, index) => {
        const typeFound = getStepsData()?.actions?.find(
          (item) => item === events?.key
        );
        if (typeFound) {
          return { ...events, isChecked: true };
        } else {
          return { ...events };
        }
      });
      let list = await Promise.all(typeList);
      setNotiTypeList(list);
      const selectedTypes = list.filter((type) => type?.isChecked);
      setSelectedType(selectedTypes);
    }
  };

  const onHandleChange = async (e, item) => {
    if (e != undefined) {
      let typeList = notiTypeList?.map((events, index) => {
        if (events?.key === item?.key) {
          if (events?.isChecked) {
            return { ...events, isChecked: false };
          } else {
            return { ...events, isChecked: true };
          }
        } else {
          return { ...events };
        }
      });
      let list = await Promise.all(typeList);
      setNotiTypeList(list);
      const selectedTypes = list.filter((type) => type?.isChecked);
      setSelectedType(selectedTypes);
    }
  };

  const createNotification = () => {
    const types = [];
    const selectedTypes = notiTypeList.filter((type) => type?.isChecked);
    selectedTypes?.forEach((item, index) => {
      types.push(item?.key);
    });
    if (!types?.includes(notiTypeList?.[2]?.key)) {
      types.push(notiTypeList?.[2]?.key);
    }
    const rule = {
      rules: [
        {
          actions: types,
          ...(props?.isEdit
            ? getStepsData()?.conditions?.length > 0 && {
                conditions: getStepsData()?.conditions,
              }
            : props?.stepData?.conditions?.length > 0 && {
                conditions: props?.stepData?.conditions,
              }),
          devices: props?.isEdit
            ? getStepsData()?.devices
            : props?.stepData?.devices,
          triggers: props?.isEdit
            ? getStepsData()?.triggers
            : props?.stepData?.triggers,
          subscribers: props?.isEdit
            ? getStepsData()?.subscribers
            : props?.stepData?.subscribers,
          name: localStorage.getItem('titleNotification'),
          ruleId: props?.isEdit ? getStepsData()?.editItem?.ruleId : '',
        },
      ],
    };
    createNotidicationCall(rule);
  };

  const createNotidicationCall = async (rule) => {
    const url = `partner/orgs/${custOrgData?.orgId}/rules`;
    const method = props?.isEdit ? 'put' : 'post';
    const response = await axios[method](url, rule, Utils.requestHeader());
    if (response?.data?.meta?.code === 200) {
      props.hideModal();
    }
  };

  const titleUpdate = (input = '') => {
    if (input !== '') {
      setInput(true);
    } else {
      setInput(false);
    }
  };

  return (
    <div ref={scollToRef} className="create-notification">
      <StepIndicator
        stepSix={true}
        onInputChange={(input) => {
          titleUpdate(input);
        }}
        {...props}
      />
      <div className="notification-type-step-six">
        <div className="step-title">
          {constants.NOTIFICATION_CREATE_NOTIFICATION_TYPE}
        </div>
        <div className="step-title-desc">
          {constants.NOTIFICATION_CREATE_NOTIFICATION_TYPE_DESC}
        </div>
        {notiTypeList?.map((item, index) => {
          return (
            <div key={item?.type} className="noti-type-conatiner">
              <div className="left-container">
                {item?.icon}
                {/* <img className="event-icon" alt="" src={item.icon}></img> */}
                <div className="title-discription-conatiner">
                  <div className="event-title">{item.title}</div>
                  <div className="event-discription">{item.desc}</div>
                </div>
              </div>

              {item?.isDisabled ? (
                // <img className="event-icon" alt="" src={webcheck}></img>
                <IoIosCheckbox
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--greyscale_56')}
                  size={28}
                />
              ) : (
                <input
                  checked={item.isChecked}
                  name={item.title}
                  className={`input-check-events`}
                  type="checkbox"
                  onChange={(e) => onHandleChange(e, item)}
                />
              )}
            </div>
          );
        })}

        <PrimaryButton
          className="adddevice-btn"
          fontSize="14px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          height="44px"
          color="#FFFFFF"
          type="submit"
          disabled={selectedType?.length <= 0 || !input}
          onClick={() => createNotification()}
        >
          {props?.isEdit
            ? constants.EDIT_CUSTOMER_ORG
            : constants.NOTIFICATION_CREATE_BTN}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default Six;
