import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import StickyBox from 'react-sticky-box';
import { PrimaryButton, SiteModal, SiteToast } from '../../components/common';
import InfiniteScroll from 'react-infinite-scroller';
import { Utils } from '../../helpers';
import { useSelector } from 'react-redux';
import { getCDNInfo } from '../../store/reducers/StreamingReducer';
import { getDeviceInformation } from '../../store/reducers/AccountReducer';
import { filter } from '../../assets/images';
import { useForm } from 'react-hook-form';
import useEventsStore from '../../store/EventsStore';
import DatePicker from 'react-multi-date-picker';
import Icon from 'react-multi-date-picker/components/icon';
import EventsWrapper from './components/EventsWrapper';

import './EventsList.scss';
import {
  getLatestEventsForDots,
  getMetaDataForEvents,
} from '../../store/reducers/EventsReducer';

const EventsList = ({ deviceId, listHeight }) => {
  const itemsPerPage = 20;
  const [hasMoreItems, sethasMoreItems] = useState(true);
  const [records, setRecords] = useState(itemsPerPage);
  const [eventsData, setEventsData] = useState([]);
  const [updateLiveEvents, setUpdateLiveEvents] = useState(true);
  const device = useSelector(getDeviceInformation);
  const cdnValue = useSelector(getCDNInfo);
  const [category, setCategory] = useState(['person', 'vehicle']);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [holdEventsData, setHoldEventsData] = useState([]);
  const categories = ['person', 'vehicle'];
  const {
    getEvents,
    setEvents,
    removeEvents,
    setEventCategory,
    setSelectedEventStore,
    getSelectedEvent,
  } = useEventsStore();
  const [filterDate, setFilterDate] = useState(new Date());
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [timezone, setTimezone] = useState(null);

  const eventsLiveData = useSelector(getLatestEventsForDots);
  const deviceLiveMetaData = useSelector(getMetaDataForEvents);

  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: { category: category },
    mode: 'onChange',
  });

  const deviceTimezone = useEventsStore((state) => state.deviceTimezone);

  const onSubmit = (data) => {
    setCategory(data?.category);
    setEventCategory(data?.category);
    setShowCategoryModal(false);
  };

  useEffect(() => {
    setEventsData([]);
    removeEvents();
  }, [deviceId]);

  useEffect(() => {
    if (Array.isArray(eventsLiveData)) {
      const output = eventsLiveData?.map((item) => ({
        eventMeta: item.eventMeta,
        deviceId: item.src?.srcId,
        eventTimestamp: Number(item.t),
      }));
      setHoldEventsData([...holdEventsData, ...output]);
    }
  }, [eventsLiveData]);

  useEffect(() => {
    if (deviceLiveMetaData !== null && updateLiveEvents) {
      const newObj = {
        start: deviceLiveMetaData.start * 1000,
        end: deviceLiveMetaData.end * 1000,
      };

      const newArray = getAgesWithGreaterDifference(
        holdEventsData,
        newObj?.end
      );
      setHoldEventsData([]);
      if (newArray[0]?.deviceId) {
        setEventsData([...eventsData, ...newArray]);
      }
    }
  }, [deviceLiveMetaData]);

  const getAgesWithGreaterDifference = (arr, endTime) => {
    // Sort the array based on age in ascending order
    arr.sort((a, b) => b.eventTimestamp - a.eventTimestamp);

    // Initialize the result array with the first object
    const result = [arr[0]];

    // Iterate over the remaining objects
    for (let i = 1; i < arr.length; i++) {
      const currentAge = arr[i].eventTimestamp;
      const previousAge = result[result.length - 1].eventTimestamp;

      // Check if the difference is greater than or equal to 3
      if (currentAge < endTime) {
        result.push(arr[i]);
      }
    }

    return result;
  };

  useEffect(() => {
    const endTime = Utils.getUnixDate(
      moment(new Date()).subtract({ hours: 0, minutes: 0 })
    );

    setSelectedEventStore(null);

    setEventsData(getEvents());

    setEventCategory(category);

    if (endTime && device?.orgId && deviceId) {
      setEvents(
        `timeline/orgs/${device?.orgId}/events/all?endTime=${endTime}000&size=20&deviceIds=${deviceId}&eventClasses=vehicle&eventClasses=person&ascOrder=false`
      );
    }
  }, [device?.orgId, deviceId]);

  useEffect(() => {
    if (getEvents() && getEvents().length > 0) {
      setEventsData(getEvents());
    }
  }, [getEvents()]);

  useEffect(() => {
    setTimezone(deviceTimezone);
  }, [deviceTimezone]);

  const fetchRecords = (endTime) => {
    if (device?.orgId && deviceId) {
      axios
        .get(
          `timeline/orgs/${device?.orgId}/events/all?endTime=${endTime}&size=20&deviceIds=${deviceId}&eventClasses=vehicle&eventClasses=person&ascOrder=false`, Utils.requestHeader()
        )
        .then((response) => {
          if (response?.data?.data) {
            setEventsData([...eventsData, ...response?.data?.data?.events]);
          }
        });
    }
  };

  const fetchRecordsViaCalendar = (endTime) => {
    setShowToast(false);
    if (device?.orgId && deviceId) {
      axios
        .get(
          `timeline/orgs/${device?.orgId}/events/all?endTime=${endTime}&size=20&deviceIds=${deviceId}&eventClasses=vehicle&eventClasses=person&ascOrder=false`, Utils.requestHeader()
        )
        .then((response) => {
          if (response?.data?.data && response?.data?.data?.events?.length) {
            setEventsData(response?.data?.data?.events);
          } else {
            //Todo: delete later
            //setUserMsg('No events found during selected date');
            // setShowToast(true);
          }
        });
    }
  };

  useEffect(() => {
    if (
      moment(new Date(filterDate)).format('yyyy-MM-dd') !==
      moment(new Date()).format('yyyy-MM-dd')
    ) {
      console.log('fetchRecordsViaCalendar today');
      setUpdateLiveEvents(false);
    } else {
      setUpdateLiveEvents(true);
    }

    const endTime = Utils.getUnixDate(
      new Date(moment(new Date(filterDate)).add({ days: 1 })).toDateString(
        'yyyy-MM-dd 0:0:0.000'
      )
    );
    if (endTime) {
      fetchRecordsViaCalendar(endTime * 1000);
    }
  }, [filterDate]);

  const massagedData = (data) => {
    let finalArray = [];
    data.map((event) => {
      const time = getActualDate(event?.eventTimestamp);
      const index = finalArray.findIndex((e) => e.eventTimestamp === time);
      if (index > -1) {
        finalArray[index]?.events.push({ ...event });
      } else {
        let eventObject = {
          eventTimestamp: '',
          events: [],
        };
        eventObject.eventTimestamp = time;
        eventObject.events.push(event);
        finalArray.push(eventObject);
      }
    });
    return finalArray;
  };

  const formattedDate = (time) => {
    if (time === moment(new Date()).format('MMM DD, YYYY')) {
      return 'Today';
    } else if (
      time === moment(new Date()).subtract({ days: 1 }).format('MMM DD, YYYY')
    ) {
      return 'Yesterday';
    } else {
      return time;
    }
  };

  const showItems = (posts) => {
    const updatedData = massagedData(posts);
    if (
      updatedData.length &&
      updatedData[0].eventTimestamp !== 'Invalid date'
    ) {
      return (
        <div key={`items-${deviceId}`} className="events-list">
          <div className="fixed-icons-wrapper">
            <div className="filterDate-image">
              <DatePicker
                render={<Icon width={24} height={24} />}
                value={filterDate}
                onChange={setFilterDate}
                minDate={new Date(moment(new Date()).subtract({ days: 30 }))}
                maxDate={new Date()}
              />
            </div>
            <div
              className="filter-image"
              onClick={() => setShowCategoryModal(true)}
            >
              <img src={filter} height="24" width="24" />
            </div>
          </div>
          {updatedData?.map((event, i) => (
            <div
              key={`${deviceId}-${i}-${category}-${event?.eventTimestamp}`}
              className="events-all-category-area"
            >
              <StickyBox offsetTop={0}>
                <div className="sticky-header">
                  <div className="header">
                    {event?.eventTimestamp !== 'Invalid date'
                      ? formattedDate(event?.eventTimestamp)
                      : ''}
                  </div>
                </div>
              </StickyBox>
              <div className="events-all-category-wrapper">
                <EventsWrapper
                  data={event}
                  category={category}
                  deviceId={deviceId}
                  cdnValue={cdnValue}
                  timezone={timezone}
                  selectedEvent={getSelectedEvent()}
                  handleOnClick={(timestamp) => {
                    setSelectedEventStore(timestamp);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  const loadMore = () => {
    if (
      eventsData[eventsData.length - 1]?.eventTimestamp <
      Utils.getUnixDate(moment(new Date()).subtract({ days: 30 })) * 1000
    ) {
      sethasMoreItems(false);
    } else {
      if (
        eventsData[eventsData.length - 1]?.eventTimestamp &&
        eventsData.length > 15
      ) {
        fetchRecords(eventsData[eventsData.length - 1]?.eventTimestamp);
      }
    }
  };

  const getActualDate = (date) => {
    const time = Utils.getDate(date / 1000);
    const actualTime = moment(time).format('MMM DD, YYYY');
    return actualTime;
  };

  return (
    <div key={`events-list-${deviceId}`}>
      <div className="scroll-container" style={{ height: listHeight }}>
        {Array.isArray(eventsData) && eventsData.length > 0 && (
          <InfiniteScroll
            key={`infinite-scroll-${deviceId}`}
            loadMore={loadMore}
            hasMore={hasMoreItems}
            loader={
              <div
                key={`infinite-scroll-loader-${deviceId}`}
                className="loader"
              ></div>
            }
            useWindow={false}
          >
            {showItems(eventsData, records)}
          </InfiniteScroll>
        )}
      </div>
      <SiteModal
        modalTitle="Category"
        showModal={showCategoryModal}
        hideModal={() => {
          setShowCategoryModal(false);
          reset({ category: category });
        }}
        classes="categories-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {categories?.map((cat, catIndex) => (
            <div
              key={`category-${catIndex}`}
              className="category-wrapper-input"
            >
              <label>
                <input
                  name="category"
                  type="checkbox"
                  value={cat}
                  {...register('category', { required: true })}
                />
                {cat}
              </label>
            </div>
          ))}
          <div>
            <PrimaryButton
              className="btn btn-primary mt-4"
              type="submit"
              width="100%"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              disabled={!formState.isValid}
            >
              Apply Filters
            </PrimaryButton>
          </div>
        </form>
      </SiteModal>
      <SiteToast
        customCss="qr-toast"
        position="top-end"
        show={showToast}
        title="Uh-oh!"
        body={userMsg}
        delay={5000}
      />
    </div>
  );
};

export default EventsList;
