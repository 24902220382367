import React, { createContext } from "react";

export const DataContext = createContext(null);

const DataContextProvider = ({ children }) => {

  return (
    <DataContext.Provider>
      {children}
    </DataContext.Provider>
  );
};

export default DataContextProvider;