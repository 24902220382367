import { useState, useEffect } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { BsFillPeopleFill, BsPerson } from 'react-icons/bs';
import { RiErrorWarningLine } from 'react-icons/ri';
import { FaCarSide } from 'react-icons/fa';
import { AiOutlinePlus } from 'react-icons/ai';
import { BiMale } from 'react-icons/bi';
import { GiBilledCap } from 'react-icons/gi';
import { IoCarSportOutline, IoBicycleOutline } from 'react-icons/io5';
import { BsHandbag } from 'react-icons/bs';
import { FiTruck } from 'react-icons/fi';
import { TbFaceMask, TbMotorbike, TbBus } from 'react-icons/tb';
import { FaFemale, FaGlasses } from 'react-icons/fa';
import { PrimaryButton, SiteModal } from '../../components/common';
import { Col, Row } from 'react-bootstrap';
import { ToggleInput } from '../../components/forms';
import { AppDefaults, constants, Utils } from '../../helpers';
import { TagStore } from '../../store/TagsStore';
import './tags.scss';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { moveArrayPosition } from '../../helpers/commonUtils';

const Tags = ({ tagsDetailList, updateTags, fillterByTag, custOrgData }) => {
  const setTagsData = TagStore((state) => state.setTagsData);
  const getTagsList = TagStore((state) => state.getTagsList);
  const getSelectedTags = TagStore((state) => state.getSelectedTags);
  const setSelectedTags = TagStore((state) => state.setSelectedTags);
  const [showAddTagModal, setShowAddTagModal] = useState(false);
  const [personSearch, setPersonSearch] = useState(false);
  const [vehicleSearch, setVehicleSearch] = useState(false);
  const [showFaces, setShowFaces] = useState(false);
  const [showLicensePlate, setShowLicensePlate] = useState(false);
  const [update, setUpdate] = useState(false);
  const [young, setYoung] = useState(false);
  const [adult, setAdult] = useState(false);
  const [middle, setMiddle] = useState(false);
  const [senior, setSenior] = useState(false);
  const [male, setMale] = useState(false);
  const [female, setFemale] = useState(false);
  const [hat, setHat] = useState(false);
  const [bag, setBag] = useState(false);
  const [optical, setOptical] = useState(false);
  const [mask, setMask] = useState(false);
  const [car, setCar] = useState(false);
  const [bus, setBus] = useState(false);
  const [truck, setTruck] = useState(false);
  const [moterCycle, setMoterCycle] = useState(false);
  const [bicycle, setBicycle] = useState(false);
  const [vehicleLimitExceed, setVehicleLimitExceed] = useState(false);
  const [topLimitExceed, setTopLimitExceed] = useState(false);
  const [bottomLimitExceed, setBottomLimitExceed] = useState(false);
  const [topColorList, setTopColorList] = useState([]);
  const [bottomColorList, setBottomColorList] = useState([]);
  const [vehicleColorList, setVehicleColorList] = useState([]);
  const addTagCat = 'addtag';
  const tagList = getTagsList();
  const deviceProperty = tagList?.find(
    (device) => device.category === addTagCat
  );
  if (!deviceProperty) {
    getTagsList().push({ category: addTagCat, text: 'Add Tags' });
  }
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const { getSelectedLocation } = useCustomerOrgLocations();
  const selectedLocation = getSelectedLocation(loggedInUserData?.accountId);

  useEffect(() => {
    const tagListIndex = tagsDetailList.findIndex(
      (data) => data.category === data.text
    );
    if (tagListIndex !== -1) {
      moveArrayPosition(tagsDetailList, tagListIndex, 0);
    }
  }, [tagsDetailList]);

  const selectedData = {
    person: false,
    face: false,
    male: false,
    female: false,
    hat: false,
    bag: false,
    pantsColors: Utils.getCommonColor([], 'bottom', 1),
    shirtsColors: Utils.getCommonColor([], 'top', 2),
    optical: false,
    mask: false,
    young: false,
    adult: false,
    middle: false,
    senior: false,
    vehicle: false,
    licensePlate: false,
    car: false,
    bus: false,
    bicycle: false,
    motorcycle: false,
    truck: false,
    extColors: Utils.getCommonColor([], '', 3),
  };
  const handleScroll = (event) => {
    const container = event.target;
    const scrollAmount = event.deltaY;
    container.scrollTo({
      top: 0,
      left: container.scrollLeft + scrollAmount,
      behavior: 'smooth',
    });
  };

  const addTags = (item) => {
    if (item.category === addTagCat) {
      setShowAddTagModal(true);
      setUpdate(!update);
    }
  };

  const setInitialData = () => {
    const tagData = Object.keys(getSelectedTags()).length !== 0;
    const requestTag = tagData ? getSelectedTags() : selectedData;
    setPersonSearch(requestTag?.person || requestTag?.face);
    setShowFaces(requestTag?.face);
    setVehicleSearch(requestTag?.vehicle);
    setShowLicensePlate(requestTag?.licensePlate);
    setYoung(requestTag?.young);
    setMiddle(requestTag?.middle);
    setAdult(requestTag?.adult);
    setSenior(requestTag?.senior);
    setMale(requestTag?.male);
    setFemale(requestTag?.female);
    setHat(requestTag?.hat);
    setBag(requestTag?.bag);
    setOptical(requestTag?.optical);
    setMask(requestTag?.mask);
    setCar(requestTag?.car);
    setBus(requestTag?.bus);
    setTruck(requestTag?.truck);
    setBicycle(requestTag?.bicycle);
    setMoterCycle(requestTag?.motorcycle);
    setTopColorList(requestTag?.shirtsColors);
    setBottomColorList(requestTag?.pantsColors);
    setVehicleColorList(requestTag?.extColors);
    setBottomLimitExceed(false);
    setTopLimitExceed(false);
    setVehicleLimitExceed(false);
  };

  useEffect(() => {
    setInitialData();
  }, [update]);

  const colorCount = (colorList) => {
    let count = 0;
    colorList?.map((data, i) => {
      if (data.isSelected) {
        count = count + 1;
      }
    });
    return count;
  };

  const selectColor = (colorItem, listColor, type) => {
    const colorList = [...listColor];
    colorList?.map((data, i) => {
      if (colorItem.id == data.id) {
        if (data.isSelected) {
          colorList[i].isSelected = false;
        } else {
          if (colorCount(colorList) < 3) {
            colorList[i].isSelected = true;
          }
        }
      }
    });

    switch (type) {
      case 0:
        if (colorCount(colorList) >= 3) {
          setBottomLimitExceed(true);
        } else {
          setBottomLimitExceed(false);
          setBottomColorList(colorList);
        }
        break;
      case 1:
        if (colorCount(colorList) >= 3) {
          setTopLimitExceed(true);
        } else {
          setTopLimitExceed(false);
          setTopColorList(colorList);
        }
        break;
      case 2:
        if (colorCount(colorList) >= 3) {
          setVehicleLimitExceed(true);
        } else {
          setVehicleLimitExceed(false);
          setVehicleColorList(colorList);
        }
        break;
    }
  };

  const getSelectedColor = (list) => {
    const selectedColorList = [];
    list?.map((data, i) => {
      if (data.isSelected) {
        selectedColorList.push(data.name);
      }
    });
    return selectedColorList;
  };
  const getSelectedBody = (list) => {
    const selectedColorList = [];
    list?.map((data, i) => {
      if (data.isSelectedBody) {
        selectedColorList.push(data.name);
      }
    });
    return selectedColorList;
  };
  const applyTag = () => {
    if (colorCount(bottomColorList) >= 3) {
      setBottomLimitExceed(true);
      return;
    } else if (colorCount(topColorList) >= 3) {
      setTopLimitExceed(true);
      return;
    } else if (colorCount(vehicleColorList) >= 3) {
      setVehicleLimitExceed(true);
      return;
    }
    const tags = {};
    const person = {};
    const face = {};
    if (personSearch || showFaces) {
      if (!showFaces) {
        selectedData.person = true;
        selectedData.face = false;
        const gender = [];
        const accessories = [];

        if (male) {
          selectedData.male = true;
          gender.push('male');
        }
        if (female) {
          selectedData.female = true;
          gender.push('female');
        }
        if (hat) {
          selectedData.hat = true;
          accessories.push('hat');
        }
        if (bag) {
          selectedData.bag = true;
          accessories.push('bag');
        }
        if (gender.length === 1) {
          person.genders = gender;
        }
        if (accessories.length > 0) {
          person.accessories = accessories;
        }
        if (getSelectedColor(bottomColorList).length > 0) {
          person.pantsColors = getSelectedColor(bottomColorList);
          selectedData.pantsColors = bottomColorList;
        }
        if (getSelectedColor(topColorList).length > 0) {
          person.shirtsColors = getSelectedColor(topColorList);
          selectedData.shirtsColors = topColorList;
        }
        tags.person = person;
      } else {
        selectedData.person = true;
        selectedData.face = true;
        const accessories = [];
        const age = [];

        if (optical) {
          selectedData.optical = true;
          accessories.push('glasses');
        }
        if (mask) {
          selectedData.mask = true;
          accessories.push('mask');
        }
        if (accessories.length > 0) {
          face.accessories = accessories;
        }
        if (young) {
          selectedData.young = true;
          age.push('young');
        }
        if (adult) {
          selectedData.adult = true;
          age.push('adult');
        }
        if (middle) {
          selectedData.middle = true;
          age.push('middle');
        }
        if (senior) {
          selectedData.senior = true;
          age.push('senior');
        }
        if (age.length > 0) {
          face.ages = age;
        }
        if (personSearch) {
          tags.face = face;
        }
      }
    }
    if (vehicleSearch) {
      selectedData.vehicle = true;
      const vehicle = {};
      if (!showLicensePlate) {
        selectedData.licensePlate = false;
        const type = [];
        if (car) {
          selectedData.car = true;
          type.push('car');
        }
        if (bus) {
          selectedData.bus = true;
          type.push('bus');
        }
        if (truck) {
          selectedData.truck = true;
          type.push('truck');
        }
        if (bicycle) {
          selectedData.bicycle = true;
          type.push('bicycle');
        }
        if (moterCycle) {
          selectedData.motorcycle = true;
          type.push('motorcycle');
        }
        if (getSelectedColor(vehicleColorList).length > 0) {
          selectedData.extColors = vehicleColorList;
          vehicle.extColors = getSelectedColor(vehicleColorList);
        }
        if (type.length > 0) {
          vehicle.types = type;
        }
        tags.vehicle = vehicle;
      } else {
        selectedData.licensePlate = true;
      }
      tags.vehicle = vehicle;
      tags.licensePlate = showLicensePlate;
    }
    const tagsParent = {
      deviceIds: getDeviceId(),
    };
    if (Object.keys(tags).length !== 0) {
      tagsParent.tags = tags;
    }
    setTagsData(tagsParent);
    setSelectedTags(selectedData);
    fillterByTag(tagsParent);
    setShowAddTagModal(false);
  };

  const getDeviceId = () => {
    let deviceIds = [];
    const orgDevice = custOrgData?.devices;
    const location = selectedLocation;
    if (location) {
      const devicesList =
        location?.locationId === AppDefaults.ALL_LOCATIONS_ID
          ? orgDevice
          : orgDevice?.filter(
              (device) => device.locationId === location?.locationId
            );
      devicesList && devicesList.length > 0
        ? devicesList?.forEach((device, i) => {
            deviceIds.push(device.deviceId);
          })
        : (deviceIds = []);
      return deviceIds;
    } else {
      orgDevice &&
        orgDevice.length > 0 &&
        orgDevice?.forEach((device, i) => {
          deviceIds.push(device.deviceId);
        });
      return deviceIds;
    }
  };

  const removeTag = (item) => {
    var index = 0;
    const tagList = [...getTagsList()];
    const tagListText = [];
    for (var itemTags of tagList) {
      if (itemTags.text == item.text) {
        tagList.splice(index, 1);
      }
      index++;
    }
    tagList?.map((data, i) => {
      tagListText.push(data.text);
    });

    let personStatus = searchCategoryByName(
      Utils.getCategoryName().PERSON,
      tagListText
    );
    let faceStatus = searchCategoryByName(
      Utils.getCategoryName().FACE,
      tagListText
    );
    let vehicleStatus = searchCategoryByName(
      Utils.getCategoryName().VEHICLE,
      tagListText
    );
    let lisenceStatus = searchCategoryByName(
      Utils.getCategoryName().LICENSEPLATE,
      tagListText
    );
    let youngStatus = searchCategoryByName(
      Utils.getCategoryText().YOUNG,
      tagListText
    );
    let middleStatus = searchCategoryByName(
      Utils.getCategoryText().MIDDLE,
      tagListText
    );
    let adultStatus = searchCategoryByName(
      Utils.getCategoryText().ADUlT,
      tagListText
    );
    let seniorStatus = searchCategoryByName(
      Utils.getCategoryText().SENIOR,
      tagListText
    );
    let opticalStatus = searchCategoryByName(
      Utils.getCategoryText().OPTICAL,
      tagListText
    );
    let maskStatus = searchCategoryByName(
      Utils.getCategoryText().MASK,
      tagListText
    );
    let maleStatus = searchCategoryByName(
      Utils.getCategoryText().MALE,
      tagListText
    );
    let femaleStatus = searchCategoryByName(
      Utils.getCategoryText().FEMALE,
      tagListText
    );
    let hatStatus = searchCategoryByName(
      Utils.getCategoryText().HAT,
      tagListText
    );
    let bagStatus = searchCategoryByName(
      Utils.getCategoryText().BAG,
      tagListText
    );
    let carStatus = searchCategoryByName(
      Utils.getCategoryText().CAR,
      tagListText
    );
    let truckStatus = searchCategoryByName(
      Utils.getCategoryText().TRUCK,
      tagListText
    );
    let moterCycleStatus = searchCategoryByName(
      Utils.getCategoryText().MOTERCYCLE,
      tagListText
    );
    let bicycleStatus = searchCategoryByName(
      Utils.getCategoryText().BICYCLE,
      tagListText
    );
    let busStatus = searchCategoryByName(
      Utils.getCategoryText().BUS,
      tagListText
    );
    let topColorListStatus = Utils.getCommonColor(tagListText, 'top', 2);
    let bottomColorListStatus = Utils.getCommonColor(tagListText, 'bottom', 1);
    let vehicleColorListStatus = Utils.getCommonColor(
      tagListText,
      'vehicletype',
      3
    );
    const tags = {};
    const person = {};
    const face = {};
    if (personStatus || faceStatus) {
      if (!faceStatus) {
        const gender = [];
        const accessories = [];
        if (maleStatus) {
          gender.push('male');
        }
        if (femaleStatus) {
          gender.push('female');
        }
        if (hatStatus) {
          accessories.push('hat');
        }
        if (bagStatus) {
          accessories.push('bag');
        }
        if (gender.length > 0) {
          person.genders = gender;
        }
        if (accessories.length > 0) {
          person.accessories = accessories;
        }
        if (getSelectedBody(bottomColorListStatus).length > 0) {
          person.pantsColors = getSelectedBody(bottomColorListStatus);
        }
        if (getSelectedBody(topColorListStatus).length > 0) {
          person.shirtsColors = getSelectedBody(topColorListStatus);
        }
        tags.person = person;
      } else {
        const accessories = [];
        const age = [];

        if (opticalStatus) {
          accessories.push('glasses');
        }
        if (maskStatus) {
          accessories.push('mask');
        }
        if (accessories.length > 0) {
          face.accessories = accessories;
        }
        if (youngStatus) {
          age.push('young');
        }
        if (adultStatus) {
          age.push('adult');
        }
        if (middleStatus) {
          age.push('middle');
        }
        if (seniorStatus) {
          age.push('senior');
        }
        if (age.length > 0) {
          face.ages = age;
        }
        tags.face = face;
      }
    }
    if (vehicleStatus) {
      const vehicle = {};
      if (!lisenceStatus) {
        const type = [];
        if (carStatus) {
          type.push('car');
        }
        if (busStatus) {
          type.push('bus');
        }
        if (truckStatus) {
          type.push('truck');
        }
        if (bicycleStatus) {
          type.push('bicycle');
        }
        if (moterCycleStatus) {
          type.push('motorcycle');
        }
        if (getSelectedBody(vehicleColorListStatus).length > 0) {
          vehicle.extColors = getSelectedBody(vehicleColorListStatus);
        }
        if (type.length > 0) {
          vehicle.types = type;
        }
        tags.vehicle = vehicle;
      }
      tags.vehicle = vehicle;
      tags.licensePlate = lisenceStatus;
    }
    const tagsParent = {
      deviceIds: getDeviceId(),
    };
    tagsParent.tags = tags;
    updateTags(tagList);
    setTagsData(tagsParent);
    fillterByTag(tagsParent);
  };

  const searchCategoryByName = (category, tagListText) => {
    let isCatExist = false;
    if (tagListText && tagListText.length > 0) {
      if (tagListText.includes(category)) {
        isCatExist = true;
      }
    }
    return isCatExist;
  };

  return (
    <div>
      <SiteModal
        modalTitle={constants.SEARCH_ALL_TAGS}
        showModal={showAddTagModal}
        hideModal={() => setShowAddTagModal(false)}
      >
        <div className="personal-parent-container">
          <div className="person-search">
            <div className="left-container">
              <BsPerson
                className="icon-person"
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_black')}
              ></BsPerson>
              <div className="search-person-container">
                <div className="search-by-person">
                  {constants.SEARCH_TAGS_BY_PERSON}
                </div>
                <div className="select-more-text">
                  {constants.SEARCH_TAGS_SELECT_ONE_MORE}
                </div>
              </div>
            </div>
            <ToggleInput
              label={''}
              name="person"
              value={personSearch}
              labelColor={true}
              changeHandler={(e) => {
                setPersonSearch(!!!JSON.parse(e?.target?.value));
              }}
            />
          </div>
          {personSearch && (
            <div className="expanded-container">
              <div className="parent-faces-container">
                <div className="face-toggle">
                  <div className="left-container">
                    <div className="face-icon">
                      <BsPerson height="10px" width="10px" />
                    </div>
                    <div className="container-text ">
                      <div className="show-faces-label">
                        {constants.SEARCH_SHOW_FACES}
                      </div>
                      <div className="warning-message">
                        <div className="icon-info">
                          <RiErrorWarningLine height="10px" width="10px" />
                        </div>
                        <div className="info-message">
                          {constants.SEARCH_ENABLING_THIS_FEATURE}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right-container">
                    <ToggleInput
                      label={''}
                      name="person"
                      value={showFaces}
                      labelColor={true}
                      changeHandler={(e) => {
                        setShowFaces(!!!JSON.parse(e?.target?.value));
                      }}
                    />
                  </div>
                </div>
                {showFaces && (
                  <div>
                    <div className="text-label-accessories">
                      {constants.SEARCH_AGE}
                    </div>
                    <div className="age-filter-box ">
                      <div className="left-container-age">
                        <div
                          onClick={(e) => setYoung(!young)}
                          className={`young-layout ${young ? 'selected' : ''}`}
                        >
                          {constants.SEARCH_YOUNG}
                        </div>
                        <div
                          onClick={(e) => setMiddle(!middle)}
                          className={`middle-age-layout ${
                            middle ? 'selected' : ''
                          }`}
                        >
                          {constants.SEARCH_MIDDLE}
                        </div>
                      </div>
                      <div className="left-container-age">
                        <div
                          onClick={(e) => setAdult(!adult)}
                          className={`young-layout ${adult ? 'selected' : ''}`}
                        >
                          {constants.SEARCH_ADULT}
                        </div>
                        <div
                          onClick={(e) => setSenior(!senior)}
                          className={`middle-age-layout ${
                            senior ? 'selected' : ''
                          }`}
                        >
                          {constants.SEARCH_SENIOR}
                        </div>
                      </div>
                    </div>
                    <div className="text-label-accessories">
                      {constants.SEARCH_ACCESSORIES}
                    </div>
                    <div className="accessories-container">
                      <div
                        onClick={(e) => setOptical(!optical)}
                        className={`glass-layout ${optical ? 'selected' : ''}`}
                      >
                        <FaGlasses
                          className={`icon-mf ${optical ? 'selected' : ''}`}
                        />
                        {constants.SEARCH_GLASSES}
                      </div>
                      <div
                        onClick={(e) => setMask(!mask)}
                        className={`mask-layout ${mask ? 'selected' : ''}`}
                      >
                        <TbFaceMask
                          className={`icon-mf ${mask ? 'selected' : ''}`}
                        />
                        {constants.SEARCH_MASK}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {!showFaces && (
                <div>
                  {/* <div className="age-label">Age</div> */}
                  <div className="age-label">{constants.SEARCH_GENDER}</div>
                  <div className="gender-container">
                    <div
                      onClick={(e) => setMale(!male)}
                      className={`male-layout ${male ? 'selected' : ''}`}
                    >
                      <BiMale className={`icon-mf ${male ? 'selected' : ''}`} />
                      {constants.SEARCH_MALE}
                    </div>
                    <div
                      onClick={(e) => setFemale(!female)}
                      className={`female-layout ${female ? 'selected' : ''}`}
                    >
                      <FaFemale
                        className={`icon-mf ${female ? 'selected' : ''}`}
                      />
                      {constants.SEARCH_FEMALE}
                    </div>
                  </div>
                  <div className="pant-color">
                    {constants.SEARCH_PANT_COLOR}
                  </div>
                  <div className="lable-show">
                    {constants.SELECT_UPTO_TWO_COLOR}
                  </div>
                  {bottomLimitExceed && (
                    <div className="error-conatiner">
                      <RiErrorWarningLine className="icon-warning-col" />
                      <div className="error-message">
                        {constants.SEARCH_ERROR_MESSAGE_COLOR}
                      </div>
                      <RxCross1
                        onClick={() => setBottomLimitExceed(false)}
                        className="icon-warning-col"
                      />
                    </div>
                  )}
                  <div className="color-container">
                    {bottomColorList &&
                      bottomColorList?.map((item, key) => {
                        return (
                          <div
                            key={key}
                            onClick={() =>
                              selectColor(item, bottomColorList, 0)
                            }
                            className={`color-layout ${item.name} ${
                              item.isSelected ? 'border-selected' : ''
                            }`}
                          ></div>
                        );
                      })}
                  </div>
                  <div className="pant-color">
                    {constants.SEARCH_SHIRT_COLOR}
                  </div>
                  <div className="lable-show">
                    {constants.SELECT_UPTO_TWO_COLOR}
                  </div>
                  {topLimitExceed && (
                    <div className="error-conatiner">
                      <RiErrorWarningLine className="icon-warning-col" />
                      <div className="error-message">
                        {constants.SEARCH_ERROR_MESSAGE_COLOR}
                      </div>
                      <RxCross1
                        onClick={() => setTopLimitExceed(false)}
                        className="icon-warning-col"
                      />
                    </div>
                  )}

                  <div className="color-container">
                    {topColorList &&
                      topColorList?.map((item, key) => {
                        return (
                          <div
                            key={key}
                            onClick={() => selectColor(item, topColorList, 1)}
                            className={`color-layout ${item.name} ${
                              item.isSelected ? 'border-selected' : ''
                            }`}
                          ></div>
                        );
                      })}
                  </div>
                  <div className="pant-color">
                    {constants.SEARCH_ACCESSORIES}
                  </div>
                  <div className="gender-container">
                    <div
                      onClick={(e) => setHat(!hat)}
                      className={`male-layout ${hat ? 'selected' : ''}`}
                    >
                      <GiBilledCap
                        className={`icon-mf ${hat ? 'selected' : ''}`}
                      />
                      {constants.SEARCH_HAT}
                    </div>
                    <div
                      onClick={(e) => setBag(!bag)}
                      className={`female-layout ${bag ? 'selected' : ''}`}
                    >
                      <BsHandbag
                        className={`icon-mf ${bag ? 'selected' : ''}`}
                      />
                      {constants.SEARCH_BAG}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="vehicle-parent-container">
          <div className="vehicle-search">
            <div className="left-container">
              <IoCarSportOutline
                className="icon-vehicle"
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_black')}
              />
              <div className="search-vehicle-container">
                <div className="search-by-vehicle">
                  {constants.SEARCH_TAGS_BY_VEHICLE}
                </div>
                <div className="select-more-text">
                  {constants.SEARCH_TAGS_SELECT_ONE_MORE}
                </div>
              </div>
            </div>
            <ToggleInput
              label={''}
              name="vehicle"
              value={vehicleSearch}
              labelColor={true}
              changeHandler={(e) => {
                setVehicleSearch(!!!JSON.parse(e?.target?.value));
              }}
            />
          </div>
          {vehicleSearch && (
            <div className="expanded-container">
              <div className="face-toggle">
                <div className="left-container">
                  <div className="face-icon">
                    <FaCarSide height="10px" width="10px" />
                  </div>
                  <div className="container-text ">
                    <div className="show-faces-label">
                      {constants.SEARCH_SHOW_LISENCE_PLATE}
                    </div>
                    <div className="warning-message">
                      <div className="icon-info">
                        <RiErrorWarningLine height="10px" width="10px" />
                      </div>
                      <div className="info-message">
                        {constants.SEARCH_ENABLING_THIS_FEATURE}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-container">
                  <ToggleInput
                    label={''}
                    name="person"
                    value={showLicensePlate}
                    labelColor={true}
                    changeHandler={(e) => {
                      setShowLicensePlate(!!!JSON.parse(e?.target?.value));
                    }}
                  />
                </div>
              </div>
              {!showLicensePlate && (
                <div>
                  <div className="label-heading">
                    {constants.SEARCH_VEHICLE_TYPE}
                  </div>
                  <div className="vehicle-container ">
                    <div
                      onClick={(e) => setCar(!car)}
                      className={`car-layout ${car ? 'selected' : ''}`}
                    >
                      <FaCarSide
                        className={`icon-mf ${car ? 'selected' : ''}`}
                      />
                      {constants.SEARCH_CAR}
                    </div>
                    <div
                      onClick={(e) => setTruck(!truck)}
                      className={`truck-layout ${truck ? 'selected' : ''}`}
                    >
                      <FiTruck
                        className={`icon-mf ${truck ? 'selected' : ''}`}
                      />
                      {constants.SEARCH_TRUCK}
                    </div>
                    <div
                      onClick={(e) => setBus(!bus)}
                      className={`suv-layout ${bus ? 'selected' : ''}`}
                    >
                      <TbBus className={`icon-mf ${bus ? 'selected' : ''}`} />
                      {constants.SEARCH_BUS}
                    </div>
                  </div>
                  <div className="vehicle-container ">
                    <div
                      onClick={(e) => setMoterCycle(!moterCycle)}
                      className={`truck-layout bike ${
                        moterCycle ? 'selected' : ''
                      }`}
                    >
                      <TbMotorbike
                        className={`icon-mf ${moterCycle ? 'selected' : ''}`}
                      />
                      {constants.SEARCH_MOTERCYCLE}
                    </div>
                    <div
                      onClick={(e) => setBicycle(!bicycle)}
                      className={`suv-layout bicyle ${
                        bicycle ? 'selected' : ''
                      }`}
                    >
                      <IoBicycleOutline
                        className={`icon-mf ${bicycle ? 'selected' : ''}`}
                      />
                      {constants.SEARCH_BICYCLE}
                    </div>
                  </div>

                  <div className="label-heading">
                    {constants.SEARCH_EXTERIOR_COLOR}
                  </div>
                  <div className="lable-show">
                    {constants.SELECT_UPTO_TWO_COLOR}
                  </div>
                  {vehicleLimitExceed && (
                    <div className="error-conatiner">
                      <RiErrorWarningLine className="icon-warning-col" />
                      <div className="error-message">
                        {constants.SEARCH_ERROR_MESSAGE_COLOR}
                      </div>
                      <RxCross1
                        onClick={() => setVehicleLimitExceed(false)}
                        className="icon-warning-col"
                      />
                    </div>
                  )}
                  <div className="color-container">
                    {vehicleColorList &&
                      vehicleColorList?.map((item, key) => {
                        return (
                          <div
                            onClick={() =>
                              selectColor(item, vehicleColorList, 2)
                            }
                            key={key}
                            className={`color-layout ${item.name} ${
                              item.isSelected ? 'border-selected' : ''
                            }`}
                          ></div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <Row className="settings-block">
          <Col>
            <PrimaryButton
              className="btn btn-primary mt-4"
              type="button"
              width="100%"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--grayscale_96')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              borderWidth="1px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              onClick={() => applyTag()}
              disabled={!personSearch && !vehicleSearch}
            >
              {constants.SEARCH_APPLY}{' '}
            </PrimaryButton>
          </Col>
        </Row>
      </SiteModal>

      <div className="scroll-container-tags">
        {tagsDetailList?.map((item, i) => {
          let isVLMCategory =
            item.category.toLowerCase() === 'vehicle' ||
            item.category.toLowerCase() === 'license plate' ||
            item.category.toLowerCase() === 'motion';
          return (
            <div
              key={i}
              onClick={() => addTags(item)}
              className={`child-item ${
                item.category === addTagCat
                  ? 'all-tag-bg'
                  : isVLMCategory
                  ? 'tag-vlm'
                  : ''
              }`}
              onWheel={(e) => handleScroll(e)}
            >
              {item.category === addTagCat ? (
                <AiOutlinePlus
                  className="icon-people"
                  height="10px"
                  width="10px"
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_32')}
                />
              ) : isVLMCategory ? (
                <FaCarSide
                  className="icon-people tag-vlm"
                  height="10px"
                  width="10px"
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--tagvlm')}
                />
              ) : (
                <BsFillPeopleFill
                  className="icon-people "
                  height="10px"
                  width="10px"
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_32')}
                />
              )}
              <div
                className={`text-tag ${
                  item.category === addTagCat
                    ? 'all-tags-label'
                    : isVLMCategory
                    ? 'tag-vlm'
                    : ''
                }`}
              >
                {item.text}
              </div>
              {item.category !== addTagCat && (
                <RxCross1
                  onClick={() => removeTag(item)}
                  className={`cross-icon ${isVLMCategory ? 'tag-vlm' : ''}`}
                  height="10px"
                  width="10px"
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tags;
