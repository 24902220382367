import { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Col, Row } from 'react-bootstrap';
import {
  Button,
  FormControl,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Radio,
  Select,
  TextField,
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import axios from 'axios';
import moment from 'moment';
import timezones from '../../../data/support/timezone.json';
import 'moment-timezone';

import { Spinner, Tags, TextBlock } from '../../../components/common/';
import { constants, Utils } from '../../../helpers';

import { useOrganizations } from '../../../store/OrganizationsStore';

import { ReactComponent as InformationCircle } from '../../../assets/images/icons/InformationCircle.svg';

import {
  HiOutlineCheckCircle,
  HiOutlineFilm,
  HiOutlineFolderAdd,
  HiOutlineShare,
} from 'react-icons/hi';
import ImageFetch from '../components/ImageFetch';
import colors from '../../../assets/css/_colors.scss';
import './modalForms.scss';
import { getCustomerOrgData } from '../../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../../store/indexDB/observer';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
const MAX_COMMENT_CHARACTERS = 250;

const CreateClip = ({
  cdnValue,
  deviceId,
  deviceTimezone,
  startTime,
  endTime,
  canSubmitEnabler = null,
  shouldSubmitCreateClipRequest = false,
  callback = null,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [showLoader, setShowLoader] = useState(false);
  const [comment, setComment] = useState(null);
  const [tags, setTags] = useState([]);
  const [numOfCharacters, setNumOfCharacters] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [clipRequestSubmitted, setClipRequestSubmitted] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [orgDetails, setOrgDetails] = useState();

  // const { getCustomerOrgData } = useOrganizations();
  // const orgDetails = getCustomerOrgData()[0];

  //   const saveViewHandler = async () => {
  //     setShowLoader(true);

  //     if (callBack) {
  //       try {
  //         let result = await callBack(setShowLoader);

  //         if (!!result) {
  //           setFormSubmitted(true);
  //           openModal && openModal(false);
  //         } else {
  //           // TODO: generate error message
  //         }
  //       } catch (err) {
  //         // TODO: generate error message
  //         console.error(err);
  //       } finally {
  //         setShowLoader(false);
  //       }
  //     } else {
  //       setShowLoader(false);
  //     }
  //   };

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    if (shouldSubmitCreateClipRequest === true) {
      // TODO: uncomment this when API is ready
      //   submitClipRequest();
      setClipRequestSubmitted(true);
      callback && callback();
    }
  }, [shouldSubmitCreateClipRequest]);

  const submitClipRequest = async () => {
    try {
      // TODO: change route path to the correct one
      const res = await axios.get(`/incident/orgs/${orgDetails?.orgId}/tags`, Utils.requestHeader());

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        this.setState({ createClipRequestSubmitted: true });
      } else {
        if (responseData?.meta?.code) {
          console.error(
            `${responseData?.meta?.code}: ${responseData?.meta?.message}`
          );
        } else if (responseData?.data) {
          console.error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getUTCDateFromTimezone = (datetime, timezone) => {
    if (!timezone) return;

    const getLocationSelected = timezones?.data?.find(
      (zone) => zone.value === timezone
    );

    const zone = getLocationSelected?.location || moment.tz.guess();
    const UTCDateJSX = (
      <>
        <div className="clipper-date">
          {/* TODO: delete this if we decide to use locale exclusively */}
          {/* {moment(datetime).tz(zone).format('MMMM Do, YYYY')} */}
          {moment(datetime).tz(zone).format('LL')}
        </div>
        <div className="clipper-time-with-timezone">
          {/* TODO: delete this if we decide to use locale exclusively */}
          {/* {moment(datetime).tz(zone).format('HH:mm:ss A')}{' '}
          {moment(datetime).tz(zone).zoneAbbr()} */}
          {moment(datetime).tz(zone).format('LT z')}
        </div>
      </>
    );
    return UTCDateJSX;
  };

  const renderIncidentsList = () => {
    return (
      <FormControl InputLabelProps={{ shrink: false }} fullWidth>
        <InputLabel
          className="incident-select-label"
          defaultValue={''}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <ListItemIcon className="incident-select-icon">
            <HiOutlineFolderAdd />
          </ListItemIcon>
          Add to Incident
        </InputLabel>
        <Select
          className="incident-select"
          value={selectedOption}
          onChange={(event) => setSelectedOption(event.target.value)}
          renderValue={(selected) => <div>{selected}</div>}
          style={{ borderRadius: '12px' }}
        >
          <MenuItem className="incident-item-wrapper" value="Option 1">
            Option 1
            <Radio checked={selectedOption === 'Option 1'} />
          </MenuItem>
          <MenuItem className="incident-item-wrapper" value="Option 2">
            Option 2
            <Radio checked={selectedOption === 'Option 2'} />
          </MenuItem>
          <MenuItem className="incident-item-wrapper" value="Option 3">
            Option 3
            <Radio checked={selectedOption === 'Option 3'} />
          </MenuItem>
        </Select>
      </FormControl>
    );
  };
  const saveClipHandler = () => {};

  const createClipForm = !formSubmitted && (
    <form onSubmit={handleSubmit(saveClipHandler)}>
      <div className="mb-3 create-clip-snapshot-wrapper">
        <ImageFetch time={startTime} cdnValue={cdnValue} deviceId={deviceId} />
      </div>
      <Row>
        <Col>
          <div className="mb-3 clip-duration-wrapper">
            <div className="clip-time clip-start-time">
              <div className="clip-duration-time">
                {constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_STARTS_AT_TEXT}
              </div>
              <div className="clip-duration-time-box">
                {getUTCDateFromTimezone(startTime, deviceTimezone)}
              </div>
            </div>
            <div className="clip-time clip-end-time">
              <div className="clip-duration-time">
                {constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_ENDS_AT_TEXT}
              </div>
              <div className="clip-duration-time-box">
                {getUTCDateFromTimezone(endTime, deviceTimezone)}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-1 clip-comment">
            <TextField
              id="clipComment"
              className="clip-comment-field"
              multiline
              rows={3}
              name="comment"
              placeholder={
                constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_COMMENT_PLACEHOLDER
              }
              onChange={(e) => {
                const comment = e.target.value;
                setNumOfCharacters(comment.length);
                setComment(comment);
                canSubmitEnabler && canSubmitEnabler(!!comment);
              }}
              disabled={false}
              fullWidth
              required
              inputProps={{ maxLength: MAX_COMMENT_CHARACTERS }}
            />
          </div>
          <div className="char-count">
            {Utils.replaceStringValues(
              constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_NUM_OF_CHARACTERS_LIMIT,
              '$numOfCharacters / $characterLimit',
              `${numOfCharacters} / ${MAX_COMMENT_CHARACTERS} `
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-3 clip-tags-title">
            {constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_TAGS_TEXT}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-3 clip-tags-content">
            <Tags />
          </div>
        </Col>
      </Row>
    </form>
  );

  const postCreateClipForm = (
    <div>
      <div className="mb-3 create-clip-snapshot-wrapper">
        <div className="create-clip-video-processing">
          <div>
            <Spinner
              height="24"
              width="24"
              strokeColor={colors.brand_white}
              strokeWidth="7"
              fill="none"
            />
            <ImageFetch
              customClass="create-clip-video-processing-snapshot"
              time={startTime}
              cdnValue={cdnValue}
              deviceId={deviceId}
            />
          </div>
          <div className="create-clip-video-processing-status">
            <div className="create-clip-video-processing-status-title">
              {constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_VIDEO_PROCESSING_TITLE}
            </div>
            <div className="create-clip-video-processing-status-message">
              {
                constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_VIDEO_PROCESSING_MESSAGE
              }
            </div>
          </div>
        </div>
      </div>
      <Row>
        <div className="mb-3 create-clip-notification-wrapper">
          <div className="notification success">
            <div className="success-title-wrapper">
              <HiOutlineCheckCircle size={'1.25rem'} />
              <span className="success-title">
                {constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_CLIP_CREATED_NOTICE}
              </span>
            </div>
          </div>
          <div className="notification error">
            <div className="error-title-wrapper">
              <InformationCircle />
              <span className="error-title">
                {
                  constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_STORAGE_CAPACITY_NOTICE_TITLE
                }
              </span>
            </div>
            <div className="error-description">
              {
                constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_STORAGE_CAPACITY_NOTICE_DESCRIPTION
              }
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <div className="mb-3 create-clip-shortcuts-wrapper">
          {constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_SHORTCUTS_TEXT}
        </div>
      </Row>
      <Row>
        <div className="mb-3 d-flex" style={{ gap: '16px' }}>
          <Col>
            <Button
              className="create-clip-shortcut-icon"
              variant="outlined"
              startIcon={<HiOutlineFilm size={'1rem'} />}
              disabled={true}
            >
              {
                constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_GO_TO_CLIPS_BUTTON_LABEL
              }
            </Button>
          </Col>
          <Col>
            <Button
              className="create-clip-shortcut-icon"
              variant="outlined"
              startIcon={<HiOutlineShare size={'1rem'} />}
            >
              {constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_SHARE_BUTTON_LABEL}
            </Button>
          </Col>
        </div>
      </Row>
      <Row>
        <div className="mb-3 create-clip-incidents-wrapper">
          {renderIncidentsList()}
        </div>
      </Row>
    </div>
  );

  const createCLipContent = () => {
    return clipRequestSubmitted ? postCreateClipForm : createClipForm;
  };

  return <>{createCLipContent()}</>;
};

export default CreateClip;
