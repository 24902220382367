import { create } from 'zustand';
import axios from 'axios';
import { constants, ServiceTypeEnum, Utils } from '../helpers';

const useLicensesStore = create((set, get) => ({
  licenses: [],
  licensesExpiredPopUpShow: true,
  expiredLicensesInStore: [],
  /**
   * Retrieves the list of licenses, which can be filtered further by status, trial
   * and service ID.
   *
   * @param {Boolean} activeOnly - active licenses flag | default: false
   * @param {Boolean} paidOnly - paid license flag | default: false
   * @param {String} serviceId - Service ID used by the app | default: vms-service
   * @returns {Array} - array of licenses
   */
  getLicenses: (
    activeOnly = false,
    paidOnly = false,
    serviceId = ServiceTypeEnum?.VMS_SERVICE,
    includeCloudStorageLicenses = true
  ) => {
    let organizationLicenses = [];

    try {
      const licenses = get().licenses;

      if (!serviceId) {
        throw new Error('ERROR: no service id provided');
      }

      if (licenses.length > 0) {
        if (includeCloudStorageLicenses) {
          // Retrieve licenses for cloud storage and licenses by service ID
          organizationLicenses = [
            ...licenses.filter(
              (license) =>
                license.serviceId.toLowerCase() ===
                  ServiceTypeEnum?.CLOUD_STORAGE ||
                license.serviceId.toLowerCase() ===
                  ServiceTypeEnum?.VMS_SERVICE ||
                license.serviceId.toLowerCase() ===
                  ServiceTypeEnum?.DM_PRO_SERVICE ||
                license.serviceId.toLowerCase() ===
                  ServiceTypeEnum?.SIGHT_MIND_SERVICE ||
                license.serviceId.toLowerCase() ===
                  ServiceTypeEnum?.FLEXAI_SERVICE ||
                license.serviceId.toLowerCase() === serviceId?.toLowerCase()
            ),
          ];
        } else {
          // Retrieve licenses by service ID
          organizationLicenses = [
            ...licenses.filter(
              (license) =>
                license.serviceId.toLowerCase() ===
                  ServiceTypeEnum?.VMS_SERVICE ||
                license.serviceId.toLowerCase() ===
                  ServiceTypeEnum?.DM_PRO_SERVICE ||
                license.serviceId.toLowerCase() ===
                  ServiceTypeEnum?.SIGHT_MIND_SERVICE ||
                license.serviceId.toLowerCase() ===
                  ServiceTypeEnum?.FLEXAI_SERVICE ||
                license.serviceId.toLowerCase() === serviceId?.toLowerCase()
            ),
          ];
        }

        if (activeOnly === true) {
          // Limit the list to just active licenses
          organizationLicenses = [
            ...organizationLicenses.filter(
              (license) => license.licenseStatus.toUpperCase() === 'ACTIVE'
            ),
          ];
        }

        if (paidOnly === true) {
          // Filter out free/trial licenses and eval licenses
          // NOTE: 'trial' is a legacy property and only used here
          // for backwards compatibility
          organizationLicenses = [
            ...organizationLicenses.filter(
              (license) =>
                license?.trial === false ||
                license?.offeringType !== constants.LICENSE_ONBOARDING_STATUS
            ),
          ];
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      set({
        licenses: [...organizationLicenses],
      });
      return organizationLicenses;
    }
  },

  /**
   * Fetches the list of licenses from the backing using the specified route
   * as the endpoint.
   *
   * @param {String} route
   * @returns {Object} result
   */
  setLicenses: async (route) => {
    if (!route) return;

    const res = await axios.get(route, Utils.requestHeader());
    const responseData = res?.data;
    let result = { status: 'failure', msg: 'Unknown internal API error' };

    // Set licenses if request is successful
    if (responseData?.meta?.code === 200) {
      set({
        licenses: [...responseData?.data],
      });
      result = { status: 'success', msg: 'licenses retrieved`' };
    } else {
      if (res?.code) {
        result = { status: 'failure', msg: `${res.code}: ${res.message}` };
      } else if (responseData?.data) {
        result = { status: 'failure', msg: responseData?.data?.userMsg };
      }
    }
    return result;
  },

  /**
   * Clears the array of licenses
   *
   * @returns void
   */
  removeLicenses: () => set({ licenses: [] }),
  setLicensesExpiredPopUpShow: (licensesExpiredPopUpShow) => {
    set({
      licensesExpiredPopUpShow: licensesExpiredPopUpShow,
    });
  },
  getLicensesExpiredPopUpShow: () => {
    return get().licensesExpiredPopUpShow;
  },
  setExpiredLicensesInStore: (licenses) => {
    set({
      expiredLicensesInStore: licenses,
    });
  },
  getExpiredLicensesInStore: () => {
    return get().expiredLicensesInStore;
  },
}));

export default useLicensesStore;
