import React, { useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import { VscChevronDown, VscChevronUp } from 'react-icons/vsc';
import { Tooltip, tooltipClasses } from '@mui/material';
import { constants } from '../../helpers';
import moment from 'moment';

const UsersListTable = ({ columns, data, defaultSortByData = [], handleClickEditUser }) => {
  // Use the useTable Hook to send the columns and data to build the table

  const tableColumns = React.useMemo(() => columns.map(column => ({
    ...column,
    sortType: (rowA, rowB, columnId) => {
      let valueA= ''
      let valueB = ''
       if(columnId == 'accountStatus'){
         valueA = rowA?.original?.accountStatus  === 'ACTIVE' && rowA?.original?.isLocked ? constants.LOCKED_ACCOUNT_TEST : rowA?.original?.accountStatus  === 'ACTIVE' ? constants.ACTIVE_USER_STATUS_LABEL : rowA?.original?.accountStatus  === 'PENDING_ACTIVATION' ?  constants.EDIT_CUSTOMER_INACTIVE_STATUS_TITLE : constants.DEVICES_RETURN_DEACTIVATED_STATUS  ;
         valueB = rowB?.original?.accountStatus  === 'ACTIVE' && rowB?.original?.isLocked ? constants.LOCKED_ACCOUNT_TEST : rowB?.original?.accountStatus  === 'ACTIVE' ? constants.ACTIVE_USER_STATUS_LABEL : rowB?.original?.accountStatus  === 'PENDING_ACTIVATION' ?  constants.EDIT_CUSTOMER_INACTIVE_STATUS_TITLE : constants.DEVICES_RETURN_DEACTIVATED_STATUS  ;
      }
      else{
         valueA = rowA.values[columnId].toString().toLowerCase();
         valueB = rowB.values[columnId].toString().toLowerCase(); 
      }
      return valueA.localeCompare(valueB);
    },
  })), [columns]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns : tableColumns,
        data,
        initialState: {
          sortBy: defaultSortByData,
        },
      },
      useSortBy
    );
 
   /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <div className="table-responsive">
      <table className="table table-borderless userTable" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={columnIndex}
                  className={`${
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'columnheader-asc'
                        : 'columnheader-desc'
                      : 'columnheader-asc'
                  }`}
                  {...column.getHeaderProps({
                    style: { minWidth: column.minWidth, width: column.width },
                  })}
                  onClick={() => {
                    column.toggleSortBy(!column.isSortedDesc, false);
                  }}
                >
                  {column.render('Header')}
                  <span>
                  { column.isSortedDesc ? (
                        <VscChevronDown size={16} strokeWidth="1px" />
                      ) : (
                        <VscChevronUp size={16} strokeWidth="1px" />
                      )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr onClick={()=>{handleClickEditUser(row.original?.accountId)}} key={rowIndex} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    cell.value !== 0 ?
                    <Tooltip
                      placement='top'
                      arrow
                      title={cell.render('Cell')}
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, -25],
                              },
                            },
                          ],
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              fontSize: 12,
                              backgroundColor: `var(--overlay)`,
                              padding: '10px'
                            },
                            [`& .${tooltipClasses.arrow}`]: {
                              color: `var(--overlay)`
                            },
                          }
                        },
                      }}
                    >
                      <td  {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    </Tooltip>:
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
          {rows?.length === 0 && (
            <tr className="no-data-found">
              <td colSpan={columns?.length}>
                {constants.NO_RECORDS_FOUND_TITLE}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UsersListTable;
