import { useEffect, useState, useCallback } from "react";
import { Row, Col, Accordion, Dropdown } from "react-bootstrap";
import { HiOutlineChevronDown } from "react-icons/hi";
import { ccimg, step2Icon } from "../../../assets/images";
import { PrimaryButton } from "../../../components/common/";
import { useContext } from "react";
import { DetailsContext } from "./DetailsContextProvider";
import { DataContext } from "./DataContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { addDeviceAPI } from "./addDeviceAPI";
import { Utils, constants } from "../../../helpers/";
import { Formik, Form, Field } from "formik";
import { SelectField, TextField } from "../../../components/forms";
import { RxCross1 } from "react-icons/rx";
import timezones from "../../../data/support/timezone.json";
import * as Yup from "yup";
import {
  getLocationsData,
  // getSelectedOrganization,
} from "../../../store/reducers/AccountReducer";
import moment from "moment";
import { getPlatformInfo } from "../../../store/reducers/StreamingReducer";
import { mqttSubscribe } from "../../../utils/connection/mqttConnection";
import { useOrganizations } from "../../../store/OrganizationsStore";
import { DeviceTypeEnum } from "../../../helpers/enums";
import { useAppsStore } from "../../../store/AppsStore";
import { setNVRDeviceData } from "../../../store/reducers/NVRDeviceReducer";
import { BsInfinity  } from "react-icons/bs";
import { findTimeZoneFromSelectedValue } from "../../../helpers/commonUtils";
import LocationSelectField from "../../../components/forms/LocationSelectField";
import { getCustomerOrgData } from '../../../store/OrganizationsStoreIDB';
import { getSelectedOrganization } from '../../../store/AccountStoreIDB';
import { observerInstance } from '../../../store/indexDB/observer';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

function Two(props) {
  let [filterList, setFilterList] = useState([]);
  let [dropdownClicked, setDropdownClicked] = useState(false);
  let appList = useAppsStore((state) => state.appList);
  const { setDatavalues } = useContext(DataContext);
  // const orgDetails = useSelector(getSelectedOrganization);
  const [orgDetails, setOrgDetails] = useState();
  const { info, setInfo } = useContext(DetailsContext);
  const { datavalues } = useContext(DataContext);
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [errormsg, setErrormsg] = useState([]);
  const [selectedAreaId, setSelectedAreaId] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const platformDetails = useSelector(getPlatformInfo);
  const [deviceNameEmptyFlag, setDeviceNameEmptyFlag] = useState(false);

  const filterOptions = [];
  const subscribeTopic =
    platformDetails?.mqtt?.topic_details?.subscribe?.settings;
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  // const defaultTimezone = getCustomerOrgData().find(
  //   (user) => user.orgId === orgDetails?.orgId
  // )?.timezone;
  const zoneTimeList = timezones?.data;
  const [currentUserTimezone, setCurrentUserTimezone] = useState(
    moment.tz.guess()
    // defaultTimezone
    //   ? findTimeZoneFromSelectedValue(defaultTimezone)?.location
    //   : moment.tz.guess()
  );

  const [currentTime, setCurrentTime] = useState(
    `${moment
      .tz(moment(), currentUserTimezone)
      .format("MMM DD, YYYY HH:mm:ss A")}`
  );
  const locationsData = useSelector(getLocationsData);
  const getDefaultLocation = () => {
    const location = locationsData?.filter(
      (location) => location.isDefault === "true"
    );
    return location[0]?.locationId;
  };

  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    const defaultTimezone = orgs.find(
      (user) => user.orgId === orgDetails?.orgId
    )?.timezone;
    setCurrentUserTimezone(
      defaultTimezone
        ? findTimeZoneFromSelectedValue(defaultTimezone)?.location
        : moment.tz.guess()
    );
  }, [orgDetails?.orgId]);

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000
  );

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
      if (data.key === 'selectedOrganization') {
        await debouncedLoadSelectedOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();
    debouncedLoadSelectedOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData, debouncedLoadSelectedOrgData]);

  useEffect(()=>{
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    })
  },[])
  useEffect(() => {
    if (selectedAreaId !== "") {
      const location = getLocationId(selectedAreaId);
      setCurrentUserTimezone(
        findTimeZoneFromSelectedValue(location?.timezone)
          ?.location
      );
    }
  }, [selectedAreaId]);

  useEffect(() => {
    const location = locationsData?.filter(
      (location) => location.isDefault === "true"
    );
    if (location.length) {
      const area = location[0]?.areas?.filter((area) => area.isDefault);
      setSelectedAreaId(area[0]?.areaId || location[0]?.areas?.[0]?.areaId);
      setSelectedLocationId(location[0]?.locationId);
    }
  }, []);

  useEffect(() => {
    let secTimer = setInterval(() => {
      setCurrentTime(
        `${moment
          .tz(moment(), currentUserTimezone)
          .format("MMM DD, YYYY HH:mm:ss A")}`
      );
    }, 1000);
    return () => clearInterval(secTimer);
  }, [currentUserTimezone]);

   //TODO Delete Later
  // useEffect(() => {
  //   console.log("props",props)
 	// let selectedModelObj = props?.valueFromOne?.filter((data) => data?.model === info?.model)[0]
	// if(selectedModelObj && selectedModelObj["dmpro"]){
	// 	if(!selectedModelObj["flexai"] && !selectedModelObj["sightmind"] && !selectedModelObj["vms"]){
	// 		onClickFilter("DMPro");
	// 	}
	// }
	// if(selectedModelObj && selectedModelObj["flexai"]){
	// 	if(!selectedModelObj["dmpro"] && !selectedModelObj["sightmind"] && !selectedModelObj["vms"]){
	// 		onClickFilter("FLEX AI");
	// 	}
	// }
	// if(selectedModelObj && selectedModelObj["sightmind"]){
	// 	if(!selectedModelObj["dmpro"] && !selectedModelObj["flexai"] && !selectedModelObj["vms"]){
	// 		onClickFilter("SightMind");
	// 	}
	// }
	// if(selectedModelObj && selectedModelObj["vms"]){
	// 	if(!selectedModelObj["dmpro"] && !selectedModelObj["flexai"] && !selectedModelObj["sightmind"]){
	// 		onClickFilter("OnCloud");
	// 	}
	// }
  // }, [props]);

  const getLocationId = (areaId) => {
    const location = locationsData?.find((location) => {
      const area = location?.areas?.find((area) => area?.areaId === areaId);
      if (area) {
        return location;
      }
    });
    if (location) {
      return location;
    }
  };

  const getDefaultArea = () => {
    const location = locationsData?.filter(
      (location) => location.isDefault === "true"
    );
    if (location.length) {
      const area = location[0]?.areas?.filter((area) => area.isDefault);
      return area[0]?.areaId || location[0]?.areas?.[0]?.areaId;
    }
  };

  const Continue = (e) => {
    e.preventDefault();
    props.nextstep();
  };

  const reEnterHandler = () => {
    let newdata = [0];
    setDatavalues(newdata);
    props.prevstep();
  };

  const validate = Yup.object({
    deviceName: Yup.string().max(
      40,
      constants.DEVICE_NAME_LENGTH_ERROR_MESSAGE
    ),
  });
  const onClickFilter = (option) => {
    setDropdownClicked(true);
    let newFilterList = [];
    if (filterList.includes(option)) {
      newFilterList = filterList.filter((item) => item !== option);
    } else {
      newFilterList = JSON.parse(JSON.stringify(filterList));
      newFilterList.push(option);
    }
    setFilterList(newFilterList);
  };

  const checkSelectedOption = (name) => filterList.includes(name);

  return (
    <div className="ctn-box innner">
      <div className="stepIcon">
        <img src={step2Icon} alt="step2icon" />
      </div>
      <div className="steptext">{constants.DEVICES_STEP_TWO_OF_THREE}</div>
      <div className="camera-details-container desktop">
        <div className="qr-block">
          <div className="qr-image">
            <img
              src={props.valueFromOne.imageURL ? props.valueFromOne.imageURL : ccimg}
              alt="Device"
              className="qr-image"
            />
          </div>
        </div>
        <div className="camera-details">
          <div className="titlename mb-4">
            {constants.DEVICES_TAB_DEVICE_INFO}
          </div>
          <div className="subtext3">{constants.DEVICES_SERIAL_NO_TEXT}</div>
          <div className="subtext2">{info.serialNo || datavalues[0]}</div>
          <div className="subtext3">{constants.DEVICES_MAC_ADDRESS_TEXT}</div>
          <div className="subtext2">{info.macAddress || datavalues[1]}</div>
          <div className="subtext3">{constants.DEVICES_MANUFACTURER_TEXT}</div>
          <div className="subtext2">
            {constants.ADD_DEVICE_MANUFRACTURE_NAME}
          </div>
          <div className="subtext3">{constants.DEVICES_CHANNELS}</div>
          <div className="subtext2">{info?.deviceType !== DeviceTypeEnum.DMSERVER ? info.channelCount : <span ><BsInfinity  size={16} /></span>}</div>
          <div className="subtext3">{constants.DEVICES_MODEL_TEXT}</div>
          <div className="subtext2">{info.model || datavalues[3]}</div>
          <div
            onClick={reEnterHandler}
            style={{
              color: getComputedStyle(
                document.documentElement
              ).getPropertyValue("--primary_40"),
              cursor: "pointer",
              marginBottom: "0px",
            }}
            className="subtext2"
          >
            {constants.DEVICES_STEP2_RE_INFO}
          </div>
        </div>
      </div>
      <div className="camera-details-container mobile">
        <Row>
          <Col md={6} lg={6} xl={6} xs={12}>
            <div className="qr-block">
              <div className="qr-image">
                <img
                  src={datavalues[6] ? datavalues[6] : ccimg}
                  alt="Device"
                  className="qr-image"
                />
              </div>
            </div>
          </Col>
          <Col md={6} lg={6} xl={6} xs={12}>
            <div className="camera-details-mobile">
              <div className="titlename mb-4">
                {constants.DEVICES_TAB_DEVICE_INFO}
              </div>
              <div className="mb-2">
              <div className="subtext3">{constants.DEVICES_SERIAL_NO_TEXT}</div>
              <div className="subtext2">{info.serialNo || datavalues[0]}</div>
              </div>
              <div className="mb-2">
              <div className="subtext3">
                {constants.DEVICES_MAC_ADDRESS_TEXT}
              </div>
              <div className="subtext2">{info.macAddress || datavalues[1]}</div>
              </div>
              <div className="mb-2">
              <div className="subtext3">
                {constants.DEVICES_MANUFACTURER_TEXT}
              </div>
              <div className="subtext2">
                {constants.ADD_DEVICE_MANUFRACTURE_NAME}
              </div>
              </div>
              <div className="mb-2">
              <div className="subtext3">{constants.DEVICES_CHANNELS}</div>
              <div className="subtext2">{info?.deviceType !== DeviceTypeEnum.DMSERVER ? info.channelCount : <span ><BsInfinity  size={16} /></span>}</div>
              </div>
              <div className="mb-2">
              <div className="subtext3">{constants.DEVICES_MODEL_TEXT}</div>
              <div className="subtext2">{info.model || datavalues[3]}</div>
              </div>
              <div
                onClick={reEnterHandler}
                style={{
                  color: getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--primary_40"),
                  cursor: "pointer",
                  marginBottom: "0px",
                }}
                className="subtext2 mt-4"
              >
                {constants.DEVICES_STEP2_RE_INFO}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="titlename mb-1 mt-3">
        {constants.DEVICES_DEVICE_NAME_TEXT}
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          deviceName:
            info.deviceType === DeviceTypeEnum.DMSERVER ? info.serialNo : "",
          locationId: getDefaultLocation(),
          areaId: getDefaultArea(),
          timezone: findTimeZoneFromSelectedValue(currentUserTimezone)?.location,
          requestedApps: [],
        }}
        validationSchema={validate}
        onSubmit={async (values) => {
          setShowLoader(true);
          const location = getLocationId(values?.areaId);
          if (values.areaId !== getDefaultArea()) {
            values.locationId = location?.locationId;
          }
          if (values?.areaId !== "") {
            values.timezone = location?.timezone;
          }
          values["orgId"] = orgDetails?.orgId;
          // let modifiedArray = filterList.map((item) =>
          //   item.replace(/\s/g, "").toLowerCase()
          // );

          // let updatedRequestedApps =
          //   modifiedArray?.map((app) => (app === "oncloud" ? "vms" : app)) ||
          //   [];
          // if (values && modifiedArray !== undefined) {
          //   modifiedArray = updatedRequestedApps;
          // }

          // values.requestedApps = modifiedArray;
          const information = { ...info };
          delete information.channelCount;
          information.manufacturer = information?.modelManufacturer ? information?.modelManufacturer : info?.manufacturer;
          delete information.modelManufacturer;
          const deviceResponse = await addDeviceAPI(
            orgDetails,
            values,
            information
          );
          if (deviceResponse?.userMsg) {
            setShowLoader(false);
            setErrormsg(deviceResponse.userMsg);
          } else {
            setShowLoader(false);
            info["deviceId"] = deviceResponse?.data?.deviceId;
            if (Array.isArray(subscribeTopic) && subscribeTopic.length > 0) {
              const deviceSubscription = {
                topic: Utils.replaceStringValues(
                  subscribeTopic[0],
                  "${deviceId}",
                  `${deviceResponse?.data?.deviceId}`
                ),
                qos: 0,
              };
              mqttSubscribe(deviceSubscription);
            }
            if (
              info.deviceType === DeviceTypeEnum.NVR ||
              info.deviceType === DeviceTypeEnum.DMSERVER
            ) {
              dispatch(
                setNVRDeviceData({
                  ...deviceResponse?.data,
                  channelCount: info.channelCount,
                })
              );
            }
            setInfo({ ...info, step2info: values });
            props.nextstep();
          }
        }}
      >
        {({ values, handleChange, setFieldValue, dirty, isValid }) => (
          <Form className="modal-form">
            <div className="mb-3 device-name">
              <TextField
                placeholder={constants.DEVICES_DEVICE_NAME_PLACEHOLDER}
                name="deviceName"
                type="text"
                value={values.deviceName}
                onChange={(e) => {
                  handleChange(e);
                  let deviceName = e.target.value
                  if(deviceName?.length === 0){
                    setDeviceNameEmptyFlag(true)
                  }else{
                    setDeviceNameEmptyFlag(false)
                  }
                }}
                removebottommargin="true"
                removetopmargin="true"
              />
              <div className="device-name-length">
                {values.deviceName.length || 0}/40
              </div>
            </div>
            <div className="titlename mb-3">
              {info.deviceType === DeviceTypeEnum.NVR
                ? constants.DEVICES_ASSIGN_LOCATION_TEXT
                : constants.DEVICES_ASSIGN_LOCATION_AREA_TEXT}
            </div>

            {info.deviceType === DeviceTypeEnum.NVR ? (
              <Accordion className="step2-location">
                <Accordion.Item>
                  <div className="nvr-mode radio-wrapper">
                    {locationsData?.map((location) => (
                      <div className="radiotitle" key={location.locationId}>
                        <Col>
                          <div
                            style={{
                              color: getComputedStyle(
                                document.documentElement
                              ).getPropertyValue("--greyscale_08"),
                            }}
                          >
                            {location.locationName}
                          </div>
                        </Col>
                        <Col xs={1} md={1} lg={1} className="makingBig">
                          <Field
                            type="radio"
                            name="locationId"
                            value={location.locationId}
                            checked={selectedLocationId === location.locationId}
                            onClick={() => {
                              setSelectedLocationId(location.locationId);
                              const selectedArea = location.areas.find(
                                (area) => area.isDefault
                              );
                              setSelectedAreaId(selectedArea?.areaId);
                            }}
                          />
                        </Col>
                      </div>
                    ))}
                  </div>
                </Accordion.Item>
              </Accordion>
            ) : (
              locationsData?.map((location) => (
                <Accordion className="step2-location" key={location.locationId}>
                  <Accordion.Item eventKey={location.locationId}>
                    <div key={location.locationId} className="radio-wrapper">
                      <Accordion.Header>
                        <div className="radiotitle">
                          <Col>
                            <div
                              className="subtext2"
                              style={{
                                color: getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue("--greyscale_08"),
                              }}
                            >
                              {location.locationName}
                            </div>
                          </Col>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {location?.areas?.length && (
                          <div className="">
                            {location?.areas?.map((area) => {
                              if (area) {
                                return (
                                  <div key={area.areaId} className="radiotitle">
                                    <Col
                                      className="subtext2"
                                      style={{
                                        color: getComputedStyle(
                                          document.documentElement
                                        ).getPropertyValue("--greyscale_08"),
                                      }}
                                    >
                                      {area?.areaName}
                                    </Col>
                                    <Col
                                      xs={1}
                                      md={1}
                                      lg={1}
                                      className="makingBig"
                                    >
                                      <Field
                                        type="radio"
                                        name="areaId"
                                        value={area?.areaId}
                                        checked={
                                          selectedAreaId === area?.areaId
                                        }
                                        onClick={() => {
                                          setSelectedAreaId(area?.areaId);
                                        }}
                                      />
                                    </Col>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        )}
                      </Accordion.Body>
                    </div>
                  </Accordion.Item>
                </Accordion>
              ))
            )}
            {/* <Row className="settings-block">
              <Col>
                <div className="device-infodate-time-title step-two">
                  {constants.DEVICE_INFO_ASSIGN_APPLICATION}
                </div>
                <div>
                  <Dropdown
                    className="device-status-selector"
                    autoClose="outside"
                  >
                    <Dropdown.Toggle
                      variant="outline-secondary"
                      id="dropdown-basic-1"
                      className="device-status-selector-toggle custom-class custom-background"
                    >
                      <Row className="dropdownContainer">
                        <Col xs={11}>
                          <div className="device-status-selected-wrapper">
                            <span className="device-status-selected-name">
                              {filterList.length > 0
                                ? filterList.join(", ")
                                : constants.DEVICE_INFO_SELECT_APPLICATION}
                            </span>
                          </div>
                        </Col>
                        <Col xs={1}>
                          <HiOutlineChevronDown
                            className="hiOutlineChevronDownStyle"
                            size={16}
                          />
                        </Col>
                      </Row>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="device-status-selector-menu custom-class">
                      {appList.map((option) => (
                        <Dropdown.Item
                          key={option.id}
                          className={`device-status-selector-item ${
                            props.valueFromOne.filter(
                              (data) => data.model == info.model
                            )[0][option.id === "oncloud" ? "vms" : option.id]
                              ? ""
                              : "disabled-dropdown-item"
                          }`}
                          onClick={(e) => onClickFilter(option.name)}
                          disabled={
                            !props.valueFromOne.filter(
                              (data) => data.model === info.model
                            )[0][option.id == "oncloud" ? "vms" : option.id]
                          }
                        >
                          <Row className="dropdownContainerRow">
                            <Col xs={1}>
                              <img
                                className="iconInDropDown"
                                src={option.gicon}
                              />
                            </Col>
                            <Col className="dropDownName" xs={10}>
                              {option.name}
                            </Col>
                            <Col xs={1}>
                              <input
                                type="checkbox"
                                checked={checkSelectedOption(option.name)}
                                className="checkbox"
                                id="checkBoxDropDown"
                                disabled={
                                  !props.valueFromOne.filter(
                                    (data) => data.model === info.model
                                  )[0][
                                    option.id == "oncloud" ? "vms" : option.id
                                  ]
                                }
                              />
                            </Col>
                          </Row>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  {filterList.length == 0 && dropdownClicked && (
                    <div className="error errorStyle">
                      {constants.APPS_SELECTION_ERROR_MESSAGE}
                    </div>
                  )}
                </div>
              </Col>
            </Row> */}
            <Row className="settings-block">
              <Col>
                <div className="device-infodate-time-title step-two">
                  {constants.DEVICE_INFO_DATE_TIME_TITLE}
                </div>
                <div className="settings-info-block-content-step">
                  <div className="subtext3">
                    {constants.DEVICE_INFO_CURRENT_TIME_TITLE}
                  </div>
                  <div className="subtext2">{currentTime}</div>
                </div>
                <div className="settings-info-block-content device">
                  <LocationSelectField
                    name="timezone"
                    options={zoneTimeList}
                    label=""
                    value={values.timezone}
                    onChange={(event) => {
                      let selectedVal = event?.target?.value;
                      const getLocationSelected = findTimeZoneFromSelectedValue(selectedVal);
                      setCurrentUserTimezone(getLocationSelected.location);
                      setFieldValue("timezone", selectedVal);
                    }}
                  />
                </div>
                <div className="label-timezone">
                  {constants.DEVICE_ADD_TIMEZONE_MESSAGE}
                </div>
              </Col>
            </Row>
            <PrimaryButton
              className="adddevice-btn"
              fontSize="14px"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--primary_40")}
              height="44px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--brand_white")}
              type="submit"
              loader={showLoader}
              disabled={
                !(
                  (isValid && dirty && info.deviceType !== DeviceTypeEnum.DMSERVER) ||
                  (deviceNameEmptyFlag === false && isValid && info.deviceType === DeviceTypeEnum.DMSERVER)
                )
              }
            >
              {constants.DEVICES_SECOND_STEP_SUBMIT_BUTTON_TEXT}
            </PrimaryButton>
          </Form>
        )}
      </Formik>
      <div className="qr-block-1">
        <div className="error">{errormsg}</div>
      </div>
    </div>
  );
}

export default Two;
