import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { Container, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import {
  BreadcrumbList,
  Header,
  PrimaryButton,
  SiteSpinner,
} from '../../components/common';

import './notificationdashboard.scss';
import { AppDefaults, Utils, constants } from '../../helpers';
import InfiniteScroll from 'react-infinite-scroller';
import { HiOutlineBell } from 'react-icons/hi';
import { BsFillPeopleFill } from 'react-icons/bs';
import brandLogo from '../../assets/images/BrandFavicon.svg';
import { FaCarSide } from 'react-icons/fa';
import { useOrganizations } from '../../store/OrganizationsStore';
import moment from 'moment';
import useEventsStore from '../../store/EventsStore';
import StickyBox from 'react-sticky-box';
import motion from '../../assets/images/motion-notification.svg';
import { icontick } from '../../assets/images';
import { PiWarning } from 'react-icons/pi';
import {
  TbDeviceComputerCameraOff,
  TbDeviceComputerCamera,
} from 'react-icons/tb';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

const NotificationDashboard = () => {
  const navigate = useNavigate();
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  // let custOrg = getCustomerOrgData();
  // const custOrgData = custOrg[0];
  const [custOrgData, setCustOrgData] = useState();
  const [hasMoreItems, sethasMoreItems] = useState(true);
  //Todo Delete Later Empty List
  const [notificationData, setNotificationData] = useState([]);
  const itemsPerPage = 20;
  const [showLoader, setShowLoader] = useState(false);
  const { setSelectedEventStore, setEventFromSearch, setEventCVRMode } =
    useEventsStore();
  const breadList = [
    { url: '/notificationdashboard.html', title: constants.NOTIFICATION_TITLE },
  ];
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const { getSelectedLocation } = useCustomerOrgLocations();
  const selectedLocation = getSelectedLocation(loggedInUserData?.accountId);

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustOrgData(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  //TODO Update Later notification api call.
  useEffect(() => {
    setShowLoader(true);
    if(custOrgData?.orgId){
      getNotificationData();
    }
  }, [selectedLocation, custOrgData]);

  const getDeviceLocationName = (device, isForDevice) => {
    let deviceLocation = '--';
    const deviceData = custOrgData?.devices.find(
      (item) => item.deviceId === device?.deviceId
    );
    if (deviceData) {
      if (isForDevice) {
        deviceLocation = deviceData?.deviceName;
      } else {
        const location = custOrgData?.locations.find(
          (item) => item.locationId === deviceData?.locationId
        );
        if (location) {
          const areas = location?.areas.find(
            (item) => item.locationId === location?.locationId
          );
          if (areas) {
            deviceLocation = `${areas?.areaName} - ${location?.locationName}`;
          }
        }
      }
    }
    return deviceLocation;
  };

  const getNotificationData = async () => {
    let response = await axios.get(
      `partner/orgs/${
        custOrgData?.orgId
      }/notifications?endTime=${new Date().getTime()}&size=${itemsPerPage}${
        selectedLocation &&
        selectedLocation != undefined &&
        Object.keys(selectedLocation).length > 0 &&
        selectedLocation?.locationId !== AppDefaults.ALL_LOCATIONS_ID
          ? `&locationId=${selectedLocation?.locationId}`
          : ''
      }`,
      Utils.requestHeader()
    );
    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
      setNotificationData(responseData?.data);
      setShowLoader(false);
    } else {
      setShowLoader(false);
    }
  };

  const loadMore = async () => {
    if (
      notificationData[notificationData.length - 1]?.eventTime <
      Utils.getUnixDate(moment(new Date()).subtract({ days: 30 })) * 1000
    ) {
      sethasMoreItems(false);
    } else {
      setTimeout(() => {
        fetchRecords(notificationData[notificationData.length - 1]?.eventTime);
      }, 100);
    }
  };

  const fetchRecords = async (endTime) => {
    let response = await axios.get(
      `partner/orgs/${
        custOrgData?.orgId
      }/notifications?endTime=${endTime}&size=${itemsPerPage}${
        selectedLocation &&
        selectedLocation != undefined &&
        Object.keys(selectedLocation).length > 0 &&
        selectedLocation?.locationId !== AppDefaults.ALL_LOCATIONS_ID
          ? `&locationId=${selectedLocation?.locationId}`
          : ''
      }`,
      Utils.requestHeader()
    );
    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
      setNotificationData([...notificationData, ...response?.data?.data]);
    }
  };

  const gotoCameraDetail = async (data) => {
    const param = {
      notificationIds: [data?.notificationId],
    };
    let response = await axios.put(
      `partner/orgs/${custOrgData?.orgId}/notifications/status/read`,
      param,
      Utils.requestHeader()
    );
    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
    }
    if (
      data?.eventType !== Utils.getNotificationEventName().cameraOnline &&
      data?.eventType !== Utils.getNotificationEventName().cameraOffline
    ) {
      setSelectedEventStore(data?.eventTime);
      setEventFromSearch(true);
      setEventCVRMode(true);
      navigate(`/cameras/dashboard.html`, {
        state: {
          id: data?.deviceId,
        },
      });
    }
  };

  const formattedDate = (time) => {
    if (time === moment(new Date()).format('MMM DD, YYYY')) {
      return 'Today';
    } else if (
      time === moment(new Date()).subtract({ days: 1 }).format('MMM DD, YYYY')
    ) {
      return 'Yesterday';
    } else {
      return time;
    }
  };
  const getActualDate = (date) => {
    const time = Utils.getDate(date / 1000);
    const actualTime = moment(time).format('MMM DD, YYYY');
    return actualTime;
  };

  const massagedData = (data) => {
    let finalArray = [];
    data.map((event) => {
      const time = getActualDate(event?.eventTime);
      const index = finalArray.findIndex((e) => e?.eventTime === time);
      if (index > -1) {
        finalArray[index]?.notificationList?.push({ ...event });
      } else {
        let eventObject = {
          eventTime: '',
          notificationList: [],
        };
        eventObject.eventTime = time;
        eventObject.notificationList?.push(event);
        finalArray.push(eventObject);
      }
    });
    return finalArray;
  };

  const getEventDetectedDetail = (eventType) => {
    switch (eventType) {
      case Utils.getNotificationEventName().person:
        return constants.NOTIFICATION_MESSAGE_DETECTED_PERSON;
      case Utils.getNotificationEventName().vehicle:
        return constants.NOTIFICATION_MESSAGE_DETECTED_VEHICLE;
      case Utils.getNotificationEventName().cameraOnline:
        return constants.NOTIFICATION_MESSAGE_CAMERA_ONLINE;
      case Utils.getNotificationEventName().cameraOffline:
        return constants.NOTIFICATION_MESSAGE_CAMERA_OFFLINE;
      case Utils.getNotificationEventName().glassBreaking:
        return constants.NOTIFICATION_MESSAGE_DETECTED_PERSON;
      case Utils.getNotificationEventName().tamperStart:
        return constants.NOTIFICATION_MESSAGE_DETECTED_TAMPERING;
      case Utils.getNotificationEventName().loitering:
        return constants.NOTIFICATION_MESSAGE_DETECTED_LOITERING;
      case Utils.getNotificationEventName().scream:
        return constants.NOTIFICATION_MESSAGE_DETECTED_AUDIO;
      case Utils.getNotificationEventName().explosion:
        return constants.NOTIFICATION_MESSAGE_DETECTED_AUDIO;
      case Utils.getNotificationEventName().glassBreaking:
        return constants.NOTIFICATION_MESSAGE_DETECTED_AUDIO;
      case Utils.getNotificationEventName().gunShot:
        return constants.NOTIFICATION_MESSAGE_DETECTED_AUDIO;
      case Utils.getNotificationEventName().motionStart:
        return constants.NOTIFICATION_MESSAGE_DETECTED_MOTION;
      case Utils.getNotificationEventName().motionEnd:
        return constants.NOTIFICATION_MESSAGE_DETECTED_MOTION;
      default:
        return eventType;
    }
  };

  const showItems = (posts) => {
    const dataItems = massagedData(posts);
    if (dataItems.length > 0) {
      return (
        <div className="list-item w-100">
          {dataItems?.map((dataNoti, i) => {
            return (
              <div key={dataNoti?.eventTime}>
                <StickyBox offsetTop={0}>
                  <div className="sticky-header">
                    <div className="header">
                      {dataNoti?.eventTime !== 'Invalid date'
                        ? formattedDate(dataNoti?.eventTime)
                        : ''}
                    </div>
                  </div>
                </StickyBox>
                {dataNoti?.notificationList?.map((data, i) => (
                  <div
                    onClick={() => gotoCameraDetail(data)}
                    key={data?.notificationId + i}
                    className="row-container"
                  >
                    <div className="event-conatiner">
                      <div
                        className={`image-layout ${
                          data?.eventType ==
                          Utils.getNotificationEventName().cameraOnline
                            ? ' camera-online'
                            : data?.eventType ==
                                Utils.getNotificationEventName()
                                  .cameraOffline && ' camera-offline'
                        }`}
                      >
                        {data?.eventType ==
                        Utils.getNotificationEventName().cameraOnline ? (
                          <TbDeviceComputerCamera size={30} />
                        ) : data?.eventType ==
                          Utils.getNotificationEventName().cameraOffline ? (
                          <TbDeviceComputerCameraOff
                            color={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--brand_white')}
                            size={30}
                          />
                        ) : (
                          <img
                            className="image-notification"
                            src={data?.imgUrl ? data?.imgUrl : brandLogo}
                            alt=""
                          ></img>
                        )}
                      </div>
                      <div className="type-layout">
                        <div
                          className={`bottom-noti-type ${
                            i == 1
                              ? 'vehicle'
                              : data?.eventType ==
                                Utils.getNotificationEventName().cameraOnline
                              ? ' camera-online'
                              : data?.eventType ==
                                  Utils.getNotificationEventName()
                                    .cameraOffline && ' camera-offline'
                          }`}
                        >
                          {data?.eventType ==
                          Utils.getNotificationEventName().vehicle ? (
                            <FaCarSide className="icon-person" />
                          ) : data?.eventType ==
                            Utils.getNotificationEventName().person ? (
                            <BsFillPeopleFill
                              className="icon-person"
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--primary_32')}
                            />
                          ) : data?.eventType ==
                            Utils.getNotificationEventName().motionStart ? (
                            <img src={motion} className="icon-person"></img>
                          ) : data?.eventType ==
                            Utils.getNotificationEventName().motionEnd ? (
                            <img src={motion} className="icon-person"></img>
                          ) : data?.eventType ==
                            Utils.getNotificationEventName().cameraOnline ? (
                            <img src={icontick} className="icon-events"></img>
                          ) : data?.eventType ==
                            Utils.getNotificationEventName().cameraOffline ? (
                            <PiWarning
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--error_48')}
                            />
                          ) : data?.eventType ==
                            Utils.getNotificationEventName().defocusStart ? (
                            <img src={motion} className="icon-person"></img>
                          ) : (
                            <TbDeviceComputerCamera size={30} />
                          )}
                        </div>
                      </div>

                      <div className="notification-text-layout">
                        <div className="header-text">
                          {getDeviceLocationName(data, true)}
                        </div>
                        <div className="title-text">
                          {getDeviceLocationName(data, false)}
                        </div>
                        <div className="title-sub-text">
                          {getEventDetectedDetail(data?.eventType)}
                        </div>
                      </div>
                    </div>
                    <div className="date-read-layout">
                      <div className="date-text">
                        {moment
                          .tz(moment(data?.eventTime), moment.tz.guess())
                          .format('hh:mm:ss A')}
                      </div>
                      {data?.notificationStatus === 0 && (
                        <div className="red-circle"></div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      );
    }
  };
  return (
    <div className="App">
      <Header />
      {showLoader ? (
        <SiteSpinner height="80px" width="80px" />
      ) : (
        <Container className='mw-100'>
          <Row className="justify-content-md-center mt-5">
            <Col lg="12">
              <div className="page-header">
                <Container className='mw-100'>
                  <Row>
                    <Col className="text-start">
                      <BreadcrumbList list={breadList} />
                    </Col>
                    <Col
                      md={6}
                      lg={6}
                      xl={6}
                      xs={12}
                      style={{ marginTop: -15 }}
                      className="text-end page-header-buttons"
                    >
                      {
                        <PrimaryButton
                          className="btn btn-primary-outline"
                          type="button"
                          width="auto"
                          borderWidth="1.5px"
                          hoverBorderWidth="1.5px"
                          color={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--primary_40')}
                          hoverColor={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--brand_white')}
                          backgroundColor="transparent"
                          onClick={() => navigate('/notificationsetting.html')}
                        >
                          {constants.NOTIFICATION_SETTINGS}
                        </PrimaryButton>
                      }
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="noti-dashboard-container">
                <div className="notification-container">
                  {notificationData && notificationData.length > 0 ? (
                    <div className="noti-item-data scroll-container">
                      <InfiniteScroll
                        loadMore={loadMore}
                        hasMore={hasMoreItems}
                        loader={<div className="loader"></div>}
                        useWindow={false}
                        threshold={1000}
                      >
                        {notificationData && showItems(notificationData)}
                      </InfiniteScroll>
                    </div>
                  ) : (
                    <div className="no-data-container">
                      <HiOutlineBell
                        size={50}
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--grayscale_32')}
                      />
                      <div className="no-result">
                        {constants.NOTIFICATION_NO_DATA_TITLE}
                      </div>
                      <div className="message-no-result">
                        {constants.NOTIFICATION_SETUP_NEW}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default NotificationDashboard;
