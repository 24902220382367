import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import RemoteVideoView from '../common/RemoteVideoView';
import '../../assets/css/timeline.scss';
import videoProcessing from '../../assets/images/cameras/videoProcessing.svg';
import { pause } from '../../assets/images';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscribeWithMQTT,
  publishWithMQTT,
  disconnectWithMQTT,
} from '../../utils/connection/mqttConnection';
import {
  getCDNInfo,
  getIsLiveViewEntitlementExist,
  getIsLiveViewEntitlementExpired,
  getLoadingStream,
  getMQTTConnection,
  getPlatformInfo,
  getRemoteStream,
  setLoadingStream,
  setRemoteStream,
} from '../../store/reducers/StreamingReducer';
import constants from '../../helpers/en';
import {
  getAccountId,
  getDeviceInformation,
} from '../../store/reducers/AccountReducer';
import { usePoliciesStore } from '../../store/policiesStore';
import { useIdleTimer } from 'react-idle-timer';
import { TbCertificate2Off } from 'react-icons/tb';
import { CiStreamOn } from 'react-icons/ci';
import { TbLoader } from 'react-icons/tb';
import { FiCameraOff } from 'react-icons/fi';
import useLicensesStore from '../../store/LicensesStore';

const Live = ({ handleClick, customer, page, isStopStreamClick = false }) => {
  const platformDetails = useSelector(getPlatformInfo);
  const deviceDetails = useSelector(getDeviceInformation);
  const remoteStream = useSelector(getRemoteStream);
  const isLiveViewEntitlementExist = useSelector(getIsLiveViewEntitlementExist);
  const isLiveViewEntitlementExpired = useSelector(
    getIsLiveViewEntitlementExpired
  );
  const loadingStream = useSelector(getLoadingStream);
  const mqttConnection = useSelector(getMQTTConnection);
  const accountId = useSelector(getAccountId);
  const dispatch = useDispatch();
  const { getLicenses } = useLicensesStore();
  const [activeLicenses, setActiveLicenses] = useState([...getLicenses(true)]);
  const [deviceStatus, setDeviceStatus] = useState({});
  const [streamStatus, setStreamStatus] = useState(false);
  const [state, setState] = useState('Active');
  const [remaining, setRemaining] = useState(0);
  const onIdle = () => setState('Idle');
  const onActive = () => setState('Active');
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    timeout: 3000,
    throttle: 500,
  });

  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    //TODO Delete Later
    setActiveLicenses([...getLicenses(true)]);
    const activeLicensesData = [...getLicenses(true)];
    let deviceStatus = {};
    let licenses =
      Array.isArray(activeLicensesData) &&
      activeLicensesData?.find(
        (licenses) => licenses.deviceId === deviceDetails?.deviceId
      );
    if (
      licenses?.licenseStatus?.toUpperCase() !== 'ACTIVE' ||
      !isLiveViewEntitlementExist ||
      isLiveViewEntitlementExpired
    ) {
      deviceStatus = {
        isStream: false,
        icon: (
          <TbCertificate2Off
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--greyscale_56'
            )}
            size={60}
          />
        ),
        label: constants.DEVICES_TAB_LICENSES_NO_LICENSE_ATTACHED,
      };
      setDeviceStatus(deviceStatus);
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  // FRED: I moved this commented blocked of code to the useEffect
  // above because the dependency array in the one below creates a
  // maximum depth rerender issue -- almost like an infinite loop.
  // Please delete this when everything is confirmed to be working
  // properly.
  // useEffect(() => {
  //   //TODO Delete Later
  //   setActiveLicenses([...getLicenses(true)]);
  //   const activeLicensesData = [...getLicenses(true)];
  //   let deviceStatus = {};
  //   let licenses =
  //     Array.isArray(activeLicensesData) &&
  //     activeLicensesData?.find(
  //       (licenses) => licenses.deviceId === deviceDetails?.deviceId
  //     );
  //   if (
  //     licenses?.licenseStatus?.toUpperCase() !== 'ACTIVE' ||
  //     !isLiveViewEntitlementExist ||
  //     isLiveViewEntitlementExpired
  //   ) {
  //     deviceStatus = {
  //       isStream: false,
  //       icon: (
  //         <TbCertificate2Off
  //           color={getComputedStyle(document.documentElement).getPropertyValue(
  //             '--greyscale_56'
  //           )}
  //           size={60}
  //         />
  //       ),
  //       label: constants.DEVICES_TAB_LICENSES_NO_LICENSE_ATTACHED,
  //     };
  //     setDeviceStatus(deviceStatus);
  //   }
  // }, [JSON.stringify(getLicenses())]);

  useEffect(() => {
    if (!streamStatus) {
      let deviceStatus = {};
      if (
        deviceDetails?.connectionStatus ===
          constants.DEVICES_ONLINE_CONNECTION_STATUS &&
        isLiveViewEntitlementExist &&
        !isLiveViewEntitlementExpired
      ) {
        setStreamStatus(true);
        publishWithMQTT(
          platformDetails.mqtt,
          platformDetails.p2p_server,
          deviceDetails,
          accountId
        );
        deviceStatus = {
          isStream: true,
        };
        setDeviceStatus(deviceStatus);
      }
    }
  }, [streamStatus]);

  useEffect(() => {
    dispatch(setRemoteStream(null));
    if (mqttConnection) {
      streamState();
    }
  }, [mqttConnection]);

  useEffect(() => {
    streamState();
  }, [deviceDetails]);

  const streamState = () => {
    let deviceStatus = {};
    if (deviceDetails?.properties?.restart === 'in-progress') {
      deviceStatus = {
        isStream: false,
        icon: (
          <CiStreamOn
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--greyscale_56'
            )}
            size={60}
          />
        ),
        label: constants.DEVICE_STATE_RESTARTING_MESSAGE,
      };
      setStreamStatus(false);
      setDeviceStatus(deviceStatus);
    } else if (
      deviceDetails?.properties?.['fw-update'] ===
      ('in-progress' || 'completed')
    ) {
      deviceStatus = {
        isStream: false,
        icon: (
          <TbLoader
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--greyscale_56'
            )}
            size={60}
          />
        ),
        label: constants.DEVICE_STATE_UPDATE_FIRMWARE,
      };
      setStreamStatus(false);
      setDeviceStatus(deviceStatus);
    } else if (
      deviceDetails?.connectionStatus ===
      constants.DEVICES_OFFLINE_CONNECTION_STATUS
    ) {
      if (
        deviceDetails?.properties?.restart !== 'in-progress' &&
        deviceDetails?.properties?.['fw-update'] !==
          ('in-progress' || 'completed')
      ) {
        deviceStatus = {
          isStream: false,
          icon: (
            <FiCameraOff
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--greyscale_56')}
              size={60}
            />
          ),
          label: constants.DEVICE_STATE_CAMERA_OFFLINE,
        };
        setStreamStatus(false);
        setDeviceStatus(deviceStatus);
      }
    } else if (deviceDetails?.properties?.['live-stream'] === 'stop') {
      setStreamStatus(false);
      deviceStatus = {
        isStream: false,
      };

      setDeviceStatus(deviceStatus);
    } else {
      deviceStatus = {
        isStream: true,
      };
      setDeviceStatus(deviceStatus);
    }
  };

  const pauseClick = () => {
    if (isStopStreamClick) return;
    disconnectWithMQTT();
    handleClick(constants.DEVICES_SWITCH_TIMELINE_TITLE);
  };

  return (
    <div
      className="wrapper-app"
      onClick={() => {
        getCustomerOrgPolicies()?.view_camera_history && pauseClick();
      }}
    >
      {deviceStatus.isStream ? (
        <div className="stream-timeline-wrapper device-setting">
          {page !== 'settings' && (
            <div
              className={`timeline-icons live-stream ${
                remaining === 0 && state === 'Idle' ? 'hide-controls' : ''
              }`}
            >
              <button className="live-button" onClick={() => pauseClick()}>
                <img src={pause} alt="pause icon" />
              </button>
            </div>
          )}
          <div
            className={`timeline-icons live ${
              remaining === 0 && state === 'Idle' ? 'hide-controls' : ''
            }`}
          >
            <button className="live-button">
              {constants.LIVE_STREAM_OVERLAY_LABEL}
            </button>
          </div>
          {remoteStream && (
            <RemoteVideoView
              deviceDetails={deviceDetails}
              remoteStream={remoteStream}
            />
          )}
          {loadingStream && (
            <div className="video-processing">
              <img src={videoProcessing} alt="video" />
            </div>
          )}
        </div>
      ) : (
        <div className="device-status-container">
          {deviceStatus.icon}
          <div className="title-status">{deviceStatus.label}</div>
        </div>
      )}
    </div>
  );
};

export default Live;
