import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ImageFetch from './ImageFetch';
import { People, CarFront } from 'react-bootstrap-icons';
import { Utils } from '../../../helpers';
import { vehicle } from '../../../assets/images';

import './EventsWrapper.scss';

const EventsWrapper = ({
  data,
  category,
  deviceId,
  cdnValue,
  timezone,
  selectedEvent,
  handleOnClick,
}) => {
  const [eventData, setEventData] = useState([]);
  useEffect(() => {
    let allData = [];
    data?.events?.map((insideEvent, i) => {
      insideEvent?.eventMeta?.events?.map((actualEvent, ij) => {
        actualEvent?.event?.map((newEvent, index) => {
          if (
            category.includes(newEvent.eventClass.toLowerCase()) &&
            insideEvent.deviceId === deviceId
            //insideEvent.deviceId === '8042f107-c4f5-4954-b943-3ba1d140037a'
          ) {
            const eData = {
              id: `${i}-${ij}-${index}`,
              class: newEvent.eventClass,
              timestamp: insideEvent?.eventTimestamp,
            };
            allData.push(eData);
          }
        });
      });
    });
    allData.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
    setEventData(allData);
  }, [data]);

  return (
    <div
      key={`scrollable-area-${deviceId}`}
      className="event-all-category-scrollable-area"
    >
      {eventData?.map((event, i) => {
        if (event) {
          return (
            <div
              key={`event-${event.timestamp}-${deviceId}-${i}`}
              className={`${
                selectedEvent === event.timestamp ||
                selectedEvent ===
                  Utils.getUnixDate(Utils.getDate(event.timestamp / 1000)) *
                    1000
                  ? 'selectedEvent'
                  : ''
              } event-wrapper-list`}
              onClick={() => {
                handleOnClick(event.timestamp);
              }}
            >
              <div className="event-list-image">
                <ImageFetch
                  time={event?.timestamp}
                  cdnValue={cdnValue}
                  deviceId={deviceId}
                />
              </div>
              <div className="event-list-content">
                {event.class === 'person' ? (
                  <div className="event-image">
                    <People height="16" width="16" />
                  </div>
                ) : (
                  <div className="event-image">
                    <img src={vehicle} height="16" width="16" />
                  </div>
                )}
                <div className="event-name">{event.class}</div>
                <div className="event-timestamp">
                  {moment
                    .tz(moment(event.timestamp), timezone)
                    .format('hh:mm:ss A z')}
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default EventsWrapper;
