import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { Loader, PrimaryButton } from "../../../components/common";
import { Formik, Form } from "formik";
import { ModalSelect, TextField } from "../../../components/forms";
import * as Yup from "yup";
import { useContext, useEffect, useState, useRef } from "react";
import { DetailsContext } from "./DetailsContextProvider";
import { DataContext } from "./DataContextProvider";
import { ReactComponent as SearchIcon } from "../../../assets/images/searchIcon.svg";
import { step1Icon, ccimg } from "../../../assets/images";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { constants, Utils } from "../../../helpers";
import { QrReader } from "react-qr-reader";
import { useTranslation } from "react-i18next";
import { RiErrorWarningLine } from "react-icons/ri";


const One = (props) => {
  const { t } = useTranslation();
  const [showLoader, setShowLoader] = useState(false);
  const { datavalues } = useContext(DataContext);
  const { setDatavalues } = useContext(DataContext);
  const scollToRef = useRef();
  useEffect(() => {
    fetchData();
  }, []);

  const regexValid =
    /^[0-9a-f]{1,2}([\.:-])(?:[0-9a-f]{1,2}\1){4}[0-9a-f]{1,2}$/gim;

  const validate = Yup.object({
    serialNo: Yup.string().required(constants.ADD_DEVICE_SERIAL_REQUIRED),
    macAddress: Yup.string()
      .matches(regexValid, {
        message: constants.ADD_DEVICE_MAC_ADDRESS_INVALID,
      })
      .required(constants.ADD_DEVICE_MAC_REQUIRED),
    manufacturer: Yup.string().required(
      constants.ADD_DEVICE_MANUFACTURER_REQUIRED
    ),
    model: Yup.string().required(constants.ADD_DEVICE_MODEL_REQUIRED),
  });

  const { info, setInfo } = useContext(DetailsContext);

  // Manufacturer and model Details
  const [data, setdata] = useState([]);
  const [manufactureData, setManufatureData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [modelListValues, setModelListValues] = useState([]);
  const [errormsg, setErrormsg] = useState([]);
  const [disabledModel, setDisabledModel] = useState(true);
  const [isStepDetailComplete, setIsStepDetailComplete] = useState(false);
  const [selectedTab, setSelectedTab] = useState("qrcode");
  const [serialNumberDMSFlag, setSerialNumberDMSFlag] = useState(false);

  const fetchData = async () => {
    await axios
      .get("/device/manufacturerSkuBanks", Utils.requestHeader())
      .then((response) => {
        const resultData = response.data;
        const responseData = resultData.data;
        if (resultData?.meta?.code === 200) {
          // const responseMeta = resultData.meta;
          const responseData = resultData.data;
          setdata(responseData);
          const manufactureList = [];
          manufactureList.push({
            label: constants.ADD_DEVICE_MANUFRACTURE_NAME,
            value: constants.ADD_DEVICE_MANUFRACTURE_NAME_TECHWIN,
          });
          setManufatureData(manufactureList);
        } else {
            setdata([]);
            setManufatureData([]);
        }
        //  to get model values
        const newmodelList = [];
        for (let index = 0; index < responseData?.length; index++) {
          const validModel = newmodelList.filter(
            (man) =>
              man.label.toLowerCase() ===
              responseData[index].model.toLowerCase()
          );

          if (validModel.length === 0) {
            newmodelList.push({
              label: responseData[index].model,
              value: responseData[index].model,
            });
          }
        }
        const sortedModelList = newmodelList.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        setModelListValues(sortedModelList);
      });
  };

  // Values from QR
  const qrValidator = (qrValues) => {
    if (qrValues !== 0) {
      const manufacturerValue = qrValues[4];
      let modelValue;
      if (qrValues[0]?.includes('/')) {
        const splitModalValue = qrValues[0]?.split('/');
        modelValue = splitModalValue[0];
      } else {
        modelValue = qrValues[0];
      }
      const macAddressValue = qrValues[2];
      if (
        data?.find(
          (o) =>
            ((o.manufacturer.toUpperCase() === manufacturerValue?.toUpperCase()) || (constants.ADD_DEVICE_MANUFRACTURE_NAME === manufacturerValue?.toUpperCase()))
        )
      ) {
        data?.forEach((manu) => {
          if (
            ((manu.manufacturer.toUpperCase() === manufacturerValue.toUpperCase()) || (constants.ADD_DEVICE_MANUFRACTURE_NAME ===manufacturerValue?.toUpperCase())) &&
            manu.model.toUpperCase() === modelValue.toUpperCase()
          ) {
            qrValues.push(manu.imageURL);
            if (regexValid.test(macAddressValue)) {
              setDatavalues(qrValues);
              const details = {
                macAddress: qrValues[2],
                manufacturer: qrValues[4],
                model: qrValues[0],
                serialNo: qrValues[1],
                channelCount: manu.channelCount,
                deviceType: manu.deviceType,
                imageURL : manu.imageURL,
              }
              setInfo(details);
              props.passValueToTwo(details);
              props.nextstepEvent();
              scollToRef.current.scrollIntoView();
            } else {
              setErrormsg(constants.INVALID_MAC_ADDRESS);
            }
          } else {
            Utils.replaceStringValues(
              t("VALIDATION_MESSAGE"),
              "$device",
              `${qrValues[4]} ${qrValues[0]}`
            );
          }
        });
        if (modelListValues.find((o) => o.label === modelValue)) {
          if (regexValid.test(macAddressValue)) {
            props.nextstepEvent();
          } else {
            setErrormsg(constants.INVALID_MAC_ADDRESS);
          }
        }
      } else {
        setErrormsg(
          Utils.replaceStringValues(
            t("VALIDATION_MESSAGE"),
            "$device",
            `${qrValues[4]} ${qrValues[0]}`
          )
        );
      }
    }
  };
  // to find model for select options
  const modelList = [];
  const manufactureHandler = (e, setFieldValue) => {
    let selectedValue = e.target && e.target.value ? e.target.value : e;
    setFieldValue(
      constants.DEVICES_MANUFACTURER_TEXT.toLowerCase(),
      selectedValue
    );
    const selectedManufacturer = selectedValue.toLowerCase();
    const selectedManufacturerList = data?.filter(
      (cur) =>
        cur.manufacturer.toLowerCase() === selectedManufacturer ||
        cur?.manufacturer === constants.ADD_DEVICE_MANUFRACTURE_NAME
    );
    for (let index = 0; index < selectedManufacturerList.length; index++) {
      modelList.push({
        label: selectedManufacturerList[index].model.toUpperCase(),
        value: selectedManufacturerList[index].model.toUpperCase(),
        channelCount: selectedManufacturerList[index].channelCount,
        deviceType: selectedManufacturerList[index].deviceType,
        manufacturer: selectedManufacturerList[index].manufacturer,
        imageURL : selectedManufacturerList[index].imageURL,
      });
    }
    setDisabledModel(false);
    const sortedModelList = modelList.sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    setModelData(sortedModelList);
  };

  const modalHandler = (e, setFieldValue) => {
    const selectedValue = e.target && e.target.value ? e.target.value : e;
    const selectedItem = modelData.find((item) => item.value === selectedValue);
    setFieldValue("modelManufacturer", selectedItem?.manufacturer);
    setFieldValue("channelCount", selectedItem?.channelCount || 1);
    setFieldValue("deviceType", selectedItem?.deviceType);
    setFieldValue("model", selectedValue);
  };

  const macAddressChange = (e, setFieldValue) => {
    setFieldValue(
      "macAddress",
      Utils.formatMACAddress(e.target.value.toUpperCase())
    );
  };

  const Continue = (e) => {
    e.preventDefault();
    props.nextstepEvent();
  };

  const reEnterHandler = () => {
    const newdata = [0];
    setErrormsg([]);
    setDatavalues(newdata);
    setIsStepDetailComplete(false);
    scollToRef.current.scrollIntoView();
  };

  const handleChangeSerialNumber = (e, setFieldValue) => {
    const inputValue = e.target.value;
    if (inputValue.toLowerCase().startsWith("dms")) {
      setSerialNumberDMSFlag(true);
      setFieldValue(
        "macAddress",
        Utils.formatMACAddress("00:00:00:00:00:00".toUpperCase())
      );
      manufactureHandler("HANWHA VISION", setFieldValue);
      modalHandler("DM-SERVER", setFieldValue);
      setFieldValue('serialNo',inputValue);
    } else {
      setSerialNumberDMSFlag(false);
    }
  };

  const headerText = !isStepDetailComplete
    ? constants.DEVICES_STEP_ONE_SCAN_CONTENT
    : constants.CONFIRM_DEVICE_INFORMATION;
  return (
    <div ref={scollToRef} className="ctn-box innner">
      {showLoader && <Loader className="white-background" />}
      <div id={"headerText"} className="header-view">
        <div className="titlename-header">
          {!isStepDetailComplete ? "" : constants.DEVICES_TAB_DEVICE_INFO}
        </div>
      </div>

      <div className="subtext">{headerText}</div>
      <div className="stepIcon">
        <img src={step1Icon} alt="step1Icon" />
      </div>
      <div className="steptext">{constants.DEVICES_STEP_ONE_OF_THREE}</div>
      {!isStepDetailComplete ? (
        <div>
          <Formik
            initialValues={{
              serialNo: "",
              macAddress: "",
              manufacturer: "",
              model: "",
            }}
            validationSchema={validate}
            onSubmit={(values) => {
              if (!values?.deviceType) {
                const selectedItem = modelData.find(
                  (item) => item.value === values?.model
                );
                values = {
                  ...values,
                  deviceType: selectedItem?.deviceType,
                  modelManufacturer: selectedItem?.manufacturer,
                };
              }
              const selectedModalData = modelData.find(
                (item) => item.value === values?.model
              );
              setInfo(values);
              props.nextstepEvent();
              props.passValueToTwo(selectedModalData);
              scollToRef.current.scrollIntoView();
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              dirty,
              resetForm,
              eventKey,
            }) => (
              <Form className="modal-form" onSubmit={handleSubmit}>
                <Tabs
                  defaultActiveKey="qrcode"
                  className="mb-3 tabs-container"
                  onSelect={(e) => {
                    setSelectedTab(e);
                    resetForm();
                  }}
                >
                  <Tab
                    eventKey="qrcode"
                    title={constants.ADD_DEVICE_SCAN_QR_CODE}
                  >
                    {selectedTab === "qrcode" && (
                      <>
                        <div className="qr-scan-wrapper">
                          {data?.length > 0 && (
                            <div>
                              <div className="titlename">
                                {constants.SCAN_QR_CODE}
                              </div>
                              <div className="subtext mb-3">
                                {constants.HOLD_CAMERA_LABEL}
                              </div>
                              <div className="qr-holder">
                                <div className="qr-block-1 text-box">
                                  <div className="qr-box">
                                    <div className="qr-size">
                                      <QrReader
                                        scanDelay={500}
                                        onResult={(result, error) => {
                                          if (!!result) {
                                            qrValidator(
                                              result?.text?.split(";")
                                            );
                                            if (!!error) {
                                              console.info(error);
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                  {/* <Row className="justify-content-end mobile">
                     <Col className="text-end">
                      <PrimaryButton
                        className="adddevice-btn btn2 addDeviceMobileButton"
                        fontSize="14px"
                        height="44px"
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--brand_white")}
                        width="auto"
                        type="submit"
                        disabled={!(dirty && isValid)}
                      >
                        {constants.DEVICES_CONTINUE_TEXT}
                      </PrimaryButton>
                    </Col>
                  </Row> */}
                        </div>
                     <div className="qr-block-1">
                          <div className="error">{errormsg}</div>
                        </div>
                      </>
                    )}
                  </Tab>
                  <Tab eventKey="manual" title={constants.ADD_DEVICE_MANUAL}>
                    <div className="titlename">
                      {constants.DEVICES_ENTER_INFORMATION}
                    </div>
                    <div className="subtext mb-3">
                      {constants.DEVICES_STEP1_INFO}
                    </div>
                    {selectedTab === "manual" && (
                      <>
                        <div>
                          <TextField
                            id="serial-"
                            placeholder={constants.DEVICES_SERIAL_NO_TEXT}
                            name="serialNo"
                            type="text"
                            removebottommargin="true"
                            removetopmargin="true"
                            beforeinputicon={<SearchIcon />}
                            value={values.serialnumber}
                            onChange={(e) => {
                              handleChange(e);
                              handleChangeSerialNumber(e, setFieldValue);
                            }}
                            onPaste={(e) => {
                              handleChange(e);
                              handleChangeSerialNumber(e, setFieldValue);
                            }}
                          />
                        </div>
                        <div>
                          <TextField
                            placeholder={constants.DEVICES_MAC_ADDRESS_TEXT}
                            name="macAddress"
                            type="text"
                            removebottommargin="true"
                            removetopmargin="true"
                            value={values.macAddress}
                            disabled={serialNumberDMSFlag}
                            onChange={(e) => macAddressChange(e, setFieldValue)}
                          />
                        </div>
                        <div className="mac-address-formate">
                          <RiErrorWarningLine/>
                          {constants.MAC_ADDRESS_FORMATE}
                        </div>
                        <div>
                          <ModalSelect
                            name="manufacturer"
                            as={constants.DEVICES_SELECT_MANUFACTURER_TEXT}
                            selectplaceholder={
                              constants.DEVICES_SELECT_MANUFACTURER_TEXT
                            }
                            disabled={serialNumberDMSFlag}
                            onChange={(e) =>
                              manufactureHandler(e, setFieldValue)
                            }
                            options={manufactureData}
                          />
                        </div>
                        <div>
                          <ModalSelect
                            name="model"
                            as={constants.DEVICES_SELECT_MODEL_TEXT}
                            selectplaceholder={
                              constants.DEVICES_SELECT_MODEL_TEXT
                            }
                            options={modelData}
                            disabled={disabledModel || serialNumberDMSFlag}
                            onChange={(e) => modalHandler(e, setFieldValue)}
                          />
                        </div>
                      </>
                    )}
                  </Tab>
                </Tabs>

                {selectedTab !== "qrcode" && (
                  <Row className="justify-content-end">
                    <Col className="text-end">
                      <PrimaryButton
                        className="adddevice-btn btn2 addDeviceMobileButton btn-primary"
                        fontSize="14px"
                        height="44px"
                        width="auto"
                        type="submit"
                        disabled={serialNumberDMSFlag ? false : !(dirty && isValid)}
                      >
                        {constants.DEVICES_CONTINUE_TEXT}
                      </PrimaryButton>
                    </Col>
                  </Row>
                )}
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div>
          <div className="qr-block">
            <div className="qr-image">
              <img
                src={datavalues[6] ? datavalues[6] : ccimg}
                alt="Device"
                className="qr-image"
              />
            </div>
          </div>
          <div className="subtext3">{constants.DEVICES_SERIAL_NO_TEXT}</div>
          <div className="subtext2">{info.serialNo || datavalues[1]}</div>
          <div className="subtext3">{constants.DEVICES_MAC_ADDRESS_TEXT}</div>
          <div className="subtext2">{info.macAddress || datavalues[2]}</div>
          <div className="subtext3">{constants.DEVICES_MANUFACTURER_TEXT}</div>
          <div className="subtext2">{info.manufacturer || datavalues[4]}</div>
          <div className="subtext3">{constants.DEVICES_MODEL_TEXT}</div>
          <div className="subtext2">{info.model || datavalues[0]}</div>
          <div
            onClick={reEnterHandler}
            style={{
              color: getComputedStyle(
                document.documentElement
              ).getPropertyValue("--primary_40"),
              cursor: "pointer",
              marginBottom: "12px",
            }}
            className="subtext2"
          >
            {constants.DEVICES_STEP2_RE_INFO}
          </div>
          <Row className="justify-content-end">
            <Col className="text-end">
              <PrimaryButton
                className="adddevice-btn btn2"
                fontSize="14px"
                backgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--primary_40")}
                height="44px"
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--brand_white")}
                width="95px"
                onClick={Continue}
              >
                {constants.DEVICES_CONTINUE_TEXT}
              </PrimaryButton>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default One;
