import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { HiOutlineX } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { Header } from '../../components/common';
import { Utils, constants } from '../../helpers';
import { useOrganizations } from '../../store/OrganizationsStore';
import timezones from '../../data/support/timezone.json';
import {
  getAccountId,
  // getAllDevicesData,
  getDeviceInformation,
  setDeviceInformation,
} from '../../store/reducers/AccountReducer';
import {
  getPlatformInfo,
  setCDNInfo,
  setLiveCameraIds,
  setLiveStream,
  setLoadingStream,
  setMetaData,
  setRemoteStream,
  setStartDate,
} from '../../store/reducers/StreamingReducer';
import PageWrapper from '../PageWrapper';
import TimelineControls from '../../components/streaming/TimelineControls';
import {
  disconnectWithMQTT,
  mqttPublish,
  mqttSubscribe,
  mqttUnsubscribe,
  publishWithMQTT,
} from '../../utils/connection/mqttConnection';
import EventsList from './EventsList';
import useEventsStore from '../../store/EventsStore';

import CameraItemNoZoom from './CameraItemNoZoom';
import './CameraDashboard.scss';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import {
  getLatestEventsForDots,
  getMetaDataForEvents,
  resetEvents,
  setLatestEventsForDots,
  setMetaDataForEvents,
} from '../../store/reducers/EventsReducer';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import {
  getAllDevicesData,
} from '../../store/AccountStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

const CameraDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let fullscreenRef = useRef(0);

  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [show, setShow] = useState(null);
  const [date, setDate] = useState(null);
  const [cdnInfo, setCdnInfo] = useState(location?.state?.cdnInfo || {});
  const [internalEventFromSearch, setInternalEventFromSearch] = useState(false);
  const [timezone, setTimezone] = useState(moment.tz.guess());
  const [categoryDashboard, setCategoryDashboard] = useState([]);
  const [eventTimestamp, setEventTimestamp] = useState(null);
  const [listHeight, setListHeight] = useState('100%');
  const deviceId = location?.state?.id;
  const deviceDetails = useSelector(getDeviceInformation);
  // const devicesDetails = useSelector(getAllDevicesData);
  const [devicesDetails, setDevicesDetails] = useState([]);
  const platformDetails = useSelector(getPlatformInfo);
  const handle = useFullScreenHandle();
  const { setDeviceTimezone } = useEventsStore();
  const dispatch = useDispatch();
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  const { getEventCVRMode } = useEventsStore();
  const eventCategory = useEventsStore((state) => state.eventCategory);
  const selectedEvent = useEventsStore((state) => state.selectedTimestamp);
  const deviceTimezone = useEventsStore((state) => state.deviceTimezone);
  const eventFromSearch = useEventsStore((state) => state.eventFromSearch);
  // const orgDetails = getCustomerOrgData()[0];
  const [orgDetails, setOrgDetails] = useState();

  const { getState } = devicesMQTTStore;
  const state = getState();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );

  const accountId = loggedInUserData.accountId;

  const eventsData = useSelector(getLatestEventsForDots);

  const deviceMetaData = useSelector(getMetaDataForEvents);

  const { setSelectedEventStore } = useEventsStore();

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    const fetchDevices = async () => {
      const devices = await getAllDevicesData();
      setDevicesDetails(devices);
    };
    fetchDevices();
  }, []);

  const handleClick = (detail) => {
    if (detail === constants.DEVICES_SWITCH_TIMELINE_TITLE) {
      setShow(constants.DEVICES_SWITCH_TIMELINE_TITLE);
    } else if (detail === constants.DEVICES_SWITCH_LIVE_TITLE) {
      dispatch(setRemoteStream(null));
      publishWithMQTT(
        platformDetails.mqtt,
        platformDetails.p2p_server,
        deviceDetails,
        accountId
      );
    }
  };

  useEffect(() => {
    const handleResize = () => {
      let fullscreenEl = fullscreenRef.current;

      if (!fullscreenEl) {
        return;
      }

      setListHeight(
        fullscreenEl.querySelector('.fullscreen')?.clientHeight + 'px'
      );
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    dispatch(setLiveCameraIds([]));

    return () => {
      window.removeEventListener('resize', handleResize);
      disconnectWithMQTT();
      setSelectedEventStore('null');
    };
  }, []);

  useEffect(() => {
    setInternalEventFromSearch(eventFromSearch);
  }, [eventFromSearch]);

  useEffect(() => {
    if (devicesDetails?.length > 0 && deviceId) {
      const deviceIndex = devicesDetails?.find(
        (device) => device.deviceId === deviceId
      );
      dispatch(setDeviceInformation(deviceIndex));
      dispatch(setLoadingStream(true));
      dispatch(setLiveStream(null));
      dispatch(setRemoteStream(null));
      dispatch(setLatestEventsForDots(null));
      dispatch(setMetaDataForEvents(null));
      dispatch(setMetaData(null));
      //handleClick('LIVE');
      //subscribeRequest(deviceIndex?.areaId);

      if (state.getAccountId() !== accountId) {
        state.setAccountId(accountId);
      }

      if (!state.getSessionId()) {
        state.setSessionId(uuidv4());
      }

      const subscribeForMetaData = {
        topic: `b/streams/${deviceId}`,
        qos: 0,
      };

      const subscribeForEvents = {
        topic: `d/rt-events/${deviceIndex?.areaId}`,
        qos: 0,
      };

      // Subscribe to the app topic
      mqttSubscribe(subscribeForMetaData);
      // Subscribe  to the device topic
      mqttSubscribe(subscribeForEvents);

      return () => {
        mqttUnsubscribe(subscribeForMetaData);
        mqttUnsubscribe(subscribeForEvents);
        dispatch(resetEvents);
        disconnectWithMQTT();
      };
    }
  }, [deviceId, JSON.stringify(devicesDetails)]);

  useEffect(() => {
    axios
      .get(`/partner/orgs/${orgDetails?.orgId}/cdn`, {
        withCredentials: true,
        ...Utils.requestHeader(),
      })
      .then((response) => {
        if (response?.data?.data) {
          dispatch(setCDNInfo(response?.data?.data?.cdn));
        }
      });
  }, [orgDetails?.orgId]);

  useEffect(() => {
    if (deviceDetails) {
      const url = deviceDetails?.entitlement?.url;

      fetch(url, {
        withCredentials: true,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            const expiryDate = data?.term?.expiry;
            const durationInDays = data?.term?.data?.durationInDays;
            const startDate =
              expiryDate - (durationInDays + 1) * 24 * 60 * 60 * 1000;
            setDate(startDate);
            dispatch(setStartDate(startDate));
          }
        });
    }
    setTimezone(deviceDetails?.properties?.timezone);
    const getLocationSelected = timezones?.data?.find(
      (zone) => zone.value === deviceDetails?.properties?.timezone
    );
    const zone = getLocationSelected?.location || moment.tz.guess();
    setDeviceTimezone(zone);
  }, [deviceDetails, deviceDetails?.properties?.timezone]);

  useEffect(() => {
    setCategoryDashboard(eventCategory);
  }, [eventCategory]);

  useEffect(() => {
    setEventTimestamp(selectedEvent);
  }, [selectedEvent]);

  return (
    <div className="App video-wall">
      <Header showCart={false} />
      <div className="main-wrapper">
        <div className="video-wall-background">
          <div className="custom-container-fluid">
            <div className="page-header mt-4">
              <Row>
                <div className="toast-wrapper">
                  <div>
                    <div className="device-title">
                      {deviceDetails?.deviceName}
                    </div>
                    <div className="device-sub-title">
                      {deviceDetails?.locationAreaNames}
                    </div>
                  </div>
                  <div className="button-nav">
                    <Button
                      className="button-nav-item close-button"
                      onClick={() => navigate(`/cameras/video-wall.html`)}
                    >
                      <HiOutlineX size={16} className="close-icon" />{' '}
                      {constants.CLOSE_BUTTON}
                    </Button>
                  </div>
                </div>
              </Row>
              <div className="cameras-container video-detail-wrapper">
                <Row className="devices-row">
                  <Col md={9} lg={9} xl={9} xs={12} ref={fullscreenRef}>
                    <FullScreen handle={handle}>
                      <div className="timeline-controls-main-container">
                        {deviceId && (
                          <TimelineControls
                            internalEventFromSearch={eventFromSearch}
                            category={categoryDashboard}
                            eventTimestamp={selectedEvent}
                            eventCVRMode={getEventCVRMode()}
                            timezone={timezone}
                            startDate={date}
                            deviceId={deviceId}
                            enterFullScreen={() => handle.enter()}
                            exitFullScreen={() => handle.exit()}
                            handleClick={handleClick}
                            customMetaData={deviceMetaData}
                            eventDotsData={eventsData}
                          />
                        )}
                      </div>
                    </FullScreen>
                  </Col>
                  <Col
                    className="events-list-panel"
                    md={3}
                    lg={3}
                    xl={3}
                    xs={12}
                  >
                    <div className="events-list-wrapper">
                      <div className="event-list-container">
                        <EventsList
                          key={deviceId}
                          deviceId={deviceId}
                          listHeight={listHeight}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CameraDashboard;
