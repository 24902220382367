import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import axios from 'axios';
import { Utils, constants } from '../helpers';

export const useCart = create(
  persist(
    (set, get) => ({
      products: [],
      licenses: [],
      cart: [],
      order: {},
      isOrderPending: false,
      orgId: null,

      getProducts: async (route) => {
        if (!route) return;

        const res = await axios.get(
          route,
          Utils.requestHeader()
        );
        const response = res?.data;
        let result = { status: 'failure', msg: 'Unknown internal API error' };

        // Set products if request is successful
        if (response?.meta?.code === 200) {
          set({
            products: [...response?.data.products],
          });
          result = { status: 'success', msg: 'products retrieved`' };
        } else {
          if (res?.code) {
            result = { status: 'failure', msg: `${res.code}: ${res.message}` };
          } else if (response?.data) {
            result = { status: 'failure', msg: response?.data?.userMsg };
          }
        }
        return result;
      },

      resetProducts: () =>
        set((state) => {
          return {
            ...state,
            products: [],
          };
        }),

      getCart: () => {
        return get().cart;
      },

      addToCart: (sku) =>
        set((state) => {
          const isPresent = state.cart.find((product) => product.sku === sku);

          // New item
          if (!isPresent) {
            const newProduct = state.products.find(
              (product) => product.sku === sku
            );

            if (newProduct) {
              return {
                ...state,
                cart: [
                  ...state.cart,
                  {
                    sku: newProduct.sku,
                    productName: newProduct.productName,
                    displayName:
                      newProduct.displayName || newProduct.productName,
                    productDescription: newProduct.productDescription,
                    price: newProduct?.price,
                    discountedPrice: newProduct?.discountedPrice,
                    total: newProduct?.discountedPrice,
                    quantity: 1,
                  },
                ],
              };
            }
          }

          const updatedCart = state.cart.map((product) =>
            product.sku === sku
              ? {
                  ...product,
                  quantity: product.quantity + 1,
                  total: product?.discountedPrice * (product.quantity + 1),
                }
              : product
          );

          return {
            ...state,
            cart: updatedCart,
          };
        }),

      removeFromCart: (sku) =>
        set((state) => {
          const isPresent = state.cart.findIndex(
            (product) => product.sku === sku
          );

          if (isPresent === -1) {
            return {
              ...state,
            };
          }

          const updatedCart = state.cart
            .map((product) =>
              product.sku === sku
                ? {
                    ...product,
                    quantity: Math.max(product.quantity - 1, 0),
                    total:
                      product?.discountedPrice *
                      Math.max(product.quantity - 1, 0),
                  }
                : product
            )
            .filter((product) => product.quantity);

          return {
            ...state,
            cart: updatedCart,
          };
        }),

      deleteFromCart: (sku) =>
        set((state) => {
          let deleteIndex = state.cart.findIndex((item) => item.sku === sku);

          const updatedCart = state.cart;

          if (deleteIndex !== -1) {
            updatedCart.splice(deleteIndex, 1);
          }

          return {
            ...state,
            cart: [...updatedCart],
          };
        }),

      getCartItemQuantity: (sku) => {
        return get().cart.find((item) => item.sku === sku) || { quantity: 0 };
      },

      getCartItemCost: (sku) => {
        let cartItem = get().cart.find((item) => item.sku === sku);

        if (!cartItem) return 0;

        return parseFloat(cartItem.discountedPrice * cartItem.quantity).toFixed(
          2
        );
      },

      getCartTotalQuantity: () => {
        return get().cart.reduce((prev, current) => prev + current.quantity, 0);
      },

      getCartTotalCost: () => {
        const totalCost = get().cart.reduce((prev, current) => {
          const item = get().cart.find((f) => f.sku === current.sku);

          const itemCost = item.discountedPrice * current.quantity;

          return prev + itemCost;
        }, 0);

        return parseFloat(totalCost).toFixed(2);
      },

      deleteCart: () => {
        set({ cart: [] });
      },

      setOrder: (newOrder) => {
        if (!newOrder) return;

        set({
          order: newOrder,
        });
      },

      setOrderPending: (boolValue) => {
        set({ isOrderPending: boolValue });
      },

      deleteOrder: () => {
        set({ order: {} });
      },

      setOrgId: (orgId) => {
        if (!orgId) return;

        set({ orgId: orgId });
      },

      getOrgId: () => {
        return get().orgId;
      },

      deleteOrgId: () => {
        set({ orgId: null });
      },

      getLicenses: async (route) => {
        if (!route) return;

        const res = await axios.get(
          route,
          Utils.requestHeader()
        );
        const response = res?.data;
        let result = { status: 'failure', msg: 'Unknown internal API error' };

        // Set licenses if request is successful
        if (response?.meta?.code === 200) {
          set({
            licenses: [
              ...response?.data.filter((respData) => respData?.offeringType !== constants.LICENSE_ONBOARDING_STATUS),
            ],
          });
          result = { status: 'success', msg: 'licenses retrieved`' };
        } else {
          if (res?.code) {
            result = { status: 'failure', msg: `${res.code}: ${res.message}` };
          } else if (response?.data) {
            result = { status: 'failure', msg: response?.data?.userMsg };
          }
        }
        return result;
      },
    }),
    {
      name: 'net.duclo.vms.cart',
      version: '1.0',
    }
  )
);
