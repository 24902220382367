import { HiOutlineMapPin } from 'react-icons/hi2';
import { HiChevronRight } from 'react-icons/hi2';

const AssignLocations = ({ label, clickHandler, classes, ...props }) => {
  return (
    <div className={`${classes} assign-location-wrap`}>
      <div className="check-location-wrap">
        {label && (
          <div className="check-location-label d-felx flex-row">
            <div
              className={`icon ${
                props?.hideIcon?.toString() === 'true' ? 'd-none' : ''
              }`}
            >
              <HiOutlineMapPin />
            </div>
            <div>{label}</div>
          </div>
        )}
        <div className="check-location-btn">
          <HiChevronRight onClick={clickHandler} />
        </div>
      </div>
    </div>
  );
};

export default AssignLocations;
