import React, { useEffect, useRef } from 'react';
import 'moment-timezone';
import '../../assets/css/timeline.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMQTTConnection,
  getPlatformInfo,
  getRemoteStream,
  getRemoteStreamAudio,
  setRemoteStream,
  setRemoteStreamAudio,
  setIsRemoteStreamPlay,
} from '../../store/reducers/StreamingReducer';
import { publishWithMQTT } from '../../utils/connection/mqttConnection';
import {
  getAccountId,
  getDeviceInformation,
} from '../../store/reducers/AccountReducer';

const TimelineControlsLive = ({ muteAudio }) => {
  const remoteStream = useSelector(getRemoteStream);
  const remoteStreamAudio = useSelector(getRemoteStreamAudio);
  const deviceDetails = useSelector(getDeviceInformation);
  const mqttConnection = useSelector(getMQTTConnection);
  const accountId = useSelector(getAccountId);
  const platformDetails = useSelector(getPlatformInfo);

  const dispatch = useDispatch();
  const remoteVideoRef = useRef();
  const remoteAudioRef = useRef();

  useEffect(() => {
    dispatch(setRemoteStream(null));
    dispatch(setIsRemoteStreamPlay(false));
    dispatch(setRemoteStreamAudio(null));
    if (mqttConnection) {
      console.log('publixh called from here');
      publishWithMQTT(
        platformDetails.mqtt,
        platformDetails.p2p_server,
        deviceDetails,
        accountId
      );
    }
  }, [mqttConnection]);

  useEffect(() => {
    const setStreamPlay = () => {
      dispatch(setIsRemoteStreamPlay(true));
    };
    if (remoteStream?.active) {
      const remoteVideo = remoteVideoRef.current;
      remoteVideo.srcObject = remoteStream;
      remoteVideoRef.current.addEventListener('play', setStreamPlay);
    }
    return () => {
      remoteVideoRef?.current?.removeEventListener('play', setStreamPlay);
    };
  }, [remoteStream]);

  useEffect(() => {
    try {
      if (remoteStreamAudio && remoteStreamAudio !== null) {
        const remoteAudio = remoteAudioRef.current;
        remoteAudio.srcObject = remoteStreamAudio;
      }
    } catch (error) {}
  }, [remoteStreamAudio]);

  return (
    remoteStream?.active && (
      <div className="remote-view-wrapper">
        <video
          id="video"
          width="764"
          height="450"
          ref={remoteVideoRef}
          autoPlay={true}
          playsInline={true}
        />
        <audio
          id="audio"
          ref={remoteAudioRef}
          autoPlay={true}
          playsInline={true}
          controls={false}
          muted={muteAudio}
        ></audio>
        <canvas id="canvas" width="764" height="450"></canvas>
      </div>
    )
  );
};

export default TimelineControlsLive;
